import React, { useState, useEffect } from "react";
import { Row, Col } from "reactstrap";
import api from "services/api";
import MUIDataTable from "mui-datatables";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from "@chakra-ui/react";
import Notiflix from "notiflix";
import IdentificationValidationUpdate from "views/updateFicheValidation/IdentificationValidationUpdate";

const IdentificationValidation = () => {
  const openResume = useDisclosure();
  const [idRow, setIdRow] = useState();
  const [ficheId, setFicheId] = useState();
  const [childId, setChildId] = useState();
  const [dataToVerify, setDataToVerify] = useState();

  const updateData = dataToVerify?.filter((item, index) => index === idRow);
  const dataToModify = updateData?.shift();

  //recuperation du role du user connecté
  const loggedUserRole = JSON.parse(localStorage.getItem("data")).roles[0];
  const loggedUserBureau = JSON.parse(localStorage.getItem("data")).bureau;

  //mui dataTables
  const columns = [
    { label: "Code", name: "id" },
    { label: "Prenom", name: "enfant.prenom" },
    { label: "Nom", name: "enfant.nom" },
    { label: "Surnom", name: "enfant.surnom" },
    { label: "Sexe", name: "enfant.sexe", display: false },
    {
      label: "Date de naissance",
      name: "enfant.ddn",
      options: {
        display: false,
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          const index = tableMeta.rowData.findIndex((el) => el?.length === 24);
          return <span>{tableMeta.rowData[index]?.substring(0, 10)} </span>;
        },
      },
    },
    {
      label: "Pays d'origine",
      name: "enfant.pays_origine",
      options: { display: false },
    },
    {
      label: "Region d'origine",
      name: "enfant.region_origine",
      options: { display: false },
    },
    {
      label: "Ville ou village d'origine",
      name: "enfant.village_ville_origine",
      options: { display: false },
    },
    {
      label: "Nom du pere",
      name: "enfant.nom_pere",
      options: { display: false },
    },
    {
      label: "Profession du pere",
      name: "enfant.profession_pere",
      options: { display: false },
    },
    {
      label: "Adresse du pere",
      name: "enfant.adresse_pere",
      options: { display: false },
    },
    {
      label: "Telephone du pere",
      name: "enfant.tel_pere",
      options: { display: false },
    },
    {
      label: "Nom de la mere",
      name: "enfant.nom_mere",
      options: { display: false },
    },
    {
      label: "Profession de la mere",
      name: "enfant.profession_mere",
      options: { display: false },
    },
    {
      label: "Adresse de la mere",
      name: "enfant.adresse_mere",
      options: { display: false },
    },
    {
      label: "Telephone de la mere",
      name: "enfant.tel_mere",
      options: { display: false },
    },
    {
      label: "Rang de l'enfant",
      name: "enfant.rang_enfant",
      options: { display: false },
    },

    {
      label: "Adresse du tuteur",
      name: "adresse_tuteur",
      options: { display: false },
    },
    {
      label: "Auteur abus  ou viol",
      name: "auteurs_abus_viol",
      options: { display: false },
    },
    {
      label: "Comportement",
      name: "comportements",
      options: { display: false },
    },
    {
      label: "Condition d'arrivee",
      name: "condition_arrivee",
      options: { display: false },
    },
    {
      label: "Date d'arrivee dans le pays",
      name: "date_arrivee_pays",
      options: {
        display: false,
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          const index = tableMeta.rowData.findIndex((el) => el?.length === 24);
          return <span>{tableMeta.rowData[index]?.substring(0, 10)} </span>;
        },
      },
    },
    {
      label: "Date d'identification",
      name: "date_identification",
      options: {
        display: false,
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          const index = tableMeta.rowData.findIndex((el) => el?.length === 24);
          return <span>{tableMeta.rowData[index]?.substring(0, 10)} </span>;
        },
      },
    },
    {
      label: "Date remplissage",
      name: "date_remplissage",
      options: {
        display: false,
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          const index = tableMeta.rowData.findIndex((el) => el?.length === 24);
          return <span>{tableMeta.rowData[index]?.substring(0, 10)} </span>;
        },
      },
    },
    { label: "Dependances", name: "dependances", options: { display: false } },
    { label: "Durée peine", name: "duree_peine", options: { display: false } },
    {
      label: "Etat sante physique",
      name: "etat_sante_physique",
      options: { display: false },
    },
    {
      label: "Lien avec l'adulte",
      name: "lien_avec_adulte",
      options: { display: false },
    },
    {
      label: "Lien parente tuteur",
      name: "lien_parente_tuteur",
      options: { display: false },
    },
    {
      label: "Localite d'identification",
      name: "localite_identification",
      options: { display: false },
    },
    {
      label: "Mode de saisine du cas de l'enfant",
      name: "mode_saisine_du_cas_de_lenfant",
      options: { display: false },
    },
    {
      label: "Motif depart milieu vie habituel",
      name: "motif_depart_milieu_vie_habituel",
      options: { display: false },
    },
    {
      label: "Nature rapport",
      name: "nature_rapport",
      options: { display: false },
    },
    {
      label: "Niveau d'instruction de l'enfant",
      name: "niveau_instruction_enfant",
      options: { display: false },
    },
    { label: "Nom du tuteur", name: "nom_tuteur", options: { display: false } },
    {
      label: "Pays d'identification",
      name: "pays_identification",
      options: { display: false },
    },
    {
      label: "Précision du niveau",
      name: "précision_niveau",
      options: { display: false },
    },
    {
      label: "Region d'identification",
      name: "region_identification",
      options: { display: false },
    },
    {
      label: "Resume de la situation de l'enfant",
      name: "resume_situation_enfant",
      options: { display: false },
    },
    {
      label: "Situation matrimoniale des parents",
      name: "situation_matrimoniale_parents",
      options: { display: false },
    },
    {
      label: "Situation de la mere",
      name: "situation_mere",
      options: { display: false },
    },
    {
      label: "Situation du pere",
      name: "situation_pere",
      options: { display: false },
    },
    { label: "Telephone", name: "tel", options: { display: false } },
    {
      label: "Telephone du tuteur",
      name: "tel_tuteur",
      options: { display: false },
    },
    {
      label: "Type lieu hebergement",
      name: "type_lieu_hebergement",
      options: { display: false },
    },
    {
      label: "Vulnerabilite enfant",
      name: "vulnerabilite_enfant",
      options: { display: false },
    },

    {
      label: "Bureau du gestionnaire de cas",
      name: "enfant.bureau",
      options: { display: false },
    },
    { label: "Gestionnaire de cas", name: "gestionnaire_cas" },
    { label: "Telephone", name: "tel" },
    { label: "Email", name: "email" },
    {
      name: "statut",
      label: "Statut",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const index = tableMeta.rowData.findIndex(
            (el) => el === "VALIDER" || el === "EN ATTENTE"
          );
          if (tableMeta.rowData[index] === "VALIDER") {
            return (
              <span style={{ color: "green" }}>
                {tableMeta.rowData[index]}{" "}
              </span>
            );
          }
          if (tableMeta.rowData[index] === "EN ATTENTE") {
            return (
              <span style={{ color: "red" }}>{tableMeta.rowData[index]} </span>
            );
          }
        },
      },
    },
    {
      name: "Action",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          const _statut = tableMeta.rowData.find(
            (el) => el === loggedUserBureau
          );

          return (
            <>
              <button
                disabled={
                  (loggedUserRole === "ROLE_RESPONSABLE" &&
                    _statut === loggedUserBureau) ||
                  loggedUserRole === "ROLE_ADMIN"
                    ? false
                    : true
                }
                onClick={() => {
                  openResume.onOpen();
                  setIdRow(tableMeta.rowIndex);
                  setChildId(tableMeta[0]?.enfantId);
                  setFicheId(tableMeta.rowData[0]);
                }}
                style={{
                  background: "orange",
                  color: "#fff",
                  fontSize: "11px",
                  padding: "3px",
                  borderRadius: "3px",
                }}
              >
                Verifier
              </button>
              <button
                disabled={true ? loggedUserRole !== "ROLE_ADMIN" : false}
                onClick={() => confirmDelete(tableMeta.rowData[0])}
                style={{
                  background: "red",
                  color: "#fff",
                  fontSize: "11px",
                  padding: " 3px",
                  borderRadius: "3px",
                  marginLeft: "5px",
                }}
              >
                Supprimer
              </button>
            </>
          );
        },
      },
    },
  ];

  const options = {
    filterType: "textField",
    pagination: false,
    print: false,
    enableNestedDataAccess: ".",
    download: false,
  };

  const confirmDelete = (id) => {
    Notiflix.Confirm.show(
      " ",
      "Etes-vous sûr de vouloir supprimer ?",
      "Confirmer",
      "Annuler",
      () => {
        api
          .deleteIdentification(id)
          .then((response) => {
            Notiflix.Notify.success("supprimé !!");
            window.location.reload();
          })
          .catch((error) => console.log(error));
      }
    );
  };

  useEffect(() => {
    api
      .getIdentificationPendingStatus()
      .then((response) => {
        if (response) {
          setDataToVerify(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <div className="content">
      <Modal
        onClose={openResume.onClose}
        size={"full"}
        isOpen={openResume.isOpen}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody>
            <IdentificationValidationUpdate
              ficheId={ficheId}
              childId={childId}
              dataToVerify={dataToModify}
              onclose={openResume.onClose}
            />
          </ModalBody>
        </ModalContent>
      </Modal>{" "}
      <Row style={{ background: "#fff", padding: "20px" }}>
        <Col md="12">
          <div>
            {" "}
            <h3
              style={{
                fontSize: "20px",
                margin: "0 0 17px",
                fontWeight: "bolder",
              }}
            >
              Tableau identification en attente
            </h3>
          </div>
          <MUIDataTable
            data={dataToVerify}
            columns={columns}
            options={options}
          />
        </Col>
      </Row>
    </div>
  );
};

export default IdentificationValidation;
