import React, { useState, useEffect } from "react";
import { Input, Textarea } from "@chakra-ui/react";
import api from "services/api";
import Notiflix from "notiflix";
import { Button } from "reactstrap";

const RetourEnFamilleValidationUpdate = ({
  ficheId,
  onclose,
  dataToVerify,
  idRow,
}) => {
  const [dataToUpdate, setDataToUpdate] = useState();
  const [inputValue, setInputValue] = useState({
    id: ficheId,
  });
  const updateData = dataToVerify[idRow];
  const [checkboxValue, setCheckboxValue] = useState();
  console.log(updateData);
  const handleChange = (event) => {
    setInputValue({
      ...inputValue,
      [event.target.name]: event.target.value,
    });
    setDataToUpdate("");
  };

  const handleCheckbox = (event) => {
    setCheckboxValue({
      ...checkboxValue,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    api
      .updateRetourEnFamilleVerification({ inputValue, checkboxValue })
      .then((response) => {
        if (response) {
          console.log(response);
          Notiflix.Notify.success("Données validées  ");
          window.location.reload(true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    setDataToUpdate(updateData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="checkbox-container">
      <form
        onSubmit={handleSubmit}
        className="dateprise"
        style={{ display: "flex", flexDirection: "column" }}
      >
        <div
          className="form-container"
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div className="form-container-block" style={{ width: "50%" }}>
            <div>
              <h3 style={{ fontSize: "13px", fontWeight: "bold" }}>
                {" "}
                Recherche famille réaliser par :
              </h3>
              <Input
                type="text"
                onChange={handleChange}
                name="recherche_famille_realise_par"
                value={dataToUpdate?.recherche_famille_realise_par}
                placeholder="...."
                style={{
                  border: "none",
                  background: "lightgray",
                  paddingLeft: "5px",
                }}
              />
            </div>
            <div>
              <h3 style={{ fontSize: "13px", fontWeight: "bold" }}>
                {" "}
                Date de la recherche:
              </h3>
              <Input
                type="date"
                required
                name="recherche_famille_date"
                onChange={handleChange}
                value={dataToUpdate?.recherche_famille_date?.substring(0, 10)}
                style={{
                  border: "none",
                  background: "lightgray",
                  paddingLeft: "5px",
                }}
                placeholder="...."
              />
            </div>
            <div>
              <h3 style={{ fontSize: "13px", fontWeight: "bold" }}>
                Structure ayant fait la recherche famille:
              </h3>
              <Input
                name="recherche_famille_structure"
                onChange={handleChange}
                value={dataToUpdate?.recherche_famille_structure}
                type="text"
                placeholder="...."
                style={{
                  border: "none",
                  background: "lightgray",
                  paddingLeft: "5px",
                }}
              />
            </div>
            <div>
              <h3 style={{ fontSize: "13px", fontWeight: "bold" }}>
                Localité recherche : {""}
              </h3>
              <h3 style={{ fontSize: "13px", fontWeight: "bolder" }}>
                {" "}
                Ville :
              </h3>
              <Input
                name="ville"
                value={dataToUpdate?.ville}
                onChange={handleChange}
                type="text"
                placeholder="...."
                style={{
                  border: "none",
                  background: "lightgray",
                  paddingLeft: "5px",
                }}
              />
              <h3 style={{ fontSize: "13px", fontWeight: "bolder" }}>
                Village/quartier :
              </h3>
              <Input
                name="village_quartier"
                value={dataToUpdate?.village_quartier}
                type="text"
                onChange={handleChange}
                placeholder="...."
                style={{
                  border: "none",
                  background: "lightgray",
                  paddingLeft: "5px",
                }}
              />
              <h3 style={{ fontSize: "13px", fontWeight: "bolder" }}>
                Region :
              </h3>
              <Input
                name="region"
                onChange={handleChange}
                type="text"
                value={dataToUpdate?.region}
                placeholder="...."
                style={{
                  border: "none",
                  background: "lightgray",
                  paddingLeft: "5px",
                }}
              />

              <div style={{ marginTop: "5px", display: "inline-block" }}>
                <h3 style={{ fontSize: "13px", fontWeight: "bolder" }}>
                  Departement :
                </h3>
                <Input
                  name="departement"
                  type="text"
                  onChange={handleChange}
                  value={dataToUpdate?.departement}
                  placeholder="...."
                  style={{
                    border: "none",
                    background: "lightgray",
                    paddingLeft: "5px",
                  }}
                />
                <h3 style={{ fontSize: "13px", fontWeight: "bolder" }}>
                  {" "}
                  Pays :
                </h3>
                <Input
                  name="pays"
                  onChange={handleChange}
                  type="text"
                  placeholder="...."
                  value={dataToUpdate?.pays}
                  style={{
                    border: "none",
                    background: "lightgray",
                    paddingLeft: "5px",
                  }}
                />
              </div>
            </div>
            <div>
              <h3 style={{ fontSize: "13px", fontWeight: "bold" }}>
                Personne(s) contactée(s) maximum trois personnes: {""}
              </h3>
              <div style={{ display: "flex", marginBottom: "5px" }}>
                Lien parenté: <br />
                <Input
                  name="lien_parente_enfant1"
                  onChange={handleChange}
                  type="text"
                  value={dataToUpdate?.lien_parente_enfant1}
                  placeholder="...."
                  style={{
                    border: "none",
                    background: "lightgray",
                    paddingLeft: "5px",
                  }}
                />{" "}
                Adresse : {""}{" "}
                <Input
                  name="adresse1"
                  value={dataToUpdate?.adresse1}
                  type="text"
                  placeholder="...."
                  onChange={handleChange}
                  style={{
                    border: "none",
                    background: "lightgray",
                    paddingLeft: "5px",
                  }}
                />{" "}
                Téléphone: {""}{" "}
                <Input
                  name="telephone1"
                  value={dataToUpdate?.telephone1}
                  onChange={handleChange}
                  type="tel"
                  placeholder="...."
                  style={{
                    border: "none",
                    background: "lightgray",
                    paddingLeft: "5px",
                  }}
                />
              </div>
              <div style={{ display: "flex", marginBottom: "5px" }}>
                Lien parenté :
                <Input
                  name="lien_parente_enfant2"
                  value={dataToUpdate?.lien_parente_enfant2}
                  type="text"
                  onChange={handleChange}
                  placeholder="...."
                  style={{
                    border: "none",
                    paddingLeft: "5px",
                    background: "lightgray",
                  }}
                />{" "}
                Adresse : {""}{" "}
                <Input
                  name="adresse2"
                  value={dataToUpdate?.adresse2}
                  type="text"
                  placeholder="...."
                  onChange={handleChange}
                  style={{
                    border: "none",
                    background: "lightgray",
                    paddingLeft: "5px",
                  }}
                />{" "}
                Téléphone: {""}{" "}
                <Input
                  name="telephone2"
                  value={dataToUpdate?.telephone2}
                  type="tel"
                  placeholder="...."
                  onChange={handleChange}
                  style={{
                    border: "none",
                    background: "lightgray",
                    paddingLeft: "5px",
                  }}
                />
              </div>
              <div style={{ display: "flex" }}>
                Lien parenté : <br />
                <Input
                  name="lien_parente_enfant3"
                  value={dataToUpdate?.lien_parente_enfant3}
                  type="text"
                  placeholder="...."
                  onChange={handleChange}
                  style={{
                    border: "none",
                    background: "lightgray",
                    paddingLeft: "5px",
                  }}
                />{" "}
                Adresse : {""}{" "}
                <Input
                  name="adresse3"
                  value={dataToUpdate?.adresse3}
                  type="text"
                  placeholder="...."
                  onChange={handleChange}
                  style={{
                    border: "none",
                    background: "lightgray",
                    paddingLeft: "5px",
                  }}
                />{" "}
                Téléphone: {""}{" "}
                <Input
                  value={dataToUpdate?.telephone3}
                  name="telephone3"
                  type="tel"
                  placeholder="...."
                  onChange={handleChange}
                  style={{
                    border: "none",
                    background: "lightgray",
                    paddingLeft: "5px",
                  }}
                />
              </div>
            </div>

            <div>
              <h3 style={{ fontSize: "13px", fontWeight: "bold" }}>
                Retour en famille réaliser par :
              </h3>
              <Input
                name="retour_realiser_par"
                value={dataToUpdate?.retour_realiser_par}
                type="text"
                onChange={handleChange}
                placeholder="...."
                style={{
                  border: "none",
                  background: "lightgray",
                  paddingLeft: "5px",
                }}
              />
            </div>
            <div>
              <h3 style={{ fontSize: "13px", fontWeight: "bold" }}>
                Personne ayant reçu en famille :
              </h3>
              Nom
              <Input
                name="personne_ayant_recu_enfant"
                value={dataToUpdate?.personne_ayant_recu_enfant}
                type="text"
                placeholder="...."
                onChange={handleChange}
                style={{
                  border: "none",
                  background: "lightgray",
                  paddingLeft: "5px",
                }}
              />
            </div>
            <div>
              <h3 style={{ fontSize: "13px" }}>Téléphone :</h3>
              <Input
                name="telephone_personne_ayant_recu_enfant"
                value={dataToUpdate?.telephone_personne_ayant_recu_enfant}
                type="text"
                placeholder="...."
                onChange={handleChange}
                style={{
                  border: "none",
                  background: "lightgray",
                  paddingLeft: "5px",
                }}
              />
            </div>
            <div>
              <h3 style={{ fontSize: "13px" }}>Adresse :</h3>
              <Input
                name="adresse_personne_ayant_recu_enfant"
                type="text"
                onChange={handleChange}
                placeholder="...."
                value={dataToUpdate?.adresse_personne_ayant_recu_enfant}
                style={{
                  border: "none",
                  background: "lightgray",
                  paddingLeft: "5px",
                }}
              />
            </div>
            <div>
              <h3 style={{ fontSize: "13px" }}>CIN :</h3>
              <Input
                name="cin_personne_ayant_recu_enfant"
                value={dataToUpdate?.cin_personne_ayant_recu_enfant}
                type="text"
                placeholder="...."
                onChange={handleChange}
                style={{
                  border: "none",
                  background: "lightgray",
                  paddingLeft: "5px",
                }}
              />
            </div>
            <div>
              <h3 style={{ fontSize: "13px", fontWeight: "bold" }}>
                Reintégration : <br />
                <span style={{ background: "yellow", fontWeight: "normal" }}>
                  {dataToUpdate?.retour_reintegration}{" "}
                </span>
              </h3>
              <input
                onChange={handleCheckbox}
                type="radio"
                value="Familiale"
                name="retour_reintegration"
              />
              Familiale
              <input
                type="radio"
                value="Professionnelle"
                name="retour_reintegration"
                onChange={handleCheckbox}
              />
              Professionnelle
              <input
                type="radio"
                value="Scolaire"
                name="retour_reintegration"
                onChange={handleCheckbox}
              />
              Scolaire
              <input
                type="radio"
                value=" Pas encore d'activités"
                name="retour_reintegration"
                onChange={handleCheckbox}
              />
              Pas encore d'activités
            </div>
            <div>
              <h3 style={{ fontSize: "13px", fontWeight: "bold" }}>
                Important à signaler
              </h3>
              <Input
                name="retour_a_signaler"
                value={dataToUpdate?.retour_a_signaler}
                type="text"
                placeholder="...."
                onChange={handleChange}
                style={{
                  border: "none",
                  background: "lightgray",
                  paddingLeft: "5px",
                }}
              />
            </div>
            <div>
              <h3 style={{ fontSize: "13px", fontWeight: "bold" }}>
                Date retour en famille :{" "}
              </h3>
              <Input
                name="retour_famille_date"
                onChange={handleChange}
                value={dataToUpdate?.retour_famille_date?.substring(0, 10)}
                type="date"
                required
                style={{
                  border: "none",
                  background: "lightgray",
                  paddingLeft: "5px",
                }}
              />
            </div>
            <div>
              <h3 style={{ fontSize: "13px", fontWeight: "bold" }}>
                Structure en charge du retour en famille :{" "}
              </h3>
              <Input
                name="retour_structure"
                value={dataToUpdate?.retour_structure}
                type="text"
                onChange={handleChange}
                placeholder="...."
                style={{
                  border: "none",
                  background: "lightgray",
                  paddingLeft: "5px",
                }}
              />
            </div>
            <div>
              <h3 style={{ fontSize: "13px", fontWeight: "bold" }}>Lieu : </h3>
              <Input
                name="retour_lieu"
                value={dataToUpdate?.retour_lieu}
                type="text"
                placeholder="...."
                onChange={handleChange}
                style={{
                  border: "none",
                  background: "lightgray",
                  paddingLeft: "5px",
                }}
              />
            </div>
          </div>
          <div
            className="form-container-block"
            style={{ width: "50%", marginLeft: "3rem" }}
          >
            <span>
              <h3 style={{ fontSize: "13px", fontWeight: "bold" }}>
                Relation entre enfant et famille : <br />
                <span style={{ background: "yellow", fontWeight: "normal" }}>
                  {dataToUpdate?.relation_enfant_famille}{" "}
                </span>
              </h3>
              <div>
                {" "}
                <input
                  type="radio"
                  onChange={handleCheckbox}
                  name="relation_enfant_famille"
                  value="Oui"
                />
                Oui{" "}
              </div>
              <div>
                {" "}
                <input
                  onChange={handleCheckbox}
                  type="radio"
                  name="relation_enfant_famille"
                  value="Non"
                />
                Non{" "}
              </div>
            </span>
            <h3 style={{ fontSize: "13px", fontWeight: "bold" }}>
              Pourquoi il n'y a pas de relation entre enfant et famille
            </h3>

            <Textarea
              maxRows={4}
              name="relation_enfant_famille_pourquoi"
              aria-label="maximum height"
              onChange={handleChange}
              value={dataToUpdate?.relation_enfant_famille_pourquoi}
              placeholder="precisez le pourquoi ici..."
              style={{
                width: 200,
                border: "none",
                background: "lightgray",
                paddingLeft: "5px",
              }}
            />
            <div>
              <h3 style={{ fontSize: "13px", fontWeight: "bold" }}>
                Ressource famille : <br />
                <span style={{ background: "yellow", fontWeight: "normal" }}>
                  {dataToUpdate?.ressources_famille_enfant}{" "}
                </span>
              </h3>
              <div>
                <span>
                  {" "}
                  <input
                    type="radio"
                    onChange={handleCheckbox}
                    name="ressources_famille_enfant"
                    value="Salarié"
                  />
                  Salarié{" "}
                </span>
                <span>
                  {" "}
                  <input
                    type="radio"
                    onChange={handleCheckbox}
                    name="ressources_famille_enfant"
                    value="Retraité"
                  />{" "}
                  Retraité{" "}
                </span>
                <span>
                  {" "}
                  <input
                    type="radio"
                    onChange={handleCheckbox}
                    name="ressources_famille_enfant"
                    value="Travailleur agricole"
                  />{" "}
                  Travailleur agricole{" "}
                </span>
                <span>
                  {" "}
                  <br />
                  <h3 style={{ fontSize: "13px", fontWeight: "bolder" }}>
                    Travailleur indépendant (Autre source des ressources)
                  </h3>
                  <Input
                    value={dataToUpdate?.ressource_famille_enfant_specifiez}
                    onChange={handleChange}
                    name="ressource_famille_enfant_specifiez"
                    type="text"
                    placeholder="spécifier ici..."
                    style={{
                      border: "none",
                      background: "lightgray",
                      paddingLeft: "5px",
                    }}
                  />
                </span>
              </div>
            </div>
            <div>
              <h3 style={{ fontSize: "13px", fontWeight: "bold" }}>
                Les parents acceptent-ils de reprendre l'enfant : <br />
                <span style={{ background: "yellow", fontWeight: "normal" }}>
                  {dataToUpdate?.reprendre_enfant}{" "}
                </span>
              </h3>
              <div>
                {" "}
                <input
                  onChange={handleCheckbox}
                  type="radio"
                  value="Oui"
                  name="reprendre_enfant"
                />
                Oui
              </div>
              <div>
                {" "}
                <input
                  onChange={handleCheckbox}
                  type="radio"
                  value="Non"
                  name="reprendre_enfant"
                />
                Non
              </div>{" "}
              <h3 style={{ fontSize: "13px", fontWeight: "bold" }}>
                {" "}
                Pourquoi les parents ne reprennent il pas l'enfant : <br />
              </h3>{" "}
              <Textarea
                maxRows={4}
                name="reprendre_enfant_pourquoi"
                onChange={handleChange}
                value={dataToUpdate?.reprendre_enfant_pourquoi}
                aria-label="maximum height"
                placeholder="..."
                style={{
                  width: 200,
                  border: "none",
                  background: "lightgray",
                  paddingLeft: "5px",
                }}
              />
            </div>
            <span>
              <h3 style={{ fontSize: "13px", fontWeight: "bold" }}>
                Conclusion evaluation : <br />
                <span style={{ background: "yellow", fontWeight: "normal" }}>
                  {dataToUpdate?.conclusion_evaluation}{" "}
                </span>
              </h3>
              <div>
                {" "}
                <input
                  type="radio"
                  onChange={handleCheckbox}
                  name="conclusion_evaluation"
                  value="Réintégration avec un risque trés élevé"
                />
                Réintégration avec un risque trés élevé{" "}
              </div>
              <div>
                {" "}
                <input
                  type="radio"
                  onChange={handleCheckbox}
                  name="conclusion_evaluation"
                  value="Réintégration avec un risque élevé"
                />
                Réintégration avec un risque élevé
              </div>{" "}
              <div>
                {" "}
                <input
                  type="radio"
                  onChange={handleCheckbox}
                  name="conclusion_evaluation"
                  value="Réintégration avec un risque moyennement élevé"
                />
                Réintégration avec un risque moyennement élevé
              </div>
              <div>
                {" "}
                <input
                  type="radio"
                  onChange={handleCheckbox}
                  name="conclusion_evaluation"
                  value="Réintégration sans risque élevé"
                />
                Réintégration sans risque élevé
              </div>
            </span>
          </div>
        </div>{" "}
        <div
          style={{
            display: "flex",
            flexDirection: "row-reverse",
            marginTop: "3rem",
          }}
        >
          <div className="sbmit-btn">
            <Button
              onClick={() => onclose()}
              style={{ marginRight: "1rem", background: "red" }}
              className="mb-4"
              variant="contained"
              color="primary"
              type="button"
            >
              Annuler
            </Button>
          </div>
          <div className="sbmit-btn">
            <Button
              style={{ marginRight: "1rem", background: "green" }}
              className="mb-4"
              variant="contained"
              color="primary"
              type="submit"
            >
              valider{" "}
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default RetourEnFamilleValidationUpdate;
