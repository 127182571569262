import React from 'react';
import Notiflix from 'notiflix';
import './login.scss';
import { Input } from '@chakra-ui/react';
import child from '../assets/img/child.jpg';
import enda from '../assets/img/enda.jpeg';
import { withRouter } from 'react-router-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';
import api from '../services/api';
const validationSchema = yup.object({
  username: yup.string().required('Ce champ ne peut pas etre vide !'),
  password: yup
    .string()

    .required('Ce champ ne peut pas etre vide ! '),
});
const Login = (props) => {
  const onSubmit = async (values) => {
    // console.log(values);

    api
      .login(values)
      .then((response) => {
        if (response) {
          console.log(response.data);
          localStorage.setItem('data', JSON.stringify(response.data));
          const token = response.data.accessToken;
          localStorage.setItem('token', token);
          document.location.href = '/admin/dashboard';
        }
      })
      .catch((error) => {
        if (error) {
          Notiflix.Notify.warning(error?.response?.data?.message);
        }
      });
  };

  const formik = useFormik({
    initialValues: { username: '', password: '' },
    validateOnBlur: true,
    onSubmit,
    validationSchema: validationSchema,
  });

  return (
    <div className="login-top-container">
      <div className="div-container">
        <div className="block">
          <div className="enda-logo">
            {' '}
            <img src={enda} alt=""></img>{' '}
          </div>
          <div className="login-form">
            <h3>Se Connecter</h3>
            <p>Veuillez vous connecter pour acceder à la platform</p>
            <div className="formulaire">
              <form onSubmit={formik.handleSubmit}>
                <Input
                  style={{ background: '#e0f4e1' }}
                  type="text"
                  name="username"
                  placeholder="username"
                  value={formik.values.username}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <div style={{ color: 'red' }}>
                  {formik.touched.username && formik.errors.username
                    ? formik.errors.username
                    : ''}
                </div>
                <Input
                  style={{ background: '#e0f4e1' }}
                  type="password"
                  name="password"
                  placeholder="mot de passe"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <div style={{ color: 'red' }}>
                  {formik.touched.password && formik.errors.password
                    ? formik.errors.password
                    : ''}
                </div>
                <p>Mot de passe oublié ?</p>
                <button
                  style={{ background: '#16b84e' }}
                  type="submit"
                  disabled={!formik.isValid}
                >
                  Se Connecter
                </button>
              </form>
            </div>
          </div>
        </div>
        <div className="block-picture">
          <img src={child} alt="" />
        </div>
      </div>
    </div>
  );
};

export default withRouter(Login);
