import React, { useState, useEffect } from "react";
import { Button } from "@material-ui/core";
import api from "services/api";
import Notiflix from "notiflix";
import { Input, Textarea } from "@chakra-ui/react";
const UpdateSuivi = ({ listSuivi, idRow }) => {
  const updateData = listSuivi.suivi[idRow];
  console.log(updateData);
  const [inputValue, setInputValue] = useState({
    id: updateData?.id,
  });
  const [checkboxValue, setCheckboxValue] = useState();
  const [dataToUpdate, setDataToUpdate] = useState();

  const handleChange = (event) => {
    setInputValue({
      ...inputValue,
      [event.target.name]: event.target.value,
    });
    setDataToUpdate("");
  };
  const handleCheckbox = (event) => {
    setCheckboxValue({
      ...checkboxValue,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    api
      .updateSuivi({ inputValue, checkboxValue })
      .then((response) => {
        if (response) {
          console.log(response);
          Notiflix.Notify.success("Données Modifiées  ");
          window.location.reload(true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    setDataToUpdate(updateData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="checkbox-container">
      <div className="dateprise" style={{ marginBottom: "2rem" }}>
        <form
          onSubmit={handleSubmit}
          className="form-container"
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div className="form-container-block">
            <div>
              <div>
                {" "}
                <h3 style={{ fontSize: "13px", fontWeight: "bolder" }}>
                  Date Suivi :{" "}
                </h3>
                <Input
                  name="Date_suivi"
                  onChange={handleChange}
                  value={dataToUpdate?.Date_suivi?.substring(0, 10)}
                  type="date"
                  style={{
                    border: "none",
                    background: "lightgray",
                  }}
                />
              </div>
              <h3
                style={{
                  fontSize: "13px",
                  margin: "10px 0 0",
                  fontWeight: "bold",
                }}
              >
                Situation actuelle de l’enfant : <br />{" "}
                <span style={{ background: "yellow", fontWeight: "normal" }}>
                  {dataToUpdate?.situation_actuelle_enfant}{" "}
                </span>
              </h3>
              <span>
                {" "}
                <input
                  value="En famille"
                  onChange={handleCheckbox}
                  name="situation_actuelle_enfant"
                  type="radio"
                  style={{ marginTop: "5px", marginRight: "3px" }}
                />
                En famille
              </span>
              <span>
                {" "}
                <input
                  value="A l’école"
                  onChange={handleCheckbox}
                  name="situation_actuelle_enfant"
                  type="radio"
                  style={{ marginTop: "5px", marginRight: "3px" }}
                />
                A l’école
              </span>
              <span>
                {" "}
                <input
                  name="situation_actuelle_enfant"
                  onChange={handleCheckbox}
                  value="Métier"
                  type="radio"
                  style={{ marginTop: "5px", marginRight: "3px" }}
                />
                Métier
              </span>
              <span>
                {" "}
                <input
                  value="Fugue"
                  onChange={handleCheckbox}
                  name="situation_actuelle_enfant"
                  type="radio"
                  style={{ marginTop: "5px", marginRight: "3px" }}
                />{" "}
                Fugue
              </span>
              <span>
                {" "}
                <input
                  value="retour dans rue"
                  name="situation_actuelle_enfant"
                  onChange={handleCheckbox}
                  type="radio"
                  style={{ marginTop: "5px", marginRight: "3px" }}
                />{" "}
                retour dans rue
              </span>
              <span>
                {" "}
                <input
                  value="destination inconnue"
                  onChange={handleCheckbox}
                  name="situation_actuelle_enfant"
                  type="radio"
                  style={{ marginTop: "5px", marginRight: "3px" }}
                />
                destination inconnue
              </span>{" "}
              <br />
              <span>
                {" "}
                <input
                  value="retour dans sa situation de départ"
                  type="radio"
                  onChange={handleCheckbox}
                  name="situation_actuelle_enfant"
                  style={{ marginTop: "5px", marginRight: "3px" }}
                />
                retour dans sa situation de départ
              </span>
            </div>

            <div>
              {" "}
              <h3 style={{ fontSize: "13px", fontWeight: "bold" }}>
                {" "}
                Nature des relations avec les parents : <br />{" "}
                <span style={{ background: "yellow", fontWeight: "normal" }}>
                  {dataToUpdate?.nature_relation_parent}{" "}
                </span>
              </h3>
              <span>
                {" "}
                <input
                  type="radio"
                  value="Bonne"
                  onChange={handleCheckbox}
                  name="nature_relation_parent"
                  style={{ marginTop: "5px", marginRight: "3px" }}
                />
                Bonne{" "}
              </span>
              <span>
                {" "}
                <input
                  type="radio"
                  value="conflictuelle"
                  onChange={handleCheckbox}
                  name="nature_relation_parent"
                  style={{ marginTop: "5px", marginRight: "3px" }}
                />
                conflictuelle
              </span>
              <span>
                {" "}
                <br />
                <h3 style={{ fontSize: "13px", fontWeight: "bolder" }}>
                  Autres :
                </h3>
                <Input
                  name="nature_relation_parent"
                  onChange={handleChange}
                  type="text"
                  placeholder="expliquez ici.."
                  style={{
                    border: "none",
                    background: "lightgray",
                  }}
                />
              </span>
            </div>
            <div>
              {" "}
              <h3 style={{ fontSize: "13px", fontWeight: "bold" }}>
                {" "}
                Nature des relations avec la fratrie : <br />{" "}
                <span style={{ background: "yellow", fontWeight: "normal" }}>
                  {dataToUpdate?.nature_relation_fratrie}{" "}
                </span>
              </h3>
              <span>
                {" "}
                <input
                  type="radio"
                  onChange={handleCheckbox}
                  value="Bonne"
                  name="nature_relation_fratrie"
                  style={{ marginTop: "5px", marginRight: "3px" }}
                />
                Bonne{" "}
              </span>
              <span>
                {" "}
                <input
                  value="conflictuelle"
                  onChange={handleCheckbox}
                  name="nature_relation_fratrie"
                  type="radio"
                  style={{ marginTop: "5px", marginRight: "3px" }}
                />{" "}
                conflictuelle{" "}
              </span>
              <span>
                {" "}
                <br />
                <h3 style={{ fontSize: "13px", fontWeight: "bolder" }}>
                  Autres :
                </h3>
                <Input
                  name="nature_relation_fratrie"
                  onChange={handleChange}
                  type="text"
                  placeholder="expliquez ici.."
                  style={{
                    border: "none",
                    background: "lightgray",
                  }}
                />
              </span>
            </div>
            <div>
              {" "}
              <h3 style={{ fontSize: "13px", fontWeight: "bold" }}>
                {" "}
                Nature des relations avec le voisinage :<br />{" "}
                <span style={{ background: "yellow", fontWeight: "normal" }}>
                  {dataToUpdate?.nature_relation_voisinage}{" "}
                </span>
              </h3>
              <span>
                {" "}
                <input
                  value="Bonne"
                  name="nature_relation_voisinage"
                  onChange={handleCheckbox}
                  type="radio"
                  style={{ marginTop: "5px", marginRight: "3px" }}
                />
                Bonne{" "}
              </span>
              <span>
                {" "}
                <input
                  value="conflictuelle"
                  type="radio"
                  onChange={handleCheckbox}
                  name="nature_relation_voisinage"
                  style={{ marginTop: "5px", marginRight: "3px" }}
                />{" "}
                conflictuelle{" "}
              </span>
              <span>
                {" "}
                <br />
                <h3 style={{ fontSize: "13px", fontWeight: "bolder" }}>
                  Autres :
                </h3>
                <Input
                  onChange={handleChange}
                  name="nature_relation_voisinage"
                  type="text"
                  placeholder="expliquez ici.."
                  style={{
                    border: "none",
                    background: "lightgray",
                  }}
                />
              </span>
            </div>
            <div>
              {" "}
              <h3 style={{ fontSize: "13px", fontWeight: "bold" }}>
                {" "}
                Etat de santé de l’enfant :<br />{" "}
                <span style={{ background: "yellow", fontWeight: "normal" }}>
                  {dataToUpdate?.etat_sante_enfant}{" "}
                </span>
              </h3>
              <span>
                {" "}
                <input
                  value="Bonne"
                  type="radio"
                  onChange={handleCheckbox}
                  name="etat_sante_enfant"
                  style={{ marginTop: "5px", marginRight: "3px" }}
                />
                Bonne{" "}
              </span>
              <span>
                {" "}
                <input
                  value="Stable"
                  name="etat_sante_enfant"
                  onChange={handleCheckbox}
                  type="radio"
                  style={{ marginTop: "5px", marginRight: "3px" }}
                />
                Stable
              </span>
              <span>
                {" "}
                <br />{" "}
                <h3 style={{ fontSize: "13px", fontWeight: "bolder" }}>
                  Autres :
                </h3>
                <Input
                  name="etat_sante_enfant"
                  onChange={handleChange}
                  type="text"
                  placeholder="expliquez ici.."
                  style={{
                    border: "none",
                    background: "lightgray",
                  }}
                />
              </span>
            </div>
            <h3 style={{ fontSize: "13px", fontWeight: "bold" }}>
              Evolution du projet de vie : <br />{" "}
              <span style={{ background: "yellow", fontWeight: "normal" }}>
                {dataToUpdate?.Evolution_Projet_vie}{" "}
              </span>
            </h3>
            <div>
              {" "}
              <h3 style={{ fontSize: "13px", fontWeight: "bolder" }}>
                {" "}
                Apprentissage :
              </h3>
              <span>
                {" "}
                <input
                  type="radio"
                  name="Evolution_Projet_vie"
                  value="Apprentissage ='Satisfait'"
                  onChange={handleCheckbox}
                  style={{ marginTop: "5px", marginRight: "3px" }}
                />{" "}
                Satisfait
              </span>
              <span>
                {" "}
                <input
                  type="radio"
                  name="Evolution_Projet_vie"
                  value="Apprentissage ='Partiellemt satisfait'"
                  onChange={handleCheckbox}
                  style={{ marginTop: "5px", marginRight: "3px" }}
                />{" "}
                Partiellemt satisfait
              </span>
              <span>
                {" "}
                <input
                  type="radio"
                  name="Evolution_Projet_vie"
                  value="Apprentissage ='Pas satisfait'"
                  onChange={handleCheckbox}
                  style={{ marginTop: "5px", marginRight: "3px" }}
                />{" "}
                Pas satisfait
              </span>
              <span>
                {" "}
                <input
                  type="radio"
                  name="Evolution_Projet_vie"
                  value="Apprentissage ='Besoins de renforcement'"
                  onChange={handleCheckbox}
                  style={{ marginTop: "5px", marginRight: "3px" }}
                />{" "}
                Besoins de renforcement
              </span>
              <span>
                {" "}
                <input
                  type="radio"
                  name="Evolution_Projet_vie"
                  value="Apprentissage ='Abandon'"
                  onChange={handleCheckbox}
                  style={{ marginTop: "5px", marginRight: "3px" }}
                />{" "}
                Abandon
              </span>
            </div>
            <div>
              <h3 style={{ fontSize: "13px", fontWeight: "bolder" }}>
                {" "}
                Formation :
              </h3>{" "}
              <span>
                {" "}
                <input
                  type="radio"
                  name="Evolution_Projet_vie"
                  value="Formation ='Satisfait'"
                  onChange={handleCheckbox}
                  style={{ marginTop: "5px", marginRight: "3px" }}
                />{" "}
                Satisfait
              </span>
              <span>
                {" "}
                <input
                  type="radio"
                  name="Evolution_Projet_vie"
                  value="Formation ='Partiellemt satisfait'"
                  onChange={handleCheckbox}
                  style={{ marginTop: "5px", marginRight: "3px" }}
                />{" "}
                Partiellemt satisfait
              </span>
              <span>
                {" "}
                <input
                  type="radio"
                  name="Evolution_Projet_vie"
                  value="Formation ='Pas satisfait'"
                  onChange={handleCheckbox}
                  style={{ marginTop: "5px", marginRight: "3px" }}
                />{" "}
                Pas satisfait
              </span>
              <span>
                {" "}
                <input
                  type="radio"
                  name="Evolution_Projet_vie"
                  value="Formation ='Besoins de renforcement'"
                  onChange={handleCheckbox}
                  style={{ marginTop: "5px", marginRight: "3px" }}
                />
                Besoins de renforcement
              </span>
              <span>
                {" "}
                <input
                  type="radio"
                  name="Evolution_Projet_vie"
                  value="Formation ='Abandon'"
                  onChange={handleCheckbox}
                  style={{ marginTop: "5px", marginRight: "3px" }}
                />{" "}
                Abandon
              </span>
            </div>
            <div>
              {" "}
              <h3 style={{ fontSize: "13px", fontWeight: "bolder" }}>
                {" "}
                Scolarisation :
              </h3>
              <span>
                {" "}
                <input
                  type="radio"
                  name="Evolution_Projet_vie"
                  value="Scolarisation ='Satisfait'"
                  onChange={handleCheckbox}
                  style={{ marginTop: "5px", marginRight: "3px" }}
                />{" "}
                Satisfait
              </span>
              <span>
                {" "}
                <input
                  type="radio"
                  name="Evolution_Projet_vie"
                  value="Scolarisation ='Partiellemt satisfait'"
                  onChange={handleCheckbox}
                  style={{ marginTop: "5px", marginRight: "3px" }}
                />{" "}
                Partiellemt satisfait
              </span>
              <span>
                {" "}
                <input
                  onChange={handleCheckbox}
                  type="radio"
                  name="Evolution_Projet_vie"
                  value="Scolarisation ='Pas satisfait'"
                  style={{ marginTop: "5px", marginRight: "3px" }}
                />
                Pas satisfait
              </span>
              <span>
                {" "}
                <input
                  type="radio"
                  name="Evolution_Projet_vie"
                  value="Scolarisation ='Besoins de renforcement'"
                  onChange={handleCheckbox}
                  style={{ marginTop: "5px", marginRight: "3px" }}
                />{" "}
                Besoins de renforcement
              </span>
              <span>
                {" "}
                <input
                  type="radio"
                  name="Evolution_Projet_vie"
                  value="Scolarisation ='Abandon'"
                  onChange={handleCheckbox}
                  style={{ marginTop: "5px", marginRight: "3px" }}
                />{" "}
                Abandon
              </span>
            </div>
            <div>
              {" "}
              <h3 style={{ fontSize: "13px", fontWeight: "bold" }}>
                {" "}
                AGR :<br />{" "}
                <span style={{ background: "yellow", fontWeight: "normal" }}>
                  {dataToUpdate?.Evolution_Projet_vie_AGR}{" "}
                </span>
              </h3>
              <span>
                {" "}
                <input
                  type="radio"
                  value=" AGR en cours"
                  onChange={handleCheckbox}
                  name="Evolution_Projet_vie_AGR"
                  style={{ marginTop: "5px", marginRight: "3px" }}
                />{" "}
                AGR en cours
              </span>
              <span>
                {" "}
                <input
                  type="radio"
                  value="Besoins de renforcement"
                  onChange={handleCheckbox}
                  name="Evolution_Projet_vie_AGR"
                  style={{ marginTop: "5px", marginRight: "3px" }}
                />{" "}
                Besoins de renforcement
              </span>
              <span>
                {" "}
                <input
                  type="radio"
                  value="AGR à l‘arrêt"
                  onChange={handleCheckbox}
                  name="Evolution_Projet_vie_AGR"
                  style={{ marginTop: "5px", marginRight: "3px" }}
                />{" "}
                AGR à l‘arrêt
              </span>
            </div>
          </div>
          <div className="form-container-block">
            <span>
              {" "}
              <h3 style={{ fontSize: "13px", fontWeight: "bold" }}>
                {" "}
                Décision prise après évaluation de la situation de l’enfant :{" "}
                <br />{" "}
                <span style={{ background: "yellow", fontWeight: "normal" }}>
                  {dataToUpdate?.decision_apres_evaluation_enfant}{" "}
                </span>
              </h3>
            </span>{" "}
            <div>
              {" "}
              <input
                type="radio"
                onChange={handleCheckbox}
                value="Clôturer le suivi de l’enfant"
                name="decision_apres_evaluation_enfant"
                style={{ marginTop: "5px", marginRight: "3px" }}
              />{" "}
              Clôturer le suivi de l’enfant
            </div>
            <div style={{ margin: "5px 0" }}>
              {" "}
              Date de prise de décision de clôture :
              <Input
                name="date_prise_decision"
                value={dataToUpdate?.date_prise_decision?.substring(0, 10)}
                onChange={handleChange}
                type="date"
                style={{
                  border: "none",
                  background: "lightgray",
                }}
              />
            </div>
            <span>
              {" "}
              <input
                value="Poursuite du cas"
                type="radio"
                onChange={handleCheckbox}
                name="decision_apres_evaluation_enfant"
                style={{ marginTop: "5px", marginRight: "3px" }}
              />{" "}
              Poursuite du cas
            </span>
            <div>
              {" "}
              <h3 style={{ fontWeight: "bolder", fontSize: "13px" }}>
                {" "}
                Motifs justificatifs :{" "}
              </h3>
              <Textarea
                name="motif_justificatif"
                maxRows={4}
                onChange={handleChange}
                aria-label="maximum height"
                placeholder="Description du projet"
                style={{
                  width: 200,
                  border: "none",
                  background: "lightgray",
                }}
              />
            </div>
          </div>

          <div className="sbmit-btn">
            <Button
              className="mb-4"
              variant="contained"
              color="primary"
              type="submit"
            >
              Modifier
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default UpdateSuivi;
