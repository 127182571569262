import Child from "views/Child";
import Dashboard from "views/Dashboard.js";
import UserProfile from "views/UserProfile.js";

var simpleUserRoutes = [
  {
    path: "/dashboard",
    name: "Tableau de bord",
    icon: "tim-icons icon-chart-pie-36",
    component: Dashboard,
    layout: "/admin",
  },
  {
    path: "/enfant",
    name: "Enfants",
    icon: "tim-icons icon-satisfied",
    component: Child,
    layout: "/admin",
  },

  {
    path: "/user-profile",
    name: "Profil",
    icon: "tim-icons icon-single-02",
    component: UserProfile,
    layout: "/admin",
  },
];

export default simpleUserRoutes;
