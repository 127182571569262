import React, { useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import Notiflix from "notiflix";
import { Input, Textarea } from "@chakra-ui/react";
import { Button } from "reactstrap";
import api from "../../services/api";
import { AiOutlineEye } from "react-icons/ai";
import { BiArrowBack } from "react-icons/bi";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from "@chakra-ui/react";
import ResumeRetourEnFamille from "./resumeFiche/ResumeRetourEnFamille";
import UpdateRetourEnFamille from "./updateFiche/UpdateRetourEnFamille";
import { withRouter } from "react-router-dom";
const RetourEnFamille = (props) => {
  const loggedUserData = JSON.parse(localStorage.getItem("data"));
  const childId = parseInt(props.match.params.id)
  const openResume = useDisclosure();
  const openUpdate = useDisclosure();
  const [idRow, setIdRow] = useState();
  const [hiddenDiv, setHiddenDiv] = useState(true);
  const [retourList, setRetourList] = useState();
  const [inputValue, setInputValue] = useState();

  //mui dataTables
  const columns = [
    {
      name: "id",
      label: "Code",
    },
    {
      label: "Recherche et mediation familiale realise",
      name: "recherche_famille_realise_par",
    },
    {
      label: "Date de la recherche famille ",
      name: "recherche_famille_date",
      options: {
        display: false,
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          const index = tableMeta.rowData.findIndex((el) => el?.length === 24);
          return <span>{tableMeta.rowData[index]?.substring(0, 10)} </span>;
        },
      },
    },
    {
      label: "Structure ayant fait la recherche famille ",
      name: "recherche_famille_structure",
      options: { display: false },
    },
    {
      label: "Departement ou  la recherche a ete faite",
      name: "departement",
      options: { display: false },
    },
    {
      label: "Pays ou la recherche a ete faite",
      name: "pays",
      options: { display: false },
    },
    {
      label: "ville ou  la recherche a ete faite",
      name: "ville",
      options: { display: false },
    },
    {
      label: "village ou  la recherche a ete faite",
      name: "village_quartier",
      options: { display: false },
    },
    {
      label: "Region ou la recherche a ete faite ",
      name: "region",
      options: { display: false },
    },

    {
      label: "premiere personne contactee",
      name: "personne_contactee1",
      options: { display: false },
    },
    {
      label: "Numero premiere personne contactee",
      name: "telephone1",
      options: { display: false },
    },
    {
      label: "Adresse de la premiere personne",
      name: "adresse1",
      options: { display: false },
    },
    {
      label: "Lien parente avec la premiere personne ",
      name: "lien_parente_enfant1",
      options: { display: false },
    },
    {
      label: "deuxieme personne contactee",
      name: "personne_contactee2",
      options: { display: false },
    },
    {
      label: "Numero deuxieme personne contactee",
      name: "telephone2",
      options: { display: false },
    },
    {
      label: "Adresse de la deuxieme personne",
      name: "adresse2",
      options: { display: false },
    },
    {
      label: "Lien parente avec la deuxieme personne ",
      name: "lien_parente_enfant2",
      options: { display: false },
    },

    {
      label: "Troisieme  personne contactee",
      name: "personne_contactee3",
      options: { display: false },
    },
    {
      label: "Numero Troisieme personne contactee",
      name: "telephone3",
      options: { display: false },
    },
    {
      label: "Adresse de la Troisieme personne",
      name: "adresse3",
      options: { display: false },
    },
    {
      label: "Lien parente avec la Troisieme personne ",
      name: "lien_parente_enfant3",
      options: { display: false },
    },

    {
      label: "Existance de relation entre enfant et famille",
      name: "relation_enfant_famille",
      options: { display: false },
    },
    {
      label: "Pourquoi non relation",
      name: "relation_enfant_famille_pourquoi",
      options: { display: false },
    },

    {
      label: "Ressources de la famille de enfant",
      name: "ressources_famille_enfant",
      options: { display: false },
    },
    {
      label: "Est ce que les parents acceptent de reprendre l'enfant",
      name: "reprendre_enfant",
      options: { display: false },
    },
    {
      label: "Pourquoi non reprise",
      name: "reprendre_enfant_pourquoi",
      options: { display: false },
    },
    {
      label: "Conclusion de l'évaluation",
      name: "conclusion_evaluation",
      options: { display: false },
    },
    {
      label: "Retour en famille realiser par",
      name: "retour_realiser_par",
      options: { display: false },
    },
    {
      label: "Date retour en famille",
      name: "retour_famille_date",
      options: {
        display: false,
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          const index = tableMeta.rowData.findIndex((el) => el?.length === 24);
          return <span>{tableMeta.rowData[index]?.substring(0, 10)} </span>;
        },
      },
    },
    {
      label: "Lieu retour en famille",
      name: "retour_lieu",
      options: { display: false },
    },

    {
      label: "Nom de la personne ayant reçu enfant",
      name: "personne_ayant_recu_enfant",
      options: { display: false },
    },
    {
      label: "Numero personne ayant recu enfant",
      name: "telephone_personne_ayant_recu_enfant",
      options: { display: false },
    },
    {
      label: "Cin personne ayant reçu l'enfant",
      name: "cin_personne_ayant_recu_enfant",
      options: { display: false },
    },
    {
      label: "Adresse  personne ayant reçu l'enfant",
      name: "adresse_personne_ayant_recu_enfant",
      options: { display: false },
    },
    {
      label: "Type de reintégration",
      name: "retour_reintegration",
      options: { display: false },
    },
    {
      label: "Important à signaler",
      name: "retour_a_signaler",
      options: { display: false },
    },
    {
      label: "Specification de la ressource",
      name: "ressources_famille_enfant_specifiez",
      options: { display: false },
    },

    {
      label: "Structure de retour",
      name: "retour_structure",
      options: { display: false },
    },
    { label: "Gestionnaire de cas", name: "gestionnaire_cas" },
    {
      name: "bureau",
      label: "Bureau",
    },
    {
      name: "statut",
      label: "Statut",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const index = tableMeta.rowData.findIndex(
            (el) => el === "VALIDER" || el === "EN ATTENTE"
          );
          if (tableMeta.rowData[index] === "VALIDER") {
            return (
              <span style={{ color: "green" }}>
                {tableMeta.rowData[index]}{" "}
              </span>
            );
          }
          if (tableMeta.rowData[index] === "EN ATTENTE") {
            return (
              <span style={{ color: "red" }}>{tableMeta.rowData[index]} </span>
            );
          }
        },
      },
    },
    {
      name: "Action",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          const _statut = tableMeta.rowData.find(
            (el) => el === "VALIDER" || el === "EN ATTENTE"
          );
          const gestCas = tableMeta.rowData.find((el) => el === loggedUserName);
          const _bureau = tableMeta.rowData.find(
            (el) => el === loggedUserBureau
          );
          return (
            <>
              <button
                onClick={() => {
                  openResume.onOpen();
                  setIdRow(tableMeta.rowIndex);
                }}
                style={{
                  background: "lightgray",
                  color: "#fff",
                  fontSize: "15px",
                  padding: " 3px",
                  borderRadius: "3px",

                  position: "relative",
                  top: "3px",
                  right: "5px",
                }}
              >
                <AiOutlineEye />
              </button>
              <button
                disabled={
                  (loggedUserRole === "ROLE_USER" &&
                    _statut === "EN ATTENTE" &&
                    gestCas === loggedUserName) ||
                    (loggedUserRole === "ROLE_RESPONSABLE" &&
                      _bureau === loggedUserBureau) ||
                    loggedUserRole === "ROLE_ADMIN"
                    ? false
                    : true
                }
                onClick={() => {
                  openUpdate.onOpen();
                  setIdRow(tableMeta.rowIndex);
                }}
                style={{
                  background: "orange",
                  color: "#fff",
                  fontSize: "11px",
                  padding: "3px",
                  borderRadius: "3px",
                }}
              >
                Editer
              </button>
              <button
                disabled={true ? loggedUserRole !== "ROLE_ADMIN" : false}
                onClick={() => confirmDelete(tableMeta.rowData[0])}
                style={{
                  background: "red",
                  color: "#fff",
                  fontSize: "11px",
                  padding: " 3px",
                  borderRadius: "3px",
                  marginLeft: "5px",
                }}
              >
                Supprimer
              </button>
            </>
          );
        },
      },
    },
  ];
  const options = {
    filterType: "textField",
    pagination: false,
    print: false,
    download: false,
    enableNestedDataAccess: ".",
    filterOptions: {
      useDisplayedColumnsOnly: true,
      useDisplayedRowsOnly: true,
    },
  };

  const loggedUserRole = JSON.parse(localStorage.getItem("data"))?.roles[0];
  const loggedUserBureau = JSON.parse(localStorage.getItem("data"))?.bureau;
  const loggedUserName = JSON.parse(localStorage.getItem("data"))?.username;
  const handleAddForm = () => {
    setHiddenDiv(!hiddenDiv);
  };

  const handleChange = (event) => {
    setInputValue({
      ...inputValue,
      [event.target.name]: event.target.value,
    });
  };
  const confirmDelete = (id) => {
    Notiflix.Confirm.show(
      " ",
      "Etes-vous sûr de vouloir supprimer ?",
      "Confirmer",
      "Annuler",
      () => {
        api
          .deleteRetourFamille(id)
          .then((response) => {
            Notiflix.Notify.success("supprimé !!");
            window.location.reload(true);
          })
          .catch((error) => console.log(error));
      }
    );
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    api
      .createFamille({
        loggedUserData,
        inputValue,
        childId,
      })
      .then((response) => {
        if (response) {
          Notiflix.Notify.success("Retour en famille créer avec succès ");
          window.location.reload(true);
        }
      })
      .catch((error) => console.log(error));
  };
  useEffect(() => {
    api
      .getAllRetour(childId)
      .then((response) => {
        if (response) {
          setRetourList(response.data);
        }
      })
      .catch((error) => console.log(error));
  }, [childId]);

  return (
    <div className="content fiche-styles">
      <div className="m-sm-30">
        <Modal
          onClose={openResume.onClose}
          size={"xl"}
          isOpen={openResume.isOpen}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalCloseButton />
            <ModalBody>
              <ResumeRetourEnFamille retourList={retourList} idRow={idRow} />
            </ModalBody>
          </ModalContent>
        </Modal>
        <Modal
          onClose={openUpdate.onClose}
          size={"full"}
          isOpen={openUpdate.isOpen}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalCloseButton />
            <ModalBody>
              <UpdateRetourEnFamille retourList={retourList} idRow={idRow} />
            </ModalBody>
          </ModalContent>
        </Modal>
        <div className="checkbox-container" hidden={hiddenDiv}>
          <div className="dateprise" style={{ marginBottom: "2rem" }}>
            <div className="info-real">
              <ul>
                <li>Gestionnaire cas : {loggedUserData?.username}</li>
                <li>Bureau : {loggedUserData?.bureau} </li>
                <li>Tél : {loggedUserData?.tel} </li>
                <li>Email : {loggedUserData?.email} </li>
              </ul>
            </div>{" "}
            <form
              onSubmit={handleSubmit}
              className="form-container"
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div
                  className="form-container-block"
                  onChange={handleChange}
                  style={{ width: "33%" }}
                >
                  <div>
                    <h3 style={{ fontSize: "13px", fontWeight: "bolder" }}>
                      {" "}
                      Recherche famille réaliser par :
                    </h3>
                    <Input
                      type="text"
                      name="recherche_famille_realise_par"
                      placeholder="...."
                      style={{
                        border: "none",
                        background: "lightgray",
                      }}
                    />
                  </div>
                  <span>
                    <h3 style={{ fontSize: "13px", fontWeight: "bolder" }}>
                      {" "}
                      Date de la recherche:
                    </h3>
                    <Input
                      type="date"
                      name="recherche_famille_date"
                      style={{
                        border: "none",
                        background: "lightgray",
                      }}
                      placeholder="...."
                    />
                  </span>
                  <span>
                    <h3 style={{ fontSize: "13px", fontWeight: "bolder" }}>
                      Structure ayant fait la recherche famille:
                    </h3>
                    <Input
                      name="recherche_famille_structure"
                      type="text"
                      placeholder="...."
                      style={{
                        border: "none",
                        background: "lightgray",
                      }}
                    />
                  </span>
                  <div>
                    <h3 style={{ fontSize: "13px", fontWeight: "bolder" }}>
                      Localité recherche : {""}
                    </h3>
                    <h3 style={{ fontSize: "13px", fontWeight: "bolder" }}>
                      Ville :
                    </h3>
                    <Input
                      name="ville"
                      type="text"
                      placeholder="...."
                      style={{
                        border: "none",
                        background: "lightgray",
                      }}
                    />
                    <h3 style={{ fontSize: "13px", fontWeight: "bolder" }}>
                      Village/quartier :
                    </h3>
                    <Input
                      name="village_quartier"
                      type="text"
                      placeholder="...."
                      style={{
                        border: "none",
                        background: "lightgray",
                      }}
                    />

                    <h3 style={{ fontSize: "13px", fontWeight: "bolder" }}>
                      Region :
                    </h3>
                    <Input
                      name="region"
                      type="text"
                      placeholder="...."
                      style={{
                        border: "none",
                        background: "lightgray",
                      }}
                    />
                    <br />
                    <div style={{ marginTop: "5px", display: "inline-block" }}>
                      <h3 style={{ fontSize: "13px", fontWeight: "bolder" }}>
                        Departement :
                      </h3>
                      <Input
                        name="departement"
                        type="text"
                        placeholder="...."
                        style={{
                          border: "none",
                          background: "lightgray",
                        }}
                      />
                      <h3 style={{ fontSize: "13px", fontWeight: "bolder" }}>
                        Pays :
                      </h3>
                      <Input
                        name="pays"
                        type="text"
                        placeholder="...."
                        style={{
                          border: "none",
                          background: "lightgray",
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div
                  className="form-container-block"
                  onChange={handleChange}
                  style={{ width: "33%", marginLeft: "3rem" }}
                >
                  <div>
                    <h3 style={{ fontSize: "13px", fontWeight: "bolder" }}>
                      Personne(s) contactée(s) maximum trois personnes: {""}
                    </h3>
                    <div>
                      Lien parenté: <br />
                      <Input
                        name="lien_parente_enfant1"
                        type="text"
                        placeholder="...."
                        style={{
                          border: "none",
                          background: "lightgray",
                        }}
                      />{" "}
                      Adresse : {""}{" "}
                      <Input
                        name="adresse1"
                        type="text"
                        placeholder="...."
                        style={{
                          border: "none",
                          background: "lightgray",
                        }}
                      />{" "}
                      Téléphone: {""}{" "}
                      <Input
                        name="telephone1"
                        type="tel"
                        placeholder="...."
                        style={{
                          border: "none",
                          background: "lightgray",
                        }}
                      />
                    </div>
                    <div>
                      Lien parenté:
                      <Input
                        name="lien_parente_enfant2"
                        type="text"
                        placeholder="...."
                        style={{
                          border: "none",
                          background: "lightgray",
                        }}
                      />{" "}
                      Adresse : {""}{" "}
                      <Input
                        name="adresse2"
                        type="text"
                        placeholder="...."
                        style={{
                          border: "none",
                          background: "lightgray",
                        }}
                      />{" "}
                      Téléphone: {""}{" "}
                      <Input
                        name="telephone2"
                        type="tel"
                        placeholder="...."
                        style={{
                          border: "none",
                          background: "lightgray",
                        }}
                      />
                    </div>
                    <div>
                      Lien parenté: <br />
                      <Input
                        name="lien_parente_enfant3"
                        type="text"
                        placeholder="...."
                        style={{
                          border: "none",
                          background: "lightgray",
                        }}
                      />{" "}
                      Adresse : {""}{" "}
                      <Input
                        name="adresse3"
                        type="text"
                        placeholder="...."
                        style={{
                          border: "none",
                          background: "lightgray",
                        }}
                      />{" "}
                      Téléphone: {""}{" "}
                      <Input
                        name="telephone3"
                        type="tel"
                        placeholder="...."
                        style={{
                          border: "none",
                          background: "lightgray",
                        }}
                      />
                    </div>
                  </div>

                  <div>
                    <h3 style={{ fontSize: "13px", fontWeight: "bolder" }}>
                      Retour en famille réaliser par :
                    </h3>
                    <Input
                      name="retour_realiser_par"
                      type="text"
                      placeholder="...."
                      style={{
                        border: "none",
                        background: "lightgray",
                      }}
                    />
                  </div>
                  <div>
                    <h3 style={{ fontSize: "13px", fontWeight: "bolder" }}>
                      Personne ayant reçu en famille :
                    </h3>
                    Nom : <br />
                    <Input
                      name="personne_ayant_recu_enfant"
                      type="text"
                      placeholder="...."
                      style={{
                        border: "none",
                        background: "lightgray",
                      }}
                    />
                  </div>
                  <div>
                    <h3 style={{ fontSize: "13px" }}>Téléphone :</h3>
                    <Input
                      name="telephone_personne_ayant_recu_enfant"
                      type="text"
                      placeholder="...."
                      style={{
                        border: "none",
                        background: "lightgray",
                      }}
                    />
                  </div>
                  <div>
                    <h3 style={{ fontSize: "13px" }}>Adresse :</h3>
                    <Input
                      name="adresse_personne_ayant_recu_enfant"
                      type="text"
                      placeholder="...."
                      style={{
                        border: "none",
                        background: "lightgray",
                      }}
                    />
                  </div>
                  <div>
                    <h3 style={{ fontSize: "13px" }}>CIN :</h3>
                    <Input
                      name="cin_personne_ayant_recu_enfant"
                      type="text"
                      placeholder="...."
                      style={{
                        border: "none",
                        background: "lightgray",
                      }}
                    />
                  </div>
                </div>
                <div
                  className="form-container-block"
                  onChange={handleChange}
                  style={{ width: "33%", marginLeft: "3rem" }}
                >
                  <div>
                    <h3 style={{ fontSize: "13px", fontWeight: "bolder" }}>
                      Reintégration
                    </h3>
                    <input
                      type="radio"
                      name="retour_reintegration"
                      value="Familiale"
                    />
                    Familiale
                    <input
                      type="radio"
                      name="retour_reintegration"
                      value="Professionnelle"
                    />
                    Professionnelle
                    <input
                      type="radio"
                      name="retour_reintegration"
                      value="Scolaire"
                    />
                    Scolaire
                    <input
                      type="radio"
                      name="retour_reintegration"
                      value=" Pas encore d'activités"
                    />
                    Pas encore d'activités
                  </div>
                  <div>
                    <h3 style={{ fontSize: "13px", fontWeight: "bolder" }}>
                      Important à signaler :
                    </h3>
                    <Input
                      name="retour_a_signaler"
                      type="text"
                      placeholder="...."
                      style={{
                        border: "none",
                        background: "lightgray",
                      }}
                    />
                  </div>
                  <div>
                    <h3 style={{ fontSize: "13px", fontWeight: "bolder" }}>
                      Date retour en famille :{" "}
                    </h3>
                    <Input
                      name="retour_famille_date"
                      type="date"
                      style={{
                        border: "none",
                        background: "lightgray",
                      }}
                    />
                  </div>
                  <div>
                    <h3 style={{ fontSize: "13px", fontWeight: "bolder" }}>
                      Structure en charge du retour en famille :{" "}
                    </h3>
                    <Input
                      name="retour_structure"
                      type="text"
                      placeholder="...."
                      style={{
                        border: "none",
                        background: "lightgray",
                      }}
                    />
                  </div>
                  <div>
                    <h3 style={{ fontSize: "13px", fontWeight: "bolder" }}>
                      Lieu :{" "}
                    </h3>
                    <Input
                      name="retour_lieu"
                      type="text"
                      placeholder="...."
                      style={{
                        border: "none",
                        background: "lightgray",
                      }}
                    />
                  </div>
                  <div>
                    <h3 style={{ fontSize: "13px", fontWeight: "bolder" }}>
                      Relation entre enfant et famille :
                    </h3>
                    <div>
                      {" "}
                      <input
                        type="radio"
                        name="relation_enfant_famille"
                        value="Oui"
                      />
                      Oui{" "}
                    </div>
                    <div>
                      {" "}
                      <input
                        type="radio"
                        name="relation_enfant_famille"
                        value="Non"
                      />
                      Non{" "}
                    </div>
                  </div>
                  <h3 style={{ fontSize: "13px", fontWeight: "bolder" }}>
                    Pourquoi il n'y a pas de relation entre enfant et famille :
                  </h3>

                  <Textarea
                    maxRows={4}
                    name="relation_enfant_famille_pourquoi"
                    aria-label="maximum height"
                    placeholder="precisez le pourquoi ici..."
                    style={{
                      width: 200,
                      border: "none",
                      background: "lightgray",
                    }}
                  />
                  <div>
                    <h3 style={{ fontSize: "13px", fontWeight: "bolder" }}>
                      Ressource famille
                    </h3>
                    <span>
                      <span>
                        {" "}
                        <input
                          type="radio"
                          name="ressources_famille_enfant"
                          value="Salarié"
                        />
                        Salarié{" "}
                      </span>
                      <span>
                        {" "}
                        <input
                          type="radio"
                          name="ressources_famille_enfant"
                          value="Retraité"
                        />{" "}
                        Retraité{" "}
                      </span>
                      <span>
                        {" "}
                        <input
                          type="radio"
                          name="ressources_famille_enfant"
                          value="Travailleur agricole"
                        />{" "}
                        Travailleur agricole{" "}
                      </span>
                      <span>
                        {" "}
                        <br />
                        <h3 style={{ fontSize: "13px", fontWeight: "bolder" }}>
                          Travailleur indépendant (Autre source des ressources) :
                        </h3>
                        <Input
                          name="ressource_famille_enfant_specifiez"
                          type="text"
                          placeholder="spécifier ici..."
                          style={{
                            border: "none",
                            background: "lightgray",
                          }}
                        />
                      </span>
                    </span>
                  </div>
                  <div>
                    <h3 style={{ fontSize: "13px", fontWeight: "bolder" }}>
                      Les parents acceptent-ils de reprendre l'enfant :
                    </h3>
                    <div>
                      {" "}
                      <input type="radio" value="Oui" name="reprendre_enfant" />
                      Oui
                    </div>
                    <div>
                      {" "}
                      <input type="radio" value="Non" name="reprendre_enfant" />
                      Non
                    </div>{" "}
                    <h3 style={{ fontSize: "13px", fontWeight: "bolder" }}>
                      {" "}
                      Pourquoi les parents ne reprennent il pas l'enfant :
                    </h3>
                    <Textarea
                      maxRows={4}
                      name="reprendre_enfant_pourquoi"
                      aria-label="maximum height"
                      placeholder="precizez le pourquoi ici..."
                      style={{
                        width: 200,
                        border: "none",
                        background: "lightgray",
                      }}
                    />
                  </div>
                  <div>
                    <h3 style={{ fontSize: "13px", fontWeight: "bolder" }}>
                      Conclusion evaluation :
                    </h3>
                    <span>
                      {" "}
                      <input
                        type="radio"
                        name="conclusion_evaluation"
                        value="Réintégration avec un risque trés élevé"
                      />
                      Réintégration avec un risque trés élevé{" "}
                    </span>
                    <span>
                      {" "}
                      <input
                        type="radio"
                        name="conclusion_evaluation"
                        value="Réintégration avec un risque élevé"
                      />
                      Réintégration avec un risque élevé
                    </span>{" "}
                    <br />
                    <span>
                      {" "}
                      <input
                        type="radio"
                        name="conclusion_evaluation"
                        value="Réintégration avec un risque moyennement élevé"
                      />
                      Réintégration avec un risque moyennement élevé
                    </span>
                    <span>
                      {" "}
                      <input
                        type="radio"
                        name="conclusion_evaluation"
                        value="Réintégration sans risque élevé"
                      />
                      Réintégration sans risque élevé
                    </span>
                  </div>
                </div>
              </div>
              <div>
                <div className="sbmit-btn">
                  <Button
                    className="mb-4"
                    variant="contained"
                    color="primary"
                    type="submit"
                  >
                    Enregistrer
                  </Button>
                  <Button
                    onClick={() => window.location.reload(true)}
                    style={{ background: "red" }}
                    className="mb-4"
                    type="button"
                  >
                    Annuler
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div className="list" hidden={!hiddenDiv}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>

            <BiArrowBack style={{ cursor: "pointer" }} size={"25"} onClick={() => window.location.href = '/admin/enfant'} />
            <Button
              onClick={() => handleAddForm()}
              className="mb-4"
              variant="contained"
              style={{ textTransform: "uppercase" }}
            >
              Créer retour en famille
            </Button>
          </div>
          <div className="info-real">
            <ul style={{ width: "15%" }}>
              <li>Nom : {retourList?.nom}</li>
              <li>Prenom : {retourList?.prenom} </li>
            </ul>
          </div>{" "}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "1rem",
            }}
          >
            <h3
              style={{
                fontSize: "20px",
                margin: "0 0 17px",
                fontWeight: "bolder",
              }}
            >
              Tableau retours en famille
            </h3>
          </div>
          <MUIDataTable
            data={retourList?.famille}
            columns={columns}
            options={options}
          />
        </div>
      </div>
    </div>

  );
};

export default withRouter(RetourEnFamille);
