/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { useState, useEffect } from "react";
import "./Child.scss";
import api from "../services/api";
import {
  ButtonGroup,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
} from "reactstrap";
// react plugin used to create charts
import {
  ComposedChart,
  Line,
  Area,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Scatter,
  ResponsiveContainer,
} from "recharts";

function Dashboard(props) {
  const [month, setMonth] = useState([]);
  const [monthNumb, setMonthNumb] = useState();
  //Dakar
  const [nbEnfDkr, setNbEnfDkr] = useState();
  const [nbIdDkr, setNbIdDkr] = useState();
  const [nbPcDkr, setNbPcDkr] = useState();
  const [nbRfDkr, setNbRfDkr] = useState();
  const [nbRDkr, setNbRDkr] = useState();
  const [nbPvDkr, setNbPvDkr] = useState();
  const [nbSDkr, setNbSDkr] = useState();
  //Kolda
  const [nbEnfKld, setNbEnfKld] = useState();
  const [nbIdKld, setNbIdKld] = useState();
  const [nbPcKld, setNbPcKld] = useState();
  const [nbRfKld, setNbRfKld] = useState();
  const [nbRKld, setNbRKld] = useState();
  const [nbPvKld, setNbPvKld] = useState();
  const [nbSKld, setNbSKld] = useState();
  //Zig
  const [nbEnfZig, setNbEnfZig] = useState();
  const [nbIdZig, setNbIdZig] = useState();
  const [nbPcZig, setNbPcZig] = useState();
  const [nbRfZig, setNbRfZig] = useState();
  const [nbRZig, setNbRZig] = useState();
  const [nbPvZig, setNbPvZig] = useState();
  const [nbSZig, setNbSZig] = useState();
  //Sedhiou
  const [nbEnfSed, setNbEnfSed] = useState();
  const [nbIdSed, setNbIdSed] = useState();
  const [nbPcSed, setNbPcSed] = useState();
  const [nbRfSed, setNbRfSed] = useState();
  const [nbRSed, setNbRSed] = useState();
  const [nbPvSed, setNbPvSed] = useState();
  const [nbSSed, setNbSSed] = useState();
  //Velingara
  const [nbEnfVel, setNbEnfVel] = useState();
  const [nbIdVel, setNbIdVel] = useState();
  const [nbPcVel, setNbPcVel] = useState();
  const [nbRfVel, setNbRfVel] = useState();
  const [nbRVel, setNbRVel] = useState();
  const [nbPvVel, setNbPvVel] = useState();
  const [nbSVel, setNbSVel] = useState();
  //Tamba
  const [nbEnfTam, setNbEnfTam] = useState();
  const [nbIdTam, setNbIdTam] = useState();
  const [nbPcTam, setNbPcTam] = useState();
  const [nbRfTam, setNbRfTam] = useState();
  const [nbRTam, setNbRTam] = useState();
  const [nbPvTam, setNbPvTam] = useState();
  const [nbSTam, setNbSTam] = useState();
  //Sarea
  const [nbEnfSar, setNbEnfSar] = useState();
  const [nbIdSar, setNbIdSar] = useState();
  const [nbPcSar, setNbPcSar] = useState();
  const [nbRfSar, setNbRfSar] = useState();
  const [nbRSar, setNbRSar] = useState();
  const [nbPvSar, setNbPvSar] = useState();
  const [nbSSar, setNbSSar] = useState();
  //Kaolack
  const [nbEnfKao, setNbEnfKao] = useState();
  const [nbIdKao, setNbIdKao] = useState();
  const [nbPcKao, setNbPcKao] = useState();
  const [nbRfKao, setNbRfKao] = useState();
  const [nbRKao, setNbRKao] = useState();
  const [nbPvKao, setNbPvKao] = useState();
  const [nbSKao, setNbSKao] = useState();
  //Thies
  const [nbEnfThi, setNbEnfThi] = useState();
  const [nbIdThi, setNbIdThi] = useState();
  const [nbPcThi, setNbPcThi] = useState();
  const [nbRfThi, setNbRfThi] = useState();
  const [nbRThi, setNbRThi] = useState();
  const [nbPvThi, setNbPvThi] = useState();
  const [nbSThi, setNbSThi] = useState();

  const monthNumbFiltred_JANV = monthNumb?.filter(
    (item) => item.name === "Janvier"
  );
  const monthNumbFiltred_FEV = monthNumb?.filter(
    (item) => item.name === "Fevrier"
  );
  const monthNumbFiltred_MARS = monthNumb?.filter(
    (item) => item.name === "Mars"
  );
  const monthNumbFiltred_AVRIL = monthNumb?.filter(
    (item) => item.name === "Avril"
  );
  const monthNumbFiltred_MAI = monthNumb?.filter((item) => item.name === "Mai");
  const monthNumbFiltred_JUIN = monthNumb?.filter(
    (item) => item.name === "Juin"
  );
  const monthNumbFiltred_JUILLET = monthNumb?.filter(
    (item) => item.name === "Juillet"
  );
  const monthNumbFiltred_AOUT = monthNumb?.filter(
    (item) => item.name === "Août"
  );
  const monthNumbFiltred_SEP = monthNumb?.filter(
    (item) => item.name === "Septembre"
  );
  const monthNumbFiltred_OCT = monthNumb?.filter(
    (item) => item.name === "Octobre"
  );
  const monthNumbFiltred_NOV = monthNumb?.filter(
    (item) => item.name === "Novembre"
  );
  const monthNumbFiltred_DEC = monthNumb?.filter(
    (item) => item.name === "Decembre"
  );

  const _month = month.map((item) => {
    const d = new Date(`${item.createdAt.substring(0, 10)}`);
    for (let i = 0; i < month.length; i++) {
      const m = {
        name:
          d.getMonth() + 1 === 1
            ? "Janvier"
            : d.getMonth() + 1 === 2
            ? "Fevrier"
            : d.getMonth() + 1 === 3
            ? "Mars"
            : d.getMonth() + 1 === 4
            ? "Avril"
            : d.getMonth() + 1 === 5
            ? "Mai"
            : d.getMonth() + 1 === 6
            ? "Juin"
            : d.getMonth() + 1 === 7
            ? "Juillet"
            : d.getMonth() + 1 === 8
            ? "Août"
            : d.getMonth() + 1 === 9
            ? "Septembre"
            : d.getMonth() + 1 === 10
            ? "Octobre"
            : d.getMonth() + 1 === 11
            ? "Novembre"
            : d.getMonth() + 1 === 12
            ? "Decembre"
            : "",
        nombre:
          d.getMonth() + 1 === 1
            ? monthNumbFiltred_JANV?.length
            : d.getMonth() + 1 === 2
            ? monthNumbFiltred_FEV?.length
            : d.getMonth() + 1 === 3
            ? monthNumbFiltred_MARS?.length
            : d.getMonth() + 1 === 4
            ? monthNumbFiltred_AVRIL?.length
            : d.getMonth() + 1 === 5
            ? monthNumbFiltred_MAI?.length
            : d.getMonth() + 1 === 6
            ? monthNumbFiltred_JUIN?.length
            : d.getMonth() + 1 === 7
            ? monthNumbFiltred_JUILLET?.length
            : d.getMonth() + 1 === 8
            ? monthNumbFiltred_AOUT?.length
            : d.getMonth() + 1 === 9
            ? monthNumbFiltred_SEP?.length
            : d.getMonth() + 1 === 10
            ? monthNumbFiltred_OCT?.length
            : d.getMonth() + 1 === 11
            ? monthNumbFiltred_NOV?.length
            : d.getMonth() + 1 === 12
            ? monthNumbFiltred_DEC?.length
            : null,
        id:
          d.getMonth() + 1 === 1
            ? 1
            : d.getMonth() + 1 === 2
            ? 2
            : d.getMonth() + 1 === 3
            ? 3
            : d.getMonth() + 1 === 4
            ? 4
            : d.getMonth() + 1 === 5
            ? 5
            : d.getMonth() + 1 === 6
            ? 6
            : d.getMonth() + 1 === 7
            ? 7
            : d.getMonth() + 1 === 8
            ? 8
            : d.getMonth() + 1 === 9
            ? 9
            : d.getMonth() + 1 === 10
            ? 10
            : d.getMonth() + 1 === 11
            ? 11
            : d.getMonth() + 1 === 12
            ? 12
            : null,
      };
      return m;
    }
  });
  const monthArray = [...new Set(_month)];
  console.log(monthArray);
  const monthArrayFiltred = Object.values(
    monthArray
      .sort()
      .reduce((acc, cur) => Object.assign(acc, { [cur.name]: cur }), {})
  );

  useEffect(() => {
    setMonthNumb(monthArray);
    api.getAllChildren().then((response) => {
      if (response) {
        console.log(response.data);
        setMonth(response.data);
      }
    });
  }, [month]);

  useEffect(() => {
    //dakar
    api.getStatDakar().then((response) => {
      setNbEnfDkr(response.data);
    });
    api.getStatIDDakar().then((response) => {
      setNbIdDkr(response.data);
    });
    api.getStatPCDakar().then((response) => {
      setNbPcDkr(response.data);
    });
    api.getStatRFDakar().then((response) => {
      setNbRfDkr(response.data);
    });
    api.getStatRDakar().then((response) => {
      setNbRDkr(response.data);
    });
    api.getStatPVDakar().then((response) => {
      setNbPvDkr(response.data);
    });
    api.getStatSDakar().then((response) => {
      setNbSDkr(response.data);
    });
    //kolda
    api.getStatKld().then((response) => {
      setNbEnfKld(response.data);
    });
    api.getStatIDkld().then((response) => {
      setNbIdKld(response.data);
    });
    api.getStatPCkld().then((response) => {
      setNbPcKld(response.data);
    });
    api.getStatRFkld().then((response) => {
      setNbRfKld(response.data);
    });
    api.getStatRkld().then((response) => {
      setNbRKld(response.data);
    });
    api.getStatPVkld().then((response) => {
      setNbPvKld(response.data);
    });
    api.getStatSkld().then((response) => {
      setNbSKld(response.data);
    });
    //Zig
    api.getStatZig().then((response) => {
      setNbEnfZig(response.data);
    });
    api.getStatIDZig().then((response) => {
      setNbIdZig(response.data);
    });
    api.getStatPCZig().then((response) => {
      setNbPcZig(response.data);
    });
    api.getStatRFZig().then((response) => {
      setNbRfZig(response.data);
    });
    api.getStatRZig().then((response) => {
      setNbRZig(response.data);
    });
    api.getStatPVZig().then((response) => {
      setNbPvZig(response.data);
    });
    api.getStatSZig().then((response) => {
      setNbSZig(response.data);
    });
    //Sedhiou
    api.getStatSed().then((response) => {
      setNbEnfSed(response.data);
    });
    api.getStatIDSed().then((response) => {
      setNbIdSed(response.data);
    });
    api.getStatPCSed().then((response) => {
      setNbPcSed(response.data);
    });
    api.getStatRFSed().then((response) => {
      setNbRfSed(response.data);
    });
    api.getStatRSed().then((response) => {
      setNbRSed(response.data);
    });
    api.getStatPVSed().then((response) => {
      setNbPvSed(response.data);
    });
    api.getStatSSed().then((response) => {
      setNbSSed(response.data);
    });
    //Velingara
    api.getStatVel().then((response) => {
      setNbEnfVel(response.data);
    });
    api.getStatIDVel().then((response) => {
      setNbIdVel(response.data);
    });
    api.getStatPCVel().then((response) => {
      setNbPcVel(response.data);
    });
    api.getStatRFVel().then((response) => {
      setNbRfVel(response.data);
    });
    api.getStatRVel().then((response) => {
      setNbRVel(response.data);
    });
    api.getStatPVVel().then((response) => {
      setNbPvVel(response.data);
    });
    api.getStatSVel().then((response) => {
      setNbSVel(response.data);
    });
    //Tamba
    api.getStatTam().then((response) => {
      setNbEnfTam(response.data);
    });
    api.getStatIDTam().then((response) => {
      setNbIdTam(response.data);
    });
    api.getStatPCTam().then((response) => {
      setNbPcTam(response.data);
    });
    api.getStatRFTam().then((response) => {
      setNbRfTam(response.data);
    });
    api.getStatRTam().then((response) => {
      setNbRTam(response.data);
    });
    api.getStatPVTam().then((response) => {
      setNbPvTam(response.data);
    });
    api.getStatSTam().then((response) => {
      setNbSTam(response.data);
    });
    //Sarea
    api.getStatSar().then((response) => {
      setNbEnfSar(response.data);
    });
    api.getStatIDSar().then((response) => {
      setNbIdSar(response.data);
    });
    api.getStatPCSar().then((response) => {
      setNbPcSar(response.data);
    });
    api.getStatRFSar().then((response) => {
      setNbRfSar(response.data);
    });
    api.getStatRSar().then((response) => {
      setNbRSar(response.data);
    });
    api.getStatPVSar().then((response) => {
      setNbPvSar(response.data);
    });
    api.getStatSSar().then((response) => {
      setNbSSar(response.data);
    });
    //Kaolack
    api.getStatKao().then((response) => {
      setNbEnfKao(response.data);
    });
    api.getStatIDKao().then((response) => {
      setNbIdKao(response.data);
    });
    api.getStatPCKao().then((response) => {
      setNbPcKao(response.data);
    });
    api.getStatRFKao().then((response) => {
      setNbRfKao(response.data);
    });
    api.getStatRKao().then((response) => {
      setNbRKao(response.data);
    });
    api.getStatPVKao().then((response) => {
      setNbPvKao(response.data);
    });
    api.getStatSKao().then((response) => {
      setNbSKao(response.data);
    });
    //Thies
    api.getStatThi().then((response) => {
      setNbEnfThi(response.data);
    });
    api.getStatIDThi().then((response) => {
      setNbIdThi(response.data);
    });
    api.getStatPCThi().then((response) => {
      setNbPcThi(response.data);
    });
    api.getStatRFThi().then((response) => {
      setNbRfThi(response.data);
    });
    api.getStatRThi().then((response) => {
      setNbRThi(response.data);
    });
    api.getStatPVThi().then((response) => {
      setNbPvThi(response.data);
    });
    api.getStatSThi().then((response) => {
      setNbSThi(response.data);
    });
  }, []);

  return (
    <>
      <div className="content">
        <Row>
          <Col xs="12">
            <Card className="card-chart">
              <CardHeader>
                <Row>
                  <Col className="text-left" sm="6">
                    <CardTitle tag="h2">Nombre d'enfant par mois</CardTitle>
                  </Col>
                  <Col sm="6">
                    <ButtonGroup
                      className="btn-group-toggle float-right"
                      data-toggle="buttons"
                    ></ButtonGroup>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <div className="chart-area">
                  <ResponsiveContainer width="100%" height="100%">
                    <ComposedChart
                      width={500}
                      height={400}
                      data={monthArrayFiltred.sort(
                        (item, item_) => item.id - item_.id
                      )}
                      margin={{
                        top: 20,
                        right: 20,
                        bottom: 20,
                        left: 20,
                      }}
                    >
                      <CartesianGrid stroke="#f5f5f5" />
                      <XAxis dataKey="name" scale="band" />
                      <YAxis />
                      <Tooltip />

                      <Area
                        type="monotone"
                        dataKey="nombre"
                        fill="#8884d8"
                        stroke="#8884d8"
                      />
                      <Bar dataKey="nombre" barSize={20} fill="#413ea0" />
                      <Line type="monotone" dataKey="nombre" stroke="#ff7300" />
                      <Scatter dataKey="nombre" fill="red" />
                    </ComposedChart>
                  </ResponsiveContainer>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col lg="3">
            <Card className="card-chart">
              <CardBody>
                <h3 className="card-category">Bureau : Enda Dakar </h3>
                <div>Nombre d'enfants : {nbEnfDkr?.length} </div>
                <div>Nombre d'identifications : {nbIdDkr?.length} </div>
                <div>
                  Nombre de prise en charge d'urgence : {nbPcDkr?.length}{" "}
                </div>
                <div>Nombre de retour en famille : {nbRfDkr?.length} </div>
                <div>Nombre de référencement : {nbRDkr?.length} </div>
                <div>Nombre de projet de vie : {nbPvDkr?.length} </div>
                <div>Nombre de suivi : {nbSDkr?.length}</div>
              </CardBody>
            </Card>
          </Col>
          <Col lg="3">
            <Card className="card-chart">
              <CardBody>
                <h3 className="card-category">Bureau : Enda Kolda </h3>
                <div>Nombre d'enfants : {nbEnfKld?.length} </div>
                <div>Nombre d'identifications : {nbIdKld?.length} </div>
                <div>
                  Nombre de prise en charge d'urgence : {nbPcKld?.length}{" "}
                </div>
                <div>Nombre de retour en famille : {nbRfKld?.length} </div>
                <div>Nombre de référencement : {nbRKld?.length} </div>
                <div>Nombre de projet de vie : {nbPvKld?.length} </div>
                <div>Nombre de suivi : {nbSKld?.length}</div>
              </CardBody>
            </Card>
          </Col>
          <Col lg="3">
            <Card className="card-chart">
              <CardBody>
                <h3 className="card-category">Bureau : Enda Ziguenchor </h3>
                <div>Nombre d'enfants : {nbEnfZig?.length} </div>
                <div>Nombre d'identifications : {nbIdZig?.length} </div>
                <div>
                  Nombre de prise en charge d'urgence : {nbPcZig?.length}{" "}
                </div>
                <div>Nombre de retour en famille : {nbRfZig?.length} </div>
                <div>Nombre de référencement : {nbRZig?.length} </div>
                <div>Nombre de projet de vie : {nbPvZig?.length} </div>
                <div>Nombre de suivi : {nbSZig?.length}</div>
              </CardBody>
            </Card>
          </Col>
          <Col lg="3">
            <Card className="card-chart">
              <CardBody>
                <h3 className="card-category">Bureau : Enda Sedhiou </h3>
                <div>Nombre d'enfants : {nbEnfSed?.length} </div>
                <div>Nombre d'identifications : {nbIdSed?.length} </div>
                <div>
                  Nombre de prise en charge d'urgence : {nbPcSed?.length}{" "}
                </div>
                <div>Nombre de retour en famille : {nbRfSed?.length} </div>
                <div>Nombre de référencement : {nbRSed?.length} </div>
                <div>Nombre de projet de vie : {nbPvSed?.length} </div>
                <div>Nombre de suivi : {nbSSed?.length}</div>
              </CardBody>
            </Card>
          </Col>
          <Col lg="3">
            <Card className="card-chart">
              <CardBody>
                <h3 className="card-category">Bureau : Enda Velingara </h3>
                <div>Nombre d'enfants : {nbEnfVel?.length} </div>
                <div>Nombre d'identifications : {nbIdVel?.length} </div>
                <div>
                  Nombre de prise en charge d'urgence : {nbPcVel?.length}{" "}
                </div>
                <div>Nombre de retour en famille : {nbRfVel?.length} </div>
                <div>Nombre de référencement : {nbRVel?.length} </div>
                <div>Nombre de projet de vie : {nbPvVel?.length} </div>
                <div>Nombre de suivi : {nbSVel?.length}</div>
              </CardBody>
            </Card>
          </Col>
          <Col lg="3">
            <Card className="card-chart">
              <CardBody>
                <h3 className="card-category">Bureau : Enda Tamba </h3>
                <div>Nombre d'enfants : {nbEnfTam?.length} </div>
                <div>Nombre d'identifications : {nbIdTam?.length} </div>
                <div>
                  Nombre de prise en charge d'urgence : {nbPcTam?.length}{" "}
                </div>
                <div>Nombre de retour en famille : {nbRfTam?.length} </div>
                <div>Nombre de référencement : {nbRTam?.length} </div>
                <div>Nombre de projet de vie : {nbPvTam?.length} </div>
                <div>Nombre de suivi : {nbSTam?.length}</div>
              </CardBody>
            </Card>
          </Col>
          <Col lg="3">
            <Card className="card-chart">
              <CardBody>
                <h3 className="card-category">Bureau : Enda Sarea </h3>
                <div>Nombre d'enfants : {nbEnfSar?.length} </div>
                <div>Nombre d'identifications : {nbIdSar?.length} </div>
                <div>
                  Nombre de prise en charge d'urgence : {nbPcSar?.length}{" "}
                </div>
                <div>Nombre de retour en famille : {nbRfSar?.length} </div>
                <div>Nombre de référencement : {nbRSar?.length} </div>
                <div>Nombre de projet de vie : {nbPvSar?.length} </div>
                <div>Nombre de suivi : {nbSSar?.length}</div>
              </CardBody>
            </Card>
          </Col>
          <Col lg="3">
            <Card className="card-chart">
              <CardBody>
                <h3 className="card-category">Bureau : Enda Kaolack </h3>
                <div>Nombre d'enfants : {nbEnfKao?.length} </div>
                <div>Nombre d'identifications : {nbIdKao?.length} </div>
                <div>
                  Nombre de prise en charge d'urgence : {nbPcKao?.length}{" "}
                </div>
                <div>Nombre de retour en famille : {nbRfKao?.length} </div>
                <div>Nombre de référencement : {nbRKao?.length} </div>
                <div>Nombre de projet de vie : {nbPvKao?.length} </div>
                <div>Nombre de suivi : {nbSKao?.length}</div>
              </CardBody>
            </Card>
          </Col>
          <Col lg="3">
            <Card className="card-chart">
              <CardBody>
                <h3 className="card-category">Bureau : Enda Thies </h3>
                <div>Nombre d'enfants : {nbEnfThi?.length} </div>
                <div>Nombre d'identifications : {nbIdThi?.length} </div>
                <div>
                  Nombre de prise en charge d'urgence : {nbPcThi?.length}{" "}
                </div>
                <div>Nombre de retour en famille : {nbRfThi?.length} </div>
                <div>Nombre de référencement : {nbRThi?.length} </div>
                <div>Nombre de projet de vie : {nbPvThi?.length} </div>
                <div>Nombre de suivi : {nbSThi?.length}</div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Dashboard;
