import React, { useState, useEffect } from "react";
import { Button } from "@material-ui/core";
import api from "services/api";
import Notiflix from "notiflix";
import { Input } from "@chakra-ui/react";
const ReferencementValidationUpdate = ({
  ficheId,
  onclose,
  idRow,
  dataToVerify,
}) => {
  const updateData = dataToVerify[idRow];
  const [dataToUpdate, setDataToUpdate] = useState();
  const [checkboxValue, setCheckboxValue] = useState();
  const [inputValue, setInputValue] = useState({
    id: ficheId,
  });

  const handleChange = (event) => {
    setInputValue({
      ...inputValue,
      [event.target.name]: event.target.value,
    });
    setDataToUpdate("");
  };
  const handleCheckbox = (event) => {
    setCheckboxValue({
      ...checkboxValue,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    api
      .updateRefVerification({ inputValue, checkboxValue })
      .then((response) => {
        if (response) {
          console.log(response);
          Notiflix.Notify.success("Données validées  ");
          window.location.reload(true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    setDataToUpdate(updateData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="checkbox-container">
      <form
        onSubmit={handleSubmit}
        className="dateprise"
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          className="form-container"
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div className="form-container-block">
            <div>
              <h3 style={{ fontSize: "13px", fontWeight: "bolder" }}>
                Référencement :
              </h3>
              <span style={{ background: "yellow" }}>
                {" "}
                {dataToUpdate?.referencement}
              </span>
              <br />
              <div>
                {" "}
                Oui{" "}
                <input
                  type="radio"
                  name="referencement"
                  value="Oui"
                  onChange={handleCheckbox}
                />
              </div>
              <div>
                {" "}
                Non{" "}
                <input
                  type="radio"
                  onChange={handleCheckbox}
                  name="referencement"
                  value="Non"
                />
              </div>
            </div>
            <div>
              <h3 style={{ fontSize: "13px", fontWeight: "bolder" }}>
                {" "}
                Date référencement :
              </h3>
              <Input
                name="date_referencement"
                onChange={handleChange}
                type="date"
                required
                value={dataToUpdate?.date_referencement?.substring(0, 10)}
                style={{
                  border: "none",
                  background: "lightgray",
                  paddingLeft: "5px",
                }}
              />
            </div>
            <div>
              <h3 style={{ fontSize: "13px", fontWeight: "bolder" }}>
                {" "}
                Heure référencement :
              </h3>
              <Input
                name="heure_referencement"
                value={dataToUpdate?.heure_referencement}
                type="time"
                onChange={handleChange}
                style={{
                  border: "none",
                  background: "lightgray",
                  paddingLeft: "5px",
                }}
              />
            </div>
            <div>
              <h3 style={{ fontSize: "13px", fontWeight: "bolder" }}>
                Prénom et nom du responsable du référencement :{" "}
              </h3>
              <Input
                name="nom_responsable_ref"
                type="text"
                onChange={handleChange}
                value={dataToUpdate?.nom_responsable_ref}
                placeholder="...."
                style={{
                  border: "none",
                  background: "lightgray",
                  paddingLeft: "5px",
                }}
              />
            </div>
            <div>
              <h3 style={{ fontSize: "13px", fontWeight: "bolder" }}>
                {" "}
                Bureau :{" "}
              </h3>
              <Input
                name="structure_ref"
                type="text"
                onChange={handleChange}
                value={dataToUpdate?.structure_ref}
                placeholder="...."
                style={{
                  border: "none",
                  background: "lightgray",
                  paddingLeft: "5px",
                }}
              />
            </div>

            <div>
              <h3 style={{ fontSize: "13px", fontWeight: "bolder" }}>
                {" "}
                Téléphone :{" "}
              </h3>
              <Input
                name="tel_ref"
                type="tel"
                onChange={handleChange}
                value={dataToUpdate?.tel_ref}
                placeholder="...."
                style={{
                  border: "none",
                  background: "lightgray",
                  paddingLeft: "5px",
                }}
              />
            </div>
            <div onChange={handleCheckbox}>
              <h3 style={{ fontSize: "13px", fontWeight: "bolder" }}>
                Nom du service :
              </h3>
              <Input
                type="text"
                name="nom_service_ref"
                value={dataToUpdate?.nom_service_ref}
                onChange={handleChange}
                placeholder="nom du service"
                style={{
                  background: "lightgray",

                  paddingLeft: "5px",
                }}
              />{" "}
              <h3 style={{ fontSize: "13px", fontWeight: "bolder" }}>
                Catégorie de la Structure ou du services de référencement :{" "}
                <br />{" "}
                <span style={{ background: "yellow" }}>
                  {dataToUpdate?.categorie_service_ref}
                </span>
              </h3>
              <span>
                {" "}
                <input
                  type="radio"
                  name="categorie_service_ref"
                  value="Centres de prise en charge"
                />{" "}
                Centres de prise en charge <br />
              </span>
              <span>
                {" "}
                <input
                  type="radio"
                  name="categorie_service_ref"
                  value="Structures sanitaires"
                />{" "}
                Structures sanitaires <br />
              </span>
              <span>
                {" "}
                <input type="radio" name="categorie_service_ref" value="AEMO" />
                AEMO <br />
              </span>{" "}
              <span>
                {" "}
                <input
                  type="radio"
                  name="categorie_service_ref"
                  value="ONG/Associations"
                />{" "}
                ONG/Associations <br />
              </span>
              <span>
                {" "}
                <h3 style={{ fontSize: "13px", fontWeight: "bolder" }}>
                  Autres à préciser :{" "}
                </h3>
                <Input
                  name="categorie_service_ref"
                  type="text"
                  placeholder="Précisez ici ..."
                  style={{
                    border: "none",
                    background: "lightgray",
                  }}
                />
              </span>
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row-reverse",
            marginTop: "3rem",
          }}
        >
          <div className="sbmit-btn">
            <Button
              onClick={() => onclose()}
              style={{ marginRight: "1rem", background: "red" }}
              className="mb-4"
              variant="contained"
              color="primary"
              type="button"
            >
              Annuler
            </Button>
          </div>
          <div className="sbmit-btn">
            <Button
              style={{ marginRight: "1rem", background: "green" }}
              className="mb-4"
              variant="contained"
              color="primary"
              type="submit"
            >
              valider{" "}
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default ReferencementValidationUpdate;
