import React, { useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import "./actionSurEnfant.scss";
import { Button } from "reactstrap";
import Notiflix from "notiflix";
import api from "services/api";
import { AiOutlineEye } from "react-icons/ai";
import { BiArrowBack } from "react-icons/bi";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from "@chakra-ui/react";
import ResumeRefs from "./resumeFiche/ResumeRefs";
import UpdateRefs from "./updateFiche/UpdateRefs";
import { Input } from "@chakra-ui/react";
import { withRouter } from "react-router-dom";
const Referencement = (props) => {
  const loggedUserData = JSON.parse(localStorage.getItem("data"));
  const childId = parseInt(props.match.params.id)
  const openResume = useDisclosure();
  const openUpdate = useDisclosure();
  const [idRow, setIdRow] = useState();
  const [hiddenDiv, setHiddenDiv] = useState(true);
  const [refsList, setRefsList] = useState();
  const [inputValue, setInputValue] = useState();

  const handleAddForm = () => {
    setHiddenDiv(!hiddenDiv);
  };

  //mui dataTables
  const columns = [
    {
      name: "id",
      label: "Code",
    },
    {
      label: "Referencement",
      name: "referencement",
      options: { display: false },
    },
    {
      label: "Date Referencement",
      name: "date_referencement",
      options: {
        display: false,
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          const index = tableMeta.rowData.findIndex((el) => el?.length === 24);
          return <span>{tableMeta.rowData[index]?.substring(0, 10)} </span>;
        },
      },
    },
    { label: "Heure Referencement", name: "heure_referencement" },
    {
      label: "Nom responsable du Referencement",
      name: "nom_responsable_ref",
      options: { display: false },
    },
    { label: "Numero du responsable ", name: "tel_ref" },
    { label: "Structure", name: "structure_ref" },
    {
      label: "Service Referencement",
      name: "categorie_service_ref",
      options: { display: false },
    },
    {
      label: "Nom service Referencement",
      name: "nom_service_ref",
      options: { display: false },
    },
    { label: "Gestionnaire de cas", name: "gestionnaire_cas" },
    {
      name: "bureau",
      label: "Bureau",
    },
    {
      name: "statut",
      label: "Statut",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const index = tableMeta.rowData.findIndex(
            (el) => el === "VALIDER" || el === "EN ATTENTE"
          );
          if (tableMeta.rowData[index] === "VALIDER") {
            return (
              <span style={{ color: "green" }}>
                {tableMeta.rowData[index]}{" "}
              </span>
            );
          }
          if (tableMeta.rowData[index] === "EN ATTENTE") {
            return (
              <span style={{ color: "red" }}>{tableMeta.rowData[index]} </span>
            );
          }
        },
      },
    },
    {
      name: "Action",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          const _statut = tableMeta.rowData.find(
            (el) => el === "VALIDER" || el === "EN ATTENTE"
          );
          const gestCas = tableMeta.rowData.find((el) => el === loggedUserName);
          const _bureau = tableMeta.rowData.find(
            (el) => el === loggedUserBureau
          );
          return (
            <>
              <button
                onClick={() => {
                  openResume.onOpen();
                  setIdRow(tableMeta.rowIndex);
                }}
                style={{
                  background: "lightgray",
                  color: "#fff",
                  fontSize: "15px",
                  padding: " 3px",
                  borderRadius: "3px",

                  position: "relative",
                  top: "3px",
                  right: "5px",
                }}
              >
                <AiOutlineEye />
              </button>
              <button
                disabled={
                  (loggedUserRole === "ROLE_USER" &&
                    _statut === "EN ATTENTE" &&
                    gestCas === loggedUserName) ||
                    (loggedUserRole === "ROLE_RESPONSABLE" &&
                      _bureau === loggedUserBureau) ||
                    loggedUserRole === "ROLE_ADMIN"
                    ? false
                    : true
                }
                onClick={() => {
                  openUpdate.onOpen();
                  setIdRow(tableMeta.rowIndex);
                }}
                style={{
                  background: "orange",
                  color: "#fff",
                  fontSize: "11px",
                  padding: "3px",
                  borderRadius: "3px",
                }}
              >
                Editer
              </button>
              <button
                disabled={true ? loggedUserRole !== "ROLE_ADMIN" : false}
                onClick={() => confirmDelete(tableMeta.rowData[0])}
                style={{
                  background: "red",
                  color: "#fff",
                  fontSize: "11px",
                  padding: " 3px",
                  borderRadius: "3px",
                  marginLeft: "5px",
                }}
              >
                Supprimer
              </button>
            </>
          );
        },
      },
    },
  ];
  const options = {
    filterType: "textField",
    pagination: false,
    print: false,
    enableNestedDataAccess: ".",
    download: false,
    filterOptions: {
      useDisplayedColumnsOnly: true,
      useDisplayedRowsOnly: true,
    },
  };
  const loggedUserRole = JSON.parse(localStorage.getItem("data"))?.roles[0];
  const loggedUserBureau = JSON.parse(localStorage.getItem("data"))?.bureau;
  const loggedUserName = JSON.parse(localStorage.getItem("data"))?.username;

  const handleChange = (event) => {
    setInputValue({
      ...inputValue,
      [event.target.name]: event.target.value,
    });
  };
  const confirmDelete = (id) => {
    Notiflix.Confirm.show(
      " ",
      "Etes-vous sûr de vouloir supprimer ?",
      "Confirmer",
      "Annuler",
      () => {
        api
          .deleteRefs(id)
          .then((response) => {
            Notiflix.Notify.success("supprimé !!");
            window.location.reload(true);
          })
          .catch((error) => console.log(error));
      }
    );
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    api
      .createReferencement({
        loggedUserData,
        inputValue,
        childId,
      })
      .then((response) => {
        if (response) {
          Notiflix.Notify.success("Referencement créer avec succès ");
          window.location.reload(true);
        }
      })
      .catch((error) => console.log(error));
  };
  useEffect(() => {
    api
      .getAllRefs(childId)
      .then((response) => {
        if (response) {
          setRefsList(response.data);
        }
      })
      .catch((error) => console.log(error));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [childId]);

  return (
    <div className="content fiche-styles">
      <div className="m-sm-30">
        <Modal
          onClose={openResume.onClose}
          size={"xl"}
          isOpen={openResume.isOpen}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalCloseButton />
            <ModalBody>
              <ResumeRefs refsList={refsList} idRow={idRow} />
            </ModalBody>
          </ModalContent>
        </Modal>
        <Modal
          onClose={openUpdate.onClose}
          size={"xl"}
          isOpen={openUpdate.isOpen}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalCloseButton />
            <ModalBody>
              <UpdateRefs refsList={refsList} idRow={idRow} />
            </ModalBody>
          </ModalContent>
        </Modal>
        <div className="checkbox-container" hidden={hiddenDiv}>
          <div className="dateprise" style={{ marginBottom: "2rem" }}>
            <div className="info-real">
              <ul>
                <li>Gestionnaire cas : {loggedUserData?.username}</li>
                <li>Bureau : {loggedUserData?.bureau} </li>
                <li>Tél : {loggedUserData?.tel} </li>
                <li>Email : {loggedUserData?.email} </li>
              </ul>
            </div>{" "}
            <form
              onSubmit={handleSubmit}
              className="form-container"
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div className="form-container-block" onChange={handleChange}>
                <div>
                  <h3 style={{ fontSize: "13px", fontWeight: "bolder" }}>
                    Référencement :
                  </h3>
                  <div>
                    {" "}
                    Oui <input type="radio" name="referencement" value="Oui" />
                  </div>
                  <div>
                    {" "}
                    Non <input type="radio" name="referencement" value="Non" />
                  </div>
                </div>
                <div>
                  <h3 style={{ fontSize: "13px", fontWeight: "bolder" }}>
                    {" "}
                    Date référencement :
                  </h3>
                  <Input
                    name="date_referencement"
                    type="date"
                    required
                    style={{
                      border: "none",
                      background: "lightgray",
                    }}
                  />
                </div>
                <div>
                  <h3 style={{ fontSize: "13px", fontWeight: "bolder" }}>
                    {" "}
                    Heure référencement :
                  </h3>
                  <Input
                    name="heure_referencement"
                    type="time"
                    style={{
                      border: "none",
                      background: "lightgray",
                    }}
                  />
                </div>
                <div>
                  <h3 style={{ fontSize: "13px", fontWeight: "bolder" }}>
                    Prénom et nom du responsable du référencement :{" "}
                  </h3>
                  <Input
                    name="nom_responsable_ref"
                    type="text"
                    placeholder="...."
                    style={{
                      border: "none",
                      background: "lightgray",
                    }}
                  />
                </div>
                <div>
                  <h3 style={{ fontSize: "13px", fontWeight: "bolder" }}>
                    {" "}
                    Structure :{" "}
                  </h3>
                  <Input
                    name="structure_ref"
                    type="text"
                    placeholder="...."
                    style={{
                      border: "none",
                      background: "lightgray",
                    }}
                  />
                </div>

                <div>
                  <h3 style={{ fontSize: "13px", fontWeight: "bolder" }}>
                    {" "}
                    Téléphone :{" "}
                  </h3>
                  <Input
                    name="tel_ref"
                    type="text"
                    placeholder="...."
                    style={{
                      border: "none",
                      background: "lightgray",
                    }}
                  />
                </div>

                <div>
                  <h3 style={{ fontSize: "13px", fontWeight: "bolder" }}>
                    nom du service
                  </h3>
                  <Input
                    type="text"
                    name="nom_service_ref"
                    placeholder="nom du service"
                    style={{
                      background: "lightgray",

                      paddingLeft: "5px",
                    }}
                  />
                  <h3 style={{ fontSize: "13px", fontWeight: "bolder" }}>
                    Catégorie de la (Structure ou du services de référencement)
                  </h3>
                  <div>
                    {" "}
                    <input
                      type="radio"
                      name="categorie_service_ref"
                      value="Centres de prise en charge"
                    />
                    Centres de prise en charge{" "}
                  </div>{" "}
                  <div>
                    {" "}
                    <input
                      type="radio"
                      name="categorie_service_ref"
                      value="Structures sanitaires"
                    />
                    Structures sanitaires{" "}
                  </div>{" "}
                  <div>
                    {" "}
                    <input
                      type="radio"
                      name="categorie_service_ref"
                      value="AEMO"
                    />{" "}
                    AEMO{" "}
                  </div>{" "}
                  <div>
                    {" "}
                    <input
                      type="radio"
                      name="categorie_service_ref"
                      value="ONG/Associations"
                    />{" "}
                    ONG/Associations{" "}
                  </div>
                  <div>
                    {" "}
                    <h3 style={{ fontSize: "13px", fontWeight: "bolder" }}>
                      Autres à préciser{" "}
                    </h3>
                    <Input
                      name="categorie_service_ref"
                      type="text"
                      placeholder="Précisez ici ..."
                      style={{
                        border: "none",
                        background: "lightgray",
                      }}
                    />
                  </div>{" "}
                </div>
              </div>
              <div className="sbmit-btn">
                <Button
                  className="mb-4"
                  variant="contained"
                  color="primary"
                  type="submit"
                >
                  Enregistrer
                </Button>
                <Button
                  onClick={() => window.location.reload(true)}
                  style={{ background: "red" }}
                  className="mb-4"
                  type="button"
                >
                  Annuler
                </Button>
              </div>
            </form>
          </div>
        </div>
        <div className="list" hidden={!hiddenDiv}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>

            <BiArrowBack style={{ cursor: "pointer" }} size={"25"} onClick={() => window.location.href = '/admin/enfant'} />
            <Button
              onClick={() => handleAddForm()}
              className="mb-4"
              variant="contained"
              style={{ textTransform: "uppercase" }}
            >
              Créer référencements
            </Button>
          </div>
          <div className="info-real">
            <ul style={{ width: "15%" }}>
              <li>Nom : {refsList?.nom}</li>
              <li>Prenom : {refsList?.prenom} </li>
            </ul>
          </div>{" "}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "1rem",
            }}
          >
            {" "}
            <h3
              style={{
                fontSize: "20px",
                margin: "0 0 17px",
                fontWeight: "bolder",
              }}
            >
              Tableau Référencements
            </h3>
          </div>
          <MUIDataTable
            data={refsList?.referencement}
            columns={columns}
            options={options}
          />
        </div>
      </div>
    </div>

  );
};

export default withRouter(Referencement)
