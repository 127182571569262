import React, { useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import { AiOutlineEye } from "react-icons/ai";
import { BiArrowBack } from "react-icons/bi";
import "./actionSurEnfant.scss";
import '../../styles/fiches.scss'
import { Input } from "@chakra-ui/react";
import Notiflix from "notiflix";
import { Button, CardBody } from "reactstrap";
import api from "../../services/api";
import Select from "react-select";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from "@chakra-ui/react";
import ResumePriseEnCharge from "./resumeFiche/ResumePriseEnCharge";
import UpdatePriseEnCharge from "./updateFiche/UpdatePriseEnCharge";

import { withRouter } from "react-router-dom";
const PriseEnCharge = (props) => {
  const loggedUserData = JSON.parse(localStorage.getItem("data"));
  const childId = parseInt(props.match.params.id)
  const openResume = useDisclosure();
  const openUpdate = useDisclosure();
  const [idRow, setIdRow] = useState();
  const [hiddenDiv, setHiddenDiv] = useState(true);
  const [urgList, setUrgList] = useState();
  const [inputValue, setInputValue] = useState();
  const [selectValue, setSelectValue] = useState({
    selectedOption: "",
  });

  const _options = [
    { value: "Assistance psychosociale", label: "Assistance psychosociale" },
    { value: "Assistance judiciaire", label: "Assistance judiciaire" },
    { value: "Assistance médicale", label: "Assistance médicale" },
    { value: "Restauration", label: "Restauration" },
    { value: "Hygiène corporelle", label: "Hygiène corporelle" },
    { value: "Habillement", label: "Habillement" },
    { value: "Hébergement", label: "Hébergement" },
    { value: "Loisir", label: "Loisir" },
  ];

  //mui dataTables
  const columns = [
    {
      name: "id",
      label: "Code",
    },
    {
      name: "date_prise_en_charge",
      label: "Date prise en charge",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <span>{tableMeta.rowData[1]?.substring(0, 10)} </span>;
        },
      },
    },
    {
      name: "services_offert_enfant",
      label: "Services Offerts",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <span>{tableMeta.rowData[2].trim()} </span>;
        },
      },
    },
    {
      name: "autres_services_offert",
      options: { display: false },
      label: "Autres",
    },
    { label: "Gestionnaire de cas", name: "gestionnaire_cas" },
    {
      name: "bureau",
      label: "Bureau",
    },
    {
      name: "statut",
      label: "Statut",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const index = tableMeta.rowData.findIndex(
            (el) => el === "VALIDER" || el === "EN ATTENTE"
          );
          if (tableMeta.rowData[index] === "VALIDER") {
            return (
              <span style={{ color: "green" }}>
                {tableMeta.rowData[index]}{" "}
              </span>
            );
          }
          if (tableMeta.rowData[index] === "EN ATTENTE") {
            return (
              <span style={{ color: "red" }}>{tableMeta.rowData[index]} </span>
            );
          }
        },
      },
    },
    {
      name: "Action",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          const _statut = tableMeta.rowData.find(
            (el) => el === "VALIDER" || el === "EN ATTENTE"
          );
          const gestCas = tableMeta.rowData.find((el) => el === loggedUserName);
          const _bureau = tableMeta.rowData.find(
            (el) => el === loggedUserBureau
          );
          return (
            <>
              <button
                disabled={
                  (loggedUserRole === "ROLE_USER" &&
                    _statut === "EN ATTENTE" &&
                    gestCas === loggedUserName) ||
                    (loggedUserRole === "ROLE_RESPONSABLE" &&
                      _bureau === loggedUserBureau) ||
                    loggedUserRole === "ROLE_ADMIN"
                    ? false
                    : true
                }
                onClick={() => {
                  openResume.onOpen();
                  setIdRow(tableMeta.rowIndex);
                }}
                style={{
                  background: "lightgray",
                  color: "#fff",
                  fontSize: "15px",
                  padding: " 3px",
                  borderRadius: "3px",

                  position: "relative",
                  top: "3px",
                  right: "5px",
                }}
              >
                <AiOutlineEye />
              </button>
              <button
                disabled={tableMeta.rowData.findIndex((el) =>
                  el === "EN ATTENTE" && el === loggedUserBureau ? false : true
                )}
                onClick={() => {
                  openUpdate.onOpen();
                  setIdRow(tableMeta.rowIndex);
                }}
                style={{
                  background: "orange",
                  color: "#fff",
                  fontSize: "11px",
                  padding: "3px",
                  borderRadius: "3px",
                }}
              >
                Editer
              </button>
              <button
                disabled={true ? loggedUserRole !== "ROLE_ADMIN" : false}
                onClick={() => confirmDelete(tableMeta.rowData[0])}
                style={{
                  background: "red",
                  color: "#fff",
                  fontSize: "11px",
                  padding: " 3px",
                  borderRadius: "3px",
                  marginLeft: "5px",
                }}
              >
                Supprimer
              </button>
            </>
          );
        },
      },
    },
  ];
  const options = {
    filterType: "textField",
    pagination: false,
    print: false,
    download: false,
    enableNestedDataAccess: ".",
    filterOptions: {
      useDisplayedColumnsOnly: true,
      useDisplayedRowsOnly: true,
    },
  };

  const loggedUserRole = JSON.parse(localStorage.getItem("data"))?.roles[0];
  const loggedUserBureau = JSON.parse(localStorage.getItem("data"))?.bureau;
  const loggedUserName = JSON.parse(localStorage.getItem("data"))?.username;

  const handleAddForm = () => {
    setHiddenDiv(!hiddenDiv);
  };
  useEffect(() => {
    api
      .getAllUrgences(childId)
      .then((response) => {
        if (response) {
          setUrgList(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [childId]);

  const confirmDelete = (id) => {
    Notiflix.Confirm.show(
      " ",
      "Etes-vous sûr de vouloir supprimer ?",
      "Confirmer",
      "Annuler",
      () => {
        api
          .deletePriseEnCharge(id)
          .then((response) => {
            Notiflix.Notify.success("supprimé !!");
            window.location.reload(true);
          })
          .catch((error) => console.log(error));
      }
    );
  };

  const handleSelect = (selectedOption) => {
    setSelectValue(selectedOption);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const services_offert_enfant = selectValue.map((item) => {
      return item.value;
    });

    api
      .createPriseEnCharge({
        services_offert_enfant: services_offert_enfant.toString(),
        loggedUserData,
        inputValue,
        childId,
      })
      .then((response) => {
        if (response) {
          Notiflix.Notify.success("Prise en charge créer avec succès ");
          window.location.reload(true);
        }
      })
      .catch((error) => console.log(error));
  };

  const handleInput = (event) => {
    setInputValue({
      ...inputValue,
      [event.target.name]: event.target.value,
    });
  };

  return (
    <div className="content fiche-styles">
      <div className="m-sm-30">
        <Modal
          onClose={openResume.onClose}
          size={"xl"}
          isOpen={openResume.isOpen}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalCloseButton />
            <ModalBody>
              <ResumePriseEnCharge urgList={urgList} idRow={idRow} />
            </ModalBody>
          </ModalContent>
        </Modal>
        <Modal
          onClose={openUpdate.onClose}
          size={"xl"}
          isOpen={openUpdate.isOpen}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalCloseButton />
            <ModalBody>
              <UpdatePriseEnCharge urgList={urgList} idRow={idRow} />
            </ModalBody>
          </ModalContent>
        </Modal>
        <div className="checkbox-container" hidden={hiddenDiv}>
          <div className="dateprise" style={{ marginBottom: "1rem" }}>
            <div className="info-real">
              <div className="info-real">
                <ul>
                  <li>Gestionnaire cas : {loggedUserData?.username}</li>
                  <li>Bureau : {loggedUserData?.bureau} </li>
                  <li>Tél : {loggedUserData?.tel} </li>
                  <li>Email : {loggedUserData?.email} </li>
                </ul>
              </div>{" "}
            </div>{" "}
          </div>
          <form
            onSubmit={handleSubmit}
            className="form-container"
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div className="form-container-block">
              <span style={{ display: "flex", marginBottom: "1rem" }}>
                <h3
                  style={{
                    fontSize: "13px",
                    width: "40%",
                    fontWeight: "bolder",
                  }}
                >
                  {" "}
                  Date prise en charge
                </h3>
                <Input
                  style={{
                    width: "60%",
                    border: "none",
                    background: "lightgray",
                  }}
                  type="date"
                  required
                  onChange={handleInput}
                  name="date_prise_en_charge"
                  placeholder="Autre"
                />
              </span>
              <div>
                {" "}
                <Select
                  onChange={handleSelect}
                  placeholder="service offert"
                  closeMenuOnSelect={false}
                  isMulti
                  options={_options}
                />
              </div>

              <div style={{ marginTop: "10px" }}>
                <Input
                  placeholder="Autre"
                  style={{
                    border: "none",
                    background: "lightgray",
                  }}
                />
              </div>
            </div>
            <div className="sbmit-btn">
              <Button
                type="submit"
                className="mb-4"
                variant="contained"
                color="primary"
              >
                Enregistrer
              </Button>
              <Button
                onClick={() => window.location.reload(true)}
                style={{ background: "red" }}
                className="mb-4"
                type="button"
              >
                Annuler
              </Button>
            </div>
          </form>
        </div>
        <div className="list" hidden={!hiddenDiv}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            {" "}

            <BiArrowBack style={{ cursor: "pointer" }} size={"25"} onClick={() => window.location.href = '/admin/enfant'} />
            <Button
              onClick={() => handleAddForm()}
              className="mb-4"
              variant="contained"
              style={{ textTransform: "uppercase" }}
            >
              Créer prise en charge
            </Button>
          </div>
          <div className="info-real">
            <ul style={{ width: "15%" }}>
              <li>Nom : {urgList?.nom}</li>
              <li>Prenom : {urgList?.prenom} </li>
            </ul>
          </div>{" "}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "1rem",
            }}
          >
            {" "}
            <h3
              style={{
                fontSize: "20px",
                margin: "0 0 17px",
                fontWeight: "bolder",
              }}
            >
              Tableau prise en charge
            </h3>
          </div>
          <CardBody className="all-icons">
            <MUIDataTable
              data={urgList?.urgence}
              columns={columns}
              options={options}
            />
          </CardBody>
        </div>
      </div>
    </div>
  );
};

export default withRouter(PriseEnCharge);
