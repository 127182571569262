import React, { useState, useEffect } from "react";
import "./resume.scss";
import api from "../services/api";
const Resume = ({ childId }) => {
  const [resumeData, setResumeData] = useState();
  useEffect(() => {
    api
      .getResume(childId)
      .then((response) => {
        if (response) {
          setResumeData(response.data);
        }
      })
      .catch((error) => console.log(error));
  }, [childId]);

  console.log(resumeData);
  return (
    <div className="resume_container">
      <div className="block_resume">
        <h3>Information général de l'enfant</h3>
        {resumeData?.gestionnaire_ca ? (
          <span>Gestionnaire cas : {resumeData?.gestionnaire_cas}, </span>
        ) : (
          ""
        )}
        {resumeData?.tel ? <span>Téléphone : {resumeData?.tel},</span> : ""}
        {resumeData?.email ? <span>Email : {resumeData?.email}, </span> : ""}
        {resumeData?.prenom ? <span>Prenom : {resumeData?.prenom},</span> : ""}
        {resumeData?.nom ? <span>Nom : {resumeData?.nom}, </span> : ""}
        {resumeData?.surnom ? <span>Surnom : {resumeData?.surnom}, </span> : ""}
        {resumeData?.sexe ? <span>Sexe : {resumeData?.sexe}, </span> : ""}
        {resumeData?.ddn ? (
          <span>Date de naissance : {resumeData?.ddn?.substring(0, 10)}, </span>
        ) : (
          ""
        )}
        {resumeData?.age ? <span>Age : {resumeData?.age}, </span> : ""}
        {resumeData?.lieu_naissance ? (
          <span>Lieu de naissance :{resumeData?.lieu_naissance}, </span>
        ) : (
          ""
        )}
        {resumeData?.pays_origine ? (
          <span>Pays d'origine :{resumeData?.pays_origine}, </span>
        ) : (
          ""
        )}
        {resumeData?.region_origine ? (
          <span>Region d'origine :{resumeData?.region_origine}, </span>
        ) : (
          ""
        )}
        {resumeData?.village_ville_origine ? (
          <span>
            Village ou ville d'origine : {resumeData?.village_ville_origine},{" "}
          </span>
        ) : (
          ""
        )}
        {resumeData?.nom_pere ? (
          <span>Nom du père :{resumeData?.nom_pere},</span>
        ) : (
          ""
        )}
        {resumeData?.profession_pere ? (
          <span>Profession du père :{resumeData?.profession_pere}, </span>
        ) : (
          ""
        )}
        {resumeData?.adresse_pere ? (
          <span>Adresse du père :{resumeData?.adresse_pere},</span>
        ) : (
          ""
        )}
        {resumeData?.tel_pere ? (
          <span>Téléphone du père : {resumeData?.tel_pere}, </span>
        ) : (
          ""
        )}
        {resumeData?.nom_mere ? (
          <span>Nom de la mère : {resumeData?.nom_mere}, </span>
        ) : (
          ""
        )}
        {resumeData?.profession_mere ? (
          <span>Profession de la mère : {resumeData?.profession_mere},</span>
        ) : (
          ""
        )}
        {resumeData?.adresse_mere ? (
          <span>Adresse de la mère :{resumeData?.adresse_mere},</span>
        ) : (
          ""
        )}
        {resumeData?.tel_mere ? (
          <span>Téléphone de la mère :{resumeData?.tel_mere},</span>
        ) : (
          ""
        )}
        {resumeData?.rang_enfant ? (
          <span>Rang de l'enfant :{resumeData?.rang_enfant}, </span>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default Resume;
