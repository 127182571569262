import React, { useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import { Card, CardHeader, CardBody, Row, Col, Button } from "reactstrap";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Input,
  FormControl,
  Select,
  FormLabel,
} from "@chakra-ui/react";
import Notiflix from "notiflix";
import api from "services/api";

const EnfantValidation = () => {
  const [fieldId, setFieldId] = useState();
  const [fieldToUpdate, setFieldToUpdate] = useState();
  const [fieldPendingData, setFieldPendingData] = useState();
  const fullModal = useDisclosure();
  const [inputValues, setInputValues] = useState();

  //recuperation du role du user connecté
  const loggedUserRole = JSON.parse(localStorage.getItem("data")).roles[0];
  const loggedUserBureau = JSON.parse(localStorage.getItem("data")).bureau;

  const initialRef = React.useRef();
  const finalRef = React.useRef();

  const confirmDelete = (id) => {
    Notiflix.Confirm.show(
      " ",
      "Etes-vous sûr de vouloir supprimer ?",
      "Confirmer",
      "Annuler",
      () => {
        api
          .deleteIdentification(id)
          .then((response) => {
            console.log(response);
            Notiflix.Notify.success("supprimé !!");
            window.location.reload();
          })
          .catch((error) => console.log(error));
      }
    );
  };
  //mui dataTables
  console.log(fieldPendingData);

  const columns = [
    {
      name: "id",
      label: "Code",
    },
    {
      name: "nom",
      label: "Nom",
    },
    {
      name: "prenom",
      label: "Prenom",
    },
    {
      name: "surnom",
      label: "Surnom",
    },
    {
      name: "ddn",
      label: "Date de Naissance",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <span>{tableMeta.rowData[4].substring(0, 10)} </span>;
        },
      },
    },
    {
      name: "age",
      label: "Age",
    },
    {
      name: "lieu_naissance",
      label: "Lieu de Naissance",
    },

    {
      name: "bureau",
      label: "Bureau",
    },
    {
      name: "statut",
      label: "Statut",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          console.log(tableMeta);
          if (tableMeta.rowData[8] === "VALIDER") {
            return (
              <span style={{ color: "green" }}>{tableMeta.rowData[8]} </span>
            );
          }
          if (tableMeta.rowData[8] === "EN ATTENTE") {
            return (
              <span style={{ color: "red" }}>{tableMeta.rowData[8]} </span>
            );
          }
        },
      },
    },
    {
      name: "",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          const _statut = tableMeta.rowData.find(
            (el) => el === loggedUserBureau
          );

          return (
            <>
              <button
                disabled={
                  (loggedUserRole === "ROLE_RESPONSABLE" &&
                    _statut === loggedUserBureau) ||
                  loggedUserRole === "ROLE_ADMIN"
                    ? false
                    : true
                }
                onClick={() => {
                  fullModal.onOpen();
                  getFieldToEditId(tableMeta.rowData[0]);
                }}
                style={{
                  background: "orange",
                  color: "#fff",
                  fontSize: "11px",
                  padding: "3px",
                  borderRadius: "3px",
                }}
              >
                Vérifier
              </button>
              <button
                disabled={true ? loggedUserRole !== "ROLE_ADMIN" : false}
                onClick={() => {
                  confirmDelete(tableMeta.rowData[0]);
                }}
                style={{
                  background: "red",
                  color: "#fff",
                  fontSize: "11px",
                  padding: " 3px",
                  borderRadius: "3px",
                  marginLeft: "5px",
                }}
              >
                Supprimer
              </button>
            </>
          );
        },
      },
    },
  ];

  const options = {
    filterType: "textField",
    pagination: false,
    print: false,
    download: false,
  };

  const handleChange = (event) => {
    setInputValues({
      ...inputValues,
      [event.target.name]: event.target.value,
    });
    setFieldToUpdate("");
  };
  const getFieldToEditId = (id) => {
    setFieldId(id);
    api
      .getChildById(id)
      .then((response) => {
        if (response) {
          console.log(response);
          setFieldToUpdate(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleSubmitUpdate = () => {
    console.log(fieldId);
    api
      .updateChildAfterVerification({ inputValues, fieldId })
      .then((response) => {
        if (response) {
          Notiflix.Notify.success("Données validées ");
          setTimeout(() => {
            window.location.reload(true);
          }, 1000);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    api
      .getChildPendingStatus()
      .then((response) => {
        if (response) {
          setFieldPendingData(response.data);
          console.log(response);
        }
      })
      .catch((error) => console.log(error));
  }, []);

  return (
    <div className="content">
      <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={fullModal?.isOpen}
        onClose={fullModal?.onClose}
        size={"xl"}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />

          <form>
            <ModalBody mt={10}>
              <div style={{ display: "flex" }} className="form-divider">
                <div style={{ width: "48%" }}>
                  <FormControl>
                    <Input
                      name="nom"
                      required
                      type="text"
                      value={fieldToUpdate?.nom}
                      ref={initialRef}
                      placeholder="nom"
                      onChange={handleChange}
                    />
                  </FormControl>
                </div>

                <div style={{ width: "48%", marginLeft: "2%" }}>
                  {" "}
                  <FormControl>
                    <Input
                      type="text"
                      name="prenom"
                      value={fieldToUpdate?.prenom}
                      required
                      placeholder="prenom"
                      onChange={handleChange}
                    />
                  </FormControl>
                </div>
              </div>
              <div
                style={{ display: "flex", marginTop: "1rem" }}
                className="form-divider"
              >
                <div style={{ width: "48%" }}>
                  <FormControl>
                    <Input
                      name="surnom"
                      value={fieldToUpdate?.surnom}
                      type="text"
                      placeholder="surnom"
                      onChange={handleChange}
                    />
                  </FormControl>
                </div>

                <div style={{ width: "48%", marginLeft: "2%" }}>
                  {" "}
                  <FormControl>
                    <Select
                      type="text"
                      value={fieldToUpdate?.sexe}
                      name="sexe"
                      required
                      placeholder="genre"
                      onChange={handleChange}
                    >
                      <option value="masculin">masculin</option>
                      <option value="feminin">feminin</option>
                    </Select>
                  </FormControl>
                </div>
              </div>
              <div style={{ display: "flex" }} className="form-divider">
                <div style={{ width: "48%" }}>
                  <FormControl>
                    <FormLabel>
                      <span
                        style={{
                          fontSize: "12px",
                          color: "lightgray",
                          marginLeft: "5px",
                        }}
                      >
                        date de naissance
                      </span>{" "}
                    </FormLabel>
                    <Input
                      value={fieldToUpdate?.ddn?.substring(0, 10)}
                      type="date"
                      name="ddn"
                      required
                      onChange={handleChange}
                    />
                  </FormControl>
                </div>

                <div style={{ width: "48%", marginLeft: "2%" }}>
                  {" "}
                  <FormControl>
                    <FormLabel>
                      <span
                        style={{
                          fontSize: "12px",
                          color: "lightgray",
                          marginLeft: "5px",
                        }}
                      ></span>{" "}
                    </FormLabel>
                    <Input
                      type="text"
                      name="age"
                      value={fieldToUpdate?.age}
                      placeholder="age"
                      onChange={handleChange}
                    />
                  </FormControl>
                </div>
              </div>
              <div
                style={{ display: "flex", marginTop: "1rem" }}
                className="form-divider"
              >
                <div style={{ width: "48%" }}>
                  <FormControl>
                    <Input
                      type="text"
                      name="lieu_naissance"
                      required
                      placeholder="lieu de naissance"
                      value={fieldToUpdate?.lieu_naissance}
                      onChange={handleChange}
                    />
                  </FormControl>
                </div>

                <div style={{ width: "48%", marginLeft: "2%" }}>
                  {" "}
                  <FormControl>
                    <Input
                      type="text"
                      name="pays_origine"
                      value={fieldToUpdate?.pays_origine}
                      placeholder="pays d'origine"
                      onChange={handleChange}
                    />
                  </FormControl>
                </div>
              </div>
              <div
                style={{ display: "flex", marginTop: "1rem" }}
                className="form-divider"
              >
                <div style={{ width: "48%" }}>
                  <FormControl>
                    <Input
                      type="text"
                      name="region_origine"
                      value={fieldToUpdate?.region_origine}
                      placeholder="region d'origine"
                      onChange={handleChange}
                    />
                  </FormControl>
                </div>

                <div style={{ width: "48%", marginLeft: "2%" }}>
                  {" "}
                  <FormControl>
                    <Input
                      type="text"
                      value={fieldToUpdate?.village_ville_origine}
                      name="village_ville_origine"
                      placeholder="village ou ville d'origine"
                      onChange={handleChange}
                    />
                  </FormControl>
                </div>
              </div>
              <div
                style={{ display: "flex", marginTop: "1rem" }}
                className="form-divider"
              >
                <div style={{ width: "48%" }}>
                  <FormControl>
                    <Input
                      type="text"
                      name="nom_pere"
                      value={fieldToUpdate?.nom_pere}
                      placeholder="nom du pére"
                      onChange={handleChange}
                    />
                  </FormControl>
                </div>

                <div style={{ width: "48%", marginLeft: "2%" }}>
                  {" "}
                  <FormControl>
                    <Input
                      type="text"
                      name="profession_pere"
                      value={fieldToUpdate?.profession_pere}
                      placeholder="profession du pére"
                      onChange={handleChange}
                    />
                  </FormControl>
                </div>
              </div>
              <div
                style={{ display: "flex", marginTop: "1rem" }}
                className="form-divider"
              >
                <div style={{ width: "48%" }}>
                  <FormControl>
                    <Input
                      type="tel"
                      name="tel_pere"
                      value={fieldToUpdate?.tel_pere}
                      placeholder="téléphone du pére"
                      onChange={handleChange}
                    />
                  </FormControl>
                </div>

                <div style={{ width: "48%", marginLeft: "2%" }}>
                  {" "}
                  <FormControl>
                    <Input
                      type="text"
                      name="adresse_pere"
                      value={fieldToUpdate?.adresse_pere}
                      placeholder="adresse du pére"
                      onChange={handleChange}
                    />
                  </FormControl>
                </div>
              </div>
              <div
                style={{ display: "flex", marginTop: "1rem" }}
                className="form-divider"
              >
                <div style={{ width: "48%" }}>
                  <FormControl>
                    <Input
                      type="text"
                      name="nom_mere"
                      value={fieldToUpdate?.nom_mere}
                      placeholder="nom de la mére"
                      onChange={handleChange}
                    />
                  </FormControl>
                </div>

                <div style={{ width: "48%", marginLeft: "2%" }}>
                  {" "}
                  <FormControl>
                    <Input
                      type="text"
                      name="profession_mere"
                      value={fieldToUpdate?.profession_mere}
                      placeholder="profession de la mére"
                      onChange={handleChange}
                    />
                  </FormControl>
                </div>
              </div>
              <div
                style={{ display: "flex", marginTop: "1rem" }}
                className="form-divider"
              >
                <div style={{ width: "48%" }}>
                  <FormControl>
                    <Input
                      type="tel"
                      name="tel_mere"
                      value={fieldToUpdate?.tel_mere}
                      placeholder="téléphone de la mére"
                      onChange={handleChange}
                    />
                  </FormControl>
                </div>

                <div style={{ width: "48%", marginLeft: "2%" }}>
                  {" "}
                  <FormControl>
                    <Input
                      type="text"
                      name="adresse_mere"
                      value={fieldToUpdate?.adresse_mere}
                      placeholder="adresse de la mére"
                      onChange={handleChange}
                    />
                  </FormControl>
                </div>
              </div>

              <FormControl mt={4}>
                <Input
                  type="text"
                  name="rang_enfant"
                  value={fieldToUpdate?.rang_enfant}
                  placeholder="rang de l'enfant"
                  onChange={handleChange}
                />
              </FormControl>
            </ModalBody>

            <ModalFooter>
              <Button
                onClick={() => handleSubmitUpdate()}
                type="button"
                style={{ background: "#16b84e" }}
                mr={3}
              >
                Valider
              </Button>
              <Button
                type="button"
                onClick={fullModal?.onClose}
                style={{ background: "red   " }}
              >
                Annuler
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>{" "}
      <Row>
        <Col md="12">
          <Card>
            <CardHeader>
              <h3
                style={{
                  fontSize: "20px",
                  margin: "0 0 17px",
                  fontWeight: "bolder",
                }}
              >
                Tableau enfant en attente
              </h3>
            </CardHeader>
            <CardBody className="all-icons">
              <MUIDataTable
                data={fieldPendingData}
                columns={columns}
                options={options}
              />
              {/* <Table className="tablesorter" responsive>
                <thead className="text-primary">
                  <tr>
                    <th>Code</th>
                    <th>Nom</th>
                    <th>Prenom</th>
                    <th>Surnom</th>
                    <th>Date de naissance</th>
                    <th>Age</th>
                    <th>Lieu de naissance</th>
                    <th>Bureau</th>
                    <th>Statut</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {fieldPendingData?.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>{item.id} </td>
                        <td>{item.nom} </td>
                        <td>{item.prenom} </td>
                        <td>{item.surnom} </td>
                        <td>{item.ddn?.substring(0, 10)} </td>
                        <td>{item.age} </td>
                        <td>{item.lieu_naissance}</td>
                        <td>{item.bureau}</td>
                        <td>
                          <span style={{ color: "red" }}>{item.statut}</span>
                        </td>

                        <td>
                          <button
                            onClick={() => {
                              fullModal.onOpen();
                              getFieldToEditId(item.id);
                            }}
                            style={{
                              background: "orange",
                              color: "#fff",
                              fontSize: "11px",
                              padding: "3px",
                              borderRadius: "3px",
                            }}
                          >
                            Verifier
                          </button>
                          <button
                            onClick={() => {}}
                            style={{
                              background: "red",
                              color: "#fff",
                              fontSize: "11px",
                              padding: "3px",
                              borderRadius: "3px",
                              marginLeft: "10px",
                            }}
                          >
                            supprimer
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table> */}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default EnfantValidation;
