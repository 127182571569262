import Notiflix from "notiflix";
import { Button } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import api from "services/api";
import { Input, Textarea } from "@chakra-ui/react";

const ProjetDeVieValidationUpdate = ({
  ficheId,
  onclose,
  idRow,
  dataToVerify,
}) => {
  const updateData = dataToVerify[idRow];
  const [inputValue, setInputValue] = useState({
    id: ficheId,
  });
  const [dataToUpdate, setDataToUpdate] = useState();
  const [checkboxValue, setCheckboxValue] = useState();

  const handleChange = (event) => {
    setInputValue({
      ...inputValue,
      [event.target.name]: event.target.value,
    });
    setDataToUpdate(false);
  };

  const handleCheckbox = (event) => {
    setCheckboxValue({
      ...checkboxValue,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    api
      .updateProjetDeVieVerification({ inputValue, checkboxValue })
      .then((response) => {
        if (response) {
          console.log(response);
          Notiflix.Notify.success("Données Modifiées ");
          window.location.reload(true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    setDataToUpdate(updateData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="checkbox-container">
      <form
        className="dateprise"
        onSubmit={handleSubmit}
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          className="form-container"
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div className="form-container-block" onChange={handleCheckbox}>
            <h3 style={{ fontWeight: "bold" }}>
              Projet de vie <br />{" "}
              <span style={{ background: "yellow", fontWeight: "normal" }}>
                {dataToUpdate?.projet}{" "}
              </span>{" "}
            </h3>
            <span
              style={{
                display: "flex",
              }}
            >
              <input
                type="radio"
                value="Scolarité"
                name="projet"
                style={{ marginTop: "5px", marginRight: "3px" }}
              />
              Scolarité
            </span>
            <span
              style={{
                display: "flex",
              }}
            >
              <input
                type="radio"
                name="projet"
                value="Professionnelle (apprentissage)"
                style={{ marginTop: "5px", marginRight: "3px" }}
              />
              Professionnelle (apprentissage)
            </span>
            <span
              style={{
                display: "flex",
              }}
            >
              <input
                type="radio"
                name="projet"
                value="Pas encore d’activités"
                style={{ marginTop: "5px", marginRight: "3px" }}
              />
              Pas encore d’activités
            </span>
            <span
              style={{
                display: "flex",
              }}
            >
              <input
                type="radio"
                name="projet"
                value="AGR pour l’enfant"
                style={{ marginTop: "5px", marginRight: "3px" }}
              />
              AGR pour l’enfant
            </span>
          </div>
          <div className="form-container-block">
            <div>
              <h3 style={{ fontSize: "13px", fontWeight: "bolder" }}>
                Description détaillé du projet :
              </h3>
              <Textarea
                name="descripton"
                onChange={handleChange}
                maxRows={4}
                value={dataToUpdate?.descripton}
                aria-label="maximum height"
                placeholder="Description du projet ici..."
                style={{
                  width: 200,
                  background: "lightgray",
                  border: "none",

                  paddingLeft: "5px",
                }}
              />
            </div>
            <div>
              <h3 style={{ fontSize: "13px", fontWeight: "bolder" }}>
                Cout du projet :
              </h3>
              <Input
                name="cout"
                type="number"
                onChange={handleChange}
                value={dataToUpdate?.cout}
                placeholder="fcfa"
                style={{
                  border: "none",
                  background: "lightgray",

                  paddingLeft: "5px",
                }}
              />
            </div>
            <div>
              <h3 style={{ fontWeight: "bolder", fontSize: "13px" }}>
                Ressources mobilisées ou disponible :
              </h3>
              <Textarea
                name="ressources"
                maxRows={4}
                value={dataToUpdate?.ressources}
                aria-label="maximum height"
                placeholder="precisez ici .."
                onChange={handleChange}
                style={{
                  width: 200,
                  background: "lightgray",
                  border: "none",
                  marginLeft: "5px",
                }}
              />
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row-reverse",
            marginTop: "2rem",
          }}
        >
          <div className="sbmit-btn">
            <Button
              onClick={() => onclose()}
              style={{ marginRight: "1rem", background: "red" }}
              className="mb-4"
              variant="contained"
              color="primary"
              type="button"
            >
              Annuler
            </Button>
          </div>
          <div className="sbmit-btn">
            <Button
              style={{ marginRight: "1rem", background: "green" }}
              className="mb-4"
              variant="contained"
              color="primary"
              type="submit"
            >
              valider{" "}
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default ProjetDeVieValidationUpdate;
