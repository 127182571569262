import axios from 'axios';
// const baseUrl = 'http://enda.sounoustyle.com';
const baseUrl = 'https://back.enda-ja.com';
// const baseUrl = 'http://localhost:8080';
// const baseUrl = 'http://167.86.74.213:8080';

axios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      //config.headers["Authorization"] = 'Bearer ' + token;  // for Spring Boot back-end
      config.headers['x-access-token'] = token; // for Node.js Express back-end
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
const userBureau = JSON.parse(localStorage.getItem('data'))?.bureau;

const api = {
  login: (body) => {
    return axios.post(`${baseUrl}/api/auth/signin`, body);
  },
  getUserProfil: (body) => {
    return axios.get(`${baseUrl}/api/auth/profil`, body);
  },
  getChildById: (id) => {
    return axios.get(`${baseUrl}/api/enfants/${id}`);
  },
  getAllChildren: (body) => {
    return axios.get(`${baseUrl}/api/enfants`, body);
  },
  getAllUsers: (body) => {
    return axios.get(`${baseUrl}/api/users`, body);
  },
  getUserById: (id) => {
    return axios.get(`${baseUrl}/api/etats/${id}`);
  },
  getAllIdentifications: (id) => {
    return axios.get(`${baseUrl}/api/enfants/identites/${id}`);
  },
  getAllUrgences: (id) => {
    return axios.get(`${baseUrl}/api/enfants/urgences/${id}`);
  },
  getAllRetour: (id) => {
    return axios.get(`${baseUrl}/api/enfants/familles/${id}`);
  },
  getAllRefs: (id) => {
    return axios.get(`${baseUrl}/api/enfants/references/${id}`);
  },
  getAllProjets: (id) => {
    return axios.get(`${baseUrl}/api/enfants/projets/${id}`);
  },
  getAllSuivis: (id) => {
    return axios.get(`${baseUrl}/api/enfants/suivis/${id}`);
  },
  getResume: (id) => {
    return axios.get(`${baseUrl}/api/fiches/${id}`);
  },
  getChildPendingStatus: (id) => {
    return axios.get(`${baseUrl}/api/enfants/validations`);
  },
  getIdentificationPendingStatus: (id) => {
    return axios.get(`${baseUrl}/api/identites/validations`);
  },
  getPriseEnChargePendingStatus: (id) => {
    return axios.get(`${baseUrl}/api/urgences/validations`);
  },
  getRetourPendingStatus: (id) => {
    return axios.get(`${baseUrl}/api/familles/validations`);
  },
  getRefPendingStatus: (id) => {
    return axios.get(`${baseUrl}/api/refs/validations`);
  },
  getProjetPendingStatus: (id) => {
    return axios.get(`${baseUrl}/api/projets/validations`);
  },
  getSuiviPendingStatus: (id) => {
    return axios.get(`${baseUrl}/api/suivis/validations`);
  },
  createUser: (body) => {
    const { userRole, inputValues } = body;
    return axios.post(`${baseUrl}/api/auth/signup`, {
      etat: 'activated',
      nom: inputValues.nom,
      username: inputValues.username,
      email: inputValues.email,
      password: inputValues.password,
      confirm_password: inputValues.password,
      tel: inputValues.tel,
      bureau: inputValues.bureau,
      roles: userRole,
    });
  },
  createChild: (body) => {
    const { loggedUserData, childId, inputValues, theAge, _child_code } = body;
    return axios.post(`${baseUrl}/api/enfants`, {
      enfantId: childId,
      child_code: _child_code,
      statut: 'EN ATTENTE',
      gestionnaire_cas:
        loggedUserData.username === null
          ? ''
          : loggedUserData.username
          ? loggedUserData.username
          : '',
      bureau:
        loggedUserData.bureau === null
          ? ''
          : loggedUserData.bureau
          ? loggedUserData.bureau
          : '',
      tel:
        loggedUserData.tel === null
          ? ''
          : loggedUserData.tel
          ? loggedUserData.tel
          : '',
      email:
        loggedUserData.email === null
          ? ''
          : loggedUserData.email
          ? loggedUserData.email
          : '',
      nom:
        inputValues?.nom === null
          ? ''
          : inputValues?.nom
          ? inputValues?.nom
          : '',
      prenom:
        inputValues?.prenom === null
          ? ''
          : inputValues?.prenom
          ? inputValues?.prenom
          : '',
      surnom:
        inputValues?.surnom === null
          ? ''
          : inputValues?.surnom
          ? inputValues?.surnom
          : '',
      sexe:
        inputValues?.sexe === null
          ? ''
          : inputValues?.sexe
          ? inputValues?.sexe
          : '',
      ddn:
        inputValues?.ddn === null
          ? ''
          : inputValues?.ddn
          ? inputValues?.ddn
          : '',
      age: theAge === null ? '' : theAge ? theAge : '',
      lieu_naissance:
        inputValues?.lieu_naissance === null
          ? ''
          : inputValues?.lieu_naissance
          ? inputValues?.lieu_naissance
          : '',
      pays_origine:
        inputValues?.pays_origine === null
          ? ''
          : inputValues?.pays_origine
          ? inputValues?.pays_origine
          : '',
      region_origine:
        inputValues?.region_origine === null
          ? ''
          : inputValues?.region_origine
          ? inputValues?.region_origine
          : '',
      village_ville_origine:
        inputValues?.village_ville_origine === null
          ? ''
          : inputValues?.village_ville_origine
          ? inputValues?.village_ville_origine
          : '',
      nom_pere:
        inputValues?.nom_pere === null
          ? ''
          : inputValues?.nom_pere
          ? inputValues?.nom_pere
          : '',
      profession_pere:
        inputValues?.profession_pere === null
          ? ''
          : inputValues?.profession_pere
          ? inputValues?.profession_pere
          : '',
      tel_pere:
        inputValues?.tel_pere === null
          ? ''
          : inputValues?.tel_pere
          ? inputValues?.tel_pere
          : '',
      adresse_pere:
        inputValues?.adresse_pere === null
          ? ''
          : inputValues?.adresse_pere
          ? inputValues?.adresse_pere
          : '',
      nom_mere:
        inputValues?.nom_mere === null
          ? ''
          : inputValues?.nom_mere
          ? inputValues?.nom_mere
          : '',
      profession_mere:
        inputValues?.profession_mere === null
          ? ''
          : inputValues?.profession_mere
          ? inputValues?.profession_mere
          : '',
      tel_mere:
        inputValues?.tel_mere === null
          ? ''
          : inputValues?.tel_mere
          ? inputValues?.tel_mere
          : '',
      adresse_mere:
        inputValues?.adresse_mere === null
          ? ''
          : inputValues?.adresse_mere
          ? inputValues?.adresse_mere
          : '',
      rang_enfant:
        inputValues?.rang_enfant === null
          ? ''
          : inputValues?.rang_enfant
          ? inputValues?.rang_enfant
          : '',
    });
  },
  createPriseEnCharge: (body) => {
    console.log(body);
    const { loggedUserData, inputValue, childId, services_offert_enfant } =
      body;
    return axios.post(`${baseUrl}/api/urgences`, {
      enfantId: childId,
      statut: 'EN ATTENTE',
      gestionnaire_cas: loggedUserData.username,
      bureau: loggedUserData.bureau,
      tel: loggedUserData.tel,
      email: loggedUserData.email,
      date_prise_en_charge: inputValue?.date_prise_en_charge
        ? inputValue?.date_prise_en_charge
        : '01-01-2040',
      services_offert_enfant: services_offert_enfant,
      autres_services_offert: inputValue?.autres_services_offert,
    });
  },
  createIdentification: (body) => {
    const { loggedUserData, inputValue, childId } = body;
    return axios.post(`${baseUrl}/api/identites`, {
      enfantId: childId === null ? '' : childId ? childId : '',
      statut: 'EN ATTENTE',
      gestionnaire_cas:
        loggedUserData.username === null
          ? ''
          : loggedUserData.username
          ? loggedUserData.username
          : '',
      bureau:
        loggedUserData.bureau === null
          ? ''
          : loggedUserData.bureau
          ? loggedUserData.bureau
          : '',
      tel:
        loggedUserData.tel === null
          ? ''
          : loggedUserData.tel
          ? loggedUserData.tel
          : '',
      email:
        loggedUserData.email === null
          ? ''
          : loggedUserData.email
          ? loggedUserData.email
          : '',
      date_remplissage:
        inputValue.date_remplissage === null
          ? '01-01-2040'
          : inputValue.date_remplissage
          ? inputValue.date_remplissage
          : '',
      pays_identification:
        inputValue.pays_identification === null
          ? ''
          : inputValue.pays_identification
          ? inputValue.pays_identification
          : '',
      region_identification:
        inputValue.region_identification === null
          ? ''
          : inputValue.region_identification
          ? inputValue.region_identification
          : '',
      date_identification:
        inputValue.date_identification === null
          ? '01-01-2040'
          : inputValue.date_identification
          ? inputValue.date_identification
          : '',
      situation_pere:
        inputValue.situation_pere === null
          ? ''
          : inputValue.situation_pere
          ? inputValue.situation_pere
          : '',
      situation_mere:
        inputValue.situation_mere === null
          ? ''
          : inputValue.situation_mere
          ? inputValue.situation_mere
          : '',
      situation_matrimoniale_parents:
        inputValue.situation_matrimoniale_parents === null
          ? ''
          : inputValue.situation_matrimoniale_parents
          ? inputValue.situation_matrimoniale_parents
          : '',
      niveau_instruction_enfant:
        inputValue.niveau_instruction_enfant === null
          ? ''
          : inputValue.niveau_instruction_enfant
          ? inputValue.niveau_instruction_enfant
          : '',
      precision_niveau:
        inputValue.precision_niveau === null
          ? ''
          : inputValue.precision_niveau
          ? inputValue.precision_niveau
          : '',
      vulnerabilite_enfant:
        inputValue.vulnerabilite_enfant === null
          ? ''
          : inputValue.vulnerabilite_enfant
          ? inputValue.vulnerabilite_enfant
          : '',
      duree_peine:
        inputValue.duree_peine === null
          ? '0'
          : inputValue.duree_peine
          ? inputValue.duree_peine
          : '0',
      auteurs_abus_viol:
        inputValue.auteurs_abus_viol === null
          ? ''
          : inputValue.auteurs_abus_viol
          ? inputValue.auteurs_abus_viol
          : '',
      nom_tuteur:
        inputValue.nom_tuteur === null
          ? ''
          : inputValue.nom_tuteur
          ? inputValue.nom_tuteur
          : '',
      lien_parente_tuteur:
        inputValue.lien_parente_tuteur === null
          ? ''
          : inputValue.lien_parente_tuteur
          ? inputValue.lien_parente_tuteur
          : '',
      adresse_tuteur:
        inputValue.adresse_tuteur === null
          ? ''
          : inputValue.adresse_tuteur
          ? inputValue.adresse_tuteur
          : '',
      tel_tuteur:
        inputValue.tel_tuteur === null
          ? ''
          : inputValue.tel_tuteur
          ? inputValue.tel_tuteur
          : '',
      date_arrivee_pays:
        inputValue.date_arrivee_pays === null
          ? '01-01-2040'
          : inputValue.date_arrivee_pays
          ? inputValue.date_arrivee_pays
          : '01-01-2040',
      localite_identification:
        inputValue.localite_identification === null
          ? ''
          : inputValue.localite_identification
          ? inputValue.localite_identification
          : '',
      condition_arrivee:
        inputValue.condition_arrivee === null
          ? ''
          : inputValue.condition_arrivee
          ? inputValue.condition_arrivee
          : '',
      lien_avec_adulte:
        inputValue.lien_avec_adulte === null
          ? ''
          : inputValue.lien_avec_adulte
          ? inputValue.lien_avec_adulte
          : '',
      motif_depart_milieu_vie_habituel:
        inputValue.motif_depart_milieu_vie_habituel === null
          ? ''
          : inputValue.motif_depart_milieu_vie_habituel
          ? inputValue.motif_depart_milieu_vie_habituel
          : '',
      mode_saisine_du_cas_de_lenfant:
        inputValue.mode_saisine_du_cas_de_lenfant === null
          ? ''
          : inputValue.mode_saisine_du_cas_de_lenfant
          ? inputValue.mode_saisine_du_cas_de_lenfant
          : '',
      etat_sante_physique:
        inputValue.etat_sante_physique === null
          ? ''
          : inputValue.etat_sante_physique
          ? inputValue.etat_sante_physique
          : '',
      comportements:
        inputValue.comportements === null
          ? ''
          : inputValue.comportements
          ? inputValue.comportements
          : '',
      dependances:
        inputValue.dependances === null
          ? ''
          : inputValue.dependances
          ? inputValue.dependances
          : '',
      nature_rapport:
        inputValue.nature_rapport === null
          ? ''
          : inputValue.nature_rapport
          ? inputValue.nature_rapport
          : '',
      type_lieu_hebergement:
        inputValue.type_lieu_hebergement === null
          ? ''
          : inputValue.type_lieu_hebergement
          ? inputValue.type_lieu_hebergement
          : '',
      resume_situation_enfant:
        inputValue.resume_situation_enfant === null
          ? ''
          : inputValue.resume_situation_enfant
          ? inputValue.resume_situation_enfant
          : '',
    });
  },
  createSuivi: (body) => {
    const { loggedUserData, inputValue, childId, checkboxValue } = body;
    return axios.post(`${baseUrl}/api/suivis`, {
      enfantId: childId,
      statut: 'EN ATTENTE',
      gestionnaire_cas: loggedUserData.username,
      bureau: loggedUserData.bureau,
      tel: loggedUserData.tel,
      email: loggedUserData.email,
      Date_suivi: inputValue.Date_suivi ? inputValue.Date_suivi : '01-01-2040',
      situation_actuelle_enfant: checkboxValue.situation_actuelle_enfant,
      nature_relation_fratrie: checkboxValue.nature_relation_fratrie,
      nature_relation_parent: checkboxValue.nature_relation_parent,
      nature_relation_voisinage: checkboxValue.nature_relation_voisinage,
      etat_sante_enfant: checkboxValue.etat_sante_enfant,
      Evolution_Projet_vie: checkboxValue.Evolution_Projet_vie,
      Evolution_Projet_vie_AGR: checkboxValue.Evolution_Projet_vie_AGR,
      motif_justificatif: inputValue.motif_justificatif,
      date_prise_decision: inputValue.date_prise_decision
        ? inputValue.date_prise_decision
        : '01-01-2040',
      decision_apres_evaluation_enfant:
        checkboxValue.decision_apres_evaluation_enfant,
    });
  },
  createProjet: (body) => {
    const { loggedUserData, inputValue, childId } = body;
    console.log(inputValue);
    return axios.post(`${baseUrl}/api/projets`, {
      enfantId: childId,
      statut: 'EN ATTENTE',
      gestionnaire_cas: loggedUserData.username,
      bureau: loggedUserData.bureau,
      tel: loggedUserData.tel,
      email: loggedUserData.email,
      projet: inputValue.projet,
      description: inputValue.description,
      cout: inputValue.cout,
      ressources: inputValue.ressources,
    });
  },
  createReferencement: (body) => {
    const { loggedUserData, inputValue, childId } = body;
    return axios.post(`${baseUrl}/api/refs`, {
      enfantId: childId,
      gestionnaire_cas: loggedUserData.username,
      bureau: loggedUserData.bureau,
      tel: loggedUserData.tel,
      statut: 'EN ATTENTE',
      email: loggedUserData.email,
      referencement: inputValue.referencement,
      date_referencement: inputValue.date_referencement
        ? inputValue.date_referencement
        : '01-01-2040',
      heure_referencement: inputValue.heure_referencement,
      nom_responsable_ref: inputValue.nom_responsable_ref,
      structure_ref: inputValue.structure_ref,
      tel_ref: inputValue.tel_ref,
      categorie_service_ref: inputValue.categorie_service_ref,
      nom_service_ref: inputValue.nom_service_ref,
    });
  },
  createFamille: (body) => {
    const { loggedUserData, inputValue, childId } = body;
    return axios.post(`${baseUrl}/api/familles`, {
      enfantId: childId,
      gestionnaire_cas: loggedUserData.username,
      bureau: loggedUserData.bureau,
      statut: 'EN ATTENTE',
      tel: loggedUserData.tel,
      email: loggedUserData.email,
      recherche_famille_realise_par: inputValue.recherche_famille_realise_par,
      recherche_famille_date: inputValue.recherche_famille_date
        ? inputValue.recherche_famille_date
        : '01-01-2040',
      recherche_famille_structure: inputValue.recherche_famille_structure,
      relation_enfant_famille: inputValue.relation_enfant_famille,
      relation_enfant_famille_pourquoi:
        inputValue.relation_enfant_famille_pourquoi,
      ressources_famille_enfant: inputValue.ressources_famille_enfant,
      ressources_famille_enfant_specifiez:
        inputValue.ressources_famille_enfant_specifiez,
      reprendre_enfant: inputValue.reprendre_enfant,
      reprendre_enfant_pourquoi: inputValue.reprendre_enfant_pourquoi,
      conclusion_evaluation: inputValue.conclusion_evaluation,
      retour_realiser_par: inputValue.retour_realiser_par,
      retour_famille_date: inputValue.retour_famille_date
        ? inputValue.retour_famille_date
        : '01-01-2040',
      retour_structure: inputValue.retour_structure,
      retour_lieu: inputValue.retour_lieu,
      personne_ayant_recu_enfant: inputValue.personne_ayant_recu_enfant,
      telephone_personne_ayant_recu_enfant:
        inputValue.telephone_personne_ayant_recu_enfant,
      adresse_personne_ayant_enfant: inputValue.adresse_personne_ayant_enfant,
      cin_personne_ayant_recu_enfant: inputValue.cin_personne_ayant_recu_enfant,
      retour_reintegration: inputValue.retour_reintegration,
      retour_a_signaler: inputValue.retour_a_signaler,
      ville: inputValue.ville,
      village_quartier: inputValue.village_quartier,
      region: inputValue.region,
      departement: inputValue.departement,
      pays: inputValue.pays,
      personne_contactee1: inputValue.personne_contactee1,
      personne_contactee2: inputValue.personne_contactee2,
      personne_contactee3: inputValue.personne_contactee3,
      adresse1: inputValue.adresse1,
      adresse2: inputValue.adresse2,
      adresse3: inputValue.adresse3,
      telephone1: inputValue.telephone1,
      telephone2: inputValue.telephone2,
      telephone3: inputValue.telephone3,
      lien_parente_enfant1: inputValue.lien_parente_enfant1,
      lien_parente_enfant2: inputValue.lien_parente_enfant2,
      lien_parente_enfant3: inputValue.lien_parente_enfant3,
    });
  },
  updateUserData: (body) => {
    const { userId, editInputs } = body;
    return axios.put(`${baseUrl}/api/etats/${userId}`, {
      id: userId,
      email: editInputs?.email,
      bureau: editInputs?.bureau,
      password: editInputs?.password,
      confirm_password: editInputs?.password,
      nom: editInputs?.nom,
      tel: editInputs?.tel,
      username: editInputs?.username,
    });
  },

  updateUserEtat: (body) => {
    const { id, etat } = body;
    return axios.put(`${baseUrl}/api/etats/${id}`, {
      id,
      etat:
        etat === 'activated'
          ? 'deactivated'
          : etat === 'deactivated'
          ? 'activated'
          : null,
    });
  },
  EditUser: (body) => {
    const { id } = body;
    return axios.put(`${baseUrl}/api/etats/${id}`);
  },

  updateChild: (body) => {
    const { inputValues, fieldId } = body;
    return axios.put(`${baseUrl}/api/enfants/${fieldId}`, {
      id: fieldId,
      statut: 'EN ATTENTE',
      nom: inputValues?.nom,
      prenom: inputValues?.prenom,
      surnom: inputValues?.surnom,
      sexe: inputValues?.sexe,
      ddn: inputValues?.ddn,
      age: inputValues?.age,
      lieu_naissance: inputValues?.lieu_naissance,
      pays_origine: inputValues?.pays_origine,
      region_origine: inputValues?.region_origine,
      village_ville_origine: inputValues?.village_ville_origine,
      nom_pere: inputValues?.nom_pere,
      profession_pere: inputValues?.profession_pere,
      tel_pere: inputValues?.tel_pere,
      adresse_pere: inputValues?.adresse_pere,
      nom_mere: inputValues?.nom_mere,
      profession_mere: inputValues?.profession_mere,
      tel_mere: inputValues?.tel_mere,
      adresse_mere: inputValues?.adresse_mere,
      rang_enfant: inputValues?.rang_enfant,
    });
  },
  updateChildAfterVerification: (body) => {
    const { inputValues, fieldId } = body;
    return axios.put(`${baseUrl}/api/enfants/${fieldId}`, {
      id: fieldId,
      statut: 'VALIDER',
      nom: inputValues?.nom,
      prenom: inputValues?.prenom,
      surnom: inputValues?.surnom,
      sexe: inputValues?.sexe,
      ddn: inputValues?.ddn,
      age: inputValues?.age,
      lieu_naissance: inputValues?.lieu_naissance,
      pays_origine: inputValues?.pays_origine,
      region_origine: inputValues?.region_origine,
      village_ville_origine: inputValues?.village_ville_origine,
      nom_pere: inputValues?.nom_pere,
      profession_pere: inputValues?.profession_pere,
      tel_pere: inputValues?.tel_pere,
      adresse_pere: inputValues?.adresse_pere,
      nom_mere: inputValues?.nom_mere,
      profession_mere: inputValues?.profession_mere,
      tel_mere: inputValues?.tel_mere,
      adresse_mere: inputValues?.adresse_mere,
      rang_enfant: inputValues?.rang_enfant,
    });
  },
  updateIdentificationVerification: (body) => {
    const {
      ficheId,
      situationPere,
      situationMere,
      situationMatri,
      niveauEtude,
      vulnerabilite,
      comportement,
      conditionArrive,
      lienAdulte,
      motifDepart,
      saisineMode,
      etatSante,
      situationActu,
      dependance,
      natureRapport,
      inputValue,
    } = body;
    return axios.put(`${baseUrl}/api/identites/${ficheId}`, {
      id: ficheId,
      statut: 'VALIDER',
      date_remplissage: inputValue.date_remplissage
        ? inputValue.date_remplissage
        : '01-01-2040',
      pays_identification: inputValue.pays_identification,
      region_identification: inputValue.region_identification,
      date_identification: inputValue.date_identification
        ? inputValue.date_identification
        : '01-01-2040',
      situation_pere: situationPere,
      situation_mere: situationMere,
      situation_matrimoniale_parents: situationMatri,
      niveau_instruction_enfant: niveauEtude,
      precision_niveau: inputValue.precision_niveau,
      vulnerabilite_enfant: vulnerabilite,
      duree_peine: inputValue.duree_peine,
      auteurs_abus_viol: inputValue.auteurs_abus_viol,
      nom_tuteur: inputValue.nom_tuteur,
      lien_parente_tuteur: inputValue.lien_parente_tuteur,
      adresse_tuteur: inputValue.adresse_tuteur,
      tel_tuteur: inputValue.tel_tuteur,
      date_arrivee_pays: inputValue.date_arrivee_pays
        ? inputValue.date_arrivee_pays
        : '01-01-2040',
      localite_identification: inputValue.localite_identification,
      condition_arrivee: conditionArrive,
      lien_avec_adulte: lienAdulte,
      motif_depart_milieu_vie_habituel: motifDepart,
      mode_saisine_du_cas_de_lenfant: saisineMode,
      etat_sante_physique: etatSante,
      comportements: comportement,
      dependances: dependance,
      nature_rapport: natureRapport,
      type_lieu_hebergement: situationActu,
      resume_situation_enfant: inputValue.resume_situation_enfant,
    });
  },
  updatePriseEnChargeVerification: (body) => {
    console.log(body.services_offert_enfant);
    const { services_offert_enfant, dateInput, id } = body;
    return axios.put(`${baseUrl}/api/urgences/${id}`, {
      statut: 'VALIDER',
      services_offert_enfant: services_offert_enfant,
      date_prise_en_charge: dateInput ? dateInput : '01-01-2040',
      id,
    });
  },
  updateRetourEnFamilleVerification: (body) => {
    const { inputValue, checkboxValue } = body;
    return axios.put(`${baseUrl}/api/familles/${inputValue?.id}`, {
      id: inputValue?.id,
      statut: 'VALIDER',
      recherche_famille_realise_par: inputValue?.recherche_famille_realise_par,
      recherche_famille_date: inputValue?.recherche_famille_date
        ? inputValue?.recherche_famille_date
        : '01-01-2040',
      recherche_famille_structure: inputValue?.recherche_famille_structure,
      relation_enfant_famille: checkboxValue?.relation_enfant_famille,
      relation_enfant_famille_pourquoi:
        inputValue?.relation_enfant_famille_pourquoi,
      ressources_famille_enfant: checkboxValue?.ressources_famille_enfant,
      ressources_famille_enfant_specifiez:
        inputValue?.ressources_famille_enfant_specifiez,
      reprendre_enfant: checkboxValue?.reprendre_enfant,
      reprendre_enfant_pourquoi: inputValue?.reprendre_enfant_pourquoi,
      conclusion_evaluation: checkboxValue?.conclusion_evaluation,
      retour_realiser_par: inputValue?.retour_realiser_par,
      retour_famille_date: inputValue?.retour_famille_date
        ? inputValue?.retour_famille_date
        : '01-01-2040',
      retour_structure: inputValue?.retour_structure,
      retour_lieu: inputValue?.retour_lieu,
      personne_ayant_recu_enfant: inputValue?.personne_ayant_recu_enfant,
      telephone_personne_ayant_recu_enfant:
        inputValue?.telephone_personne_ayant_recu_enfant,
      adresse_personne_ayant_enfant: inputValue?.adresse_personne_ayant_enfant,
      cin_personne_ayant_recu_enfant:
        inputValue?.cin_personne_ayant_recu_enfant,
      retour_reintegration: checkboxValue?.retour_reintegration,
      retour_a_signaler: inputValue?.retour_a_signaler,
      ville: inputValue?.ville,
      village_quartier: inputValue?.village_quartier,
      region: inputValue?.region,
      departement: inputValue?.departement,
      pays: inputValue?.pays,
      personne_contactee1: inputValue?.personne_contactee1,
      personne_contactee2: inputValue?.personne_contactee2,
      personne_contactee3: inputValue?.personne_contactee3,
      adresse1: inputValue?.adresse1,
      adresse2: inputValue?.adresse2,
      adresse3: inputValue?.adresse3,
      telephone1: inputValue?.telephone1,
      telephone2: inputValue?.telephone2,
      telephone3: inputValue?.telephone3,
      lien_parente_enfant1: inputValue?.lien_parente_enfant1,
      lien_parente_enfant2: inputValue?.lien_parente_enfant2,
      lien_parente_enfant3: inputValue?.lien_parente_enfant3,
    });
  },
  updateRefVerification: (body) => {
    console.log(body);
    const { inputValue, checkboxValue } = body;
    return axios.put(`${baseUrl}/api/refs/${inputValue?.id}`, {
      id: inputValue?.id,
      statut: 'VALIDER',
      referencement: checkboxValue?.referencement,
      date_referencement: inputValue.date_referencement
        ? inputValue.date_referencement
        : '01-01-2040',
      heure_referencement: inputValue.heure_referencement,
      nom_responsable_ref: inputValue.nom_responsable_ref,
      structure_ref: inputValue.structure_ref,
      tel_ref: inputValue.tel_ref,
      categorie_service_ref: checkboxValue?.categorie_service_ref,
      nom_service_ref: inputValue.nom_service_ref,
    });
  },
  updateProjetDeVieVerification: (body) => {
    const { inputValue, checkboxValue } = body;
    return axios.put(`${baseUrl}/api/projets/${inputValue?.id}`, {
      id: inputValue?.id,
      statut: 'VALIDER',
      projet: checkboxValue?.projet,
      descripton: inputValue?.descripton,
      cout: inputValue?.cout,
      ressources: inputValue?.ressources,
    });
  },
  updateSuiviVerification: (body) => {
    const { inputValue, checkboxValue } = body;
    return axios.put(`${baseUrl}/api/suivis/${inputValue?.id}`, {
      id: inputValue?.id,
      statut: 'VALIDER',
      Date_suivi: inputValue?.Date_suivi
        ? inputValue?.Date_suivi
        : '01-01-2040',
      situation_actuelle_enfant: checkboxValue?.situation_actuelle_enfant,
      nature_relation_fratrie: checkboxValue?.nature_relation_fratrie,
      nature_relation_parent: checkboxValue?.nature_relation_parent,
      nature_relation_voisinage: checkboxValue?.nature_relation_voisinage,
      etat_sante_enfant: checkboxValue?.etat_sante_enfant,
      Evolution_Projet_vie: checkboxValue?.Evolution_Projet_vie,
      Evolution_Projet_vie_AGR: checkboxValue?.Evolution_Projet_vie_AGR,
      motif_justificatif: inputValue?.motif_justificatif,
      date_prise_decision: inputValue?.date_prise_decision
        ? inputValue?.date_prise_decision
        : '01-01-2040',
      decision_apres_evaluation_enfant:
        checkboxValue?.decision_apres_evaluation_enfant,
    });
  },
  updateUserPassword: (body) => {
    const { passwordInput, id } = body;
    return axios.put(`${baseUrl}/api/users/${id}`, {
      id,
      password: passwordInput.password,
      confirm_password: passwordInput.confirmPassword,
    });
  },
  updateIdentification: (body) => {
    const {
      inputValue,
      situationPere,
      situationMere,
      situationMatri,
      niveauEtude,
      vulnerabilite,
      conditionArrive,
      lienAdulte,
      motifDepart,
      saisineMode,
      etatSante,
      situationActu,
      dependance,
      comportement,
      natureRapport,
    } = body;
    return axios.put(`${baseUrl}/api/identites/${inputValue?.id}`, {
      id: inputValue?.id,
      date_remplissage: inputValue.date_remplissage
        ? inputValue.date_remplissage
        : '01-01-2040',
      pays_identification: inputValue.pays_identification,
      region_identification: inputValue.region_identification,
      date_identification: inputValue.date_identification
        ? inputValue.date_identification
        : '01-01-2040',
      situation_pere: situationPere,
      situation_mere: situationMere,
      situation_matrimoniale_parents: situationMatri,
      niveau_instruction_enfant: niveauEtude,
      precision_niveau: inputValue.precision_niveau,
      vulnerabilite_enfant: vulnerabilite,
      duree_peine: inputValue.duree_peine,
      auteurs_abus_viol: inputValue.auteurs_abus_viol,
      nom_tuteur: inputValue.nom_tuteur,
      lien_parente_tuteur: inputValue.lien_parente_tuteur,
      adresse_tuteur: inputValue.adresse_tuteur,
      tel_tuteur: inputValue.tel_tuteur,
      date_arrivee_pays: inputValue.date_arrivee_pays
        ? inputValue.date_arrivee_pays
        : '01-01-2040',
      localite_identification: inputValue.localite_identification,
      condition_arrivee: conditionArrive,
      lien_avec_adulte: lienAdulte,
      motif_depart_milieu_vie_habituel: motifDepart,
      mode_saisine_du_cas_de_lenfant: saisineMode,
      etat_sante_physique: etatSante,
      comportements: comportement,
      dependances: dependance,
      nature_rapport: natureRapport,
      type_lieu_hebergement: situationActu,
      resume_situation_enfant: inputValue.resume_situation_enfant,
    });
  },
  updatePriseEnCharge: (body) => {
    const { services_offert_enfant, dateInput, oldValue, id } = body;
    return axios.put(`${baseUrl}/api/urgences/${id}`, {
      id,
      services_offert_enfant: services_offert_enfant
        ? services_offert_enfant
        : services_offert_enfant === ''
        ? oldValue
        : '',
      date_prise_en_charge: dateInput ? dateInput : '01-01-2040',
    });
  },
  updateRetourFamille: (body) => {
    const { inputValue, checkboxValue } = body;
    return axios.put(`${baseUrl}/api/familles/${inputValue?.id}`, {
      id: inputValue?.id,
      recherche_famille_realise_par: inputValue?.recherche_famille_realise_par,
      recherche_famille_date: inputValue?.recherche_famille_date
        ? inputValue?.recherche_famille_date
        : '01-01-2040',
      recherche_famille_structure: inputValue?.recherche_famille_structure,
      relation_enfant_famille: checkboxValue?.relation_enfant_famille,
      relation_enfant_famille_pourquoi:
        inputValue?.relation_enfant_famille_pourquoi,
      ressources_famille_enfant: checkboxValue?.ressources_famille_enfant,
      ressources_famille_enfant_specifiez:
        inputValue?.ressources_famille_enfant_specifiez,
      reprendre_enfant: checkboxValue?.reprendre_enfant,
      reprendre_enfant_pourquoi: inputValue?.reprendre_enfant_pourquoi,
      conclusion_evaluation: checkboxValue?.conclusion_evaluation,
      retour_realiser_par: inputValue?.retour_realiser_par,
      retour_famille_date: inputValue?.retour_famille_date
        ? inputValue?.retour_famille_date
        : '01-01-2040',
      retour_structure: inputValue?.retour_structure,
      retour_lieu: inputValue?.retour_lieu,
      personne_ayant_recu_enfant: inputValue?.personne_ayant_recu_enfant,
      telephone_personne_ayant_recu_enfant:
        inputValue?.telephone_personne_ayant_recu_enfant,
      adresse_personne_ayant_enfant: inputValue?.adresse_personne_ayant_enfant,
      cin_personne_ayant_recu_enfant:
        inputValue?.cin_personne_ayant_recu_enfant,
      retour_reintegration: checkboxValue?.retour_reintegration,
      retour_a_signaler: inputValue?.retour_a_signaler,
      ville: inputValue?.ville,
      village_quartier: inputValue?.village_quartier,
      region: inputValue?.region,
      departement: inputValue?.departement,
      pays: inputValue?.pays,
      personne_contactee1: inputValue?.personne_contactee1,
      personne_contactee2: inputValue?.personne_contactee2,
      personne_contactee3: inputValue?.personne_contactee3,
      adresse1: inputValue?.adresse1,
      adresse2: inputValue?.adresse2,
      adresse3: inputValue?.adresse3,
      telephone1: inputValue?.telephone1,
      telephone2: inputValue?.telephone2,
      telephone3: inputValue?.telephone3,
      lien_parente_enfant1: inputValue?.lien_parente_enfant1,
      lien_parente_enfant2: inputValue?.lien_parente_enfant2,
      lien_parente_enfant3: inputValue?.lien_parente_enfant3,
    });
  },
  updateRefs: (body) => {
    const { inputValue, checkboxValue } = body;
    return axios.put(`${baseUrl}/api/refs/${inputValue?.id}`, {
      id: inputValue?.id,
      referencement: checkboxValue?.referencement,
      date_referencement: inputValue.date_referencement
        ? inputValue.date_referencement
        : '01-01-2040',
      heure_referencement: inputValue.heure_referencement,
      nom_responsable_ref: inputValue.nom_responsable_ref,
      structure_ref: inputValue.structure_ref,
      tel_ref: inputValue.tel_ref,
      categorie_service_ref: checkboxValue?.categorie_service_ref,
      nom_service_ref: inputValue.nom_service_ref,
    });
  },
  updateProjet: (body) => {
    const { inputValue, checkboxValue } = body;
    return axios.put(`${baseUrl}/api/projets/${inputValue?.id}`, {
      id: inputValue?.id,
      projet: checkboxValue?.projet,
      descripton: inputValue?.descripton,
      cout: inputValue?.cout,
      ressources: inputValue?.ressources,
    });
  },
  updateSuivi: (body) => {
    const { inputValue, checkboxValue } = body;
    return axios.put(`${baseUrl}/api/suivis/${inputValue?.id}`, {
      id: inputValue?.id,
      Date_suivi: inputValue?.Date_suivi
        ? inputValue?.Date_suivi
        : '01-01-2040',
      situation_actuelle_enfant: checkboxValue?.situation_actuelle_enfant,
      nature_relation_fratrie: checkboxValue?.nature_relation_fratrie,
      nature_relation_parent: checkboxValue?.nature_relation_parent,
      nature_relation_voisinage: checkboxValue?.nature_relation_voisinage,
      etat_sante_enfant: checkboxValue?.etat_sante_enfant,
      Evolution_Projet_vie: checkboxValue?.Evolution_Projet_vie,
      Evolution_Projet_vie_AGR: checkboxValue?.Evolution_Projet_vie_AGR,
      motif_justificatif: inputValue?.motif_justificatif,
      date_prise_decision: inputValue?.date_prise_decision
        ? inputValue?.date_prise_decision
        : '01-01-2040',
      decision_apres_evaluation_enfant:
        checkboxValue?.decision_apres_evaluation_enfant,
    });
  },
  deleteUser: (id) => {
    return axios.delete(`${baseUrl}/api/etats/${id}`);
  },
  deleteIdentification: (id) => {
    console.log('id', id);
    return axios.delete(`${baseUrl}/api/identites/${id}`);
  },
  deletePriseEnCharge: (id) => {
    return axios.delete(`${baseUrl}/api/urgences/${id}`);
  },
  deleteRetourFamille: (id) => {
    return axios.delete(`${baseUrl}/api/familles/${id}`);
  },
  deleteRefs: (id) => {
    return axios.delete(`${baseUrl}/api/refs/${id}`);
  },
  deleteProjet: (id) => {
    return axios.delete(`${baseUrl}/api/projets/${id}`);
  },
  deleteSuivi: (id) => {
    return axios.delete(`${baseUrl}/api/suivis/${id}`);
  },
  deleteChild: (id) => {
    console.log(id);
    return axios.delete(`${baseUrl}/api/enfants/${id}`);
  },

  //Endpoints to fetch data by admin
  getAllFiltredIdentification: () => {
    return axios.get(`${baseUrl}/api/identites/valides`);
  },
  getAllFiltredUrgence: () => {
    return axios.get(`${baseUrl}/api/urgences/valides`);
  },
  getAllFiltredFamille: () => {
    return axios.get(`${baseUrl}/api/familles/valides`);
  },
  getAllFiltredRefs: () => {
    return axios.get(`${baseUrl}/api/refs/valides`);
  },
  getAllFiltredProjet: () => {
    return axios.get(`${baseUrl}/api/projets/valides`);
  },
  getAllFiltredSuivi: () => {
    return axios.get(`${baseUrl}/api/suivis/valides`);
  },

  //Endponits to fetch data by office
  getAllFiltredChildData: () => {
    return axios.post(`${baseUrl}/api/enfants/bureau`, {
      bureau: userBureau,
    });
  },
  getAllFiltredIdentificationData: () => {
    return axios.post(`${baseUrl}/api/identites/bureau`, {
      bureau: userBureau,
    });
  },
  getAllFiltredUrgenceData: () => {
    return axios.post(`${baseUrl}/api/urgences/bureau`, {
      bureau: userBureau,
    });
  },
  getAllFiltredFamilleData: () => {
    return axios.post(`${baseUrl}/api/familles/bureau`, {
      bureau: userBureau,
    });
  },
  getAllFiltredRefsData: () => {
    return axios.post(`${baseUrl}/api/refs/bureau`, {
      bureau: userBureau,
    });
  },
  getAllFiltredProjetData: () => {
    return axios.post(`${baseUrl}/api/projets/bureau`, {
      bureau: userBureau,
    });
  },
  getAllFiltredSuiviData: () => {
    return axios.post(`${baseUrl}/api/suivis/bureau`, {
      bureau: userBureau,
    });
  },

  //stat dakar
  getStatDakar: () => {
    return axios.post(`${baseUrl}/api/enfants/bureau`, {
      bureau: 'Enda Dakar',
    });
  },
  getStatIDDakar: () => {
    return axios.post(`${baseUrl}/api/identites/bureau`, {
      bureau: 'Enda Dakar',
    });
  },
  getStatPCDakar: () => {
    return axios.post(`${baseUrl}/api/urgences/bureau`, {
      bureau: 'Enda Dakar',
    });
  },
  getStatRFDakar: () => {
    return axios.post(`${baseUrl}/api/familles/bureau`, {
      bureau: 'Enda Dakar',
    });
  },
  getStatRDakar: () => {
    return axios.post(`${baseUrl}/api/refs/bureau`, {
      bureau: 'Enda Dakar',
    });
  },
  getStatPVDakar: () => {
    return axios.post(`${baseUrl}/api/projets/bureau`, {
      bureau: 'Enda Dakar',
    });
  },
  getStatSDakar: () => {
    return axios.post(`${baseUrl}/api/suivis/bureau`, {
      bureau: 'Enda Dakar',
    });
  },
  //stat kolda
  getStatKld: () => {
    return axios.post(`${baseUrl}/api/enfants/bureau`, {
      bureau: 'Enda Kolda',
    });
  },
  getStatIDkld: () => {
    return axios.post(`${baseUrl}/api/identites/bureau`, {
      bureau: 'Enda Kolda',
    });
  },
  getStatPCkld: () => {
    return axios.post(`${baseUrl}/api/urgences/bureau`, {
      bureau: 'Enda Kolda',
    });
  },
  getStatRFkld: () => {
    return axios.post(`${baseUrl}/api/familles/bureau`, {
      bureau: 'Enda Kolda',
    });
  },
  getStatRkld: () => {
    return axios.post(`${baseUrl}/api/refs/bureau`, {
      bureau: 'Enda Kolda',
    });
  },
  getStatPVkld: () => {
    return axios.post(`${baseUrl}/api/projets/bureau`, {
      bureau: 'Enda Kolda',
    });
  },
  getStatSkld: () => {
    return axios.post(`${baseUrl}/api/suivis/bureau`, {
      bureau: 'Enda Kolda',
    });
  },
  // stat zig
  getStatZig: () => {
    return axios.post(`${baseUrl}/api/enfants/bureau`, {
      bureau: 'Enda Ziguinchor',
    });
  },
  getStatIDZig: () => {
    return axios.post(`${baseUrl}/api/identites/bureau`, {
      bureau: 'Enda Ziguinchor',
    });
  },
  getStatPCZig: () => {
    return axios.post(`${baseUrl}/api/urgences/bureau`, {
      bureau: 'Enda Ziguinchor',
    });
  },
  getStatRFZig: () => {
    return axios.post(`${baseUrl}/api/familles/bureau`, {
      bureau: 'Enda Ziguinchor',
    });
  },
  getStatRZig: () => {
    return axios.post(`${baseUrl}/api/refs/bureau`, {
      bureau: 'Enda Ziguinchor',
    });
  },
  getStatPVZig: () => {
    return axios.post(`${baseUrl}/api/projets/bureau`, {
      bureau: 'Enda Ziguinchor',
    });
  },
  getStatSZig: () => {
    return axios.post(`${baseUrl}/api/suivis/bureau`, {
      bureau: 'Enda Ziguinchor',
    });
  },
  // stat sedhiou
  getStatSed: () => {
    return axios.post(`${baseUrl}/api/enfants/bureau`, {
      bureau: 'Enda Sedhiou',
    });
  },
  getStatIDSed: () => {
    return axios.post(`${baseUrl}/api/identites/bureau`, {
      bureau: 'Enda Sedhiou',
    });
  },
  getStatPCSed: () => {
    return axios.post(`${baseUrl}/api/urgences/bureau`, {
      bureau: 'Enda Sedhiou',
    });
  },
  getStatRFSed: () => {
    return axios.post(`${baseUrl}/api/familles/bureau`, {
      bureau: 'Enda Sedhiou',
    });
  },
  getStatRSed: () => {
    return axios.post(`${baseUrl}/api/refs/bureau`, {
      bureau: 'Enda Sedhiou',
    });
  },
  getStatPVSed: () => {
    return axios.post(`${baseUrl}/api/projets/bureau`, {
      bureau: 'Enda Sedhiou',
    });
  },
  getStatSSed: () => {
    return axios.post(`${baseUrl}/api/suivis/bureau`, {
      bureau: 'Enda Sedhiou',
    });
  },
  // stat velingara
  getStatVel: () => {
    return axios.post(`${baseUrl}/api/enfants/bureau`, {
      bureau: 'Enda Velingara',
    });
  },
  getStatIDVel: () => {
    return axios.post(`${baseUrl}/api/identites/bureau`, {
      bureau: 'Enda Velingara',
    });
  },
  getStatPCVel: () => {
    return axios.post(`${baseUrl}/api/urgences/bureau`, {
      bureau: 'Enda Velingara',
    });
  },
  getStatRFVel: () => {
    return axios.post(`${baseUrl}/api/familles/bureau`, {
      bureau: 'Enda Velingara',
    });
  },
  getStatRVel: () => {
    return axios.post(`${baseUrl}/api/refs/bureau`, {
      bureau: 'Enda Velingara',
    });
  },
  getStatPVVel: () => {
    return axios.post(`${baseUrl}/api/projets/bureau`, {
      bureau: 'Enda Velingara',
    });
  },
  getStatSVel: () => {
    return axios.post(`${baseUrl}/api/suivis/bureau`, {
      bureau: 'Enda Velingara',
    });
  },
  // stat tamba
  getStatTam: () => {
    return axios.post(`${baseUrl}/api/enfants/bureau`, {
      bureau: 'Enda Tamba',
    });
  },
  getStatIDTam: () => {
    return axios.post(`${baseUrl}/api/identites/bureau`, {
      bureau: 'Enda Tamba',
    });
  },
  getStatPCTam: () => {
    return axios.post(`${baseUrl}/api/urgences/bureau`, {
      bureau: 'Enda Tamba',
    });
  },
  getStatRFTam: () => {
    return axios.post(`${baseUrl}/api/familles/bureau`, {
      bureau: 'Enda Tamba',
    });
  },
  getStatRTam: () => {
    return axios.post(`${baseUrl}/api/refs/bureau`, {
      bureau: 'Enda Tamba',
    });
  },
  getStatPVTam: () => {
    return axios.post(`${baseUrl}/api/projets/bureau`, {
      bureau: 'Enda Tamba',
    });
  },
  getStatSTam: () => {
    return axios.post(`${baseUrl}/api/suivis/bureau`, {
      bureau: 'Enda Tamba',
    });
  },
  // stat sarea
  getStatSar: () => {
    return axios.post(`${baseUrl}/api/enfants/bureau`, {
      bureau: 'Enda Sarea',
    });
  },
  getStatIDSar: () => {
    return axios.post(`${baseUrl}/api/identites/bureau`, {
      bureau: 'Enda Sarea',
    });
  },
  getStatPCSar: () => {
    return axios.post(`${baseUrl}/api/urgences/bureau`, {
      bureau: 'Enda Sarea',
    });
  },
  getStatRFSar: () => {
    return axios.post(`${baseUrl}/api/familles/bureau`, {
      bureau: 'Enda Sarea',
    });
  },
  getStatRSar: () => {
    return axios.post(`${baseUrl}/api/refs/bureau`, {
      bureau: 'Enda Sarea',
    });
  },
  getStatPVSar: () => {
    return axios.post(`${baseUrl}/api/projets/bureau`, {
      bureau: 'Enda Sarea',
    });
  },
  getStatSSar: () => {
    return axios.post(`${baseUrl}/api/suivis/bureau`, {
      bureau: 'Enda Sarea',
    });
  },
  // stat kalock
  getStatKao: () => {
    return axios.post(`${baseUrl}/api/enfants/bureau`, {
      bureau: 'Enda Kaolack',
    });
  },
  getStatIDKao: () => {
    return axios.post(`${baseUrl}/api/identites/bureau`, {
      bureau: 'Enda Kaolack',
    });
  },
  getStatPCKao: () => {
    return axios.post(`${baseUrl}/api/urgences/bureau`, {
      bureau: 'Enda Kaolack',
    });
  },
  getStatRFKao: () => {
    return axios.post(`${baseUrl}/api/familles/bureau`, {
      bureau: 'Enda Kaolack',
    });
  },
  getStatRKao: () => {
    return axios.post(`${baseUrl}/api/refs/bureau`, {
      bureau: 'Enda Kaolack',
    });
  },
  getStatPVKao: () => {
    return axios.post(`${baseUrl}/api/projets/bureau`, {
      bureau: 'Enda Kaolack',
    });
  },
  getStatSKao: () => {
    return axios.post(`${baseUrl}/api/suivis/bureau`, {
      bureau: 'Enda Kaolack',
    });
  },
  //stat thies
  getStatThi: () => {
    return axios.post(`${baseUrl}/api/enfants/bureau`, {
      bureau: 'Enda Thies',
    });
  },
  getStatIDThi: () => {
    return axios.post(`${baseUrl}/api/identites/bureau`, {
      bureau: 'Enda Thies',
    });
  },
  getStatPCThi: () => {
    return axios.post(`${baseUrl}/api/urgences/bureau`, {
      bureau: 'Enda Thies',
    });
  },
  getStatRFThi: () => {
    return axios.post(`${baseUrl}/api/familles/bureau`, {
      bureau: 'Enda Thies',
    });
  },
  getStatRThi: () => {
    return axios.post(`${baseUrl}/api/refs/bureau`, {
      bureau: 'Enda Thies',
    });
  },
  getStatPVThi: () => {
    return axios.post(`${baseUrl}/api/projets/bureau`, {
      bureau: 'Enda Thies',
    });
  },
  getStatSThi: () => {
    return axios.post(`${baseUrl}/api/suivis/bureau`, {
      bureau: 'Enda Thies',
    });
  },
};

export default api;
