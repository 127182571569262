import React, { useRef, useCallback } from "react";
import "./resumeStyle.scss";
import ReactToPrint from "react-to-print";
const ResumePriseEnCharge = ({ urgList }) => {
  const componentRef = useRef(null);
  const reactToPrintContent = useCallback(() => {
    return componentRef.current;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [componentRef.current]);
  const reactToPrintTrigger = React.useCallback(() => {
    return (
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
          margin: "50px 0 0",
          position: "relative",
          top: "50px"
        }}
      >
        {" "}
        <button
          style={{
            background: "lightgray",
            padding: "3px 5px",
            margin: "0 5px",
            borderRadius: "3px",
            color: "white",
            fontSize: "12px",
            border: "none",
          }}
        >
          Inprimer
        </button>
      </div>
    ); // eslint-disable-line max-len
  }, []);

  return (
    <>
      <ReactToPrint
        content={reactToPrintContent}
        documentTitle="AwesomeFileName"
        removeAfterPrint
        trigger={reactToPrintTrigger}
      />
      <div className="resu_container" ref={componentRef}>
        <span style={{ textTransform: "uppercase", textDecoration: "underline", fontWeight: "500" }}> Enfant :</span>
        <p style={{ color: "green", fontWeight: "500" }}> Code: {urgList?.child_code}</p>
        <p style={{ color: "green", fontWeight: "500" }}> Prénom et Nom : {urgList?.prenom} {urgList?.nom} </p>
        <p style={{ color: "green", fontWeight: "500" }}>Date de naissance : {urgList?.ddn?.substring(0, 10)}  </p>
        <p style={{ color: "green", fontWeight: "500" }}>Lieu de naissance : {urgList?.lieu_naissance}  </p>
        {urgList.urgence?.map((item, index) => {
          if (item?.date_prise_en_charge !== null) {
            return (
              <div className="resu_block" key={index}>
                <span style={{ textTransform: "uppercase", textDecoration: "underline", fontWeight: "500" }}>Gestionnaire du cas :</span>
                <p style={{ color: "blue", fontWeight: "500" }}>Nom : {item?.gestionnaire_cas} </p>
                <p style={{ color: "blue", fontWeight: "500" }}>Tél : {item?.tel} </p>
                <p style={{ color: "blue", fontWeight: "500" }}>Bureau : {item?.bureau}  </p>
                <span style={{ textTransform: "uppercase", textDecoration: "underline", fontWeight: "500" }}>Informations générales :</span>

                <span>
                  <h3>Date de la prise en charge</h3> :{" "}
                  {item?.date_prise_en_charge?.substring(0, 10)}
                </span>
                <span>
                  <h3>Services offert à l'enfant</h3> :{" "}
                  {item?.services_offert_enfant}
                </span>
                <span>
                  <h3>Autres services offert à l'enfant</h3> :{" "}
                  {item?.autres_services_offert}
                </span>
              </div>
            );
          } else return null;
        })}
      </div>
    </>

  );
};

export default ResumePriseEnCharge;
