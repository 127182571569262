import React, { useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import { Card, CardBody, CardHeader, Col, Row, Button } from "reactstrap";
import api from "services/api";

const Exportation = () => {
  const [identiteValid, setIdentiteValid] = useState([]);
  const [urgenceValid, setUrgenceValid] = useState([]);
  const [familleValid, setFamilleValid] = useState([]);
  const [refValid, setRefValid] = useState([]);
  const [projetValid, setProjetValid] = useState([]);
  const [suiviValid, setSuiviValid] = useState([]);
  const [currentData, setCurrentData] = useState([]);
  const [dataColum, setDataColumn] = useState([]);

  console.log(currentData);
  //recuperation du bureau du user connecté

  useEffect(() => {
    console.log("monté");
    api.getAllFiltredIdentificationData().then((response) => {
      if (response) {
        console.log(response);
        setIdentiteValid(response.data);
        setDataColumn(DataColunmIdentification);
      }
    });
    api.getAllFiltredUrgenceData().then((response) => {
      if (response) {
        console.log(response.data);
        setUrgenceValid(response.data);
      }
    });
    api.getAllFiltredFamilleData().then((response) => {
      if (response) {
        setFamilleValid(response.data);
      }
    });
    api.getAllFiltredRefsData().then((response) => {
      if (response) {
        setRefValid(response.data);
      }
    });
    api.getAllFiltredProjetData().then((response) => {
      if (response) {
        setProjetValid(response.data);
      }
    });
    api.getAllFiltredSuiviData().then((response) => {
      if (response) {
        setSuiviValid(response.data);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // data colums
  const DataColunmIdentification = [
    { label: "Code", name: "enfant.id" },
    { label: "Prenom", name: "enfant.prenom" },
    { label: "Nom", name: "enfant.nom" },
    { label: "Surnom", name: "enfant.surnom" },
    { label: "Sexe", name: "enfant.sexe" },
    {
      label: "Date de naissance",
      name: "enfant.ddn",
      options: {
        display: false,
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          const index = tableMeta.rowData.findIndex((el) => el?.length === 24);
          return <span>{tableMeta.rowData[index]?.substring(0, 10)} </span>;
        },
      },
    },
    {
      label: "Pays d'origine",
      name: "enfant.pays_origine",
      options: { display: false },
    },
    {
      label: "Region d'origine",
      name: "enfant.region_origine",
      options: { display: false },
    },
    {
      label: "Ville ou village d'origine",
      name: "enfant.village_ville_origine",
      options: { display: false },
    },
    {
      label: "Nom du pere",
      name: "enfant.nom_pere",
      options: { display: false },
    },
    {
      label: "Profession du pere",
      name: "enfant.profession_pere",
      options: { display: false },
    },
    {
      label: "Adresse du pere",
      name: "enfant.adresse_pere",
      options: { display: false },
    },
    {
      label: "Telephone du pere",
      name: "enfant.tel_pere",
      options: { display: false },
    },
    {
      label: "Nom de la mere",
      name: "enfant.nom_mere",
      options: { display: false },
    },
    {
      label: "Profession de la mere",
      name: "enfant.profession_mere",
      options: { display: false },
    },
    {
      label: "Adresse de la mere",
      name: "enfant.adresse_mere",
      options: { display: false },
    },
    {
      label: "Telephone de la mere",
      name: "enfant.tel_mere",
      options: { display: false },
    },
    {
      label: "Rang de l'enfant",
      name: "enfant.rang_enfant",
      options: { display: false },
    },

    {
      label: "Adresse du tuteur",
      name: "adresse_tuteur",
      options: { display: false },
    },
    {
      label: "Auteur abus  ou viol",
      name: "auteurs_abus_viol",
      options: { display: false },
    },
    {
      label: "Comportement",
      name: "comportements",
      options: { display: false },
    },
    {
      label: "Condition d'arrivee",
      name: "condition_arrivee",
      options: { display: false },
    },
    {
      label: "Date d'arrivee dans le pays",
      name: "date_arrivee_pays",
      options: {
        display: false,
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          const index = tableMeta.rowData.findIndex((el) => el?.length === 24);
          return <span>{tableMeta.rowData[index]?.substring(0, 10)} </span>;
        },
      },
    },
    {
      label: "Date d'identification",
      name: "date_identification",
      options: {
        display: false,
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          const index = tableMeta.rowData.findIndex((el) => el?.length === 24);
          return <span>{tableMeta.rowData[index]?.substring(0, 10)} </span>;
        },
      },
    },
    {
      label: "Date remplissage",
      name: "date_remplissage",
      options: {
        display: false,
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          const index = tableMeta.rowData.findIndex((el) => el?.length === 24);
          return <span>{tableMeta.rowData[index]?.substring(0, 10)} </span>;
        },
      },
    },
    { label: "Dependances", name: "dependances", options: { display: false } },
    { label: "Durée peine", name: "duree_peine", options: { display: false } },
    {
      label: "Etat sante physique",
      name: "etat_sante_physique",
      options: { display: false },
    },
    {
      label: "Lien avec l'adulte",
      name: "lien_avec_adulte",
      options: { display: false },
    },
    {
      label: "Lien parente tuteur",
      name: "lien_parente_tuteur",
      options: { display: false },
    },
    {
      label: "Localite d'identification",
      name: "localite_identification",
      options: { display: false },
    },
    {
      label: "Mode de saisine du cas de l'enfant",
      name: "mode_saisine_du_cas_de_lenfant",
      options: { display: false },
    },
    {
      label: "Motif depart milieu vie habituel",
      name: "motif_depart_milieu_vie_habituel",
      options: { display: false },
    },
    {
      label: "Nature rapport",
      name: "nature_rapport",
      options: { display: false },
    },
    {
      label: "Niveau d'instruction de l'enfant",
      name: "niveau_instruction_enfant",
      options: { display: false },
    },
    { label: "Nom du tuteur", name: "nom_tuteur", options: { display: false } },
    {
      label: "Pays d'identification",
      name: "pays_identification",
      options: { display: false },
    },
    {
      label: "Précision du niveau",
      name: "précision_niveau",
      options: { display: false },
    },
    {
      label: "Region d'identification",
      name: "region_identification",
      options: { display: false },
    },
    {
      label: "Resume de la situation de l'enfant",
      name: "resume_situation_enfant",
      options: { display: false },
    },
    {
      label: "Situation matrimoniale des parents",
      name: "situation_matrimoniale_parents",
      options: { display: false },
    },
    {
      label: "Situation de la mere",
      name: "situation_mere",
      options: { display: false },
    },
    {
      label: "Situation du pere",
      name: "situation_pere",
      options: { display: false },
    },
    { label: "Telephone", name: "tel", options: { display: false } },
    {
      label: "Telephone du tuteur",
      name: "tel_tuteur",
      options: { display: false },
    },
    {
      label: "Type lieu hebergement",
      name: "type_lieu_hebergement",
      options: { display: false },
    },
    {
      label: "Vulnerabilite enfant",
      name: "vulnerabilite_enfant",
      options: { display: false },
    },

    {
      label: "Bureau du gestionnaire de cas",
      name: "enfant.bureau",
      options: { display: false },
    },
    { label: "Gestionnaire de cas", name: "gestionnaire_cas" },
    { label: "Telephone", name: "tel" },
    { label: "Email", name: "email" },
  ];

  const DataColunmUrgence = [
    { label: "Code", name: "enfant.id" },
    { label: "Prenom", name: "enfant.prenom" },
    { label: "Nom", name: "enfant.nom" },
    { label: "Surnom", name: "enfant.surnom" },
    { label: "Sexe", name: "enfant.sexe" },
    { label: "Age", name: "enfant.age" },
    { label: "Bureau ", name: "bureau" },
    {
      label: "Gestionnaire de cas",
      name: "gestionnaire_cas",
      options: { display: false },
    },
    { label: "Telephone", name: "tel" },
    { label: "Email", name: "email" },
    { label: "Services offerts", name: "services_offert_enfant" },
    {
      label: "Autres services offerts",
      name: "autres_services_offert",
      options: { display: false },
    },
    {
      label: "Date prise en charge",
      name: "date_prise_en_charge",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          const index = tableMeta.rowData.findIndex((el) => el?.length === 24);
          return <span>{tableMeta.rowData[index]?.substring(0, 10)} </span>;
        },
      },
    },
  ];
  const DataColunmFamille = [
    { label: "Code", name: "enfant.id" },
    { label: "Prenom", name: "enfant.prenom" },
    { label: "Nom", name: "enfant.nom" },
    { label: "Surnom", name: "enfant.surnom" },
    { label: "Sexe", name: "enfant.sexe" },
    { label: "Age", name: "enfant.age" },
    { label: "Bureau ", name: "bureau" },
    {
      label: "Gestionnaire de cas",
      name: "gestionnaire_cas",
      options: { display: false },
    },
    { label: "Telephone", name: "tel" },
    { label: "Email", name: "email" },

    {
      label: "Recherche et mediation familiale realise",
      name: "recherche_famille_realise_par",
    },
    {
      label: "Date de la recherche famille ",
      name: "recherche_famille_date",
      options: {
        display: false,
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          const index = tableMeta.rowData.findIndex((el) => el?.length === 24);
          return <span>{tableMeta.rowData[index]?.substring(0, 10)} </span>;
        },
      },
    },
    {
      label: "Structure ayant fait la recherche famille ",
      name: "recherche_famille_structure",
      options: { display: false },
    },
    {
      label: "Departement ou  la recherche a ete faite",
      name: "departement",
      options: { display: false },
    },
    {
      label: "Pays ou la recherche a ete faite",
      name: "pays",
      options: { display: false },
    },
    {
      label: "ville ou  la recherche a ete faite",
      name: "ville",
      options: { display: false },
    },
    {
      label: "village ou  la recherche a ete faite",
      name: "village_quartier",
      options: { display: false },
    },
    {
      label: "Region ou la recherche a ete faite ",
      name: "region",
      options: { display: false },
    },

    {
      label: "premiere personne contactee",
      name: "personne_contactee1",
      options: { display: false },
    },
    {
      label: "Numero premiere personne contactee",
      name: "telephone1",
      options: { display: false },
    },
    {
      label: "Adresse de la premiere personne",
      name: "adresse1",
      options: { display: false },
    },
    {
      label: "Lien parente avec la premiere personne ",
      name: "lien_parente_enfant1",
      options: { display: false },
    },
    {
      label: "deuxieme personne contactee",
      name: "personne_contactee2",
      options: { display: false },
    },
    {
      label: "Numero deuxieme personne contactee",
      name: "telephone2",
      options: { display: false },
    },
    {
      label: "Adresse de la deuxieme personne",
      name: "adresse2",
      options: { display: false },
    },
    {
      label: "Lien parente avec la deuxieme personne ",
      name: "lien_parente_enfant2",
      options: { display: false },
    },

    {
      label: "Troisieme  personne contactee",
      name: "personne_contactee3",
      options: { display: false },
    },
    {
      label: "Numero Troisieme personne contactee",
      name: "telephone3",
      options: { display: false },
    },
    {
      label: "Adresse de la Troisieme personne",
      name: "adresse3",
      options: { display: false },
    },
    {
      label: "Lien parente avec la Troisieme personne ",
      name: "lien_parente_enfant3",
      options: { display: false },
    },

    {
      label: "Existance de relation entre enfant et famille",
      name: "relation_enfant_famille",
      options: { display: false },
    },
    {
      label: "Pourquoi non relation",
      name: "relation_enfant_famille_pourquoi",
      options: { display: false },
    },

    {
      label: "Ressources de la famille de enfant",
      name: "ressources_famille_enfant",
      options: { display: false },
    },
    {
      label: "Est ce que les parents acceptent de reprendre l'enfant",
      name: "reprendre_enfant",
      options: { display: false },
    },
    {
      label: "Pourquoi non reprise",
      name: "reprendre_enfant_pourquoi",
      options: { display: false },
    },
    {
      label: "Conclusion de l'évaluation",
      name: "conclusion_evaluation",
      options: { display: false },
    },
    {
      label: "Retour en famille realiser par",
      name: "retour_realiser_par",
      options: { display: false },
    },
    {
      label: "Date retour en famille",
      name: "retour_famille_date",
      options: {
        display: false,
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          const index = tableMeta.rowData.findIndex((el) => el?.length === 24);
          return <span>{tableMeta.rowData[index]?.substring(0, 10)} </span>;
        },
      },
    },
    {
      label: "Lieu retour en famille",
      name: "retour_lieu",
      options: { display: false },
    },

    {
      label: "Nom de la personne ayant reçu enfant",
      name: "personne_ayant_recu_enfant",
      options: { display: false },
    },
    {
      label: "Numero personne ayant recu enfant",
      name: "telephone_personne_ayant_recu_enfant",
      options: { display: false },
    },
    {
      label: "Cin personne ayant reçu l'enfant",
      name: "cin_personne_ayant_recu_enfant",
      options: { display: false },
    },
    {
      label: "Adresse  personne ayant reçu l'enfant",
      name: "adresse_personne_ayant_recu_enfant",
      options: { display: false },
    },
    {
      label: "Type de reintégration",
      name: "retour_reintegration",
      options: { display: false },
    },
    {
      label: "Important à signaler",
      name: "retour_a_signaler",
      options: { display: false },
    },
    {
      label: "Specification de la ressource",
      name: "ressources_famille_enfant_specifiez",
      options: { display: false },
    },

    {
      label: "Structure de retour",
      name: "retour_structure",
      options: { display: false },
    },
  ];

  const DataColunmRef = [
    { label: "Code", name: "enfant.id" },
    { label: "Prenom", name: "enfant.prenom" },
    { label: "Nom", name: "enfant.nom" },
    { label: "Surnom", name: "enfant.surnom" },
    { label: "Sexe", name: "enfant.sexe" },
    { label: "Age", name: "enfant.age" },
    { label: "Bureau ", name: "bureau" },
    {
      label: "Gestionnaire de cas",
      name: "gestionnaire_cas",
      options: { display: false },
    },
    { label: "Telephone", name: "tel" },
    { label: "Email", name: "email" },
    {
      label: "Referencement",
      name: "referencement",
      options: { display: false },
    },
    {
      label: "Date Referencement",
      name: "date_referencement",
      options: {
        display: false,
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          const index = tableMeta.rowData.findIndex((el) => el?.length === 24);
          return <span>{tableMeta.rowData[index]?.substring(0, 10)} </span>;
        },
      },
    },
    { label: "Heure Referencement", name: "heure_referencement" },
    {
      label: "Nom responsable du Referencement",
      name: "nom_responsable_ref",
      options: { display: false },
    },
    { label: "Numero du responsable ", name: "tel_ref" },
    { label: "Structure", name: "structure_ref" },
    {
      label: "Service Referencement",
      name: "categorie_service_ref",
      options: { display: false },
    },
    {
      label: "Nom service Referencement",
      name: "nom_service_ref",
      options: { display: false },
    },
  ];
  const DataColunmProjet = [
    { label: "Code", name: "enfant.id" },
    { label: "Prenom", name: "enfant.prenom" },
    { label: "Nom", name: "enfant.nom" },
    { label: "Surnom", name: "enfant.surnom" },
    { label: "Sexe", name: "enfant.sexe" },
    { label: "Age", name: "enfant.age" },
    { label: "Bureau ", name: "bureau" },
    {
      label: "Gestionnaire de cas",
      name: "gestionnaire_cas",
      options: { display: false },
    },
    { label: "Telephone", name: "tel" },
    { label: "Email", name: "email" },
    { label: "Projet de vie", name: "projet" },
    { label: "Descripton", name: "descripton" },
    { label: "Cout", name: "cout" },
    { label: "Ressources", name: "ressources" },
  ];

  const DataColunmSuivi = [
    { label: "Code", name: "enfant.id" },
    { label: "Prenom", name: "enfant.prenom" },
    { label: "Nom", name: "enfant.nom" },
    { label: "Surnom", name: "enfant.surnom" },
    { label: "Sexe", name: "enfant.sexe" },
    { label: "Age", name: "enfant.age" },
    { label: "Bureau ", name: "bureau" },
    {
      label: "Gestionnaire de cas",
      name: "gestionnaire_cas",
      options: { display: false },
    },
    { label: "Telephone", name: "tel" },
    { label: "Email", name: "email" },
    {
      label: "Situation actuelle de l'enfant",
      name: "situation_actuelle_enfant",
      options: { display: false },
    },
    {
      label: "Nature des relations avec les parent",
      name: "nature_relation_parent",
      options: { display: false },
    },
    {
      label: "Nature des relations avec la fratrie",
      name: "nature_relation_fratrie",
      options: { display: false },
    },
    {
      label: "Nature des relation avec le voisinage",
      name: "nature_relation_voisinage",
      options: { display: false },
    },
    {
      label: "Etat de sante l'enfant",
      name: "etat_sante_enfant",
      options: { display: false },
    },
    {
      label: "Date_suivi",
      name: "Date_suivi",
      options: {
        display: false,
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          const index = tableMeta.rowData.findIndex((el) => el?.length === 24);
          return <span>{tableMeta.rowData[index]?.substring(0, 10)} </span>;
        },
      },
    },
    {
      label: "Evolution du  projet de vie",
      name: "Evolution_Projet_vie",
      options: { display: false },
    },
    {
      label: "Evolution du Projet AGR",
      name: "Evolution_Projet_vie_AGR",
      options: { display: false },
    },
    {
      label: "Decision prise apres evaluation de l'enfant",
      name: "decision_apres_evaluation_enfant",
      options: { display: false },
    },
    {
      label: "Date prise de décision",
      name: "date_prise_decision",
      options: {
        display: false,
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          const index = tableMeta.rowData.findIndex((el) => el?.length === 24);
          return <span>{tableMeta.rowData[index]?.substring(0, 10)} </span>;
        },
      },
    },
    {
      label: "Motif justificatif",
      name: "motif_justificatif",
      options: { display: false },
    },
  ];

  const options = {
    filterType: "textField",
    pagination: false,
    print: false,
    enableNestedDataAccess: ".",
    filterOptions: {
      useDisplayedColumnsOnly: true,
      useDisplayedRowsOnly: true,
    },
    downloadOptions: {
      filename: "Enda.csv",
      separator: ";",
    },
  };
  return (
    <div className="content">
      <Row>
        <Col>
          <Card>
            <CardHeader style={{ display: "flex" }}>
              <Button
                onClick={() => {
                  setCurrentData(identiteValid);
                  setDataColumn(DataColunmIdentification);
                }}
                style={{
                  background: "#00d6b4",
                  display: "flex",
                  textTransform: " uppercase",
                }}
              >
                <span>Identification</span>
              </Button>
              <Button
                onClick={() => {
                  setCurrentData(urgenceValid);
                  setDataColumn(DataColunmUrgence);
                }}
                style={{
                  background: "#00d6b4",
                  display: "flex",
                  textTransform: " uppercase",
                }}
              >
                <span>Prise en charge d'urgence</span>
              </Button>
              <Button
                onClick={() => {
                  setCurrentData(familleValid);
                  setDataColumn(DataColunmFamille);
                }}
                style={{
                  background: "#00d6b4",
                  display: "flex",
                  textTransform: " uppercase",
                }}
              >
                <span>Retour en famille</span>
              </Button>
              <Button
                onClick={() => {
                  setCurrentData(refValid);
                  setDataColumn(DataColunmRef);
                }}
                style={{
                  background: "#00d6b4",
                  display: "flex",
                  textTransform: " uppercase",
                }}
              >
                <span>Referencement</span>
              </Button>
              <Button
                onClick={() => {
                  setCurrentData(projetValid);
                  setDataColumn(DataColunmProjet);
                }}
                style={{
                  background: "#00d6b4",
                  display: "flex",
                  textTransform: " uppercase",
                }}
              >
                <span>Projet de vie</span>
              </Button>
              <Button
                onClick={() => {
                  setCurrentData(suiviValid);
                  setDataColumn(DataColunmSuivi);
                }}
                style={{
                  background: "#00d6b4",
                  display: "flex",
                  textTransform: " uppercase",
                }}
              >
                <span>Suivi</span>
              </Button>
            </CardHeader>
            <CardBody className="all-icons">
              <MUIDataTable
                data={currentData}
                columns={dataColum}
                options={options}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Exportation;
