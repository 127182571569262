/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import '../../styles/fiches.scss'
import { Button } from "reactstrap";
import { BiArrowBack } from "react-icons/bi";

import Notiflix from "notiflix";
import { Input, Textarea } from "@chakra-ui/react";
import api from "../../services/api";
import { AiOutlineEye } from "react-icons/ai";
import MUIDataTable from "mui-datatables";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from "@chakra-ui/react";
import ResumeIdentification from "./resumeFiche/ResumeIdentification";
import UpdateIdentification from "./updateFiche/UpdateIdentification";
import { withRouter } from "react-router-dom";

const Identification = (props) => {
  const loggedUserData = JSON.parse(localStorage.getItem("data"));
  const childId = parseInt(props.match.params.id)
  const openResume = useDisclosure();
  const openUpdate = useDisclosure();
  const [idRow, setIdRow] = useState();
  const [hiddenDiv, setHiddenDiv] = useState(true);
  const [idList, setIdList] = useState();


  const handleAddForm = () => {
    setHiddenDiv(!hiddenDiv);
  };

  //mui dataTables
  const columns = [
    { label: "Code", name: "id" },
    {
      label: "Adresse du tuteur",
      name: "adresse_tuteur",
      options: { display: false },
    },
    {
      label: "Auteur abus  ou viol",
      name: "auteurs_abus_viol",
      options: { display: false },
    },
    {
      label: "Comportement",
      name: "comportements",
      options: { display: false },
    },
    {
      label: "Condition d'arrivee",
      name: "condition_arrivee",
      options: { display: false },
    },
    {
      label: "Date d'arrivee dans le pays",
      name: "date_arrivee_pays",
      options: {
        display: false,
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          const index = tableMeta.rowData.findIndex((el) => el?.length === 24);
          return <span>{tableMeta.rowData[index]?.substring(0, 10)} </span>;
        },
      },
    },
    {
      label: "Date d'identification",
      name: "date_identification",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          const index = tableMeta.rowData.findIndex((el) => el?.length === 24);
          return <span>{tableMeta.rowData[index]?.substring(0, 10)} </span>;
        },
      },
    },
    {
      label: "Date remplissage",
      name: "date_remplissage",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          const index = tableMeta.rowData.findIndex((el) => el?.length === 24);
          return <span>{tableMeta.rowData[index]?.substring(0, 10)} </span>;
        },
      },
    },
    { label: "Dependances", name: "dependances", options: { display: false } },
    { label: "Durée peine", name: "duree_peine", options: { display: false } },
    {
      label: "Etat sante physique",
      name: "etat_sante_physique",
      options: { display: false },
    },
    {
      label: "Lien avec l'adulte",
      name: "lien_avec_adulte",
      options: { display: false },
    },
    {
      label: "Lien parente tuteur",
      name: "lien_parente_tuteur",
      options: { display: false },
    },
    {
      label: "Localite d'identification",
      name: "localite_identification",
      options: { display: false },
    },
    {
      label: "Mode de saisine du cas de l'enfant",
      name: "mode_saisine_du_cas_de_lenfant",
      options: { display: false },
    },
    {
      label: "Motif depart milieu vie habituel",
      name: "motif_depart_milieu_vie_habituel",
      options: { display: false },
    },
    {
      label: "Nature rapport",
      name: "nature_rapport",
      options: { display: false },
    },
    {
      label: "Niveau d'instruction de l'enfant",
      name: "niveau_instruction_enfant",
      options: { display: false },
    },
    { label: "Nom du tuteur", name: "nom_tuteur", options: { display: false } },
    {
      label: "Pays d'identification",
      name: "pays_identification",
      options: { display: false },
    },
    {
      label: "Précision du niveau",
      name: "précision_niveau",
      options: { display: false },
    },
    {
      label: "Region d'identification",
      name: "region_identification",
      options: { display: false },
    },
    {
      label: "Resume de la situation de l'enfant",
      name: "resume_situation_enfant",
      options: { display: false },
    },
    {
      label: "Situation matrimoniale des parents",
      name: "situation_matrimoniale_parents",
      options: { display: false },
    },
    {
      label: "Situation de la mere",
      name: "situation_mere",
      options: { display: false },
    },
    {
      label: "Situation du pere",
      name: "situation_pere",
      options: { display: false },
    },
    { label: "Telephone", name: "tel", options: { display: false } },
    {
      label: "Telephone du tuteur",
      name: "tel_tuteur",
      options: { display: false },
    },
    {
      label: "Type lieu hebergement",
      name: "type_lieu_hebergement",
      options: { display: false },
    },
    {
      label: "Vulnerabilite enfant",
      name: "vulnerabilite_enfant",
      options: { display: false },
    },

    {
      label: "Bureau du gestionnaire de cas",
      name: "bureau",
      options: { display: false },
    },
    { label: "Gestionnaire de cas", name: "gestionnaire_cas" },
    { label: "Telephone", name: "tel" },
    { label: "Email", name: "email" },
    {
      name: "bureau",
      label: "Bureau",
    },
    {
      name: "statut",
      label: "Statut",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const index = tableMeta.rowData.findIndex(
            (el) => el === "VALIDER" || el === "EN ATTENTE"
          );
          if (tableMeta.rowData[index] === "VALIDER") {
            return (
              <span style={{ color: "green" }}>
                {tableMeta.rowData[index]}{" "}
              </span>
            );
          }
          if (tableMeta.rowData[index] === "EN ATTENTE") {
            return (
              <span style={{ color: "red" }}>{tableMeta.rowData[index]} </span>
            );
          }
        },
      },
    },
    {
      name: "Action",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          const _statut = tableMeta.rowData.find(
            (el) => el === "VALIDER" || el === "EN ATTENTE"
          );
          const gestCas = tableMeta.rowData.find((el) => el === loggedUserName);
          const _bureau = tableMeta.rowData.find(
            (el) => el === loggedUserBureau
          );
          return (
            <>
              <button
                onClick={() => {
                  openResume.onOpen();
                  setIdRow(tableMeta.rowIndex);


                }}
                style={{
                  background: "lightgray",
                  color: "#fff",
                  fontSize: "15px",
                  padding: " 3px",
                  borderRadius: "3px",

                  position: "relative",
                  top: "3px",
                  right: "5px",
                }}
              >
                <AiOutlineEye />
              </button>
              <button
                disabled={
                  (loggedUserRole === "ROLE_USER" &&
                    _statut === "EN ATTENTE" &&
                    gestCas === loggedUserName) ||
                    (loggedUserRole === "ROLE_RESPONSABLE" &&
                      _bureau === loggedUserBureau) ||
                    loggedUserRole === "ROLE_ADMIN"
                    ? false
                    : true
                }
                onClick={() => {
                  openUpdate.onOpen();
                  setIdRow(tableMeta.rowIndex);
                }}
                style={{
                  background: "orange",
                  color: "#fff",
                  fontSize: "11px",
                  padding: "3px",
                  borderRadius: "3px",
                }}
              >
                Editer
              </button>
              <button
                disabled={true ? loggedUserRole !== "ROLE_ADMIN" : false}
                onClick={() => confirmDelete(tableMeta.rowData[0])}
                style={{
                  background: "red",
                  color: "#fff",
                  fontSize: "11px",
                  padding: " 3px",
                  borderRadius: "3px",
                  marginLeft: "5px",
                }}
              >
                Supprimer
              </button>
            </>
          );
        },
      },
    },
  ];
  const options = {
    filterType: "textField",
    pagination: false,
    print: false,
    enableNestedDataAccess: ".",
    download: false,
  };

  const loggedUserRole = JSON.parse(localStorage.getItem("data"))?.roles[0];
  const loggedUserBureau = JSON.parse(localStorage.getItem("data"))?.bureau;
  const loggedUserName = JSON.parse(localStorage.getItem("data"))?.username;
  const [inputValue, setIputValue] = useState();

  const handleInputValue = (event) => {
    setIputValue({
      ...inputValue,
      [event.target.name]: event.target.value,
    });
  };

  const confirmDelete = (id) => {
    Notiflix.Confirm.show(
      " ",
      "Etes-vous sûr de vouloir supprimer ?",
      "Confirmer",
      "Annuler",
      () => {
        api
          .deleteIdentification(id)
          .then((response) => {
            Notiflix.Notify.success("supprimé !!");
            window.location.reload();
          })
          .catch((error) => console.log(error));
      }
    );
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    api
      .createIdentification({
        loggedUserData,
        inputValue,
        childId,
      })
      .then((response) => {
        if (response) {
          Notiflix.Notify.success("Identification créer avec succès ");
          window.location.reload(true);
        }
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    api
      .getAllIdentifications(childId)
      .then((response) => {
        if (response) {
          setIdList(() => response.data);
        }
      })
      .catch((error) => console.log(error));
  }, [childId]);

  return (
    <div className="content fiche-styles">
      <div className="m-sm-30">
        <Modal
          onClose={openResume.onClose}
          size={"xl"}
          isOpen={openResume.isOpen}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalCloseButton />
            <ModalBody>
              <ResumeIdentification idList={idList} idRow={idRow} chi />
            </ModalBody>
          </ModalContent>
        </Modal>
        <Modal
          onClose={openUpdate.onClose}
          size={"full"}
          isOpen={openUpdate.isOpen}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalCloseButton />
            <ModalBody>
              <UpdateIdentification idList={idList} idRow={idRow} />
            </ModalBody>
          </ModalContent>
        </Modal>
        <div className="checkbox-container" hidden={hiddenDiv}>
          <div className="dateprise" style={{ marginBottom: "2rem" }}>
            <div className="info-real">
              <ul>
                <li>Gestionnaire cas : {loggedUserData?.username}</li>
                <li>Bureau : {loggedUserData?.bureau} </li>
                <li>Tél : {loggedUserData?.tel} </li>
                <li>Email : {loggedUserData?.email} </li>
              </ul>
            </div>{" "}
            <form
              onSubmit={handleSubmit}
              className="form-container"
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div>
                <div className="form-container-block">
                  <h3 style={{ fontSize: "15px", fontWeight: "bolder" }}>
                    IDENTITE DE L’ENFANT
                  </h3>
                  <div style={{ margin: "10px 0", width: "50%" }}>
                    {" "}
                    <h3
                      style={{
                        fontSize: "13px",
                        fontWeight: "bolder",
                      }}
                    >
                      Pays d'identification :
                    </h3>
                    <Input
                      name="pays_identification"
                      onChange={handleInputValue}
                      type="text"
                      placeholder="...."
                      style={{
                        border: "none",
                        background: "lightgray",
                      }}
                    />
                  </div>
                  <div style={{ margin: "10px 0", display: "flex" }}>
                    {" "}
                    <div style={{ width: "50%", marginRight: "1rem" }}>
                      {" "}
                      <h3
                        style={{
                          fontSize: "13px",
                          fontWeight: "bolder",
                        }}
                      >
                        Région d'identification :
                      </h3>
                      <Input
                        name="region_identification"
                        onChange={handleInputValue}
                        type="text"
                        placeholder="...."
                        style={{
                          border: "none",
                          background: "lightgray",
                        }}
                      />
                    </div>
                    <div style={{ width: "50%" }}>
                      {" "}
                      <h3 style={{ fontWeight: "bolder", fontSize: "13px" }}>
                        Ville ou village d'identification :
                      </h3>
                      <Input
                        name="localite_identification"
                        onChange={handleInputValue}
                        placeholder="..."
                        type="text"
                        style={{
                          border: "none",
                          background: "lightgray",
                        }}
                      />
                    </div>
                  </div>
                  <div
                    style={{ margin: "10px 0", width: "100%", display: "flex" }}
                  >
                    {" "}
                    <div style={{ width: "50%", marginRight: "1rem" }}>
                      {" "}
                      <h3 style={{ fontWeight: "bolder", fontSize: "13px" }}>
                        Date d'identification :
                      </h3>
                      <Input
                        name="date_identification"
                        onChange={handleInputValue}
                        type="date"
                        style={{
                          border: "none",
                          background: "lightgray",
                        }}
                      />
                    </div>
                    <div style={{ width: "50%" }}>
                      {" "}
                      <h3
                        style={{
                          fontSize: "13px",
                          fontWeight: "bolder",
                        }}
                      >
                        {" "}
                        Date de remplissage :{" "}
                      </h3>
                      <Input
                        name="date_remplissage"
                        onChange={handleInputValue}
                        type="date"
                        style={{
                          border: "none",
                          background: "lightgray",
                        }}
                      />
                    </div>
                  </div>
                  <div onChange={handleInputValue}>
                    <h3
                      style={{
                        fontSize: "15px",
                        fontWeight: "bolder",
                      }}
                    >
                      Situation familiale :
                    </h3>
                    <h3 style={{ fontSize: "13px", fontWeight: "bolder" }}>
                      Situation du pére :
                    </h3>
                    <input
                      type="radio"
                      value="Orphelin(e) de Père"
                      name="situation_pere"
                    />
                    Orphelin(e) de Père
                    <input
                      type="radio"
                      name="situation_pere"
                      value="  Père vivant "
                      style={{ marginLeft: " 5px" }}
                    />
                    Père vivant
                    <input
                      type="radio"
                      value="Père non retrouvé"
                      name="situation_pere"
                    />
                    Père non retrouvé
                  </div>{" "}
                  <div onChange={handleInputValue}>
                    {" "}
                    <h3 style={{ fontSize: "13px", fontWeight: "bolder" }}>
                      Situation de la mére :
                    </h3>
                    <input
                      type="radio"
                      value="Orphelin(e) de mére"
                      name="situation_mere"
                    />
                    Orphelin(e) de mére
                    <input
                      type="radio"
                      value="mère vivante"
                      style={{ marginLeft: " 5px" }}
                      name="situation_mere"
                    />
                    Mère vivante
                    <input
                      type="radio"
                      value="mère non retrouvée"
                      name="situation_mere"
                    />
                    Mère non retrouvée
                  </div>{" "}
                  <div onChange={handleInputValue}>
                    {" "}
                    <h3 style={{ fontSize: "13px", fontWeight: "bolder" }}>
                      {" "}
                      Situation matrimonial des parents
                    </h3>
                    <input
                      type="radio"
                      value="Parents vivent ensemble"
                      name="situation_matrimonial_parents"
                    />
                    Parents vivent ensemble
                    <input
                      type="radio"
                      value="Parents séparés"
                      name="situation_matrimonial_parents"
                    />
                    Parents séparés
                    <input
                      type="radio"
                      value="Père remarié"
                      name="situation_matrimonial_parents"
                      style={{ marginLeft: "5px" }}
                    />
                    Père remarié
                    <input
                      type="radio"
                      value="Mère remariée"
                      name="situation_matrimonial_parents"
                    />
                    Mère remariée <br />
                    <div style={{ width: "50%" }}>
                      {" "}
                      <h3 style={{ fontSize: "13px", fontWeight: "bolder" }}>
                        Autres (préciser):
                      </h3>
                      <Input
                        name="situation_matrimonial_parents"
                        style={{
                          border: "none",
                          background: "lightgray",
                          paddingLeft: "5px",
                        }}
                        type="text"
                        placeholder="precisez ici..."
                      />
                    </div>
                  </div>{" "}
                  <div onChange={handleInputValue}>
                    {" "}
                    <h3 style={{ fontSize: "13px", fontWeight: "bolder" }}>
                      {" "}
                      Niveau d’instruction de l’enfant
                    </h3>
                    <input
                      type="radio"
                      name="niveau_instruction_enfant"
                      value="Primaire"
                    />
                    Primaire
                    <input
                      type="radio"
                      name="niveau_instruction_enfant"
                      value="Secondaire"
                      style={{ marginLeft: "5px" }}
                    />
                    Secondaire
                    <input
                      type="radio"
                      name="niveau_instruction_enfant"
                      value="Ecole coranique"
                      style={{ marginLeft: "5px" }}
                    />
                    Ecole coranique
                    <input
                      type="radio"
                      value="Alphabétisation en langue locale"
                      name="niveau_instruction_enfant"
                      style={{ marginLeft: "5px" }}
                    />
                    Alphabétisation en langue locale <br />
                    <input type="radio" name="niveau_instruction_enfant" />
                    Jamais scolarisé : <br />
                    <Input
                      name="precision_niveau"
                      style={{
                        border: "none",
                        background: "lightgray",
                        paddingLeft: "5px",
                        width: "50%",
                      }}
                      type="text"
                      placeholder="precisez ici..."
                    />
                  </div>{" "}
                </div>
                <div className="form-container-block">
                  <h3
                    style={{
                      fontSize: "15px",
                      fontWeight: "bolder",
                      margin: "1rem 0",
                    }}
                  >
                    TYPE DE VULNERABILITE POUR LEQUEL L’ENFANT A ETE IDENTIFIE
                  </h3>
                  <div onChange={handleInputValue}>
                    <div>
                      <input
                        type="radio"
                        name="vulnerabilite_enfant"
                        value=" Conflit avec la loi"
                      />{" "}
                      En conflit avec la loi <br />
                      <Input
                        name="duree_peine"
                        style={{
                          width: "50%",
                          border: "none",
                          background: "lightgray",
                          marginBottom: "5px",
                        }}
                        type="text"
                        placeholder="Si oui precisez durée de la peine (en mois )"
                      />{" "}
                      <br />
                    </div>{" "}
                    <span style={{ margin: "1rem 0" }}>
                      {" "}
                      <input
                        type="radio"
                        name="vulnerabilite_enfant"
                        value="Abus sexuel"
                      />
                      Abus sexuel
                    </span>
                    <span style={{ margin: "1rem 0" }}>
                      {" "}
                      <input
                        type="radio"
                        name="vulnerabilite_enfant"
                        value="Viol"
                      />
                      Viol
                    </span>{" "}
                    <br />
                    <span style={{ margin: "1rem 0" }}>
                      {" "}
                      <Input
                        name="auteurs_abus_viol"
                        style={{
                          border: "none",
                          background: "lightgray",
                          width: "50%",
                        }}
                        type="text"
                        placeholder="Auteurs"
                      />
                    </span>{" "}
                    <br />
                    <br />
                    <span style={{ margin: "1rem 0" }}>
                      {" "}
                      <input
                        type="radio"
                        name="vulnerabilite_enfant"
                        value="Exploitation sexuelle"
                      />
                      Exploitation sexuelle
                    </span>
                    <span style={{ margin: "1rem 0" }}>
                      {" "}
                      <input type="radio" name="vulnerabilite_enfant" />
                      Mariage d’enfants
                    </span>{" "}
                    <span style={{ margin: "1rem 0" }}>
                      {" "}
                      <input type="radio" name="vulnerabilite_enfant" /> enfants
                      victimes de violences basées sur le genre
                    </span>{" "}
                    <span style={{ margin: "1rem 0" }}>
                      {" "}
                      <input type="radio" name="vulnerabilite_enfant" />
                      Traite des enfants et exploitation
                    </span>
                    <span style={{ margin: "1rem 0" }}>
                      {" "}
                      <input
                        type="radio"
                        name="vulnerabilite_enfant"
                        value="Victime de Traite"
                      />{" "}
                      Victime de Traite
                    </span>{" "}
                    <span style={{ margin: "1rem 0" }}>
                      {" "}
                      <input
                        type="radio"
                        name="vulnerabilite_enfant"
                        value="Victime d’exploitation économique"
                      />
                      Victime d’exploitation économique
                    </span>{" "}
                    <br />
                    <span style={{ margin: "1rem 0" }}>
                      {" "}
                      <input
                        type="radio"
                        name="vulnerabilite_enfant"
                        value="Enfant égaré"
                      />{" "}
                      Enfant égaré
                    </span>
                    <span style={{ margin: "1rem 0" }}>
                      {" "}
                      <input
                        type="radio"
                        name="vulnerabilite_enfant"
                        value="Enfant en fugue"
                      />
                      Enfant en fugue
                    </span>
                    <span style={{ margin: "1rem 0" }}>
                      {" "}
                      <input
                        type="radio"
                        name="vulnerabilite_enfant"
                        value=" Enfant aventurier"
                      />
                      Enfant aventurier
                    </span>{" "}
                    <span style={{ margin: "1rem 0" }}>
                      {" "}
                      <input
                        type="radio"
                        name="vulnerabilite_enfant"
                        value="Victime de maltraitance et négligence"
                      />
                      Victime de maltraitance et négligence
                    </span>{" "}
                    <span style={{ margin: "1rem 0" }}>
                      {" "}
                      <input
                        type="radio"
                        name="vulnerabilite_enfant"
                        value="enfants migrants non accompagnés"
                      />
                      Enfants migrants non accompagnés
                    </span>{" "}
                    <br />
                    <div style={{ margin: "1rem 0" }}>
                      {" "}
                      Autres (préciser):
                      <Input
                        name="vulnerabilite_enfant"
                        style={{
                          border: "none",
                          background: "lightgray",
                          width: "50%",
                        }}
                        type="text"
                        placeholder="precisez ici..."
                      />
                    </div>
                  </div>
                  <h3
                    style={{
                      fontSize: "15px",
                      margin: "1rem 0 0",
                      fontWeight: "bolder",
                    }}
                  >
                    FILIATION DE L’ENFANT
                  </h3>
                  <div>
                    <h3 style={{ fontWeight: "bolder" }}>
                      {" "}
                      Tuteur de l'enfant:{" "}
                    </h3>
                    <div
                      style={{
                        display: "flex",
                      }}
                    >
                      <Input
                        onChange={handleInputValue}
                        name="nom_tuteur"
                        style={{
                          border: "none",
                          background: "lightgray",
                          width: "50%",
                          marginRight: "1rem",
                        }}
                        type="text"
                        placeholder="Nom et Prénom"
                      />
                      <Input
                        onChange={handleInputValue}
                        name="lien_parente_tuteur"
                        style={{
                          border: "none",
                          background: "lightgray",
                          width: "50%",
                        }}
                        type="text"
                        placeholder="Lien de parenté"
                      />
                    </div>
                    <div style={{ marginRight: "1rem", marginTop: "1rem" }}>
                      {" "}
                      <Input
                        onChange={handleInputValue}
                        name="tel_tuteur"
                        style={{
                          border: "none",
                          background: "lightgray",
                          width: "50%",
                        }}
                        type="text"
                        placeholder="Téléphone tuteur"
                      />
                    </div>
                  </div>
                </div>
                <div className="form-container-block">
                  <h3
                    style={{
                      fontSize: "15px",
                      margin: "1rem 0 0",
                      fontWeight: "bolder",
                    }}
                  >
                    CONTEXTE D’IDENTIFICATION
                  </h3>
                  <div
                    style={{
                      marginBottom: "5px",
                      display: "flex",
                      width: "100%",
                    }}
                  >
                    <div style={{ width: "50%", marginRight: "1rem" }}>
                      {" "}
                      <h3 style={{ fontSize: "13px", fontWeight: "bolder" }}>
                        Date d’arrivée dans le pays :{" "}
                      </h3>
                      <Input
                        onChange={handleInputValue}
                        name="date_arrivee_pays"
                        style={{
                          border: "none",
                          background: "lightgray",
                        }}
                        type="date"
                        placeholder="...."
                      />
                    </div>
                    <div style={{ width: "50%" }}>
                      {" "}
                      <h3 style={{ fontSize: "13px", fontWeight: "bolder" }}>
                        Localité d’identification :
                      </h3>{" "}
                      <Input
                        onChange={handleInputValue}
                        name="localite_identification"
                        style={{
                          border: "none",
                          background: "lightgray",
                        }}
                        type="text"
                        placeholder="...."
                      />
                    </div>{" "}
                  </div>{" "}
                  <div onChange={handleInputValue}>
                    {" "}
                    <h3 style={{ fontSize: "13px", fontWeight: "bolder" }}>
                      {" "}
                      Condition d’arrivée (CU):
                    </h3>
                    <input type="radio" name="condition_arrivee" value="Seul" />
                    Seul
                    <input
                      type="radio"
                      name="condition_arrivee"
                      value="En groupe avec d’autres enfants"
                    />
                    En groupe avec d’autres enfants{" "}
                    <input
                      type="radio"
                      name="condition_arrivee"
                      value="Avec ses parents"
                    />
                    Avec ses parents
                    <input
                      type="radio"
                      name="condition_arrivee"
                      value="En groupe avec d’autres adultes Autre"
                    />{" "}
                    En groupe avec d’autres adultes <br />
                    <div style={{ margin: "1rem 0" }}>
                      {" "}
                      Autres (préciser):
                      <Input
                        name="condition_arrivee"
                        style={{
                          border: "none",
                          background: "lightgray",
                          width: "50%",
                        }}
                        type="text"
                        placeholder="precisez ici..."
                      />
                    </div>
                  </div>{" "}
                  <div onChange={handleInputValue}>
                    {" "}
                    <h3 style={{ fontSize: "13px", fontWeight: "bolder" }}>
                      {" "}
                      Lien avec l’adulte :
                    </h3>
                    <input type="radio" />
                    Educatif
                    <input
                      type="radio"
                      value="Economique"
                      name="lien_avec_adulte"
                    />
                    Economique
                    <input
                      type="radio"
                      value="Voisinage"
                      name="lien_avec_adulte"
                    />
                    Voisinage
                    <input
                      type="radio"
                      value="Parenté"
                      name="lien_avec_adulte"
                    />
                    Parenté
                    <input
                      type="radio"
                      value="Humanitaire"
                      name="lien_avec_adulte"
                    />
                    Humanitaire
                  </div>{" "}
                  <div onChange={handleInputValue}>
                    {" "}
                    <h3 style={{ fontSize: "13px", fontWeight: "bolder" }}>
                      {" "}
                      Motif de départ de son milieu de vie habituel (CM):
                    </h3>
                    <input
                      type="radio"
                      value="Economique"
                      name="motif_depart_milieu_vie_habituel"
                    />
                    Educatif{" "}
                    <input
                      type="radio"
                      value="Educatif"
                      name="motif_depart_milieu_vie_habituel"
                    />
                    Economique
                    <input
                      type="radio"
                      value="Economique"
                      name="motif_depart_milieu_vie_habituel"
                    />
                    Fugue/Fuite
                    <input
                      type="radio"
                      value="Fugue/Fuite"
                      name="motif_depart_milieu_vie_habituel"
                    />
                    Familial
                    <input
                      type="radio"
                      value="Familial"
                      name="motif_depart_milieu_vie_habituel"
                    />
                    Humanitaire
                    <br />{" "}
                    <input
                      type="radio"
                      value="Humanitaire"
                      name="motif_depart_milieu_vie_habituel"
                    />{" "}
                    Aventure
                    <input
                      type="radio"
                      value="Enlèvement"
                      name="motif_depart_milieu_vie_habituel"
                    />{" "}
                    Enlèvement
                    <input
                      type="radio"
                      value="Confiage"
                      name="motif_depart_milieu_vie_habituel"
                    />{" "}
                    Confiage
                    <br />
                    <div style={{ margin: "1rem 0" }}>
                      {" "}
                      Autres (préciser):
                      <Input
                        name="motif_depart_milieu_vie_habituel"
                        style={{
                          border: "none",
                          background: "lightgray",
                          width: "50%",
                        }}
                        type="text"
                        placeholder="precisez ici..."
                      />
                    </div>
                  </div>{" "}
                  <div onChange={handleInputValue}>
                    {" "}
                    <h3 style={{ fontSize: "13px", fontWeight: "bolder" }}>
                      {" "}
                      Mode de saisine du cas de l’enfant (CU):
                    </h3>
                    <input
                      type="radio"
                      value="Auto saisine"
                      name="mode_saisine_du_cas_de_lenfant"
                    />
                    Auto saisine
                    <input
                      name="mode_saisine_du_cas_de_lenfant"
                      type="radio"
                      value="Référé(e) par les forces de l’ordre"
                    />
                    Référé(e) par les forces de l’ordre{" "}
                    <div style={{ margin: "1rem 0" }}>
                      {" "}
                      N° de la mention :
                      <Input
                        name="condition_arrivee"
                        style={{
                          border: "none",
                          background: "lightgray",
                          width: "50%",
                        }}
                        type="text"
                        placeholder="precisez ici..."
                      />
                    </div>
                    <input
                      type="radio"
                      value="Référé(e) par une tierce personne"
                      name="mode_saisine_du_cas_de_lenfant"
                    />{" "}
                    Référé(e) par une tierce personne
                    <br />
                    <h3 style={{ fontSize: "13px", fontWeight: "bolder" }}>
                      Référé(e) par une autre organisation :{" "}
                    </h3>
                    <Input
                      name="mode_saisine_du_cas_de_lenfant"
                      style={{
                        border: "none",
                        background: "lightgray",
                        width: "50%",
                      }}
                      type="text"
                      placeholder="precisez le nom de l'organisation..."
                    />
                  </div>{" "}
                  <h3
                    style={{
                      fontSize: "15px",
                      margin: "1rem 0 0",
                      fontWeight: "bolder",
                    }}
                  >
                    ETAT PSYCHOLOGIQUE ET SOCIALE DE L’ENFANT
                  </h3>
                  <div onChange={handleInputValue}>
                    <h3 style={{ fontSize: "13px", fontWeight: "bolder" }}>
                      Etat de santé physique :
                    </h3>{" "}
                    <input
                      type="radio"
                      value="bonne"
                      name="etat_sante_physique"
                    />
                    bonne
                    <input
                      type="radio"
                      value="Souffrant"
                      name="etat_sante_physique"
                    />
                    Souffrant
                    <div>
                      {" "}
                      Autres (préciser):
                      <Input
                        name="etat_sante_physique"
                        style={{
                          border: "none",
                          background: "lightgray",
                          width: "50%",
                        }}
                        type="text"
                        placeholder="precisez ici..."
                      />
                    </div>
                  </div>
                  <div>
                    <h3 style={{ fontWeight: "bolder", fontSize: "13px" }}>
                      Etat psychologique :
                    </h3>{" "}
                    <input type="radio" />
                    Stable
                    <input type="radio" />
                    Instable <br />
                  </div>
                  <span onChange={handleInputValue}>
                    <h3 style={{ fontSize: "13px", fontWeight: "bolder" }}>
                      Comportements (CM):{" "}
                    </h3>
                    <input type="radio" value="violent" name="comportements" />
                    violent
                    <input type="radio" name="comportements" value="Calme" />
                    Calme
                    <input
                      type="radio"
                      name="comportements"
                      value="Caractériel"
                    />
                    Caractériel
                    <input
                      type="radio"
                      name="comportements"
                      value="Refus de soumission aux règles"
                    />
                    Refus de soumission aux règles
                  </span>
                  <div onChange={handleInputValue}>
                    <h3 style={{ fontSize: "13px", fontWeight: "bolder" }}>
                      Nature de ses rapports avec les autres (CM):
                    </h3>{" "}
                    <input
                      type="radio"
                      value="Peur/Méfiance"
                      name="nature_rapport"
                    />
                    Peur/Méfiance
                    <input
                      type="radio"
                      value="Peur/Méfiance"
                      name="nature_rapport"
                    />
                    Désintérêt{" "}
                    <input
                      type="radio"
                      name="nature_rapport"
                      value="Intérêt/considération pour les autres"
                    />
                    Intérêt/considération pour les autres
                    <input
                      type="radio"
                      value="Saines fréquentations"
                      name="nature_rapport"
                    />
                    Saines fréquentations
                    <input
                      type="radio"
                      value="Mauvaises fréquentations"
                      name="nature_rapport"
                    />
                    Mauvaises fréquentations
                  </div>
                  <div onChange={handleInputValue}>
                    <h3 style={{ fontSize: "13px", fontWeight: "bolder" }}>
                      Dépendance (CM) :
                    </h3>{" "}
                    <input type="radio" value="Alcool" name="dependances" />
                    Alcool
                    <input type="radio" value="Tabac" name="dependances" />
                    Tabac
                    <input type="radio" value="Drogues" name="dependances" />
                    Drogues
                  </div>
                  <h3
                    style={{
                      fontSize: "15px",
                      fontWeight: "bolder",
                      margin: "1rem 0 0",
                    }}
                  >
                    SITUATION ACTUELLE DE L’ENFANT
                  </h3>
                  <div onChange={handleInputValue}>
                    <h3 style={{ fontSize: "13px", fontWeight: "bolder" }}>
                      Type de lieu d’hébergement (CU):{" "}
                    </h3>
                    <input
                      type="radio"
                      name="type_lieu_hebergement"
                      value="Hébergement dans un centre d’accueil"
                    />
                    Hébergement dans un centre d’accueil
                    <input
                      type="radio"
                      value="Hébergement dans une famille d’accueil"
                      name="type_lieu_hebergement"
                    />
                    Hébergement dans une famille d’accueil
                    <input
                      type="radio"
                      value="Rue"
                      name="type_lieu_hebergement"
                    />
                    Rue <br />
                    <div>
                      {" "}
                      Autres (préciser):
                      <Input
                        name="type_lieu_hebergement"
                        style={{
                          border: "none",
                          background: "lightgray",
                          width: "50%",
                        }}
                        type="text"
                        placeholder="precisez ici..."
                      />
                    </div>
                  </div>
                  <h3 style={{ fontSize: "13px", fontWeight: "bolder" }}>
                    {" "}
                    Résumé situation de l’enfant
                  </h3>
                  <span onChange={handleInputValue}>
                    <Textarea
                      name="resume_situation_enfant"
                      maxRows={4}
                      aria-label="maximum height"
                      placeholder="saisir ici...."
                      style={{
                        background: "lightgray",
                        width: "50%",
                      }}
                    />
                  </span>
                </div>
              </div>
              <div className="sbmit-btn" style={{ marginTop: "1em" }}>
                <Button
                  className="mb-4"
                  variant="contained"
                  color="primary"
                  type="submit"
                >
                  Enregistrer
                </Button>
                <Button
                  onClick={() => window.location.reload(true)}
                  style={{ background: "red" }}
                  className="mb-4"
                  type="button"
                >
                  Annuler
                </Button>
              </div>
            </form>
          </div>
        </div>
        <div className="list" hidden={!hiddenDiv}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            {" "}

            < BiArrowBack style={{ cursor: "pointer" }} size={"25"} onClick={() => window.location.href = '/admin/enfant'} />
            <Button
              onClick={() => handleAddForm()}
              className="mb-4"
              variant="contained"
              style={{ textTransform: "uppercase" }}
            >
              Créer une identification
            </Button>
          </div>
          <div className="info-real">
            <ul style={{ width: "15%" }}>
              <li>Nom : {idList?.nom}</li>
              <li>Prenom : {idList?.prenom} </li>
            </ul>
          </div>{" "}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "1rem",
            }}
          >
            {" "}
            <h3
              style={{
                fontSize: "20px",
                margin: "0 0 17px",
                fontWeight: "bolder",
              }}
            >
              Tableau identification
            </h3>
          </div>
          <MUIDataTable
            data={idList?.identite}
            columns={columns}
            options={options}
          />
        </div>
      </div>
    </div>
  );
};

export default withRouter(Identification);
