import React, { useState, useEffect } from "react";
import { Button } from "@material-ui/core";
import api from "services/api";
import Notiflix from "notiflix";
import { Input, Textarea } from "@chakra-ui/react";
const UpdateRetourEnFamille = ({ retourList, idRow }) => {
  const updateData = retourList.famille[idRow];
  const [dataToUpdate, setDataToUpdate] = useState();
  const [inputValue, setInputValue] = useState({
    id: updateData?.id,
  });
  const [checkboxValue, setCheckboxValue] = useState();

  const handleChange = (event) => {
    setInputValue({
      ...inputValue,
      [event.target.name]: event.target.value,
    });
    setDataToUpdate("");
  };
  const handleCheckbox = (event) => {
    setCheckboxValue({
      ...checkboxValue,
      [event.target.name]: event.target.value,
    });
  };
  console.log(checkboxValue);
  const handleSubmit = (event) => {
    event.preventDefault();
    api
      .updateRetourFamille({ inputValue, checkboxValue })
      .then((response) => {
        if (response) {
          console.log(response);
          Notiflix.Notify.success("Données Modifiées  ");
          window.location.reload(true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    setDataToUpdate(updateData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="checkbox-container">
      <div className="dateprise" style={{ marginBottom: "2rem" }}>
        <form
          onSubmit={handleSubmit}
          className="form-container"
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div className="form-container-block" style={{ width: "33%" }}>
            <div>
              <h3 style={{ fontSize: "13px", fontWeight: "bolder" }}>
                {" "}
                Recherche famille réaliser par :
              </h3>
              <Input
                type="text"
                onChange={handleChange}
                name="recherche_famille_realise_par"
                value={dataToUpdate?.recherche_famille_realise_par}
                placeholder="...."
                style={{
                  border: "none",
                  background: "lightgray",
                  paddingLeft: "5px",
                }}
              />
            </div>
            <div>
              <h3 style={{ fontSize: "13px", fontWeight: "bolder" }}>
                {" "}
                Date de la recherche :
              </h3>
              <Input
                type="date"
                onChange={handleChange}
                name="recherche_famille_date"
                value={dataToUpdate?.recherche_famille_date?.substring(0, 10)}
                style={{
                  border: "none",
                  background: "lightgray",
                  paddingLeft: "5px",
                }}
                placeholder="...."
              />
            </div>
            <div>
              <h3 style={{ fontSize: "13px", fontWeight: "bolder" }}>
                Structure ayant fait la recherche famille:
              </h3>
              <Input
                onChange={handleChange}
                name="recherche_famille_structure"
                value={dataToUpdate?.recherche_famille_structure}
                type="text"
                placeholder="...."
                style={{
                  border: "none",
                  background: "lightgray",
                  paddingLeft: "5px",
                }}
              />
            </div>
            <div onChange={handleChange}>
              <h3 style={{ fontSize: "13px", fontWeight: "bolder" }}>
                Localité recherche : {""}
              </h3>
              <h3 style={{ fontSize: "13px", fontWeight: "bolder" }}>
                Ville :
              </h3>
              <Input
                name="ville"
                value={dataToUpdate?.ville}
                type="text"
                placeholder="...."
                style={{
                  border: "none",
                  background: "lightgray",
                  paddingLeft: "5px",
                }}
              />
              <h3 style={{ fontSize: "13px", fontWeight: "bolder" }}>
                Village/quartier :
              </h3>
              <Input
                name="village_quartier"
                value={dataToUpdate?.village_quartier}
                type="text"
                placeholder="...."
                style={{
                  border: "none",
                  background: "lightgray",
                  paddingLeft: "5px",
                }}
              />
              <h3 style={{ fontSize: "13px", fontWeight: "bolder" }}>
                Region :
              </h3>
              <Input
                name="region"
                type="text"
                value={dataToUpdate?.region}
                placeholder="...."
                style={{
                  border: "none",
                  background: "lightgray",
                  paddingLeft: "5px",
                }}
              />
              <br />
              <div style={{ marginTop: "5px", display: "inline-block" }}>
                <h3 style={{ fontSize: "13px", fontWeight: "bolder" }}>
                  Departement :
                </h3>
                <Input
                  name="departement"
                  type="text"
                  value={dataToUpdate?.departement}
                  placeholder="...."
                  style={{
                    border: "none",
                    background: "lightgray",
                    paddingLeft: "5px",
                  }}
                />
                <h3 style={{ fontSize: "13px", fontWeight: "bolder" }}>
                  Pays :
                </h3>
                <Input
                  name="pays"
                  type="text"
                  placeholder="...."
                  value={dataToUpdate?.pays}
                  style={{
                    border: "none",
                    background: "lightgray",
                    paddingLeft: "5px",
                  }}
                />
              </div>
            </div>
            <div onChange={handleChange}>
              <h3 style={{ fontSize: "13px", fontWeight: "bolder" }}>
                Personne(s) contactée(s) maximum trois personnes : {""}
              </h3>
              <div>
                Lien parenté: <br />
                <Input
                  name="lien_parente_enfant1"
                  type="text"
                  value={dataToUpdate?.lien_parente_enfant1}
                  placeholder="...."
                  style={{
                    border: "none",
                    background: "lightgray",
                    paddingLeft: "5px",
                  }}
                />{" "}
                Adresse : {""}{" "}
                <Input
                  name="adresse1"
                  value={dataToUpdate?.adresse1}
                  type="text"
                  placeholder="...."
                  style={{
                    border: "none",
                    background: "lightgray",
                    paddingLeft: "5px",
                  }}
                />{" "}
                Téléphone: {""}{" "}
                <Input
                  name="telephone1"
                  value={dataToUpdate?.telephone1}
                  type="tel"
                  placeholder="...."
                  style={{
                    border: "none",
                    background: "lightgray",
                    paddingLeft: "5px",
                  }}
                />
              </div>
              <div>
                Lien parenté :
                <Input
                  name="lien_parente_enfant2"
                  value={dataToUpdate?.lien_parente_enfant2}
                  type="text"
                  placeholder="...."
                  style={{
                    border: "none",
                    paddingLeft: "5px",
                    background: "lightgray",
                  }}
                />{" "}
                Adresse : {""}{" "}
                <Input
                  name="adresse2"
                  value={dataToUpdate?.adresse2}
                  type="text"
                  placeholder="...."
                  style={{
                    border: "none",
                    background: "lightgray",
                    paddingLeft: "5px",
                  }}
                />{" "}
                Téléphone: {""}{" "}
                <Input
                  name="telephone2"
                  value={dataToUpdate?.telephone2}
                  type="tel"
                  placeholder="...."
                  style={{
                    border: "none",
                    background: "lightgray",
                    paddingLeft: "5px",
                  }}
                />
              </div>
              <div>
                Lien parenté : <br />
                <Input
                  name="lien_parente_enfant3"
                  value={dataToUpdate?.lien_parente_enfant3}
                  type="text"
                  placeholder="...."
                  style={{
                    border: "none",
                    background: "lightgray",
                    paddingLeft: "5px",
                  }}
                />{" "}
                Adresse : {""}{" "}
                <Input
                  name="adresse3"
                  value={dataToUpdate?.adresse3}
                  type="text"
                  placeholder="...."
                  style={{
                    border: "none",
                    background: "lightgray",
                    paddingLeft: "5px",
                  }}
                />{" "}
                Téléphone: {""}{" "}
                <Input
                  value={dataToUpdate?.telephone3}
                  name="telephone3"
                  type="tel"
                  placeholder="...."
                  style={{
                    border: "none",
                    background: "lightgray",
                    paddingLeft: "5px",
                  }}
                />
              </div>
            </div>

            <div>
              <h3 style={{ fontSize: "13px", fontWeight: "bolder" }}>
                Retour en famille réaliser par :
              </h3>
              <Input
                name="retour_realiser_par"
                value={dataToUpdate?.retour_realiser_par}
                type="text"
                placeholder="...."
                style={{
                  border: "none",
                  background: "lightgray",
                  paddingLeft: "5px",
                }}
              />
            </div>
          </div>
          <div className="form-container-block" style={{ width: "33%" }}>
            <div>
              <h3 style={{ fontSize: "13px", fontWeight: "bolder" }}>
                Personne ayant reçu en famille :
              </h3>
              Nom : <br />
              <Input
                name="personne_ayant_recu_enfant"
                value={dataToUpdate?.personne_ayant_recu_enfant}
                type="text"
                placeholder="...."
                style={{
                  border: "none",
                  background: "lightgray",
                  paddingLeft: "5px",
                }}
              />
            </div>
            <div>
              <h3 style={{ fontSize: "13px" }}>Téléphone :</h3>
              <Input
                name="telephone_personne_ayant_recu_enfant"
                value={dataToUpdate?.telephone_personne_ayant_recu_enfant}
                type="text"
                placeholder="...."
                style={{
                  border: "none",
                  background: "lightgray",
                  paddingLeft: "5px",
                }}
              />
            </div>
            <div>
              <h3 style={{ fontSize: "13px" }}>Adresse :</h3>
              <Input
                name="adresse_personne_ayant_recu_enfant"
                type="text"
                placeholder="...."
                value={dataToUpdate?.adresse_personne_ayant_recu_enfant}
                style={{
                  border: "none",
                  background: "lightgray",
                  paddingLeft: "5px",
                }}
              />
            </div>
            <div>
              <h3 style={{ fontSize: "13px" }}>CIN :</h3>
              <Input
                name="cin_personne_ayant_recu_enfant"
                value={dataToUpdate?.cin_personne_ayant_recu_enfant}
                type="text"
                placeholder="...."
                style={{
                  border: "none",
                  background: "lightgray",
                  paddingLeft: "5px",
                }}
              />
            </div>
            <div onChange={handleCheckbox}>
              <div style={{ fontSize: "13px" }}>
                <h3 style={{ fontWeight: "bolder", fontSize: "13px" }}>
                  Reintégration :
                </h3>{" "}
                <span style={{ background: "yellow" }}>
                  {dataToUpdate?.retour_reintegration}{" "}
                </span>
              </div>
              <input
                type="radio"
                name="retour_reintegration"
                value="Familiale"
              />
              Familiale
              <input
                type="radio"
                name="retour_reintegration"
                value="Professionnelle"
              />
              Professionnelle
              <input
                type="radio"
                name="retour_reintegration"
                value="Scolaire"
              />
              Scolaire
              <input
                type="radio"
                name="retour_reintegration"
                value="Pas encore d'activités"
              />
              Pas encore d'activités
            </div>
            <div>
              <h3 style={{ fontSize: "13px", fontWeight: "bolder" }}>
                Important à signaler :
              </h3>
              <Input
                name="retour_a_signaler"
                onChange={handleChange}
                value={dataToUpdate?.retour_a_signaler}
                type="text"
                placeholder="...."
                style={{
                  border: "none",
                  background: "lightgray",
                  paddingLeft: "5px",
                }}
              />
            </div>
            <div>
              <h3 style={{ fontSize: "13px", fontWeight: "bolder" }}>
                Date retour en famille :{" "}
              </h3>
              <Input
                name="retour_famille_date"
                onChange={handleChange}
                value={dataToUpdate?.retour_famille_date?.substring(0, 10)}
                type="date"
                style={{
                  border: "none",
                  background: "lightgray",
                  paddingLeft: "5px",
                }}
              />
            </div>
            <div>
              <h3 style={{ fontSize: "13px", fontWeight: "bolder" }}>
                Structure en charge du retour en famille :{" "}
              </h3>
              <Input
                name="retour_structure"
                onChange={handleChange}
                value={dataToUpdate?.retour_structure}
                type="text"
                placeholder="...."
                style={{
                  border: "none",
                  background: "lightgray",
                  paddingLeft: "5px",
                }}
              />
            </div>
            <div>
              <h3 style={{ fontSize: "13px", fontWeight: "bolder" }}>
                Lieu :{" "}
              </h3>
              <Input
                name="retour_lieu"
                value={dataToUpdate?.retour_lieu}
                type="text"
                onChange={handleChange}
                placeholder="...."
                style={{
                  border: "none",
                  background: "lightgray",
                  paddingLeft: "5px",
                }}
              />
            </div>
            <div onChange={handleCheckbox}>
              <h3 style={{ fontSize: "13px", fontWeight: "bolder" }}>
                Relation entre enfant et famille : <br />{" "}
                <span style={{ background: "yellow" }}>
                  {dataToUpdate?.relation_enfant_famille}{" "}
                </span>
              </h3>
              <div>
                {" "}
                <input
                  type="radio"
                  name="relation_enfant_famille"
                  value="Oui"
                />
                Oui{" "}
              </div>
              <div>
                {" "}
                <input
                  type="radio"
                  name="relation_enfant_famille"
                  value="Non"
                />
                Non{" "}
              </div>
            </div>
            <h3 style={{ fontSize: "13px", fontWeight: "bolder" }}>
              Pourquoi il n'y a pas de relation entre enfant et famille :
            </h3>

            <Textarea
              maxRows={4}
              name="relation_enfant_famille_pourquoi"
              onChange={handleChange}
              aria-label="maximum height"
              value={dataToUpdate?.relation_enfant_famille_pourquoi}
              placeholder="precisez le pourquoi ici..."
              style={{
                width: 200,
                border: "none",
                background: "lightgray",
                paddingLeft: "5px",
              }}
            />
            <div>
              <h3 style={{ fontSize: "13px", fontWeight: "bolder" }}>
                Ressource famille : <br />
                <span style={{ background: "yellow" }}>
                  {dataToUpdate?.ressources_famille_enfant}
                </span>
              </h3>
              <div>
                <div>
                  {" "}
                  <input
                    type="radio"
                    onChange={handleCheckbox}
                    name="ressources_famille_enfant"
                    value="Salarié"
                  />
                  Salarié{" "}
                </div>
                <span>
                  {" "}
                  <input
                    type="radio"
                    onChange={handleCheckbox}
                    name="ressources_famille_enfant"
                    value="Retraité"
                  />{" "}
                  Retraité{" "}
                </span>
                <span>
                  {" "}
                  <input
                    type="radio"
                    onChange={handleCheckbox}
                    name="ressources_famille_enfant"
                    value="Travailleur agricole"
                  />{" "}
                  Travailleur agricole{" "}
                </span>
                <div>
                  {" "}
                  <h3 style={{ fontSize: "13px", fontWeight: "bolder" }}>
                    Travailleur indépendant (Autre source des ressources) :
                  </h3>
                  <Input
                    value={dataToUpdate?.ressource_famille_enfant_specifiez}
                    onChange={handleChange}
                    name="ressource_famille_enfant_specifiez"
                    type="text"
                    placeholder="spécifier ici..."
                    style={{
                      border: "none",
                      background: "lightgray",
                      paddingLeft: "5px",
                    }}
                  />
                </div>
              </div>
            </div>
            <div>
              <h3 style={{ fontSize: "13px", fontWeight: "bolder" }}>
                Les parents acceptent-ils de reprendre l'enfant :
              </h3>
              <div>
                {" "}
                <input
                  onChange={handleCheckbox}
                  type="radio"
                  value="Oui"
                  name="reprendre_enfant"
                />
                Oui
              </div>
              <div>
                {" "}
                <input
                  onChange={handleCheckbox}
                  type="radio"
                  value="Non"
                  name="reprendre_enfant"
                />
                Non
              </div>{" "}
              <h3 style={{ fontSize: "13px", fontWeight: "bolder" }}>
                Pourquoi les parents ne reprennent il pas l'enfant :
              </h3>
              <Textarea
                maxRows={4}
                name="reprendre_enfant_pourquoi"
                onChange={handleChange}
                value={dataToUpdate?.reprendre_enfant_pourquoi}
                aria-label="maximum height"
                placeholder="precisez le pourquoi ici..."
                style={{
                  width: 200,
                  border: "none",
                  background: "lightgray",
                  paddingLeft: "5px",
                }}
              />
            </div>
            <div onChange={handleCheckbox}>
              <h3 style={{ fontSize: "13px", fontWeight: "bolder" }}>
                Conclusion evaluation :<br />
                <span style={{ background: "yellow" }}>
                  {dataToUpdate?.conclusion_evaluation}{" "}
                </span>
              </h3>
              <div>
                {" "}
                <input
                  type="radio"
                  name="conclusion_evaluation"
                  value="Réintégration avec un risque trés élevé"
                />
                Réintégration avec un risque trés élevé{" "}
              </div>
              <div>
                {" "}
                <input
                  type="radio"
                  name="conclusion_evaluation"
                  value="Réintégration avec un risque élevé"
                />
                Réintégration avec un risque élevé
              </div>{" "}
              <div>
                {" "}
                <input
                  type="radio"
                  name="conclusion_evaluation"
                  value="Réintégration avec un risque moyennement élevé"
                />
                Réintégration avec un risque moyennement élevé
              </div>
              <div>
                {" "}
                <input
                  type="radio"
                  name="conclusion_evaluation"
                  value="Réintégration sans risque élevé"
                />
                Réintégration sans risque élevé
              </div>
            </div>
          </div>
          <div className="sbmit-btn">
            <Button
              className="mb-4"
              variant="contained"
              color="primary"
              type="submit"
            >
              Modifier
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default UpdateRetourEnFamille;
