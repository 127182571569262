import React, { useState, useEffect } from 'react';
import api from '../services/api';
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Input,
  Row,
  Col,
} from 'reactstrap';
import Notiflix from 'notiflix';

function UserProfile() {
  const [profil, setProfil] = useState();
  const [passwordInput, setPasswordInput] = useState({
    password: '',
    confirmPassword: '',
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    if (passwordInput?.password === '') {
      Notiflix.Notify.warning('Attention champs de mot de passe vide');
    }
    if (
      passwordInput?.password !== '' &&
      passwordInput?.password === passwordInput?.confirmPassword
    ) {
      api
        .updateUserPassword({ id: profil.id, passwordInput })
        .then((response) => {
          if (response) {
            Notiflix.Notify.success(
              'Le mot de passe a été mis à jour avec succès'
            );
          }
        });
    } else if (
      passwordInput?.password !== '' &&
      passwordInput?.password.length < 8
    ) {
      Notiflix.Notify.failure("Le mot doit être d'au moins 8 carractéres !");
    } else if (passwordInput?.password !== passwordInput?.confirmPassword) {
      Notiflix.Notify.failure('Les valeurs des champs ne correspondent pas ');
    } else {
      return null;
    }
  };

  const handlePassword = (event) => {
    setPasswordInput({
      ...passwordInput,
      [event.target.name]: event.target.value,
    });
  };

  useEffect(() => {
    api.getUserProfil().then((response) => {
      if (response) {
        setProfil(response.data.user);
      }
    });
  }, []);
  return (
    <>
      <div className="content">
        <Row>
          <Col md="8">
            <Card>
              <CardHeader>
                <h5 className="title">Editer Profil</h5>
              </CardHeader>
              <CardBody>
                <form onSubmit={handleSubmit} style={{ marginLeft: '10px' }}>
                  <Row>
                    <Col className="px-md-1" md="3">
                      <FormGroup>
                        <label>Username</label>
                        <Input
                          defaultValue="michael23"
                          value={profil?.username}
                          placeholder="Username"
                          disabled
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                    <Col className="pl-md-1" md="4">
                      <FormGroup>
                        <label htmlFor="exampleInputEmail1">Email</label>
                        <Input disabled type="email" value={profil?.email} />
                      </FormGroup>
                    </Col>{' '}
                    <Col className="pl-md-1" md="4">
                      <FormGroup>
                        <label htmlFor="exampleInputEmail1">Mot de passe</label>
                        <Input
                          placeholder="saisir nouveau mot de passe"
                          type="password"
                          name="password"
                          onChange={handlePassword}
                        />
                      </FormGroup>
                    </Col>
                    <Col className="pl-md-1" md="4">
                      <FormGroup>
                        <label htmlFor="exampleInputEmail1">
                          Confirmer mot de passe
                        </label>
                        <Input
                          placeholder="confirmer mot de passe "
                          type="password"
                          name="confirmPassword"
                          onChange={handlePassword}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Button className="btn-fill" color="primary" type="submit">
                    Modifier
                  </Button>
                  <Button
                    onClick={() => window.location.reload(true)}
                    style={{ background: 'red' }}
                    type="button"
                  >
                    Annuler
                  </Button>
                </form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default UserProfile;
