import React, { useRef, useCallback } from "react";
import "./resumeStyle.scss";
import ReactToPrint from "react-to-print";
const ResumeIdentification = ({ idList, idRow }) => {

  const componentRef = useRef(null);
  const reactToPrintContent = useCallback(() => {
    return componentRef.current;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [componentRef.current]);

  const reactToPrintTrigger = React.useCallback(() => {
    return (
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
          margin: "50px 0 0",
          position: "relative",
          top: "50px"
        }}
      >
        {" "}
        <button
          style={{
            background: "lightgray",
            padding: "3px 5px",
            margin: "0 5px",
            borderRadius: "3px",
            color: "white",
            fontSize: "12px",
            border: "none",
          }}
        >
          Inprimer
        </button>
      </div>
    ); // eslint-disable-line max-len
  }, []);

  return (
    <>
      <ReactToPrint
        content={reactToPrintContent}
        documentTitle="AwesomeFileName"
        removeAfterPrint
        trigger={reactToPrintTrigger}
      />
      <div className="resu_container" ref={componentRef}>
        <span style={{ textTransform: "uppercase", textDecoration: "underline", fontWeight: "500" }}> Enfant :</span>
        <p style={{ color: "green", fontWeight: "500" }}> Code: {idList?.child_code}</p>
        <p style={{ color: "green", fontWeight: "500" }}> Prénom et Nom : {idList?.prenom} {idList?.nom} </p>
        <p style={{ color: "green", fontWeight: "500" }}>Date de naissance : {idList?.ddn?.substring(0, 10)}  </p>
        <p style={{ color: "green", fontWeight: "500" }}>Lieu de naissance : {idList?.lieu_naissance}  </p>


        {idList.identite?.map((item, index) => {
          if (index === idRow) {
            return (
              <div className="resu_block" key={index}>
                <span style={{ textTransform: "uppercase", textDecoration: "underline", fontWeight: "500" }}>Gestionnaire du cas :</span>
                <p style={{ color: "blue", fontWeight: "500" }}>Nom : {item?.gestionnaire_cas} </p>
                <p style={{ color: "blue", fontWeight: "500" }}>Tél : {item?.tel} </p>
                <p style={{ color: "blue", fontWeight: "500" }}>Bureau : {item?.bureau}  </p>
                <span style={{ textTransform: "uppercase", textDecoration: "underline", fontWeight: "500" }}>Informations générales :</span>
                <div>
                  <span>Date de remplissage</span> :{" "}
                  {item?.date_remplissage?.substring(0, 10)}
                </div>
                <div>
                  <span> Pays d'identification</span> : {item?.pays_identification}
                </div>
                <div>
                  <span>Region d'identification </span> :{" "}
                  {item?.region_identification}
                </div>
                <div>
                  <span> Date d'identification </span> :{" "}
                  {item?.date_identification?.substring(0, 10)}
                </div>
                <div>
                  <span> Situation du père</span> : {item?.situation_pere}
                </div>
                <div>
                  <span>Situation de la mère </span> : {item?.situation_mere}
                </div>
                <div>
                  <span> Situation matrimoniale des parents </span> :{" "}
                  {item?.situation_matrimoniale_parents}
                </div>
                <div>
                  <span>Niveau d'instruction</span> :{" "}
                  {item?.niveau_instruction_enfant}
                </div>
                <div>
                  <span>Précision du niveau</span> : {item?.précision_niveau}
                </div>
                <div>
                  <span>Vulnerabilité(s) de l'enfant </span> :{" "}
                  {item?.vulnerabilite_enfant}
                </div>
                <div>
                  <span>Auteur de l'abus ou du viol </span> :{" "}
                  {item?.auteurs_abus_viol}
                </div>
                <div>
                  <span> Nom du tuteur </span> : {item?.nom_tuteur}
                </div>
                <div>
                  <span> Lien de parenté du tuteur avec l'enfant </span> :{" "}
                  {item?.lien_parente_tuteur}
                </div>
                <div>
                  <span> Adresse du tuteur </span> : {item?.adresse_tuteur}
                </div>
                <div>
                  <span> Téléphonedu tuteur </span> : {item?.tel_tuteur}
                </div>
                <div>
                  <span> Date d'arrivée dans le pays </span> :{" "}
                  {item?.date_arrivee_pays}
                </div>
                <div>
                  <span> Localité d'identification </span> :{" "}
                  {item?.localite_identification}
                </div>
                <div>
                  <span> Condition d'arrivée </span> : {item?.condition_arrivee}
                </div>
                <div>
                  <span> Lien avec l'adulte</span> : {item?.lien_avec_adulte}
                </div>
                <div>
                  <span> Motif depart de son milieu de vie habituel </span> :{" "}
                  {item?.motif_depart_milieu_vie_habituel}
                </div>
                <div>
                  <span> Mode de saisine du cas de l'enfant</span> :{" "}
                  {item?.mode_saisine_du_cas_de_lenfant}
                </div>
                <div>
                  <span> Etat de santé physique </span> : {item?.etat_sante_physique}
                </div>
                {/* <span>
              <span> Etat de santé psychologique</span> :{" "}{item?.}
            </span> */}
                <div>
                  <span> Comportement</span> : {item?.comportements}
                </div>
                <div>
                  <span> Dépendances</span> : {item?.dependances}
                </div>
                <div>
                  <span> Nature des rapports avec les autres</span> :{" "}
                  {item?.nature_rapport}
                </div>
                <div>
                  <span> Type de lieu d'hébérgement</span> :{" "}
                  {item?.type_lieu_hebergement}
                </div>
                <div>
                  <span> Résumé de la situation de l'enfant</span> :{" "}
                  {item?.resume_situation_enfant}
                </div>
              </div>
            );
          } else return "";
        })}
      </div ></>

  );
};

export default ResumeIdentification;
