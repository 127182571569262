import React, { useEffect, useState } from "react";
import { Button } from "@material-ui/core";
import api from "services/api";
import Notiflix from "notiflix";
import { Input, Textarea } from "@chakra-ui/react";
const IdentificationValidationUpdate = ({
  ficheId,
  dataToVerify,
  childId,
  onclose,
}) => {
  const [dataToUpdate, setDataToUpdate] = useState();
  const [inputValue, setIputValue] = useState({
    id: ficheId,
  });
  console.log(dataToVerify);
  const handleInputValue = (event) => {
    setIputValue({
      ...inputValue,
      [event.target.name]: event.target.value,
    });
    setDataToUpdate("");
  };
  const [situationPere, setSituationPere] = useState("");
  const handleSituationDuPere = (event) => {
    setSituationPere(event.target.value);
  };

  const [situationMere, setSituationMere] = useState("");
  const handleSituationMere = (event) => {
    setSituationMere(event.target.value);
  };

  const [situationMatri, setSituationMatri] = useState("");
  const handleSituationMatri = (event) => {
    setSituationMatri(event.target.value);
  };
  const [niveauEtude, setNiveauEtude] = useState("");
  const handleNiveauEtude = (event) => {
    setNiveauEtude(event.target.value);
  };
  const [vulnerabilite, setVulnerabilite] = useState("");
  const handleVulnerabilite = (event) => {
    setVulnerabilite(event.target.value);
  };
  const [comportement, setComportement] = useState("");
  const handleComportement = (event) => {
    setComportement(event.target.value);
  };
  const [conditionArrive, setConditionArrive] = useState("");
  const handleConditionArrive = (event) => {
    setConditionArrive(event.target.value);
  };
  const [lienAdulte, setLienAdulte] = useState("");
  const handleAdulteLink = (event) => {
    setLienAdulte(event.target.value);
  };
  const [motifDepart, setMotifDepart] = useState("");
  const handleMotifDepart = (event) => {
    setMotifDepart(event.target.value);
  };
  const [saisineMode, setSaisineMode] = useState("");
  const handleSaisineMode = (event) => {
    setSaisineMode(event.target.value);
  };
  const [etatSante, setEtatSante] = useState("");
  const handleEtatSante = (event) => {
    setEtatSante(event.target.value);
  };
  const [situationActu, setSituationActu] = useState("");
  const handleSituationActu = (event) => {
    setSituationActu(event.target.value);
  };
  const [dependance, setDependance] = useState("");
  const handleDependance = (event) => {
    setDependance(event.target.value);
  };
  const [natureRapport, setNatureRapport] = useState("");
  const handleNatureRelation = (event) => {
    setNatureRapport(event.target.value);
  };
  useEffect(() => {
    setDataToUpdate(dataToVerify);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmitVerifiedData = () => {
    api
      .updateIdentificationVerification({
        ficheId,
        childId,
        situationPere,
        situationMere,
        situationMatri,
        niveauEtude,
        vulnerabilite,
        comportement,
        conditionArrive,
        lienAdulte,
        motifDepart,
        saisineMode,
        etatSante,
        situationActu,
        dependance,
        natureRapport,
        inputValue,
      })
      .then((response) => {
        if (response) {
          Notiflix.Notify.success("Données Validées ");
          window.location.reload(true);
          console.log(response);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <div className="content">
      <div className="m-sm-30">
        <div className="checkbox-container">
          <form
            className="dateprise"
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div
              className="form-container"
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div className="form-container-block" style={{ width: "33%" }}>
                <h3 style={{ fontSize: "15px", fontWeight: "bolder" }}>
                  Identification
                </h3>
                <div>
                  {" "}
                  <h3 style={{ fontSize: "13px", fontWeight: "bolder" }}>
                    Date de remplissage{" "}
                  </h3>
                  <Input
                    name="date_remplissage"
                    required
                    onChange={handleInputValue}
                    value={dataToUpdate?.date_remplissage?.substring(0, 10)}
                    type="date"
                    style={{
                      border: "none",
                      background: "lightgray",
                      paddingLeft: "5px",
                    }}
                  />
                </div>
                <div>
                  {" "}
                  <h3 style={{ fontSize: "13px", fontWeight: "bolder" }}>
                    Pays d'identification :
                  </h3>
                  <Input
                    name="pays_identification"
                    onChange={handleInputValue}
                    value={dataToUpdate?.pays_identification}
                    type="text"
                    placeholder="...."
                    style={{
                      border: "none",
                      background: "lightgray",
                      paddingLeft: "5px",
                    }}
                  />
                </div>
                <div>
                  {" "}
                  <h3 style={{ fontWeight: "bolder", fontSize: "13px" }}>
                    {" "}
                    Région d'identification :
                  </h3>
                  <Input
                    name="region_identification"
                    value={dataToUpdate?.region_identification}
                    onChange={handleInputValue}
                    type="text"
                    placeholder="...."
                    style={{
                      border: "none",
                      background: "lightgray",
                      paddingLeft: "5px",
                    }}
                  />
                </div>
                <div>
                  {" "}
                  <h3 style={{ fontSize: "13px", fontWeight: "bolder" }}>
                    Date d'identification :
                  </h3>
                  <Input
                    name="date_identification"
                    value={dataToUpdate?.date_identification?.substring(0, 10)}
                    onChange={handleInputValue}
                    type="date"
                    required
                    style={{
                      border: "none",
                      background: "lightgray",
                      paddingLeft: "5px",
                    }}
                  />
                </div>
                <div>
                  {" "}
                  <h3 style={{ fontWeight: "bolder", fontSize: "13px" }}>
                    Ville ou village d'identification :
                  </h3>
                  <Input
                    name="localite_identification"
                    value={dataToUpdate?.localite_identification}
                    onChange={handleInputValue}
                    placeholder="..."
                    type="text"
                    style={{
                      border: "none",
                      background: "lightgray",
                      paddingLeft: "5px",
                    }}
                  />
                </div>
                <div onChange={handleSituationDuPere}>
                  {" "}
                  <h3 style={{ fontSize: "13px", fontWeight: "bolder" }}>
                    {" "}
                    Situation du pére
                  </h3>
                  <input
                    style={{ fontSize: "10px", background: "yellow" }}
                    type="text"
                    disabled
                    value={dataToUpdate?.situation_pere}
                  />{" "}
                  <br />
                  <input
                    type="radio"
                    value="Orphelin(e) de Père"
                    name="situation_pere"
                  />
                  Orphelin(e) de Père
                  <input
                    type="radio"
                    name="situation_pere"
                    value="Père vivant "
                    style={{ marginLeft: " 5px" }}
                  />
                  Père vivant <br />
                  <input
                    type="radio"
                    value="Père non retrouvé"
                    name="situation_pere"
                  />
                  Père non retrouvé
                </div>{" "}
                <div onChange={handleSituationMere}>
                  {" "}
                  <h3 style={{ fontSize: "13px", fontWeight: "bold" }}>
                    {" "}
                    Situation de la mére
                  </h3>
                  <input
                    style={{ fontSize: "10px", background: "yellow" }}
                    type="text"
                    disabled
                    value={dataToUpdate?.situation_mere}
                  />{" "}
                  <br />
                  <input
                    type="radio"
                    value="Orphelin(e) de mére"
                    name="situation_mere"
                  />
                  Orphelin(e) de mére
                  <input
                    type="radio"
                    value="mère vivante"
                    style={{ marginLeft: " 5px" }}
                    name="situation_mere"
                  />
                  mère vivante <br />
                  <input
                    type="radio"
                    value="mère non retrouvée"
                    name="situation_mere"
                  />
                  mère non retrouvée
                </div>{" "}
                <div onChange={handleSituationMatri}>
                  {" "}
                  <h3 style={{ fontSize: "13px", fontWeight: "bolder" }}>
                    {" "}
                    Situation matrimonial des parents
                  </h3>
                  <input
                    style={{ fontSize: "10px", background: "yellow" }}
                    type="text"
                    disabled
                    value={dataToUpdate?.situation_matrimonial_parents}
                  />{" "}
                  <br />
                  <input
                    type="radio"
                    value="Parents vivent ensemble"
                    name="situation_matrimonial_parents"
                  />
                  Parents vivent ensemble <br />
                  <input
                    type="radio"
                    value="Parents séparés"
                    name="situation_matrimonial_parents"
                  />
                  Parents séparés
                  <input
                    type="radio"
                    value="Père remarié"
                    name="situation_matrimonial_parents"
                    style={{ marginLeft: "5px" }}
                  />
                  Père remarié
                  <br />
                  <input
                    type="radio"
                    value="Mère remariée"
                    name="situation_matrimonial_parents"
                  />
                  Mère remariée <br />
                  <h3 style={{ fontSize: "13px", fontWeight: "bolder" }}>
                    Autres (préciser):
                  </h3>
                  <Input
                    name="situation_matrimonial_parents"
                    value={dataToUpdate?.situation_matrimonial_parents}
                    style={{
                      border: "none",
                      background: "lightgray",
                    }}
                    type="text"
                    placeholder="precisez ici..."
                  />
                </div>{" "}
                <div onChange={handleNiveauEtude}>
                  {" "}
                  <h3 style={{ fontSize: "13px", fontWeight: "bolder" }}>
                    {" "}
                    Niveau d’instruction de l’enfant
                  </h3>
                  <input
                    style={{ fontSize: "10px", background: "yellow" }}
                    type="text"
                    disabled
                    value={dataToUpdate?.niveau_instruction_enfant}
                  />{" "}
                  <br />
                  <input
                    type="radio"
                    name="niveau_instruction_enfant"
                    value="Primaire"
                  />
                  Primaire
                  <input
                    type="radio"
                    name="niveau_instruction_enfant"
                    value="Secondaire"
                    style={{ marginLeft: "5px" }}
                  />
                  Secondaire
                  <input
                    type="radio"
                    name="niveau_instruction_enfant"
                    value="Ecole coranique"
                    style={{ marginLeft: "5px" }}
                  />
                  Ecole coranique
                  <input
                    type="radio"
                    value="Alphabétisation en langue locale"
                    name="niveau_instruction_enfant"
                    style={{ marginLeft: "5px" }}
                  />
                  Alphabétisation en langue locale
                  <br />{" "}
                  <input
                    type="radio"
                    name="niveau_instruction_enfant"
                    value="Jamais scolarisé"
                  />
                  Jamais scolarisé <br />{" "}
                  <h3 style={{ fontSize: "13px", fontWeight: "bolder" }}>
                    Préciser le niveau:
                  </h3>
                  <Input
                    name="precision_niveau"
                    value={dataToUpdate?.precision_niveau}
                    style={{
                      border: "none",
                      background: "lightgray",
                    }}
                    type="text"
                    placeholder="precisez ici..."
                  />
                </div>{" "}
              </div>
              <div
                className="form-container-block"
                style={{ width: "33%", marginLeft: "3rem" }}
              >
                <h3 style={{ fontSize: "13px", fontWeight: "bolder" }}>
                  Vulnérabilité enfant
                </h3>
                {/* <input
                  style={{
                    fontSize: "10px",
                    background: "yellow",
                  }}
                  type="text"
                  disabled
                  value={dataToUpdate?.vulnerabilite_enfant}
                />{" "} */}
                <div onChange={handleVulnerabilite}>
                  <div>
                    <input
                      type="checkbox"
                      name="vulnerabilite_enfant"
                      value=" Conflit avec la loi"
                    />{" "}
                    Conflit avec la loi <br />
                    duree peine :{" "}
                    <Input
                      name="duree_peine"
                      value={dataToUpdate?.duree_peine}
                      style={{
                        border: "none",
                        background: "lightgray",
                      }}
                      type="number"
                      placeholder="precisez ici.."
                    />{" "}
                    <br />
                  </div>{" "}
                  <span style={{ margin: "1rem 0" }}>
                    {" "}
                    <input
                      type="checkbox"
                      name="vulnerabilite_enfant"
                      value="Abus sexuel"
                    />
                    Abus sexuel
                  </span>
                  <span style={{ margin: "1rem 0" }}>
                    {" "}
                    <input
                      type="checkbox"
                      name="vulnerabilite_enfant"
                      value="Viol"
                    />
                    Viol
                  </span>{" "}
                  <div>
                    {" "}
                    Auteur :{" "}
                    <Input
                      name="auteurs_abus_viol"
                      value={dataToUpdate?.auteurs_abus_viol}
                      style={{
                        border: "none",
                        background: "lightgray",
                      }}
                      type="text"
                      placeholder="nom auteur abus..."
                    />
                  </div>{" "}
                  <span style={{ margin: "1rem 0" }}>
                    {" "}
                    <input
                      type="checkbox"
                      name="vulnerabilite_enfant"
                      value="Exploitation sexuelle"
                    />
                    Exploitation sexuelle
                  </span>
                  <span style={{ margin: "1rem 0" }}>
                    {" "}
                    <input
                      value="Mariage d’enfants"
                      type="checkbox"
                      name="vulnerabilite_enfant"
                    />
                    Mariage d’enfants
                  </span>{" "}
                  <br />
                  <span style={{ margin: "1rem 0" }}>
                    {" "}
                    <input
                      type="checkbox"
                      name="vulnerabilite_enfant"
                      value="enfants
                    victimes de violences basées sur le genre"
                    />{" "}
                    enfants victimes de violences basées sur le genre
                  </span>{" "}
                  <br />
                  <span style={{ margin: "1rem 0" }}>
                    {" "}
                    <input
                      value="Traite des enfants et exploitation"
                      type="checkbox"
                      name="vulnerabilite_enfant"
                    />
                    Traite des enfants et exploitation
                  </span>
                  <span style={{ margin: "1rem 0" }}>
                    {" "}
                    <input
                      type="checkbox"
                      name="vulnerabilite_enfant"
                      value="Victime de Traite"
                    />{" "}
                    Victime de Traite
                  </span>{" "}
                  <br />
                  <span style={{ margin: "1rem 0" }}>
                    {" "}
                    <input
                      type="checkbox"
                      name="vulnerabilite_enfant"
                      value="Victime d’exploitation économique"
                    />
                    Victime d’exploitation économique
                  </span>{" "}
                  <br />
                  <span style={{ margin: "1rem 0" }}>
                    {" "}
                    <input
                      type="checkbox"
                      name="vulnerabilite_enfant"
                      value="Enfant égaré"
                    />{" "}
                    Enfant égaré
                  </span>
                  <span style={{ margin: "1rem 0" }}>
                    {" "}
                    <input
                      type="checkbox"
                      name="vulnerabilite_enfant"
                      value="Enfant en fugue"
                    />
                    Enfant en fugue
                  </span>
                  <span style={{ margin: "1rem 0" }}>
                    {" "}
                    <input
                      type="checkbox"
                      name="vulnerabilite_enfant"
                      value=" Enfant aventurier"
                    />
                    Enfant aventurier
                  </span>{" "}
                  <br />
                  <span style={{ margin: "1rem 0" }}>
                    {" "}
                    <input
                      type="checkbox"
                      name="vulnerabilite_enfant"
                      value="Victime de maltraitance et négligence"
                    />
                    Victime de maltraitance et négligence
                  </span>{" "}
                  <br />
                  <span style={{ margin: "1rem 0" }}>
                    {" "}
                    <input
                      type="checkbox"
                      name="vulnerabilite_enfant"
                      value="enfants migrants non accompagnés"
                    />
                    enfants migrants non accompagnés
                  </span>{" "}
                  <div>
                    {" "}
                    <h3 style={{ fontWeight: "bolder", fontSize: "13px" }}>
                      {" "}
                      Autres (préciser):
                    </h3>
                    <Input
                      name="vulnerabilite_enfant"
                      value={dataToUpdate?.vulnerabilite_enfant}
                      style={{
                        border: "none",
                        background: "lightgray",
                      }}
                      type="text"
                      placeholder="precisez ici..."
                    />
                  </div>
                </div>
                <h3
                  style={{
                    fontSize: "13px",
                    fontWeight: "bolder",
                  }}
                >
                  Filiation de l'enfant
                </h3>
                <div>
                  <div
                    style={{
                      display: "flex",

                      flexDirection: "column",
                    }}
                  >
                    {" "}
                    <h3 style={{ fontWeight: "bolder", fontSize: "13px" }}>
                      {" "}
                      Nom et Prénom(s) du tuteur{" "}
                    </h3>
                    <Input
                      onChange={handleInputValue}
                      name="nom_tuteur"
                      value={dataToUpdate?.nom_tuteur}
                      style={{
                        border: "none",
                        background: "lightgray",
                      }}
                      type="text"
                      placeholder="...."
                    />
                  </div>
                  <div>
                    <h3 style={{ fontSize: "13px", fontWeight: "bolder" }}>
                      {" "}
                      Lien de parenté
                    </h3>
                    <Input
                      onChange={handleInputValue}
                      value={dataToUpdate?.lien_parente_tuteur}
                      name="lien_parente_tuteur"
                      style={{
                        border: "none",
                        background: "lightgray",
                      }}
                      type="text"
                      placeholder="...."
                    />
                  </div>{" "}
                  <div>
                    {" "}
                    <h3 style={{ fontSize: "13px", fontWeight: "bolder" }}>
                      Adresse du tuteur :
                    </h3>
                    <Input
                      onChange={handleInputValue}
                      name="adresse_tuteur"
                      style={{
                        border: "none",
                        background: "lightgray",
                        display: "inline-block",
                      }}
                      type="text"
                      placeholder="...."
                    />
                  </div>{" "}
                  <div>
                    {" "}
                    <h3 style={{ fontSize: "13px", fontWeight: "bolder" }}>
                      téléphone du tuteur :
                    </h3>
                    <Input
                      onChange={handleInputValue}
                      name="tel_tuteur"
                      value={dataToUpdate?.tel_tuteur}
                      style={{
                        border: "none",
                        background: "lightgray",
                      }}
                      type="text"
                      placeholder="...."
                    />
                  </div>{" "}
                </div>
              </div>
              <div
                className="form-container-block"
                style={{ width: "33%", marginLeft: "3rem" }}
              >
                <h3
                  style={{
                    fontSize: "13px",
                    margin: "1rem 0 0",
                    fontWeight: "bolder",
                  }}
                >
                  Context d'identification
                </h3>
                <div>
                  {" "}
                  <h3 style={{ fontWeight: "bolder", fontSize: "13px" }}>
                    Date d’arrivée dans le pays :{" "}
                  </h3>
                  <Input
                    onChange={handleInputValue}
                    name="date_arrivee_pays"
                    value={dataToUpdate?.date_arrivee_pays?.substring(0, 10)}
                    style={{
                      border: "none",
                      background: "lightgray",
                      paddingLeft: "5px",
                    }}
                    type="date"
                    placeholder="...."
                    required
                  />
                </div>{" "}
                <div>
                  {" "}
                  <h3 style={{ fontSize: "13px", fontWeight: "bolder" }}>
                    {" "}
                    localité d’identification :{" "}
                  </h3>
                  <Input
                    onChange={handleInputValue}
                    name="localite_identification"
                    value={dataToUpdate?.localite_identification}
                    style={{
                      border: "none",
                      background: "lightgray",
                      paddingLeft: "5px",
                    }}
                    type="text"
                    placeholder="...."
                  />
                </div>{" "}
                <div onChange={handleConditionArrive}>
                  {" "}
                  <h3 style={{ fontSize: "13px", fontWeight: "bolder" }}>
                    {" "}
                    Condition d’arrivée (CU):
                  </h3>
                  <input
                    type="text"
                    value={dataToUpdate?.condition_arrivee}
                    style={{ background: "yellow" }}
                    disabled
                  />{" "}
                  <br />
                  <input type="radio" name="condition_arrivee" value="Seul" />
                  Seul
                  <input
                    type="radio"
                    name="condition_arrivee"
                    value="En groupe avec d’autres enfants"
                  />
                  En groupe avec d’autres enfants{" "}
                  <input
                    type="radio"
                    name="condition_arrivee"
                    value="Avec ses parents"
                  />
                  Avec ses parents
                  <br />{" "}
                  <input
                    type="radio"
                    name="condition_arrivee"
                    value="En groupe avec d’autres adultes Autre"
                  />{" "}
                  En groupe avec d’autres adultes <br />
                  <h3 style={{ fontSize: "13px", fontWeight: "bolder" }}>
                    {" "}
                    Autre :{" "}
                  </h3>
                  <Input
                    name="condition_arrivee"
                    value={dataToUpdate?.condition_arrivee}
                    style={{
                      border: "none",
                      background: "lightgray",
                    }}
                    type="text"
                    placeholder="...."
                  />
                </div>{" "}
                <div onChange={handleAdulteLink}>
                  {" "}
                  <h3 style={{ fontSize: "13px", fontWeight: "bolder" }}>
                    {" "}
                    Lien avec l’adulte :
                  </h3>
                  <input
                    type="text"
                    value={dataToUpdate?.lien_avec_adulte}
                    style={{ background: "yellow" }}
                    disabled
                  />{" "}
                  <br />
                  <input
                    type="radio"
                    value="Educatif"
                    name="lien_avec_adulte"
                  />
                  Educatif
                  <input
                    type="radio"
                    value="Economique"
                    name="lien_avec_adulte"
                  />
                  Economique
                  <input
                    type="radio"
                    value="Voisinage"
                    name="lien_avec_adulte"
                  />
                  Voisinage
                  <input type="radio" value="Parenté" name="lien_avec_adulte" />
                  Parenté
                  <input
                    type="radio"
                    value="Humanitaire"
                    name="lien_avec_adulte"
                  />
                  Humanitaire
                </div>{" "}
                <div onChange={handleMotifDepart}>
                  {" "}
                  <h3 style={{ fontSize: "13px", fontWeight: "bolder" }}>
                    {" "}
                    Motif de départ de son milieu de vie habituel (CM):
                  </h3>
                  <input
                    type="text"
                    disabled
                    style={{ background: "yellow" }}
                    value={dataToUpdate?.motif_depart_milieu_vie_habituel}
                  />
                  <br />
                  Educatif{" "}
                  <input
                    type="radio"
                    value="Educatif"
                    name="motif_depart_milieu_vie_habituel"
                  />
                  Economique
                  <input
                    type="radio"
                    value="Economique"
                    name="motif_depart_milieu_vie_habituel"
                  />
                  Fugue/Fuite
                  <input
                    type="radio"
                    value="Fugue/Fuite"
                    name="motif_depart_milieu_vie_habituel"
                  />
                  Familial
                  <input
                    type="radio"
                    value="Familial"
                    name="motif_depart_milieu_vie_habituel"
                  />
                  Humanitaire
                  <br />{" "}
                  <input
                    type="radio"
                    value="Humanitaire"
                    name="motif_depart_milieu_vie_habituel"
                  />{" "}
                  Aventure
                  <h3 style={{ fontSize: "13px", fontWeight: "bolder" }}>
                    {" "}
                    Autre :{" "}
                  </h3>
                  <Input
                    name="motif_depart_milieu_vie_habituel"
                    value={dataToUpdate?.motif_depart_milieu_vie_habituel}
                    style={{
                      border: "none",
                      background: "lightgray",
                      paddingLeft: "5px",
                    }}
                    type="text"
                    placeholder="...."
                  />
                </div>{" "}
                <div onChange={handleSaisineMode}>
                  {" "}
                  <h3 style={{ fontSize: "13px", fontWeight: "bolder" }}>
                    {" "}
                    Mode de saisine du cas de l’enfant (CU):
                  </h3>
                  <input
                    type="text"
                    value={dataToUpdate?.mode_saisine_du_cas_de_lenfant}
                    style={{ background: "yellow" }}
                    disabled
                  />{" "}
                  <br />
                  <input
                    type="radio"
                    value="Auto saisine"
                    name="mode_saisine_du_cas_de_lenfant"
                  />
                  Auto saisine
                  <input
                    name="mode_saisine_du_cas_de_lenfant"
                    type="radio"
                    value="Référé(e) par les forces de l’ordre"
                  />
                  <span> Référé(e) par les forces de l’ordre</span>
                  <br />{" "}
                  <h3 style={{ fontSize: "13px", fontWeight: "bolder" }}>
                    {" "}
                    N° de la mention :{" "}
                  </h3>
                  <Input
                    style={{
                      border: "none",
                      background: "lightgray",
                      paddingLeft: " 5px",
                    }}
                    type="text"
                    placeholder="...."
                  />{" "}
                  <br />
                  <input
                    type="radio"
                    value="Référé(e) par une tierce personne"
                    name="mode_saisine_du_cas_de_lenfant"
                  />{" "}
                  Référé(e) par une tierce personne
                  <br />
                  Référé(e) par une autre organisation :{" "}
                  <Input
                    name="mode_saisine_du_cas_de_lenfant"
                    value={dataToUpdate?.mode_saisine_du_cas_de_lenfant}
                    style={{
                      border: "none",
                      background: "lightgray",
                      paddingLeft: "5px",
                    }}
                    type="text"
                    placeholder="precisez l'organisation..."
                  />
                </div>{" "}
                {/* <h3
                  style={{
                    fontSize: "13px",
                    margin: "1rem 0 0",
                    fontWeight: "bolder",
                  }}
                >
                  Etat physiologique et social de l'enfant
                </h3>
                <input
                  type="text"
                  disabled
                  style={{ background: "yellow" }}
                  value="etat_sante_physique"
                />{" "}
                <br /> */}
                <div onChange={handleEtatSante}>
                  <h3 style={{ fontSize: "13px", fontWeight: "bolder" }}>
                    {" "}
                    Etat de santé physique :{" "}
                  </h3>
                  <input
                    type="text"
                    style={{ background: "yellow" }}
                    value={dataToUpdate?.etat_sante_physique}
                    disabled
                  />{" "}
                  <br />
                  <input
                    type="radio"
                    value="bonne"
                    name="etat_sante_physique"
                  />
                  bonne <br />
                  <input
                    type="radio"
                    value="Souffrant"
                    name="etat_sante_physique"
                  />
                  Souffrant <br />
                  <h3 style={{ fontWeight: "bolder", fontSize: "13px" }}>
                    Autre :{" "}
                  </h3>
                  <Input
                    name="etat_sante_physique"
                    value={dataToUpdate?.etat_sante_physique}
                    style={{
                      border: "none",
                      background: "lightgray",
                      paddingLeft: "5px",
                    }}
                    type="text"
                    placeholder="...."
                  />
                </div>
                <br />
                {/* <div>
                  <h3 style={{ fontSize: "13px", fontWeight: "bolder" }}>
                    {" "}
                    Etat psychologique :
                  </h3>{" "}
                  <input type="text" />
                  <input type="radio" />
                  Stable
                  <input type="radio" />
                  Instable <br />
                </div> */}
                <div onChange={handleComportement}>
                  <h3 style={{ fontSize: "13px", fontWeight: "bolder" }}>
                    Comportements (CM):{" "}
                  </h3>
                  <input
                    type="text"
                    disabled
                    value={dataToUpdate?.comportements}
                    style={{ background: "yellow" }}
                  />{" "}
                  <br />
                  <input type="radio" value="violent" name="comportements" />
                  violent
                  <input type="radio" name="comportements" value="Calme" />
                  Calme
                  <input
                    type="radio"
                    name="comportements"
                    value="Caractériel"
                  />
                  Caractériel
                  <input
                    type="radio"
                    name="comportements"
                    value="Refus de soumission aux règles"
                  />
                  Refus de soumission aux règles
                </div>
                <div onChange={handleNatureRelation}>
                  <h3 style={{ fontWeight: "bolder", fontSize: "13px" }}>
                    {" "}
                    Nature de ses rapports avec les autres (CM):
                  </h3>
                  <input
                    type="text"
                    disabled
                    value={dataToUpdate?.nature_rapport}
                    style={{ background: "yellow" }}
                  />{" "}
                  <br />
                  <input
                    type="radio"
                    value="Peur/Méfiance"
                    name="nature_rapport"
                  />{" "}
                  Peur/Méfiance
                  <input
                    type="radio"
                    name="nature_rapport"
                    value="Intérêt/considération pour les autres"
                  />{" "}
                  Désintérêt{" "}
                  <input
                    type="radio"
                    value="Saines fréquentations"
                    name="nature_rapport"
                  />
                  Intérêt/considération pour les autres
                  <input
                    type="radio"
                    value="Mauvaises fréquentations"
                    name="nature_rapport"
                  />{" "}
                  Saines fréquentations
                  <input
                    type="radio"
                    name="nature_rapport"
                    value="Mauvaises fréquentations"
                  />{" "}
                  Mauvaises fréquentations
                </div>
                <div onChange={handleDependance}>
                  <h3 style={{ fontWeight: "bolder", fontSize: "13px" }}>
                    Dépendance (CM):{" "}
                  </h3>
                  <input
                    type="text"
                    value={dataToUpdate?.dependances}
                    style={{ background: "yellow" }}
                  />{" "}
                  <br />
                  <input type="checkbox" value="Alcool" name="dependances" />
                  Alcool
                  <input type="checkbox" value="Tabac" name="dependances" />
                  Tabac
                  <input type="checkbox" value="Drogues" name="dependances" />
                  Drogues
                </div>
                <div onChange={handleSituationActu}>
                  <h3
                    style={{
                      fontSize: "13px",

                      fontWeight: "bolder",
                    }}
                  >
                    Situation actuelle de l'enfant
                  </h3>
                  <input
                    type="radio"
                    value="Hébergement dans une famille d’accueil"
                    name="type_lieu_hebergement"
                  />
                  Hébergement dans un centre d’accueil
                  <input
                    type="radio"
                    name="type_lieu_hebergement"
                    value="Hébergement dans un centre d’accueil"
                  />{" "}
                  Hébergement dans une famille d’accueil
                  <input
                    type="radio"
                    value="Rue"
                    name="type_lieu_hebergement"
                  />{" "}
                  Rue
                  <br />{" "}
                  <h3 style={{ fontSize: "13px", fontWeight: "bolder" }}>
                    Autre
                  </h3>{" "}
                  <Input
                    name="type_lieu_hebergement"
                    value={dataToUpdate?.type_lieu_hebergement}
                    style={{
                      border: "none",
                      background: "lightgray",
                      paddingLeft: "5px",
                    }}
                    type="text"
                    placeholder="...."
                  />
                </div>
                <h3 style={{ fontSize: "13px", fontWeight: "bolder" }}>
                  {" "}
                  Résumé situation de l’enfant
                </h3>
                <div onChange={handleInputValue}>
                  <Textarea
                    name="resume_situation_enfant"
                    value={dataToUpdate?.resume_situation_enfant}
                    maxRows={4}
                    aria-label="maximum height"
                    placeholder="saisir ici...."
                    style={{ width: 200 }}
                  />
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row-reverse",
                marginTop: "3rem",
              }}
            >
              <div className="sbmit-btn">
                <Button
                  onClick={() => onclose()}
                  style={{ marginRight: "1rem", background: "red" }}
                  className="mb-4"
                  variant="contained"
                  color="primary"
                  type="button"
                >
                  Annuler
                </Button>
              </div>
              <div className="sbmit-btn">
                <Button
                  onClick={() => handleSubmitVerifiedData()}
                  style={{ marginRight: "1rem", background: "green" }}
                  className="mb-4"
                  variant="contained"
                  color="primary"
                  type="button"
                >
                  valider{" "}
                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default IdentificationValidationUpdate;
