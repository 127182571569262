/* eslint-disable array-callback-return */
import React, { useState, useEffect } from "react";
import { Button } from "@material-ui/core";
import api from "services/api";
import Notiflix from "notiflix";
import Select from "react-select";
import { Input } from "@chakra-ui/react";
const PriseEnChargeValidationUpdate = ({
  ficheId,
  close,
  dataToVerify,
  idRow,
}) => {
  const [urgence_, setUrgence_] = useState([]);
  const updateData = dataToVerify[idRow];
  const [dataToUpdate, setDataToUpdate] = useState();
  const [inputValue, setInputValue] = useState();
  const [selectValue, setSelectValue] = useState([]);
  const options = [
    { value: "Assistance psychosociale", label: "Assistance psychosociale" },
    { value: "Assistance judiciaire", label: "Assistance judiciaire" },
    { value: "Assistance médicale", label: "Assistance médicale" },
    { value: "Restauration", label: "Restauration" },
    { value: "Hygiène corporelle", label: "Hygiène corporelle" },
    { value: "Habillement", label: "Habillement" },
    { value: "Hébergement", label: "Hébergement" },
    { value: "Loisir", label: "Loisir" },
  ];
  const handleDel = (index) => {
    const newUrg = urgence_.slice();
    newUrg.splice(index, 1);
    setUrgence_(newUrg);
  };

  const handleSelect = (selectedOption) => {
    setSelectValue(selectedOption);
  };

  const handleInput = (event) => {
    setInputValue({
      ...inputValue,
      [event.target.name]: event.target.value,
    });
    setDataToUpdate("");
  };

  const handleSubmit = () => {
    const selectedValue = selectValue?.map((item) => {
      return item.value;
    });

    const selectedValPop = urgence_?.map((item) => {
      return item;
    });
    api
      .updatePriseEnChargeVerification({
        services_offert_enfant: `${selectedValue?.toString()} , ${
          selectedValPop === " " ? null : selectedValPop?.toString().trim()
        }`,
        inputValue,
        id: updateData?.id,
      })
      .then((response) => {
        if (response) {
          console.log(response);
          Notiflix.Notify.success("Données validées ");
          window.location.reload(true);
        }
      })
      .catch((error) => console.log(error));
  };
  useEffect(() => {
    const words = updateData.services_offert_enfant.split(",");
    setUrgence_(words);
    setDataToUpdate(updateData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <div className="checkbox-container">
        <div className="dateprise" style={{ marginBottom: "1rem" }}></div>
        <form
          className="form-container"
          style={{
            justifyContent: "space-between",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div className="form-container-block">
            <span style={{ display: "flex", marginBottom: "1rem" }}>
              <h3
                style={{ fontSize: "13px", width: "30%", fontWeight: "bolder" }}
              >
                {" "}
                Date prise en charge :
              </h3>
              <Input
                style={{ width: "50%" }}
                type="date"
                required
                onChange={handleInput}
                name="date_prise_en_charge"
                value={dataToUpdate?.date_prise_en_charge?.substring(0, 10)}
                placeholder="Autre"
              />
            </span>
            <div>
              {" "}
              <ul style={{ margin: "0 0  10px" }}>
                {urgence_?.map((item, index) => {
                  if (item !== " " || item !== null || item.length < 3) {
                    return (
                      <li key={index} hidden={item.length < 3 ? true : false}>
                        {" "}
                        <span>
                          {item}{" "}
                          <button
                            type="button"
                            onClick={() => handleDel(index)}
                            style={{
                              background: "lightgray",
                              borderRadius: "3px",
                              fontWeight: "bolder",
                              padding: "0 2px",
                            }}
                          >
                            x
                          </button>{" "}
                        </span>
                      </li>
                    );
                  }
                })}
              </ul>
              <Select
                onChange={handleSelect}
                placeholder="service offert"
                closeMenuOnSelect={false}
                isMulti
                options={options}
              />
            </div>
            <div style={{ marginTop: "10px" }}>
              <Input placeholder="Autre" style={{ background: "lightgray" }} />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row-reverse",
              marginTop: "3rem",
            }}
          >
            <div className="sbmit-btn">
              <Button
                onClick={() => close()}
                style={{ marginRight: "1rem", background: "red" }}
                className="mb-4"
                variant="contained"
                color="primary"
                type="button"
              >
                Annuler
              </Button>
            </div>
            <div className="sbmit-btn">
              <Button
                onClick={() => handleSubmit()}
                style={{ marginRight: "1rem", background: "green" }}
                className="mb-4"
                variant="contained"
                color="primary"
                type="button"
              >
                valider{" "}
              </Button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default PriseEnChargeValidationUpdate;
