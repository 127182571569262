import React, { useRef, useCallback } from "react";
import "./resumeStyle.scss";
import ReactToPrint from "react-to-print";
const ResumeRefs = ({ refsList }) => {
  const componentRef = useRef(null);
  const reactToPrintContent = useCallback(() => {
    return componentRef.current;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [componentRef.current]);
  const reactToPrintTrigger = React.useCallback(() => {
    return (
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
          margin: "50px 0 0",
          position: "relative",
          top: "50px"
        }}
      >
        {" "}
        <button
          style={{
            background: "lightgray",
            padding: "3px 5px",
            margin: "0 5px",
            borderRadius: "3px",
            color: "white",
            fontSize: "12px",
            border: "none",
          }}
        >
          Inprimer
        </button>
      </div>
    ); // eslint-disable-line max-len
  }, []);

  return (
    <>
      <ReactToPrint
        content={reactToPrintContent}
        documentTitle="AwesomeFileName"
        removeAfterPrint
        trigger={reactToPrintTrigger}
      />
      <div className="resu_container" ref={componentRef}>
        <span style={{ textTransform: "uppercase", textDecoration: "underline", fontWeight: "500" }}> Enfant :</span>
        <p style={{ color: "green", fontWeight: "500" }}> Code: {refsList?.child_code}</p>
        <p style={{ color: "green", fontWeight: "500" }}> Prénom et Nom : {refsList?.prenom} {refsList?.nom} </p>
        <p style={{ color: "green", fontWeight: "500" }}>Date de naissance : {refsList?.ddn?.substring(0, 10)}  </p>
        <p style={{ color: "green", fontWeight: "500" }}>Lieu de naissance : {refsList?.lieu_naissance}  </p>
        {refsList.referencement?.map((item, index) => {
          return (
            <div className="resu_block" key={index}>
              <span style={{ textTransform: "uppercase", textDecoration: "underline", fontWeight: "500" }}>Gestionnaire du cas :</span>
              <p style={{ color: "blue", fontWeight: "500" }}>Nom : {item?.gestionnaire_cas} </p>
              <p style={{ color: "blue", fontWeight: "500" }}>Tél : {item?.tel} </p>
              <p style={{ color: "blue", fontWeight: "500" }}>Bureau : {item?.bureau}  </p>
              <span style={{ textTransform: "uppercase", textDecoration: "underline", fontWeight: "500" }}>Informations générales :</span>

              <span>
                <h3>Référencement</h3> : {item?.referencement}
              </span>
              <span>
                <h3>Date référencement</h3> :{" "}
                {item?.date_referencement?.substring(0, 10)}
              </span>
              <span>
                <h3>Heure référencement</h3> : {item?.heure_referencement}
              </span>
              <span>
                <h3>Prénom et nom du responsable du référencement</h3> :{" "}
                {item?.nom_responsable_ref}
              </span>
              <span>
                <h3>Bureau</h3> : {item?.structure_ref}
              </span>
              <span>
                <h3>Téléphone</h3> : {item?.tel_ref}
              </span>
              <span>
                <h3>Structure / services de référencement</h3> :{" "}
                {item?.nom_service_ref}
              </span>
              <span>
                <h3>Centres de prise en charge</h3> : {item?.nom_service_ref}
              </span>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default ResumeRefs;
