import React, { useState, useEffect } from "react";

import { Button } from "@material-ui/core";
import api from "services/api";
import { Input, Textarea } from "@chakra-ui/react";
import Notiflix from "notiflix";
const UpdateProjectDeVie = ({ listProjet, idRow }) => {
  const updateData = listProjet.projet[idRow];
  const [dataToUpdate, setDataToUpdate] = useState();
  const [inputValue, setInputValue] = useState({
    id: updateData?.id,
  });

  const [checkboxValue, setCheckboxValue] = useState();
  const handleChange = (event) => {
    setInputValue({
      ...inputValue,
      [event.target.name]: event.target.value,
    });
    setDataToUpdate(false);
  };

  const handleCheckbox = (event) => {
    setCheckboxValue({
      ...checkboxValue,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    api
      .updateProjet({ inputValue, checkboxValue })
      .then((response) => {
        if (response) {
          console.log(response);
          Notiflix.Notify.success("Données Modifiées ");
          window.location.reload(true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    setDataToUpdate(updateData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="checkbox-container">
      <div className="dateprise">
        <form
          onSubmit={handleSubmit}
          className="form-container"
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            {" "}
            <div
              className="form-container-block"
              onChange={handleCheckbox}
              style={{ width: "50%" }}
            >
              <h3 style={{ fontWeight: "bold" }}>
                Projet de vie <br />{" "}
                <span style={{ background: "yellow", fontWeight: "normal" }}>
                  {dataToUpdate?.projet}{" "}
                </span>{" "}
              </h3>
              <span
                style={{
                  display: "flex",
                }}
              >
                <input
                  type="radio"
                  value="Scolarité"
                  name="projet"
                  style={{ marginTop: "5px", marginRight: "3px" }}
                />
                Scolarité
              </span>
              <span
                style={{
                  display: "flex",
                }}
              >
                <input
                  type="radio"
                  name="projet"
                  value="Professionnelle (apprentissage)"
                  style={{ marginTop: "5px", marginRight: "3px" }}
                />
                Professionnelle (apprentissage)
              </span>
              <span
                style={{
                  display: "flex",
                }}
              >
                <input
                  type="radio"
                  name="projet"
                  value="Pas encore d’activités"
                  style={{ marginTop: "5px", marginRight: "3px" }}
                />
                Pas encore d’activités
              </span>
              <span
                style={{
                  display: "flex",
                }}
              >
                <input
                  type="radio"
                  name="projet"
                  value="AGR pour l’enfant"
                  style={{ marginTop: "5px", marginRight: "3px" }}
                />
                AGR pour l’enfant
              </span>
            </div>
            <div className="form-container-block" style={{ width: "50%" }}>
              <div>
                <h3 style={{ fontSize: "13px", fontWeight: "bolder" }}>
                  Description détaillé du projet :
                </h3>
                <Textarea
                  name="descripton"
                  onChange={handleChange}
                  maxRows={4}
                  value={dataToUpdate?.descripton}
                  aria-label="maximum height"
                  placeholder="Description du projet ici..."
                  style={{
                    width: 200,
                    background: "lightgray",
                    border: "none",

                    paddingLeft: "5px",
                  }}
                />
              </div>
              <div>
                <h3 style={{ fontSize: "13px", fontWeight: "bolder" }}>
                  {" "}
                  Cout du projet :
                </h3>
                <Input
                  name="cout"
                  type="number"
                  onChange={handleChange}
                  value={dataToUpdate?.cout}
                  placeholder="..."
                  style={{
                    border: "none",
                    background: "lightgray",

                    paddingLeft: "5px",
                  }}
                />
              </div>
              <div>
                <h3 style={{ fontSize: "13px", fontWeight: "bolder" }}>
                  Ressources mobilisées ou disponible
                </h3>
                <Textarea
                  name="ressources"
                  maxRows={4}
                  value={dataToUpdate?.ressources}
                  aria-label="maximum height"
                  placeholder="precisez ici .."
                  onChange={handleChange}
                  style={{
                    width: 200,
                    background: "lightgray",
                    border: "none",
                  }}
                />
              </div>
            </div>{" "}
          </div>
          <div className="sbmit-btn" style={{ marginLeft: "12px" }}>
            <Button
              className="mb-4"
              variant="contained"
              color="primary"
              type="submit"
            >
              Modifier
            </Button>
          </div>
          *
        </form>
      </div>
    </div>
  );
};

export default UpdateProjectDeVie;
