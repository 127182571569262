import React, { useEffect, useState } from 'react';
import MUIDataTable from 'mui-datatables';
import { customAlphabet } from 'nanoid';
import './Child.scss';
import { Card, CardHeader, CardBody, Row, Col, Button } from 'reactstrap';
import PriseEnCharge from './actionSurEnfant/priseEnCharge';
import ProjetDeVie from './actionSurEnfant/projetDeVie';
import RetourEnFamille from './actionSurEnfant/retourEnFamille';
import Referencement from './actionSurEnfant/referencement';
import Suivi from './actionSurEnfant/suivi';
import Identification from './actionSurEnfant/Identification';
import { BiArrowBack } from 'react-icons/bi';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Input,
  FormControl,
  Select,
  FormLabel,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  MenuButton,
  MenuList,
  MenuItem,
  Menu,
} from '@chakra-ui/react';

import { RiAddLine } from 'react-icons/ri';
import { AiOutlineEye } from 'react-icons/ai';
import api from '../services/api';
import Notiflix from 'notiflix';
import { withRouter } from 'react-router-dom';

import Resume from './resume';

function Child(props) {
  const [childList, setChildList] = useState();
  const [fieldId, setFieldId] = useState();
  const [childId, setChildId] = useState();
  const [fieldToUpdate, setFieldToUpdate] = useState();
  const [, setOfficeData] = useState([]);
  const createModal = useDisclosure();
  const updateModal = useDisclosure();
  const resumeModal = useDisclosure();
  const [inputValues, setInputValues] = useState();
  const initialRef = React.useRef();
  const finalRef = React.useRef();
  const [displayFiche, setDisplayFiche] = useState(true);
  console.log(childList);
  const columns = [
    { label: 'N°', name: 'id' },
    { label: 'Code', name: 'child_code' },
    { label: 'Prénom', name: 'prenom' },
    { label: 'Nom', name: 'nom' },
    { label: 'Surnom', name: 'surnom' },
    {
      label: 'Sexe',
      name: 'sexe',
      options: {
        display: false,
      },
    },
    {
      name: 'ddn',
      label: 'Date de Naissance',
      options: {
        display: false,
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          const index = tableMeta.rowData.findIndex((el) => el.length === 24);
          return <span>{tableMeta.rowData[index]?.substring(0, 10)} </span>;
        },
      },
    },
    {
      name: 'age',
      label: 'Age',
    },
    {
      name: 'lieu_naissance',
      label: 'Lieu de Naissance',
    },

    {
      name: 'bureau',
      label: 'Bureau',
    },
    {
      name: 'statut',
      label: 'Statut',
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const index = tableMeta.rowData.findIndex(
            (el) => el === 'VALIDER' || el === 'EN ATTENTE'
          );
          if (tableMeta.rowData[index] === 'VALIDER') {
            return (
              <span style={{ color: 'green' }}>
                {tableMeta.rowData[index]}{' '}
              </span>
            );
          }
          if (tableMeta.rowData[index] === 'EN ATTENTE') {
            return (
              <span style={{ color: 'red' }}>{tableMeta.rowData[index]} </span>
            );
          }
        },
      },
    },

    {
      label: "Pays d'origine",
      name: 'pays_origine',
      options: { display: false },
    },
    {
      label: "Région d'origine",
      name: 'region_origine',
      options: { display: false },
    },
    {
      label: "Ville ou village d'origine",
      name: 'village_ville_origine',
      options: { display: false },
    },
    { label: 'Nom du pére', name: 'nom_pere', options: { display: false } },
    {
      label: 'Profession du pére',
      name: 'profession_pere',
      options: { display: false },
    },
    {
      label: 'Adresse du pére',
      name: 'adresse_pere',
      options: { display: false },
    },
    {
      label: 'Téléphone du pére',
      name: 'tel_pere',
      options: { display: false },
    },
    { label: 'Nom de la mére', name: 'nom_mere', options: { display: false } },
    {
      label: 'Profession de la mére',
      name: 'profession_mere',
      options: { display: false },
    },
    {
      label: 'Adresse de la mére',
      name: 'adresse_mere',
      options: { display: false },
    },
    {
      label: 'Téléphone de la mére',
      name: 'tel_mere',
      options: { display: false },
    },
    {
      label: "Rang de l'enfant",
      name: 'rang_enfant',
      options: { display: false },
    },
    {
      label: 'Bureau du gestionnaire de cas',
      name: 'bureau',
      options: { display: false },
    },
    {
      label: 'Gestionnaire de cas',
      name: 'gestionnaire_cas',
      options: { display: false },
    },
    { label: 'Téléphone', name: 'tel', options: { display: false } },
    { label: 'Email', name: 'email', options: { display: false } },
    {
      name: '',
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          const _statut = tableMeta.rowData.find(
            (el) => el === 'VALIDER' || el === 'EN ATTENTE'
          );
          const gestCas = tableMeta.rowData.find((el) => el === loggedUserName);
          const _bureau = tableMeta.rowData.find(
            (el) => el === loggedUserBureau
          );
          return (
            <>
              <button
                onClick={() => {
                  resumeModal.onOpen();
                  setChildId(tableMeta.rowData[0]);
                }}
                style={{
                  background: 'lightgray',
                  color: '#fff',
                  fontSize: '15px',
                  padding: ' 3px',
                  borderRadius: '3px',

                  position: 'relative',
                  top: '3px',
                  right: '5px',
                }}
              >
                <AiOutlineEye />
              </button>
              <button
                disabled={
                  (loggedUserRole === 'ROLE_USER' &&
                    _statut === 'EN ATTENTE' &&
                    gestCas === loggedUserName) ||
                  (loggedUserRole === 'ROLE_RESPONSABLE' &&
                    _bureau === loggedUserBureau) ||
                  loggedUserRole === 'ROLE_ADMIN'
                    ? false
                    : true
                }
                onClick={() => {
                  updateModal.onOpen();
                  getFieldToEditId(tableMeta.rowData[0]);
                }}
                style={{
                  background: 'orange',
                  color: '#fff',
                  fontSize: '11px',
                  padding: '3px',
                  borderRadius: '3px',
                }}
              >
                Editer
              </button>
              <button
                disabled={true ? loggedUserRole !== 'ROLE_ADMIN' : false}
                onClick={() => {
                  confirmDelete(tableMeta.rowData[0]);
                }}
                style={{
                  background: 'red',
                  color: '#fff',
                  fontSize: '11px',
                  padding: ' 3px',
                  borderRadius: '3px',
                  marginLeft: '5px',
                }}
              >
                Supprimer
              </button>
              <Menu isLazy>
                <MenuButton
                  onClick={() => setChildId(tableMeta.rowData[0])}
                  style={{
                    background: 'green',
                    color: '#fff',
                    fontSize: '11px',
                    padding: ' 3px',
                    borderRadius: '3px',
                    marginLeft: '5px',
                    border: 'none',
                  }}
                >
                  Fiche
                </MenuButton>
                <MenuList>
                  {/* MenuItems are not rendered unless Menu is open */}
                  <MenuItem
                    onClick={() => {
                      props.history.push(
                        `/admin/enfant/${childId}/identification`
                      );
                    }}
                  >
                    Identification
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      props.history.push(
                        `/admin/enfant/${childId}/prise-en-charge`
                      );
                      setChildId(tableMeta.rowData[0]);
                    }}
                  >
                    Prise en charge
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      props.history.push(
                        `/admin/enfant/${childId}/retour-en-famille`
                      );
                    }}
                  >
                    Retour en famille
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      props.history.push(
                        `/admin/enfant/${childId}/referencement`
                      );
                    }}
                  >
                    Referencement
                  </MenuItem>
                  <MenuItem
                    onClick={() =>
                      props.history.push(
                        `/admin/enfant/${childId}/projet-de-vie`
                      )
                    }
                  >
                    Projet de vie
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      props.history.push(`/admin/enfant/${childId}/suivi`);
                    }}
                  >
                    Suivi
                  </MenuItem>
                </MenuList>
              </Menu>
            </>
          );
        },
      },
    },
  ];
  const options = {
    filterType: 'textField',
    pagination: false,
    print: false,
    download: false,
    enableNestedDataAccess: '.',
    filterOptions: {
      useDisplayedColumnsOnly: true,
      useDisplayedRowsOnly: true,
    },
  };
  const loggedUserRole = JSON.parse(localStorage.getItem('data'))?.roles[0];
  const loggedUserBureau = JSON.parse(localStorage.getItem('data'))?.bureau;
  const loggedUserName = JSON.parse(localStorage.getItem('data'))?.username;

  //recuperer l'age
  const inputAge = inputValues?.ddn?.substring(0, 4);
  const currentYear = new Date().getFullYear();
  const theAge = currentYear - parseInt(inputAge);

  //gerer genearation code enfant
  const nanoid = customAlphabet('1234567890abcdef', 10);

  const enda_cities = [
    'Dakar',
    'Kolda',
    'Sedhiou',
    'Velingara',
    'Tambacounda',
    'Saraya',
    'Ziguinchor',
    'Thies',
    'Kaolack',
    'Diourbel',
    'Louga',
  ];

  //form submit handler
  const handleSubmit = (event) => {
    event.preventDefault();

    let child_code = '';
    for (let i = 0; i < enda_cities.length; i++) {
      const result = loggedUserBureau.indexOf(enda_cities[i]);
      if (~result) {
        if (enda_cities[i] === 'Dakar') {
          child_code = `DK`;
        }
        if (enda_cities[i] === 'Kolda') {
          child_code = `KD`;
        }
        if (enda_cities[i] === 'Sedhiou') {
          child_code = `SD`;
        }
        if (enda_cities[i] === 'Velingara') {
          child_code = `VL`;
        }
        if (enda_cities[i] === 'Tambacounda') {
          child_code = `TB`;
        }
        if (enda_cities[i] === 'Kaolack') {
          child_code = `KB`;
        }
        if (enda_cities[i] === 'Diourbel') {
          child_code = `DL`;
        }
        if (enda_cities[i] === 'Louga') {
          child_code = `LG`;
        }
        if (enda_cities[i] === 'Thies') {
          child_code = `TH`;
        }
        if (enda_cities[i] === 'Saraya') {
          child_code = `SR`;
        }
        if (enda_cities[i] === 'Ziguinchor') {
          child_code = `ZG00`;
        }
      }
    }

    const loggedUserData = JSON.parse(localStorage.getItem('data'));
    const _child_code = `${child_code}${nanoid(4)}`;
    api
      .createChild({ inputValues, loggedUserData, theAge, _child_code })
      .then((response) => {
        if (response) {
          Notiflix.Notify.success('Enfant crée avec succès ');
          setTimeout(() => {
            window.location.reload(true);
          }, 1000);
        }
      })
      .catch((error) => {
        if (error) {
          Notiflix.Notify.warning('Veuillez verifier les données saisies !');
        }
      });
  };

  // form update handler
  const handleSubmitUpdate = () => {
    api
      .updateChild({ inputValues, fieldId })
      .then((response) => {
        if (response) {
          Notiflix.Notify.success('Données modifiées ');
          setTimeout(() => {
            window.location.reload(true);
          }, 1000);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleDeleteChild = (id) => {
    api
      .deleteChild(id)
      .then((response) => {
        if (response) {
          Notiflix.Notify.success('Enfant supprimé  avec succès ');
          setTimeout(() => {
            window.location.reload(true);
          }, 1000);
        }
      })
      .catch((error) => console.log(error));
  };

  const confirmDelete = (id) => {
    Notiflix.Confirm.show(
      ' ',
      'Etes-vous sûr de vouloir supprimer ?',
      'Confirmer',
      'Annuler',
      () => {
        handleDeleteChild(id);
      }
    );
  };

  const handleChange = (event) => {
    setInputValues({
      ...inputValues,
      [event.target.name]: event.target.value,
    });
    setFieldToUpdate('');
  };

  const getFieldToEditId = (id) => {
    setFieldId(id);
    api
      .getChildById(id)
      .then((response) => {
        if (response) {
          setFieldToUpdate(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    api
      .getAllFiltredChildData(loggedUserBureau)
      .then((response) => {
        if (response) {
          setOfficeData(response?.data);
        }
      })
      .catch((error) => console.log(error));
    api
      .getAllChildren()
      .then((response) => {
        if (response) {
          setChildList(response.data);
        }
      })
      .catch((error) => console.log(error));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDisplay = () => {
    setDisplayFiche(!displayFiche);
  };
  const loggedUserData = JSON.parse(localStorage.getItem('data'));
  return (
    <>
      <div className="content" hidden={displayFiche}>
        <div
          style={{ cursor: 'pointer', marginBottom: '2rem' }}
          onClick={() => {
            setChildId(childId);
            handleDisplay();
          }}
        >
          {' '}
          <BiArrowBack size={25} />{' '}
        </div>
        <Tabs>
          <TabList>
            <Tab>Identification</Tab>
            <Tab>Prise en charge d'urgence</Tab>
            <Tab>Retour en famille</Tab>
            <Tab>Référencement</Tab>
            <Tab>Projet de vie</Tab>
            <Tab>Suivi</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <Identification
                childId={childId}
                loggedUserData={loggedUserData}
              />
            </TabPanel>
            <TabPanel>
              <PriseEnCharge
                childId={childId}
                loggedUserData={loggedUserData}
              />
            </TabPanel>

            <TabPanel>
              <RetourEnFamille
                childId={childId}
                loggedUserData={loggedUserData}
              />
            </TabPanel>
            <TabPanel>
              <Referencement
                childId={childId}
                loggedUserData={loggedUserData}
              />
            </TabPanel>
            <TabPanel>
              <ProjetDeVie childId={childId} loggedUserData={loggedUserData} />
            </TabPanel>
            <TabPanel>
              <Suivi childId={childId} loggedUserData={loggedUserData} />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </div>
      <div className="content" hidden={!displayFiche}>
        <Modal
          onClose={resumeModal.onClose}
          size={'xl'}
          isOpen={resumeModal.isOpen}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalCloseButton />
            <ModalBody>
              <div>
                {' '}
                <Resume childId={childId} />{' '}
              </div>
            </ModalBody>
          </ModalContent>
        </Modal>
        <Modal
          initialFocusRef={initialRef}
          finalFocusRef={finalRef}
          isOpen={createModal?.isOpen}
          onClose={createModal?.onClose}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalCloseButton />
            <form onSubmit={handleSubmit}>
              <ModalBody mt={10}>
                <div style={{ display: 'flex' }} className="form-divider">
                  <div style={{ width: '48%' }}>
                    <FormControl>
                      <Input
                        type="text"
                        name="prenom"
                        required
                        placeholder="prenom"
                        onChange={handleChange}
                      />
                    </FormControl>
                  </div>

                  <div style={{ width: '48%', marginLeft: '2%' }}>
                    {' '}
                    <FormControl>
                      <Input
                        name="nom"
                        required
                        type="text"
                        ref={initialRef}
                        placeholder="nom"
                        onChange={handleChange}
                      />
                    </FormControl>
                  </div>
                </div>
                <div
                  style={{ display: 'flex', marginTop: '1rem' }}
                  className="form-divider"
                >
                  <div style={{ width: '48%' }}>
                    <FormControl>
                      <Input
                        name="surnom"
                        type="text"
                        placeholder="surnom"
                        onChange={handleChange}
                      />
                    </FormControl>
                  </div>

                  <div style={{ width: '48%', marginLeft: '2%' }}>
                    {' '}
                    <FormControl>
                      <Select
                        type="text"
                        name="sexe"
                        required
                        placeholder="Sexe"
                        onChange={handleChange}
                      >
                        <option value="masculin">masculin</option>
                        <option value="feminin">feminin</option>
                      </Select>
                    </FormControl>
                  </div>
                </div>
                <div style={{ display: 'flex' }} className="form-divider">
                  <div style={{ width: '48%' }}>
                    <FormControl>
                      <FormLabel>
                        <span
                          style={{
                            fontSize: '12px',
                            color: 'lightgray',
                            marginLeft: '5px',
                          }}
                        >
                          date de naissance
                        </span>{' '}
                      </FormLabel>
                      <Input type="date" name="ddn" onChange={handleChange} />
                    </FormControl>
                  </div>

                  <div style={{ width: '48%', marginLeft: '2%' }}>
                    {' '}
                    <FormControl>
                      <FormLabel>
                        <span
                          style={{
                            fontSize: '12px',
                            color: 'lightgray',
                            marginLeft: '5px',
                          }}
                        ></span>{' '}
                      </FormLabel>
                      <Input
                        type="text"
                        name="age"
                        value={theAge ? theAge : ''}
                        disabled
                        placeholder="age"
                        onChange={handleChange}
                      />
                    </FormControl>
                  </div>
                </div>
                <div
                  style={{ display: 'flex', marginTop: '1rem' }}
                  className="form-divider"
                >
                  <div style={{ width: '48%' }}>
                    <FormControl>
                      <Input
                        type="text"
                        name="lieu_naissance"
                        required
                        placeholder="lieu de naissance"
                        onChange={handleChange}
                      />
                    </FormControl>
                  </div>

                  <div style={{ width: '48%', marginLeft: '2%' }}>
                    {' '}
                    <FormControl>
                      <Input
                        type="text"
                        name="pays_origine"
                        placeholder="pays d'origine"
                        onChange={handleChange}
                      />
                    </FormControl>
                  </div>
                </div>
                <div
                  style={{ display: 'flex', marginTop: '1rem' }}
                  className="form-divider"
                >
                  <div style={{ width: '48%' }}>
                    <FormControl>
                      <Input
                        type="text"
                        name="region_origine"
                        placeholder="region d'origine"
                        onChange={handleChange}
                      />
                    </FormControl>
                  </div>

                  <div style={{ width: '48%', marginLeft: '2%' }}>
                    {' '}
                    <FormControl>
                      <Input
                        type="text"
                        name="village_ville_origine"
                        placeholder="village ou ville d'origine"
                        onChange={handleChange}
                      />
                    </FormControl>
                  </div>
                </div>
                <div
                  style={{ display: 'flex', marginTop: '1rem' }}
                  className="form-divider"
                >
                  <div style={{ width: '48%' }}>
                    <FormControl>
                      <Input
                        type="text"
                        name="nom_pere"
                        placeholder="nom du pére"
                        onChange={handleChange}
                      />
                    </FormControl>
                  </div>

                  <div style={{ width: '48%', marginLeft: '2%' }}>
                    {' '}
                    <FormControl>
                      <Input
                        type="text"
                        name="profession_pere"
                        placeholder="profession du pére"
                        onChange={handleChange}
                      />
                    </FormControl>
                  </div>
                </div>
                <div
                  style={{ display: 'flex', marginTop: '1rem' }}
                  className="form-divider"
                >
                  <div style={{ width: '48%' }}>
                    <FormControl>
                      <Input
                        type="tel"
                        name="tel_pere"
                        placeholder="téléphone du pére"
                        onChange={handleChange}
                      />
                    </FormControl>
                  </div>

                  <div style={{ width: '48%', marginLeft: '2%' }}>
                    {' '}
                    <FormControl>
                      <Input
                        type="text"
                        name="adresse_pere"
                        placeholder="adresse du pére"
                        onChange={handleChange}
                      />
                    </FormControl>
                  </div>
                </div>
                <div
                  style={{ display: 'flex', marginTop: '1rem' }}
                  className="form-divider"
                >
                  <div style={{ width: '48%' }}>
                    <FormControl>
                      <Input
                        type="text"
                        name="nom_mere"
                        placeholder="nom de la mére"
                        onChange={handleChange}
                      />
                    </FormControl>
                  </div>

                  <div style={{ width: '48%', marginLeft: '2%' }}>
                    {' '}
                    <FormControl>
                      <Input
                        type="text"
                        name="profession_mere"
                        placeholder="profession de la mére"
                        onChange={handleChange}
                      />
                    </FormControl>
                  </div>
                </div>
                <div
                  style={{ display: 'flex', marginTop: '1rem' }}
                  className="form-divider"
                >
                  <div style={{ width: '48%' }}>
                    <FormControl>
                      <Input
                        type="tel"
                        name="tel_mere"
                        placeholder="téléphone de la mére"
                        onChange={handleChange}
                      />
                    </FormControl>
                  </div>

                  <div style={{ width: '48%', marginLeft: '2%' }}>
                    {' '}
                    <FormControl>
                      <Input
                        type="text"
                        name="adresse_mere"
                        placeholder="adresse de la mére"
                        onChange={handleChange}
                      />
                    </FormControl>
                  </div>
                </div>

                <FormControl mt={4}>
                  <Input
                    type="text"
                    name="rang_enfant"
                    placeholder="rang de l'enfant"
                    onChange={handleChange}
                  />
                </FormControl>
                {/* <FormControl mt={3}>
                  <FormLabel>
                    <span
                      style={{
                        marginLeft: "5px",
                        textDecoration: "green wavy underline",
                        cursor: "pointer",
                      }}
                    >
                      {" "}
                      Cliquer pour inserer une image
                    </span>
                  </FormLabel>
                  <Input
                    type="filen"
                    style={{ visibility: "hidden" }}
                    onChange={handleChange}
                  />
                </FormControl> */}
              </ModalBody>

              <ModalFooter>
                <Button type="submit" style={{ background: '#16b84e' }} mr={3}>
                  Ajouter
                </Button>
                <Button
                  type="button"
                  onClick={createModal?.onClose}
                  style={{ background: 'red' }}
                >
                  Annuler
                </Button>
              </ModalFooter>
            </form>
          </ModalContent>
        </Modal>
        {/* ---------------------------- */}
        {/* update child modal */}
        <Modal
          initialFocusRef={initialRef}
          finalFocusRef={finalRef}
          isOpen={updateModal?.isOpen}
          onClose={updateModal?.onClose}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalCloseButton />
            <form onSubmit={handleSubmit}>
              <ModalBody mt={10}>
                <div style={{ display: 'flex' }} className="form-divider">
                  <div style={{ width: '48%' }}>
                    <FormControl>
                      <Input
                        name="nom"
                        required
                        type="text"
                        value={fieldToUpdate?.nom}
                        ref={initialRef}
                        placeholder="nom"
                        onChange={handleChange}
                      />
                    </FormControl>
                  </div>

                  <div style={{ width: '48%', marginLeft: '2%' }}>
                    {' '}
                    <FormControl>
                      <Input
                        type="text"
                        name="prenom"
                        value={fieldToUpdate?.prenom}
                        required
                        placeholder="prenom"
                        onChange={handleChange}
                      />
                    </FormControl>
                  </div>
                </div>
                <div
                  style={{ display: 'flex', marginTop: '1rem' }}
                  className="form-divider"
                >
                  <div style={{ width: '48%' }}>
                    <FormControl>
                      <Input
                        name="surnom"
                        value={fieldToUpdate?.surnom}
                        type="text"
                        placeholder="surnom"
                        onChange={handleChange}
                      />
                    </FormControl>
                  </div>

                  <div style={{ width: '48%', marginLeft: '2%' }}>
                    {' '}
                    <FormControl>
                      <Select
                        type="text"
                        value={fieldToUpdate?.sexe}
                        name="sexe"
                        required
                        placeholder="genre"
                        onChange={handleChange}
                      >
                        <option value="masculin">masculin</option>
                        <option value="feminin">feminin</option>
                      </Select>
                    </FormControl>
                  </div>
                </div>
                <div style={{ display: 'flex' }} className="form-divider">
                  <div style={{ width: '48%' }}>
                    <FormControl>
                      <FormLabel>
                        <span
                          style={{
                            fontSize: '12px',
                            color: 'lightgray',
                            marginLeft: '5px',
                          }}
                        >
                          date de naissance
                        </span>{' '}
                      </FormLabel>
                      <Input
                        value={fieldToUpdate?.ddn?.substring(0, 10)}
                        type="date"
                        name="ddn"
                        onChange={handleChange}
                      />
                    </FormControl>
                  </div>

                  <div style={{ width: '48%', marginLeft: '2%' }}>
                    {' '}
                    <FormControl>
                      <FormLabel>
                        <span
                          style={{
                            fontSize: '12px',
                            color: 'lightgray',
                            marginLeft: '5px',
                          }}
                        ></span>{' '}
                      </FormLabel>
                      <Input
                        type="text"
                        name="age"
                        value={fieldToUpdate?.age}
                        placeholder="age"
                        onChange={handleChange}
                      />
                    </FormControl>
                  </div>
                </div>
                <div
                  style={{ display: 'flex', marginTop: '1rem' }}
                  className="form-divider"
                >
                  <div style={{ width: '48%' }}>
                    <FormControl>
                      <Input
                        type="text"
                        name="lieu_naissance"
                        required
                        placeholder="lieu de naissance"
                        value={fieldToUpdate?.lieu_naissance}
                        onChange={handleChange}
                      />
                    </FormControl>
                  </div>

                  <div style={{ width: '48%', marginLeft: '2%' }}>
                    {' '}
                    <FormControl>
                      <Input
                        type="text"
                        name="pays_origine"
                        value={fieldToUpdate?.pays_origine}
                        placeholder="pays d'origine"
                        onChange={handleChange}
                      />
                    </FormControl>
                  </div>
                </div>
                <div
                  style={{ display: 'flex', marginTop: '1rem' }}
                  className="form-divider"
                >
                  <div style={{ width: '48%' }}>
                    <FormControl>
                      <Input
                        type="text"
                        name="region_origine"
                        value={fieldToUpdate?.region_origine}
                        placeholder="region d'origine"
                        onChange={handleChange}
                      />
                    </FormControl>
                  </div>

                  <div style={{ width: '48%', marginLeft: '2%' }}>
                    {' '}
                    <FormControl>
                      <Input
                        type="text"
                        value={fieldToUpdate?.village_ville_origine}
                        name="village_ville_origine"
                        placeholder="village ou ville d'origine"
                        onChange={handleChange}
                      />
                    </FormControl>
                  </div>
                </div>
                <div
                  style={{ display: 'flex', marginTop: '1rem' }}
                  className="form-divider"
                >
                  <div style={{ width: '48%' }}>
                    <FormControl>
                      <Input
                        type="text"
                        name="nom_pere"
                        value={fieldToUpdate?.nom_pere}
                        placeholder="nom du pére"
                        onChange={handleChange}
                      />
                    </FormControl>
                  </div>

                  <div style={{ width: '48%', marginLeft: '2%' }}>
                    {' '}
                    <FormControl>
                      <Input
                        type="text"
                        name="profession_pere"
                        value={fieldToUpdate?.profession_pere}
                        placeholder="profession du pére"
                        onChange={handleChange}
                      />
                    </FormControl>
                  </div>
                </div>
                <div
                  style={{ display: 'flex', marginTop: '1rem' }}
                  className="form-divider"
                >
                  <div style={{ width: '48%' }}>
                    <FormControl>
                      <Input
                        type="tel"
                        name="tel_pere"
                        value={fieldToUpdate?.tel_pere}
                        placeholder="téléphone du pére"
                        onChange={handleChange}
                      />
                    </FormControl>
                  </div>

                  <div style={{ width: '48%', marginLeft: '2%' }}>
                    {' '}
                    <FormControl>
                      <Input
                        type="text"
                        name="adresse_pere"
                        value={fieldToUpdate?.adresse_pere}
                        placeholder="adresse du pére"
                        onChange={handleChange}
                      />
                    </FormControl>
                  </div>
                </div>
                <div
                  style={{ display: 'flex', marginTop: '1rem' }}
                  className="form-divider"
                >
                  <div style={{ width: '48%' }}>
                    <FormControl>
                      <Input
                        type="text"
                        name="nom_mere"
                        value={fieldToUpdate?.nom_mere}
                        placeholder="nom de la mére"
                        onChange={handleChange}
                      />
                    </FormControl>
                  </div>

                  <div style={{ width: '48%', marginLeft: '2%' }}>
                    {' '}
                    <FormControl>
                      <Input
                        type="text"
                        name="profession_mere"
                        value={fieldToUpdate?.profession_mere}
                        placeholder="profession de la mére"
                        onChange={handleChange}
                      />
                    </FormControl>
                  </div>
                </div>
                <div
                  style={{ display: 'flex', marginTop: '1rem' }}
                  className="form-divider"
                >
                  <div style={{ width: '48%' }}>
                    <FormControl>
                      <Input
                        type="tel"
                        name="tel_mere"
                        value={fieldToUpdate?.tel_mere}
                        placeholder="téléphone de la mére"
                        onChange={handleChange}
                      />
                    </FormControl>
                  </div>

                  <div style={{ width: '48%', marginLeft: '2%' }}>
                    {' '}
                    <FormControl>
                      <Input
                        type="text"
                        name="adresse_mere"
                        value={fieldToUpdate?.adresse_mere}
                        placeholder="adresse de la mére"
                        onChange={handleChange}
                      />
                    </FormControl>
                  </div>
                </div>

                <FormControl mt={4}>
                  <Input
                    type="text"
                    name="rang_enfant"
                    value={fieldToUpdate?.rang_enfant}
                    placeholder="rang de l'enfant"
                    onChange={handleChange}
                  />
                </FormControl>
                {/* <FormControl mt={4}>
                  <FormLabel>
                    <span
                      style={{
                        marginLeft: "5px",
                        textDecoration: "green wavy underline",
                        cursor: "pointer",
                      }}
                    >
                      {" "}
                      Cliquer pour modifier l'image
                    </span>
                  </FormLabel>
                  <Input
                    type="file"
                    style={{ visibility: "hidden" }}
                    name="rang_enfant"
                    onChange={handleChange}
                  />
                </FormControl> */}
              </ModalBody>

              <ModalFooter>
                <Button
                  onClick={() => handleSubmitUpdate()}
                  type="button"
                  style={{ background: '#16b84e' }}
                  mr={3}
                >
                  Modifier
                </Button>
                <Button
                  type="button"
                  onClick={updateModal?.onClose}
                  style={{ background: 'red   ' }}
                >
                  Annuler
                </Button>
              </ModalFooter>
            </form>
          </ModalContent>
        </Modal>
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                {/* <h5 className="title">Listes des enfants</h5> */}

                <Button
                  onClick={createModal?.onOpen}
                  style={{
                    float: 'right',
                    background: '#16b84e',
                    display: 'flex',
                    textTransform: ' uppercase',
                  }}
                >
                  <RiAddLine
                    style={{
                      position: 'relative',
                      top: '4px',
                      marginRight: '4px',
                    }}
                  />{' '}
                  <span> nouvel enfant</span>
                </Button>
              </CardHeader>
              <CardBody className="all-icons">
                <MUIDataTable
                  data={childList}
                  columns={columns}
                  options={options}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default withRouter(Child);
