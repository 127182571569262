import React, { useState, useEffect } from "react";
import "./actionSurEnfant.scss";
import MUIDataTable from "mui-datatables";
import Notiflix from "notiflix";
import { Button } from "reactstrap";
import { Input, Textarea } from "@chakra-ui/react";
import api from "../../services/api";
import { AiOutlineEye } from "react-icons/ai";
import { BiArrowBack } from "react-icons/bi";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from "@chakra-ui/react";
import ResumeSuivi from "./resumeFiche/ResumeSuivi";
import UpdateSuivi from "./updateFiche/UpdateSuivi";
import { withRouter } from "react-router-dom";
const Suivi = (props) => {
  const loggedUserData = JSON.parse(localStorage.getItem("data"));
  const childId = parseInt(props.match.params.id)
  const openResume = useDisclosure();
  const openUpdate = useDisclosure();
  const [idRow, setIdRow] = useState();
  const [hiddenDiv, setHiddenDiv] = useState(true);
  const [inputValue, setInputValue] = useState();
  const [checkboxValue, setCheckboxValue] = useState();
  const [listSuivi, setListSuivi] = useState();
  const handleAddForm = () => {
    setHiddenDiv(!hiddenDiv);
  };
  //mui dataTables
  const columns = [
    {
      name: "id",
      label: "Code",
    },
    {
      label: "Situation actuelle de l'enfant",
      name: "situation_actuelle_enfant",
      options: { display: false },
    },
    {
      label: "Nature des relations avec les parent",
      name: "nature_relation_parent",
      options: { display: false },
    },
    {
      label: "Nature des relations avec la fratrie",
      name: "nature_relation_fratrie",
      options: { display: false },
    },
    {
      label: "Nature des relation avec le voisinage",
      name: "nature_relation_voisinage",
      options: { display: false },
    },
    {
      label: "Etat de sante l'enfant",
      name: "etat_sante_enfant",
      options: { display: false },
    },
    {
      label: "Date_suivi",
      name: "Date_suivi",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          const index = tableMeta.rowData.findIndex((el) => el?.length === 24);
          return <span>{tableMeta.rowData[index]?.substring(0, 10)} </span>;
        },
      },
    },
    {
      label: "Evolution du  projet de vie",
      name: "Evolution_Projet_vie",
    },
    {
      label: "Evolution du Projet AGR",
      name: "Evolution_Projet_vie_AGR",
      options: { display: false },
    },
    {
      label: "Decision prise apres evaluation de l'enfant",
      name: "decision_apres_evaluation_enfant",
      options: { display: false },
    },
    {
      label: "Date prise de décision",
      name: "date_prise_decision",
      options: {
        display: false,
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          const index = tableMeta.rowData.findIndex((el) => el?.length === 24);
          return <span>{tableMeta.rowData[index]?.substring(0, 10)} </span>;
        },
      },
    },
    {
      label: "Motif justificatif",
      name: "motif_justificatif",
      options: { display: false },
    },
    { label: "Gestionnaire de cas", name: "gestionnaire_cas" },
    {
      name: "bureau",
      label: "Bureau",
    },
    {
      name: "statut",
      label: "Statut",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const index = tableMeta.rowData.findIndex(
            (el) => el === "VALIDER" || el === "EN ATTENTE"
          );
          if (tableMeta.rowData[index] === "VALIDER") {
            return (
              <span style={{ color: "green" }}>
                {tableMeta.rowData[index]}{" "}
              </span>
            );
          }
          if (tableMeta.rowData[index] === "EN ATTENTE") {
            return (
              <span style={{ color: "red" }}>{tableMeta.rowData[index]} </span>
            );
          }
        },
      },
    },
    {
      name: "Action",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          const _statut = tableMeta.rowData.find(
            (el) => el === "VALIDER" || el === "EN ATTENTE"
          );
          const gestCas = tableMeta.rowData.find((el) => el === loggedUserName);
          const _bureau = tableMeta.rowData.find(
            (el) => el === loggedUserBureau
          );
          return (
            <>
              <button
                onClick={() => {
                  openResume.onOpen();
                  setIdRow(tableMeta.rowIndex);
                }}
                style={{
                  background: "lightgray",
                  color: "#fff",
                  fontSize: "15px",
                  padding: " 3px",
                  borderRadius: "3px",

                  position: "relative",
                  top: "3px",
                  right: "5px",
                }}
              >
                <AiOutlineEye />
              </button>
              <button
                disabled={
                  (loggedUserRole === "ROLE_USER" &&
                    _statut === "EN ATTENTE" &&
                    gestCas === loggedUserName) ||
                    (loggedUserRole === "ROLE_RESPONSABLE" &&
                      _bureau === loggedUserBureau) ||
                    loggedUserRole === "ROLE_ADMIN"
                    ? false
                    : true
                }
                onClick={() => {
                  openUpdate.onOpen();
                  setIdRow(tableMeta.rowIndex);
                }}
                style={{
                  background: "orange",
                  color: "#fff",
                  fontSize: "11px",
                  padding: "3px",
                  borderRadius: "3px",
                }}
              >
                Editer
              </button>
              <button
                disabled={true ? loggedUserRole !== "ROLE_ADMIN" : false}
                onClick={() => confirmDelete(tableMeta.rowData[0])}
                style={{
                  background: "red",
                  color: "#fff",
                  fontSize: "11px",
                  padding: " 3px",
                  borderRadius: "3px",
                  marginLeft: "5px",
                }}
              >
                Supprimer
              </button>
            </>
          );
        },
      },
    },
  ];
  const options = {
    filterType: "textField",
    pagination: false,
    print: false,
    download: false,
    enableNestedDataAccess: ".",
    filterOptions: {
      useDisplayedColumnsOnly: true,
      useDisplayedRowsOnly: true,
    },
  };

  const loggedUserRole = JSON.parse(localStorage.getItem("data"))?.roles[0];
  const loggedUserBureau = JSON.parse(localStorage.getItem("data"))?.bureau;
  const loggedUserName = JSON.parse(localStorage.getItem("data"))?.username;

  const handleChange = (event) => {
    setInputValue({
      ...inputValue,
      [event.target.name]: event.target.value,
    });
  };
  const handleCheckbox = (event) => {
    setCheckboxValue({
      ...checkboxValue,
      [event.target.name]: event.target.value,
    });
  };

  const confirmDelete = (id) => {
    Notiflix.Confirm.show(
      " ",
      "Etes-vous sûr de vouloir supprimer ?",
      "Confirmer",
      "Annuler",
      () => {
        api
          .deleteSuivi(id)
          .then((response) => {
            Notiflix.Notify.success("supprimé !!");
            window.location.reload(true);
          })
          .catch((error) => console.log(error));
      }
    );
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    api
      .createSuivi({
        loggedUserData,
        inputValue,
        childId,
        checkboxValue,
      })
      .then((response) => {
        if (response) {
          Notiflix.Notify.success("Suivi créé avec succès ");
          window.location.reload(true);
        }
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    api
      .getAllSuivis(childId)
      .then((response) => {
        if (response) {
          setListSuivi(response.data);
        }
      })
      .catch((error) => console.log(error));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [childId]);

  return (
    <div className="content fiche-styles">

      <div className="m-sm-30">
        <Modal
          onClose={openResume.onClose}
          size={"xl"}
          isOpen={openResume.isOpen}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalCloseButton />
            <ModalBody>
              <ResumeSuivi listSuivi={listSuivi} idRow={idRow} />
            </ModalBody>
          </ModalContent>
        </Modal>
        <Modal
          onClose={openUpdate.onClose}
          size={"full"}
          isOpen={openUpdate.isOpen}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalCloseButton />
            <ModalBody>
              <UpdateSuivi listSuivi={listSuivi} idRow={idRow} />
            </ModalBody>
          </ModalContent>
        </Modal>
        <div className="checkbox-container" hidden={hiddenDiv}>
          <div className="dateprise" style={{ marginBottom: "2rem" }}>
            <div className="info-real">
              <ul>
                <li>Gestionnaire cas : {loggedUserData?.username}</li>
                <li>Bureau : {loggedUserData?.bureau} </li>
                <li>Tél : {loggedUserData?.tel} </li>
                <li>Email : {loggedUserData?.email} </li>
              </ul>
            </div>{" "}
            <form
              onSubmit={handleSubmit}
              className="form-container"
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div className="form-container-block">
                <div>
                  <div>
                    {" "}
                    <h3 style={{ fontSize: "13px", fontWeight: "bolder" }}>
                      Date Suivi :{" "}
                    </h3>
                    <Input
                      name="Date_suivi"
                      onChange={handleChange}
                      type="date"
                      required
                      style={{
                        border: "none",
                        background: "lightgray",
                      }}
                    />
                  </div>
                  <h3
                    style={{
                      fontSize: "13px",

                      fontWeight: "bold",
                    }}
                  >
                    Situation actuelle de l’enfant :
                  </h3>
                  <span>
                    {" "}
                    <input
                      value="En famille"
                      onChange={handleCheckbox}
                      name="situation_actuelle_enfant"
                      type="radio"
                      style={{ marginTop: "5px", marginRight: "3px" }}
                    />
                    En famille
                  </span>
                  <span>
                    {" "}
                    <input
                      value="A l’école"
                      onChange={handleCheckbox}
                      name="situation_actuelle_enfant"
                      type="radio"
                      style={{ marginTop: "5px", marginRight: "3px" }}
                    />
                    A l’école
                  </span>
                  <span>
                    {" "}
                    <input
                      name="situation_actuelle_enfant"
                      onChange={handleCheckbox}
                      value="Métier"
                      type="radio"
                      style={{ marginTop: "5px", marginRight: "3px" }}
                    />
                    Métier
                  </span>
                  <span>
                    {" "}
                    <input
                      value="Fugue"
                      onChange={handleCheckbox}
                      name="situation_actuelle_enfant"
                      type="radio"
                      style={{ marginTop: "5px", marginRight: "3px" }}
                    />{" "}
                    Fugue
                  </span>
                  <span>
                    {" "}
                    <input
                      value="retour dans rue"
                      name="situation_actuelle_enfant"
                      onChange={handleCheckbox}
                      type="radio"
                      style={{ marginTop: "5px", marginRight: "3px" }}
                    />{" "}
                    retour dans rue
                  </span>
                  <span>
                    {" "}
                    <input
                      value="destination inconnue"
                      onChange={handleCheckbox}
                      name="situation_actuelle_enfant"
                      type="radio"
                      style={{ marginTop: "5px", marginRight: "3px" }}
                    />
                    destination inconnue
                  </span>{" "}
                  <br />
                  <span>
                    {" "}
                    <input
                      value="retour dans sa situation de départ"
                      type="radio"
                      onChange={handleCheckbox}
                      name="situation_actuelle_enfant"
                      style={{ marginTop: "5px", marginRight: "3px" }}
                    />
                    retour dans sa situation de départ
                  </span>
                </div>
                <span></span>
                <div>
                  {" "}
                  <h3 style={{ fontSize: "13px", fontWeight: "bold" }}>
                    {" "}
                    Nature des relations avec les parents :{" "}
                  </h3>
                  <span>
                    {" "}
                    <input
                      type="radio"
                      value="Bonne"
                      onChange={handleCheckbox}
                      name="nature_relation_parent"
                      style={{ marginTop: "5px", marginRight: "3px" }}
                    />
                    Bonne{" "}
                  </span>
                  <span>
                    {" "}
                    <input
                      type="radio"
                      value="conflictuelle"
                      onChange={handleCheckbox}
                      name="nature_relation_parent"
                      style={{ marginTop: "5px", marginRight: "3px" }}
                    />
                    conflictuelle
                  </span>
                  <span>
                    {" "}
                    <br />
                    <h3 style={{ fontSize: "13px", fontWeight: "bolder" }}>
                      Autres :
                    </h3>
                    <Input
                      name="nature_relation_parent"
                      onChange={handleChange}
                      type="text"
                      placeholder="expliquez ici.."
                      style={{
                        border: "none",
                        background: "lightgray",
                      }}
                    />
                  </span>
                </div>
                <div>
                  {" "}
                  <h3 style={{ fontSize: "13px", fontWeight: "bold" }}>
                    {" "}
                    Nature des relations avec la fratrie :
                  </h3>
                  <span>
                    {" "}
                    <input
                      type="radio"
                      onChange={handleCheckbox}
                      value="Bonne"
                      name="nature_relation_fratrie"
                      style={{ marginRight: "3px" }}
                    />
                    Bonne{" "}
                  </span>
                  <span>
                    {" "}
                    <input
                      value="conflictuelle"
                      onChange={handleCheckbox}
                      name="nature_relation_fratrie"
                      type="radio"
                      style={{ marginTop: "5px", marginRight: "3px" }}
                    />{" "}
                    conflictuelle{" "}
                  </span>
                  <div>
                    {" "}
                    <h3 style={{ fontSize: "13px", fontWeight: "bolder" }}>
                      Autres :
                    </h3>
                    <Input
                      name="nature_relation_fratrie"
                      onChange={handleChange}
                      type="text"
                      placeholder="expliquez ici.."
                      style={{
                        border: "none",
                        background: "lightgray",
                      }}
                    />
                  </div>
                </div>
                <div>
                  {" "}
                  <h3 style={{ fontSize: "13px", fontWeight: "bold" }}>
                    {" "}
                    Nature des relations avec le voisinage :
                  </h3>
                  <span>
                    {" "}
                    <input
                      value="Bonne"
                      name="nature_relation_voisinage"
                      onChange={handleCheckbox}
                      type="radio"
                      style={{ marginTop: "5px", marginRight: "3px" }}
                    />
                    Bonne{" "}
                  </span>
                  <span>
                    {" "}
                    <input
                      value="conflictuelle"
                      type="radio"
                      onChange={handleCheckbox}
                      name="nature_relation_voisinage"
                      style={{ marginTop: "5px", marginRight: "3px" }}
                    />{" "}
                    conflictuelle{" "}
                  </span>
                  <span>
                    {" "}
                    <br />
                    <h3 style={{ fontSize: "13px", fontWeight: "bolder" }}>
                      Autres :
                    </h3>
                    <Input
                      onChange={handleChange}
                      name="nature_relation_voisinage"
                      type="text"
                      placeholder="expliquez ici.."
                      style={{
                        border: "none",
                        background: "lightgray",
                      }}
                    />
                  </span>
                </div>
                <div>
                  {" "}
                  <h3 style={{ fontSize: "13px", fontWeight: "bold" }}>
                    {" "}
                    Etat de santé de l’enfant :
                  </h3>
                  <span>
                    {" "}
                    <input
                      value="Bonne"
                      type="radio"
                      onChange={handleCheckbox}
                      name="etat_sante_enfant"
                      style={{ marginTop: "5px", marginRight: "3px" }}
                    />
                    Bonne{" "}
                  </span>
                  <span>
                    {" "}
                    <input
                      value="Stable"
                      name="etat_sante_enfant"
                      onChange={handleCheckbox}
                      type="radio"
                      style={{ marginTop: "5px", marginRight: "3px" }}
                    />
                    Stable
                  </span>
                  <span>
                    {" "}
                    <br />{" "}
                    <h3 style={{ fontSize: "13px", fontWeight: "bolder" }}>
                      Autres :
                    </h3>
                    <Input
                      name="etat_sante_enfant"
                      onChange={handleChange}
                      type="text"
                      placeholder="expliquez ici.."
                      style={{
                        border: "none",
                        background: "lightgray",
                      }}
                    />
                  </span>
                </div>
                <h3 style={{ fontSize: "13px", fontWeight: "bold" }}>
                  Evolution du projet de vie :{" "}
                </h3>
                <div>
                  {" "}
                  <h3 style={{ fontSize: "13px", fontWeight: "bolder" }}>
                    {" "}
                    Apprentissage :
                  </h3>
                  <span>
                    {" "}
                    <input
                      type="radio"
                      name="Evolution_Projet_vie"
                      value="Apprentissage ='Satisfait'"
                      onChange={handleCheckbox}
                      style={{ marginTop: "5px", marginRight: "3px" }}
                    />{" "}
                    Satisfait
                  </span>
                  <span>
                    {" "}
                    <input
                      type="radio"
                      name="Evolution_Projet_vie"
                      value="Apprentissage ='Partiellemt satisfait'"
                      onChange={handleCheckbox}
                      style={{ marginTop: "5px", marginRight: "3px" }}
                    />{" "}
                    Partiellemt satisfait
                  </span>
                  <span>
                    {" "}
                    <input
                      type="radio"
                      name="Evolution_Projet_vie"
                      value="Apprentissage ='Pas satisfait'"
                      onChange={handleCheckbox}
                      style={{ marginTop: "5px", marginRight: "3px" }}
                    />{" "}
                    Pas satisfait
                  </span>
                  <span>
                    {" "}
                    <input
                      type="radio"
                      name="Evolution_Projet_vie"
                      value="Apprentissage ='Besoins de renforcement'"
                      onChange={handleCheckbox}
                      style={{ marginTop: "5px", marginRight: "3px" }}
                    />{" "}
                    Besoins de renforcement
                  </span>
                  <span>
                    {" "}
                    <input
                      type="radio"
                      name="Evolution_Projet_vie"
                      value="Apprentissage ='Abandon'"
                      onChange={handleCheckbox}
                      style={{ marginTop: "5px", marginRight: "3px" }}
                    />{" "}
                    Abandon
                  </span>
                </div>
                <div>
                  <h3 style={{ fontSize: "13px", fontWeight: "bolder" }}>
                    {" "}
                    Formation :
                  </h3>{" "}
                  <span>
                    {" "}
                    <input
                      type="radio"
                      name="Evolution_Projet_vie"
                      value="Formation ='Satisfait'"
                      onChange={handleCheckbox}
                      style={{ marginTop: "5px", marginRight: "3px" }}
                    />{" "}
                    Satisfait
                  </span>
                  <span>
                    {" "}
                    <input
                      type="radio"
                      name="Evolution_Projet_vie"
                      value="Formation ='Partiellemt satisfait'"
                      onChange={handleCheckbox}
                      style={{ marginTop: "5px", marginRight: "3px" }}
                    />{" "}
                    Partiellemt satisfait
                  </span>
                  <span>
                    {" "}
                    <input
                      type="radio"
                      name="Evolution_Projet_vie"
                      value="Formation ='Pas satisfait'"
                      onChange={handleCheckbox}
                      style={{ marginTop: "5px", marginRight: "3px" }}
                    />{" "}
                    Pas satisfait
                  </span>
                  <span>
                    {" "}
                    <input
                      type="radio"
                      name="Evolution_Projet_vie"
                      value="Formation ='Besoins de renforcement'"
                      onChange={handleCheckbox}
                      style={{ marginTop: "5px", marginRight: "3px" }}
                    />
                    Besoins de renforcement
                  </span>
                  <span>
                    {" "}
                    <input
                      type="radio"
                      name="Evolution_Projet_vie"
                      value="Formation ='Abandon'"
                      onChange={handleCheckbox}
                      style={{ marginTop: "5px", marginRight: "3px" }}
                    />{" "}
                    Abandon
                  </span>
                </div>
                <div>
                  {" "}
                  <h3 style={{ fontSize: "13px", fontWeight: "bolder" }}>
                    Scolarisation :
                  </h3>
                  <span>
                    {" "}
                    <input
                      type="radio"
                      name="Evolution_Projet_vie"
                      value="Scolarisation ='Satisfait'"
                      onChange={handleCheckbox}
                      style={{ marginTop: "5px", marginRight: "3px" }}
                    />{" "}
                    Satisfait
                  </span>
                  <span>
                    {" "}
                    <input
                      type="radio"
                      name="Evolution_Projet_vie"
                      value="Scolarisation ='Partiellemt satisfait'"
                      onChange={handleCheckbox}
                      style={{ marginTop: "5px", marginRight: "3px" }}
                    />{" "}
                    Partiellemt satisfait
                  </span>
                  <span>
                    {" "}
                    <input
                      onChange={handleCheckbox}
                      type="radio"
                      name="Evolution_Projet_vie"
                      value="Scolarisation ='Pas satisfait'"
                      style={{ marginTop: "5px", marginRight: "3px" }}
                    />
                    Pas satisfait
                  </span>
                  <span>
                    {" "}
                    <input
                      type="radio"
                      name="Evolution_Projet_vie"
                      value="Scolarisation ='Besoins de renforcement'"
                      onChange={handleCheckbox}
                      style={{ marginTop: "5px", marginRight: "3px" }}
                    />{" "}
                    Besoins de renforcement
                  </span>
                  <span>
                    {" "}
                    <input
                      type="radio"
                      name="Evolution_Projet_vie"
                      value="Scolarisation ='Abandon'"
                      onChange={handleCheckbox}
                      style={{ marginTop: "5px", marginRight: "3px" }}
                    />{" "}
                    Abandon
                  </span>
                </div>
                <div>
                  {" "}
                  <h3 style={{ fontSize: "13px", fontWeight: "bold" }}> AGR</h3>
                  <span>
                    {" "}
                    <input
                      type="radio"
                      value=" AGR en cours"
                      onChange={handleCheckbox}
                      name="Evolution_Projet_vie_AGR"
                      style={{ marginTop: "5px", marginRight: "3px" }}
                    />{" "}
                    AGR en cours
                  </span>
                  <span>
                    {" "}
                    <input
                      type="radio"
                      value="Besoins de renforcement"
                      onChange={handleCheckbox}
                      name="Evolution_Projet_vie_AGR"
                      style={{ marginTop: "5px", marginRight: "3px" }}
                    />{" "}
                    Besoins de renforcement
                  </span>
                  <span>
                    {" "}
                    <input
                      type="radio"
                      value="AGR à l‘arrêt"
                      onChange={handleCheckbox}
                      name="Evolution_Projet_vie_AGR"
                      style={{ marginTop: "5px", marginRight: "3px" }}
                    />{" "}
                    AGR à l‘arrêt
                  </span>
                </div>
              </div>
              <div className="form-container-block">
                <span>
                  {" "}
                  <h3 style={{ fontSize: "13px", fontWeight: "bold" }}>
                    {" "}
                    Décision prise après évaluation de la situation de l’enfant :
                  </h3>
                </span>{" "}
                <span>
                  {" "}
                  <input
                    type="radio"
                    onChange={handleCheckbox}
                    value="Clôturer le suivi de l’enfant"
                    name="decision_apres_evaluation_enfant"
                    style={{ marginTop: "5px", marginRight: "3px" }}
                  />{" "}
                  Clôturer le suivi de l’enfant
                </span>
                <span style={{ margin: "5px 0" }}>
                  {" "}
                  <h3 style={{ fontWeight: "bolder", fontSize: "13px" }}>
                    {" "}
                    Date de prise de décision de clôture :
                  </h3>
                  <Input
                    name="date_prise_decision"
                    onChange={handleChange}
                    type="date"
                    style={{
                      border: "none",
                      background: "lightgray",
                    }}
                  />
                </span>
                <span>
                  {" "}
                  <input
                    value="Poursuite du cas"
                    type="radio"
                    onChange={handleCheckbox}
                    name="decision_apres_evaluation_enfant"
                    style={{ marginTop: "5px", marginRight: "3px" }}
                  />{" "}
                  Poursuite du cas
                </span>
                <span>
                  {" "}
                  <h3 style={{ fontSize: "13px", fontWeight: "bolder" }}>
                    Motifs justificatifs :{" "}
                  </h3>
                  <Textarea
                    name="motif_justificatif"
                    maxRows={4}
                    onChange={handleChange}
                    aria-label="maximum height"
                    placeholder="Description du projet"
                    style={{
                      width: 200,
                      border: "none",
                      background: "lightgray",
                    }}
                  />
                </span>
              </div>

              <div className="sbmit-btn">
                <Button
                  className="mb-4"
                  variant="contained"
                  color="primary"
                  type="submit"
                >
                  Enregistrer
                </Button>
                <Button
                  onClick={() => window.location.reload(true)}
                  style={{ background: "red" }}
                  className="mb-4"
                  type="button"
                >
                  Annuler
                </Button>
              </div>
            </form>
          </div>
        </div>
        <div className="list" hidden={!hiddenDiv}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>

            <BiArrowBack style={{ cursor: "pointer" }} size={"25"} onClick={() => window.location.href = '/admin/enfant'} />
            <Button
              onClick={() => handleAddForm()}
              className="mb-4"
              variant="contained"
              style={{ textTransform: "uppercase" }}
            >
              Créer suivi
            </Button>
          </div>
          <div className="info-real">
            <ul style={{ width: "15%" }}>
              <li>Nom : {listSuivi?.nom}</li>
              <li>Prenom : {listSuivi?.prenom} </li>
            </ul>
          </div>{" "}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "1rem",
            }}
          >
            {" "}
            <h3
              style={{
                fontSize: "20px",
                margin: "0 0 17px",
                fontWeight: "bolder",
              }}
            >
              Tableau suivi(s)
            </h3>
          </div>
          <MUIDataTable
            data={listSuivi?.suivi}
            columns={columns}
            options={options}
          />
        </div>
      </div>
    </div>

  );
};

export default withRouter(Suivi);
