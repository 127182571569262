import React from "react";
import { Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";
import IdentificationValidation from "./ficheValidation/IdentificationValidation";
import PriseEnChargeValidation from "./ficheValidation/PriseEnChargeValidation";
import RetourEnFamilleValidation from "./ficheValidation/RetourEnFamilleValidation";
import ReferencementValidation from "./ficheValidation/ReferencementValidation";
import ProjetDeVieValidation from "./ficheValidation/ProjetDeVieValidation";
import SuiviValidation from "./ficheValidation/SuiviValidation";
import EnfantValidation from "./ficheValidation/EnfantValidation";
const ValidationForms = () => {
  return (
    <div className="content">
      <Tabs>
        <TabList>
          <Tab>Enfant</Tab>
          <Tab>Identification</Tab>
          <Tab>Prise en charge d'urgence</Tab>
          <Tab>Retour en famille</Tab>
          <Tab>Référencement</Tab>
          <Tab>Projet de vie</Tab>
          <Tab>Suivi</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <EnfantValidation />
          </TabPanel>
          <TabPanel>
            <IdentificationValidation />
          </TabPanel>
          <TabPanel>
            <PriseEnChargeValidation />
          </TabPanel>

          <TabPanel>
            <RetourEnFamilleValidation />
          </TabPanel>
          <TabPanel>
            <ReferencementValidation />
          </TabPanel>
          <TabPanel>
            <ProjetDeVieValidation />
          </TabPanel>
          <TabPanel>
            <SuiviValidation />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </div>
  );
};

export default ValidationForms;
