import React, { useEffect } from "react";
import { NavLink, Link, useLocation } from "react-router-dom";
// nodejs library to set properties for components
import { PropTypes } from "prop-types";
import simpleUserRoutes from "simpleUserRoutes";
import responsableRoute from "responsableRoute";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";

// reactstrap components
import { Nav } from "reactstrap";
import { BackgroundColorContext } from "contexts/BackgroundColorContext";

var ps;

function Sidebar(props) {
  const location = useLocation();
  const sidebarRef = React.useRef(null);
  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    return location.pathname === routeName ? "active" : "";
  };

  useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(sidebarRef.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
    }
    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
    };
  });
  const userData = JSON.parse(localStorage.getItem("data"));
  console.log(userData.roles[0]);

  const { routes, rtlActive, logo } = props;
  let logoImg = null;
  let logoText = null;
  if (logo !== undefined) {
    if (logo.outterLink !== undefined) {
      logoImg = (
        <a
          href={logo.outterLink}
          className="simple-text logo-mini"
          target="_blank"
          rel="noreferrer"
          onClick={props.toggleSidebar}
        >
          <div className="logo-img">
            <img src={logo.imgSrc} alt="react-logo" />
          </div>
        </a>
      );
      logoText = (
        <a
          href={logo.outterLink}
          rel="noreferrer"
          className="simple-text logo-normal"
          target="_blank"
          onClick={props.toggleSidebar}
        >
          {logo.text}
        </a>
      );
    } else {
      logoImg = (
        <Link
          to={logo.innerLink}
          className="simple-text logo-mini"
          onClick={props.toggleSidebar}
        >
          <div className="logo-img">
            <img src={logo.imgSrc} alt="react-logo" />
          </div>
        </Link>
      );
      logoText = (
        <Link
          to={logo.innerLink}
          className="simple-text logo-normal"
          onClick={props.toggleSidebar}
        >
          {logo.text}
        </Link>
      );
    }
  }
  return (
    <BackgroundColorContext.Consumer>
      {({ color }) => (
        <div className="sidebar" data={color}>
          <div
            style={{ background: "#00d6b4" }}
            className="sidebar-wrapper"
            ref={sidebarRef}
          >
            {logoImg !== null || logoText !== null ? (
              <div className="logo" style={{ textAlign: "center" }}>
                {"Enda Jeunesse Action"}
              </div>
            ) : null}
            <Nav>
              {userData.roles[0] === "ROLE_ADMIN"
                ? routes.map((prop, key) => {
                    if (prop.redirect) return null;
                    return (
                      <li
                        className={
                          activeRoute(prop.path) +
                          (prop.pro ? " active-pro" : "")
                        }
                        key={key}
                      >
                        <NavLink
                          to={prop.layout + prop.path}
                          className="nav-link"
                          activeClassName="active"
                          onClick={props.toggleSidebar}
                        >
                          <i className={prop.icon} />
                          <p style={{ color: "#fff", fontWeight: "600" }}>
                            {rtlActive ? prop.rtlName : prop.name}
                          </p>
                        </NavLink>
                      </li>
                    );
                  })
                : userData.roles[0] === "ROLE_RESPONSABLE"
                ? responsableRoute.map((prop, key) => {
                    if (prop.redirect) return null;
                    return (
                      <li
                        className={
                          activeRoute(prop.path) +
                          (prop.pro ? " active-pro" : "")
                        }
                        key={key}
                      >
                        <NavLink
                          to={prop.layout + prop.path}
                          className="nav-link"
                          activeClassName="active"
                          onClick={props.toggleSidebar}
                        >
                          <i className={prop.icon} />
                          <p style={{ color: "#fff", fontWeight: "600" }}>
                            {rtlActive ? prop.rtlName : prop.name}
                          </p>
                        </NavLink>
                      </li>
                    );
                  })
                : simpleUserRoutes.map((prop, key) => {
                    if (prop.redirect) return null;
                    return (
                      <li
                        className={
                          activeRoute(prop.path) +
                          (prop.pro ? " active-pro" : "")
                        }
                        key={key}
                      >
                        <NavLink
                          to={prop.layout + prop.path}
                          className="nav-link"
                          activeClassName="active"
                          onClick={props.toggleSidebar}
                        >
                          <i className={prop.icon} />
                          <p style={{ color: "#fff", fontWeight: "600" }}>
                            {rtlActive ? prop.rtlName : prop.name}
                          </p>
                        </NavLink>
                      </li>
                    );
                  })}

              {/* <li className="active-pro">
                <ReactstrapNavLink href="https://www.creative-tim.com/product/black-dashboard-pro-react?ref=bdr-user-archive-sidebar-upgrade-pro">
                  <i className="tim-icons icon-spaceship" />
                  <p>Upgrade to PRO</p>
                </ReactstrapNavLink>
              </li> */}
            </Nav>
          </div>
        </div>
      )}
    </BackgroundColorContext.Consumer>
  );
}

Sidebar.defaultProps = {
  rtlActive: false,
  routes: [{}],
};

Sidebar.propTypes = {
  // if true, then instead of the routes[i].name, routes[i].rtlName will be rendered
  // insde the links of this component
  rtlActive: PropTypes.bool,
  routes: PropTypes.arrayOf(PropTypes.object),
  logo: PropTypes.shape({
    // innerLink is for links that will direct the user within the app
    // it will be rendered as <Link to="...">...</Link> tag
    innerLink: PropTypes.string,
    // outterLink is for links that will direct the user outside the app
    // it will be rendered as simple <a href="...">...</a> tag
    outterLink: PropTypes.string,
    // the text of the logo
    text: PropTypes.node,
    // the image src of the logo
    imgSrc: PropTypes.string,
  }),
};

export default Sidebar;
