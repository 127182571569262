import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import AdminLayout from "layouts/Admin/Admin.js";
import "assets/scss/black-dashboard-react.scss";
import "assets/demo/demo.css";
import "assets/css/nucleo-icons.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import ThemeContextWrapper from "./components/ThemeWrapper/ThemeWrapper";
import BackgroundColorWrapper from "./components/BackgroundColorWrapper/BackgroundColorWrapper";
import { ChakraProvider } from "@chakra-ui/react";
import Login from "views/Login";
import Identification from "views/actionSurEnfant/Identification";
import PriseEnCharge from "views/actionSurEnfant/priseEnCharge";
import RetourEnFamille from "views/actionSurEnfant/retourEnFamille";
import Referencement from "views/actionSurEnfant/referencement";
import ProjetDeVie from "views/actionSurEnfant/projetDeVie";
import Suivi from "views/actionSurEnfant/suivi";

ReactDOM.render(
  <ThemeContextWrapper>
    <BackgroundColorWrapper>
      <ChakraProvider>
        <BrowserRouter>
          <Switch>
            <Route exact path="/login" component={Login} />
            <Route
              path="/admin/enfant/:id/identification"
              component={Identification}
            />
            <Route
              path="/admin/enfant/:id/prise-en-charge"
              component={PriseEnCharge}
            />
            <Route
              path="/admin/enfant/:id/retour-en-famille"
              component={RetourEnFamille}
            />
            <Route
              path="/admin/enfant/:id/referencement"
              component={Referencement}
            />
            <Route
              path="/admin/enfant/:id/projet-de-vie"
              component={ProjetDeVie}
            />
            <Route path="/admin/enfant/:id/suivi" component={Suivi} />
            <Route
              path="/admin"
              render={(props) => <AdminLayout {...props} />}
            />

            <Redirect from="/" to="/login" />
          </Switch>
        </BrowserRouter>
      </ChakraProvider>
    </BackgroundColorWrapper>
  </ThemeContextWrapper>,
  document.getElementById("root")
);
