import React, { useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import api from "services/api";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from "@chakra-ui/react";
import { Col, Row } from "reactstrap";
import RetourEnFamilleValidationUpdate from "views/updateFicheValidation/RetourEnFamilleValidationUpdate";
//import { AiOutlineEye } from "react-icons/ai";
import Notiflix from "notiflix";
const RetourEnFamilleValidation = () => {
  const [dataToVerify, setDataToVerify] = useState();
  const [ficheId, setFicheId] = useState();
  const [idRow, setIdRow] = useState();
  const openResume = useDisclosure();

  //recuperation du role du user connecté
  const loggedUserRole = JSON.parse(localStorage.getItem("data")).roles[0];
  const loggedUserBureau = JSON.parse(localStorage.getItem("data")).bureau;
  // const openUpdate = useDisclosure();

  const confirmDelete = (id) => {
    Notiflix.Confirm.show(
      " ",
      "Etes-vous sûr de vouloir supprimer ?",
      "Confirmer",
      "Annuler",
      () => {
        api
          .deleteRetourFamille(id)
          .then((response) => {
            console.log(response);
            Notiflix.Notify.success("supprimé !!");
            window.location.reload(true);
          })
          .catch((error) => console.log(error));
      }
    );
  };

  const columns = [
    { label: "Code", name: "id" },
    { label: "Prenom", name: "enfant.prenom" },
    { label: "Nom", name: "enfant.nom" },
    { label: "Surnom", name: "enfant.surnom" },
    { label: "Sexe", name: "enfant.sexe", options: { display: false } },
    { label: "Age", name: "enfant.age", options: { display: false } },
    { label: "Bureau ", name: "bureau" },
    {
      label: "Gestionnaire de cas",
      name: "gestionnaire_cas",
    },
    { label: "Telephone", name: "tel" },
    { label: "Email", name: "email", options: { display: false } },

    {
      label: "Recherche et mediation familiale realise",
      name: "recherche_famille_realise_par",
    },
    {
      label: "Date de la recherche famille ",
      name: "recherche_famille_date",
      options: {
        display: false,
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          const index = tableMeta.rowData.findIndex((el) => el?.length === 24);
          return <span>{tableMeta.rowData[index]?.substring(0, 10)} </span>;
        },
      },
    },
    {
      label: "Structure ayant fait la recherche famille ",
      name: "recherche_famille_structure",
      options: { display: false },
    },
    {
      label: "Departement ou  la recherche a ete faite",
      name: "departement",
      options: { display: false },
    },
    {
      label: "Pays ou la recherche a ete faite",
      name: "pays",
      options: { display: false },
    },
    {
      label: "ville ou  la recherche a ete faite",
      name: "ville",
      options: { display: false },
    },
    {
      label: "village ou  la recherche a ete faite",
      name: "village_quartier",
      options: { display: false },
    },
    {
      label: "Region ou la recherche a ete faite ",
      name: "region",
      options: { display: false },
    },

    {
      label: "premiere personne contactee",
      name: "personne_contactee1",
      options: { display: false },
    },
    {
      label: "Numero premiere personne contactee",
      name: "telephone1",
      options: { display: false },
    },
    {
      label: "Adresse de la premiere personne",
      name: "adresse1",
      options: { display: false },
    },
    {
      label: "Lien parente avec la premiere personne ",
      name: "lien_parente_enfant1",
      options: { display: false },
    },
    {
      label: "deuxieme personne contactee",
      name: "personne_contactee2",
      options: { display: false },
    },
    {
      label: "Numero deuxieme personne contactee",
      name: "telephone2",
      options: { display: false },
    },
    {
      label: "Adresse de la deuxieme personne",
      name: "adresse2",
      options: { display: false },
    },
    {
      label: "Lien parente avec la deuxieme personne ",
      name: "lien_parente_enfant2",
      options: { display: false },
    },

    {
      label: "Troisieme  personne contactee",
      name: "personne_contactee3",
      options: { display: false },
    },
    {
      label: "Numero Troisieme personne contactee",
      name: "telephone3",
      options: { display: false },
    },
    {
      label: "Adresse de la Troisieme personne",
      name: "adresse3",
      options: { display: false },
    },
    {
      label: "Lien parente avec la Troisieme personne ",
      name: "lien_parente_enfant3",
      options: { display: false },
    },

    {
      label: "Existance de relation entre enfant et famille",
      name: "relation_enfant_famille",
      options: { display: false },
    },
    {
      label: "Pourquoi non relation",
      name: "relation_enfant_famille_pourquoi",
      options: { display: false },
    },

    {
      label: "Ressources de la famille de enfant",
      name: "ressources_famille_enfant",
      options: { display: false },
    },
    {
      label: "Est ce que les parents acceptent de reprendre l'enfant",
      name: "reprendre_enfant",
      options: { display: false },
    },
    {
      label: "Pourquoi non reprise",
      name: "reprendre_enfant_pourquoi",
      options: { display: false },
    },
    {
      label: "Conclusion de l'évaluation",
      name: "conclusion_evaluation",
      options: { display: false },
    },
    {
      label: "Retour en famille realiser par",
      name: "retour_realiser_par",
      options: { display: false },
    },
    {
      label: "Date retour en famille",
      name: "retour_famille_date",
      options: {
        display: false,
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          const index = tableMeta.rowData.findIndex((el) => el?.length === 24);
          return <span>{tableMeta.rowData[index]?.substring(0, 10)} </span>;
        },
      },
    },
    {
      label: "Lieu retour en famille",
      name: "retour_lieu",
      options: { display: false },
    },

    {
      label: "Nom de la personne ayant reçu enfant",
      name: "personne_ayant_recu_enfant",
      options: { display: false },
    },
    {
      label: "Numero personne ayant recu enfant",
      name: "telephone_personne_ayant_recu_enfant",
      options: { display: false },
    },
    {
      label: "Cin personne ayant reçu l'enfant",
      name: "cin_personne_ayant_recu_enfant",
      options: { display: false },
    },
    {
      label: "Adresse  personne ayant reçu l'enfant",
      name: "adresse_personne_ayant_recu_enfant",
      options: { display: false },
    },
    {
      label: "Type de reintégration",
      name: "retour_reintegration",
      options: { display: false },
    },
    {
      label: "Important à signaler",
      name: "retour_a_signaler",
      options: { display: false },
    },
    {
      label: "Specification de la ressource",
      name: "ressources_famille_enfant_specifiez",
      options: { display: false },
    },

    {
      label: "Structure de retour",
      name: "retour_structure",
      options: { display: false },
    },
    {
      name: "statut",
      label: "Statut",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const index = tableMeta.rowData.findIndex(
            (el) => el === "VALIDER" || el === "EN ATTENTE"
          );
          if (tableMeta.rowData[index] === "VALIDER") {
            return (
              <span style={{ color: "green" }}>
                {tableMeta.rowData[index]}{" "}
              </span>
            );
          }
          if (tableMeta.rowData[index] === "EN ATTENTE") {
            return (
              <span style={{ color: "red" }}>{tableMeta.rowData[index]} </span>
            );
          }
        },
      },
    },
    {
      name: "Action",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          const _statut = tableMeta.rowData.find(
            (el) => el === loggedUserBureau
          );
          return (
            <>
              <button
                disabled={
                  (loggedUserRole === "ROLE_RESPONSABLE" &&
                    _statut === loggedUserBureau) ||
                  loggedUserRole === "ROLE_ADMIN"
                    ? false
                    : true
                }
                onClick={() => {
                  openResume.onOpen();
                  setIdRow(tableMeta.rowIndex);
                  setFicheId(tableMeta.rowData[0]);
                }}
                style={{
                  background: "orange",
                  color: "#fff",
                  fontSize: "11px",
                  padding: "3px",
                  borderRadius: "3px",
                }}
              >
                Verifier
              </button>
              <button
                disabled={true ? loggedUserRole !== "ROLE_ADMIN" : false}
                onClick={() => confirmDelete(tableMeta.rowData[0])}
                style={{
                  background: "red",
                  color: "#fff",
                  fontSize: "11px",
                  padding: " 3px",
                  borderRadius: "3px",
                  marginLeft: "5px",
                }}
              >
                Supprimer
              </button>
            </>
          );
        },
      },
    },
  ];
  const options = {
    filterType: "textField",
    pagination: false,
    print: false,
    enableNestedDataAccess: ".",
    download: false,
  };

  useEffect(() => {
    api
      .getRetourPendingStatus()
      .then((response) => {
        if (response) {
          setDataToVerify(response.data);
          console.log(response);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <div className="content">
      <Modal
        onClose={openResume.onClose}
        size={"full"}
        isOpen={openResume.isOpen}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody>
            <RetourEnFamilleValidationUpdate
              ficheId={ficheId}
              idRow={idRow}
              dataToVerify={dataToVerify}
              onclose={openResume.onClose}
            />
          </ModalBody>
        </ModalContent>
      </Modal>{" "}
      <Row style={{ background: "#fff", padding: "20px" }}>
        <Col md="12">
          <div style={{ marginBottom: "3rem " }}>
            {" "}
            <h3
              style={{
                fontSize: "20px",
                margin: "0 0 17px",
                fontWeight: "bolder",
              }}
            >
              Tableau retour en famille en attente
            </h3>
          </div>
          <MUIDataTable
            data={dataToVerify}
            columns={columns}
            options={options}
          />
        </Col>
      </Row>
    </div>
  );
};

export default RetourEnFamilleValidation;
