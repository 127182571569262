import React, { useState, useEffect } from 'react';
import './UserList.scss';
import MUIDataTable from 'mui-datatables';
import { Card, CardHeader, CardBody, Row, Col, Button } from 'reactstrap';
import Notiflix from 'notiflix';

import { RiAddLine } from 'react-icons/ri';
import api from '../services/api';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Input,
  FormControl,
  Select,
} from '@chakra-ui/react';

const UserList = () => {
  const updateModal = useDisclosure();
  const createModal = useDisclosure();
  const [inputValues, setInputValues] = useState();
  const [editInputs, setEditInputs] = useState();
  const [userId, setUserId] = useState();
  const [userList, setUserList] = useState();
  const [roles, setRoles] = useState();
  const [editUserData, setEditUserData] = useState();
  const initialRef = React.useRef();
  const finalRef = React.useRef();

  const handleSubmit = (event) => {
    event.preventDefault();
    const userRole = [];
    userRole.push(roles);
    api
      .createUser({ userRole, inputValues })
      .then((response) => {
        if (response) {
          Notiflix.Notify.success('Utilisateur crée avec succès ');
          window.location.reload(true);
        }
      })
      .catch((error) => {
        if (error) {
          Notiflix.Notify.warning('Veuillez verifier les données saisies !');
        }
      });
  };
  const handleSubmitEditUser = (event) => {
    event.preventDefault();
    api
      .updateUserData({ userId, editInputs })
      .then((response) => {
        if (response) {
          console.log(response);
          Notiflix.Notify.success('Données utilisateur modifier');
          window.location.reload(true);
        }
      })
      .catch((error) => {
        if (error) {
          console.log(error);
        }
      });
  };

  const handleChangeEditUser = (event) => {
    setEditInputs({
      [event.target.name]: event.target.value,
    });
    setEditUserData('');
  };
  const handleRole = (event) => {
    setRoles((event.target.name = event.target.value));
  };

  const handleState = (body) => {
    const { id, etat } = body;
    api
      .updateUserEtat({ id, etat })
      .then((response) => {
        if (response) {
          Notiflix.Notify.success('Etat mis à jour ');
          window.location.reload(true);
        }
      })
      .catch((error) => console.log(error));
  };

  const handleChange = (event) => {
    setInputValues({
      ...inputValues,
      [event.target.name]: event.target.value,
    });
  };

  //mui dataTables

  const columns = [
    {
      name: 'id',
      label: 'Code',
    },
    {
      name: 'nom',
      label: "Nom d'utilisateur",
    },
    {
      name: 'username',
      label: 'Prenom',
    },
    {
      name: 'email',
      label: 'Email',
    },
    {
      name: 'tel',
      label: 'Téléphone',
    },
    {
      name: 'bureau',
      label: 'Bureau',
    },
    {
      name: 'etat',
      label: 'Etat',
      options: {
        display: false,
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          if (tableMeta.rowData[6] === 'activated') {
            return <span style={{ color: 'green' }}>Activer </span>;
          }
          if (tableMeta.rowData[6] === 'deactivated') {
            return <span style={{ color: 'red' }}>Désactiver </span>;
          }
        },
      },
    },
    {
      name: 'Action',
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <button
                onClick={() =>
                  handleState({
                    etat: tableMeta.rowData[6],
                    id: tableMeta.rowData[0],
                  })
                }
                style={{
                  color: '#fff',
                  fontSize: '11px',
                  padding: '3px',
                  borderRadius: '3px',
                  width: '70px',
                }}
              >
                {tableMeta.rowData[6] === 'activated' ? (
                  <span
                    style={{
                      background: 'orange',
                      color: '#fff',
                      fontSize: '11px',
                      padding: '3px',
                      borderRadius: '3px',
                    }}
                  >
                    Désactiver{' '}
                  </span>
                ) : tableMeta.rowData[6] === 'deactivated' ? (
                  <span
                    style={{
                      background: 'green',
                      color: '#fff',
                      fontSize: '11px',
                      padding: '3px',
                      borderRadius: '3px',
                    }}
                  >
                    Activer{' '}
                  </span>
                ) : (
                  ''
                )}
              </button>
              <button
                onClick={() => {
                  updateModal.onOpen();
                  findUserById(tableMeta.rowData[0]);
                  setUserId(tableMeta.rowData[0]);
                }}
                style={{
                  background: 'gray',
                  color: '#fff',
                  fontSize: '11px',
                  padding: '3px',
                  borderRadius: '3px',
                  marginLeft: '5px',
                }}
              >
                Modifier
              </button>
              <button
                onClick={() => {
                  confirmDelete(tableMeta.rowData[0]);
                }}
                style={{
                  background: 'red',
                  color: '#fff',
                  fontSize: '11px',
                  padding: '3px',
                  borderRadius: '3px',
                  marginLeft: '10px',
                }}
              >
                Supprimer
              </button>
            </>
          );
        },
      },
    },
  ];

  const options = {
    filterType: 'textField',
    pagination: false,
    print: false,
    download: false,
  };

  const findUserById = (userId) => {
    api
      .getUserById(userId)
      .then((response) => {
        if (response) setEditUserData(response?.data);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    api
      .getAllUsers()
      .then((response) => {
        if (response) {
          setUserList(response.data);
        }
      })
      .catch((error) => console.log(error));
  }, []);

  const handleDeleteUser = (id) => {
    api
      .deleteUser(id)
      .then((response) => {
        if (response) {
          Notiflix.Notify.success('utilisateur supprimé  avec succès ');
          setTimeout(() => {
            window.location.reload(true);
          }, 1000);
        }
      })
      .catch((error) => console.log(error));
  };
  const confirmDelete = (id) => {
    Notiflix.Confirm.show(
      ' ',
      'Etes-vous sûr de vouloir supprimer ?',
      'Confirmer',
      'Annuler',
      () => {
        handleDeleteUser(id);
      }
    );
  };

  return (
    <div className="content">
      {/* create modal */}
      <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={createModal.isOpen}
        onClose={createModal.onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <form onSubmit={handleSubmit}>
            <ModalBody pb={6} mt={10}>
              <FormControl>
                <Input
                  name="nom"
                  type="text"
                  ref={initialRef}
                  placeholder="Nom"
                  onChange={handleChange}
                />
              </FormControl>

              <FormControl mt={4}>
                <Input
                  type="text"
                  name="username"
                  placeholder="Username"
                  onChange={handleChange}
                />
              </FormControl>
              <FormControl mt={4}>
                <Input
                  name="email"
                  type="email"
                  placeholder="Email"
                  onChange={handleChange}
                />
              </FormControl>
              <FormControl mt={4}>
                <Input
                  type="password"
                  name="password"
                  placeholder="Mot de passe"
                  onChange={handleChange}
                />
              </FormControl>
              <FormControl mt={4}>
                <Input
                  type="tel"
                  name="tel"
                  placeholder="Tel"
                  onChange={handleChange}
                />
              </FormControl>
              <FormControl mt={4}>
                <Select
                  name="bureau"
                  placeholder="Bureau"
                  onChange={handleChange}
                >
                  <option value="Enda Dakar">Enda Dakar</option>
                  <option value="Enda Kolda">Enda Kolda</option>
                  <option value="Enda Sedhiou">Enda Sedhiou</option>
                  <option value="Enda Velingara">Enda Velingara</option>
                  <option value="Enda Tamba">Enda Tambacounda</option>
                  <option value="Enda Sarea">Enda Saraya</option>
                  <option value="Enda Ziguinchor">Enda Ziguinchor</option>
                  <option value="Enda Thies">Enda Thies</option>
                  <option value="Enda Thies">Enda Koalack</option>
                  <option value="Enda Thies">Enda Louga</option>
                  <option value="Enda Thies">Enda Diourbel</option>
                </Select>
              </FormControl>
              <FormControl mt={4}>
                <Select name="roles" placeholder="roles" onChange={handleRole}>
                  <option value="user">User</option>
                  <option value="admin">Admin</option>
                  <option value="responsable">Responsable</option>
                </Select>
              </FormControl>
            </ModalBody>

            <ModalFooter>
              <Button type="submit" style={{ background: '#16b84e' }} mr={3}>
                Ajouter
              </Button>
              <Button
                type="button"
                onClick={createModal.onClose}
                style={{ background: 'red   ' }}
              >
                Annuler
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
      {/* upadate modal */}
      <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={updateModal.isOpen}
        onClose={updateModal.onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <form onSubmit={handleSubmitEditUser}>
            <ModalBody pb={6} mt={10}>
              <FormControl>
                <Input
                  name="nom"
                  placeholder="Nom et prenom"
                  type="text"
                  ref={initialRef}
                  value={editUserData?.nom}
                  onChange={handleChangeEditUser}
                />
              </FormControl>

              <FormControl mt={4}>
                <Input
                  type="text"
                  name="username"
                  placeholder="Nom d'utilisateur"
                  value={editUserData?.username}
                  onChange={handleChangeEditUser}
                />
              </FormControl>
              <FormControl mt={4}>
                <Input
                  name="email"
                  placeholder="Email"
                  type="email"
                  value={editUserData?.email}
                  onChange={handleChangeEditUser}
                />
              </FormControl>
              <FormControl mt={4}>
                <Input
                  type="text"
                  name="password"
                  placeholder="Mot de passe"
                  value={editUserData?.password}
                  onChange={handleChangeEditUser}
                />
              </FormControl>
              <FormControl mt={4}>
                <Input
                  type="tel"
                  placeholder="Téléphone"
                  name="tel"
                  value={editUserData?.teleditUserData}
                  onChange={handleChangeEditUser}
                />
              </FormControl>
              <FormControl mt={4}>
                <Select
                  name="bureau"
                  placeholder="Bureau"
                  onChange={handleChangeEditUser}
                >
                  <option>{editUserData?.bureau} </option>
                  <option value="Enda Dakar">Enda Dakar</option>
                  <option value="Enda Kolda">Enda Kolda</option>
                  <option value="Enda Sedhiou">Enda Sedhiou</option>
                  <option value="Enda Velingara">Enda Velingara</option>
                  <option value="Enda Tamba">Enda Tambacounda</option>
                  <option value="Enda Sarea">Enda Saraya</option>
                  <option value="Enda Ziguinchor">Enda Ziguinchor</option>
                  <option value="Enda Thies">Enda Thies</option>
                  <option value="Enda Thies">Enda Koalack</option>
                  <option value="Enda Thies">Enda Louga</option>
                  <option value="Enda Thies">Enda Diourbel</option>
                </Select>
              </FormControl>
              <FormControl mt={4}>
                <Select name="roles" placeholder="roles" onChange={handleRole}>
                  <option value="user">User</option>
                  <option value="admin">Admin</option>
                  <option value="responsable">Responsable</option>
                </Select>
              </FormControl>
            </ModalBody>

            <ModalFooter>
              <Button type="submit" style={{ background: '#16b84e' }} mr={3}>
                Modifier
              </Button>
              <Button
                type="button"
                onClick={updateModal.onClose}
                style={{ background: 'red   ' }}
              >
                Annuler
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
      <Row>
        <Col md="12">
          <Card>
            <CardHeader>
              {/* <h5 className="title">Listes des utilisateurs</h5> */}

              <Button
                style={{
                  float: 'right',
                  background: '#16b84e',
                  display: 'flex',
                }}
                onClick={createModal.onOpen}
              >
                <RiAddLine
                  style={{
                    position: 'relative',
                    top: '4px',
                    marginRight: '4px',
                  }}
                />{' '}
                <span style={{ textTransform: 'uppercase' }}>
                  {' '}
                  Ajouter un utilisateur
                </span>
              </Button>
            </CardHeader>
            <CardBody className="all-icons">
              <MUIDataTable
                data={userList}
                columns={columns}
                options={options}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default UserList;
