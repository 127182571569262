import React, { useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import api from "services/api";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from "@chakra-ui/react";

import { Col, Row } from "reactstrap";
import ProjetDeVieValidationUpdate from "views/updateFicheValidation/ProjetDeVieValidationUpdate";
//import { AiOutlineEye } from "react-icons/ai";
import Notiflix from "notiflix";
const ProjetDeVieValidation = () => {
  const openResume = useDisclosure();
  //const openUpdate = useDisclosure();
  const [ficheId, setFicheId] = useState();
  const [dataToVerify, setDataToVerify] = useState();
  const [idRow, setIdRow] = useState();

  //recuperation du role du user connecté
  const loggedUserRole = JSON.parse(localStorage.getItem("data")).roles[0];
  const loggedUserBureau = JSON.parse(localStorage.getItem("data")).bureau;

  const confirmDelete = (id) => {
    Notiflix.Confirm.show(
      " ",
      "Etes-vous sûr de vouloir supprimer ?",
      "Confirmer",
      "Annuler",
      () => {
        api
          .deleteProjet(id)
          .then((response) => {
            console.log(response);
            Notiflix.Notify.success("supprimé !!");
            window.location.reload(true);
          })
          .catch((error) => console.log(error));
      }
    );
  };
  const columns = [
    { label: "Code", name: "id" },
    { label: "Prenom", name: "enfant.prenom" },
    { label: "Nom", name: "enfant.nom" },
    { label: "Surnom", name: "enfant.surnom" },
    { label: "Bureau ", name: "bureau" },
    {
      label: "Gestionnaire de cas",
      name: "gestionnaire_cas",
      options: { display: false },
    },
    { label: "Telephone", name: "tel", options: { display: false } },
    { label: "Email", name: "email", options: { display: false } },
    { label: "Projet de vie", name: "projet" },
    { label: "Descripton", name: "descripton" },
    { label: "Cout", name: "cout" },
    { label: "Ressources", name: "ressources" },
    {
      name: "statut",
      label: "Statut",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const index = tableMeta.rowData.findIndex(
            (el) => el === "VALIDER" || el === "EN ATTENTE"
          );
          if (tableMeta.rowData[index] === "VALIDER") {
            return (
              <span style={{ color: "green" }}>
                {tableMeta.rowData[index]}{" "}
              </span>
            );
          }
          if (tableMeta.rowData[index] === "EN ATTENTE") {
            return (
              <span style={{ color: "red" }}>{tableMeta.rowData[index]} </span>
            );
          }
        },
      },
    },
    {
      name: "Action",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          const _statut = tableMeta.rowData.find(
            (el) => el === loggedUserBureau
          );

          return (
            <>
              <button
                disabled={
                  (loggedUserRole === "ROLE_RESPONSABLE" &&
                    _statut === loggedUserBureau) ||
                  loggedUserRole === "ROLE_ADMIN"
                    ? false
                    : true
                }
                onClick={() => {
                  openResume.onOpen();
                  setFicheId(tableMeta.rowData[0]);
                  setIdRow(tableMeta.rowIndex);
                }}
                style={{
                  background: "orange",
                  color: "#fff",
                  fontSize: "11px",
                  padding: "3px",
                  borderRadius: "3px",
                }}
              >
                Verifier
              </button>
              <button
                disabled={true ? loggedUserRole !== "ROLE_ADMIN" : false}
                onClick={() => confirmDelete(tableMeta.rowData[0])}
                style={{
                  background: "red",
                  color: "#fff",
                  fontSize: "11px",
                  padding: " 3px",
                  borderRadius: "3px",
                  marginLeft: "5px",
                }}
              >
                Supprimer
              </button>
            </>
          );
        },
      },
    },
  ];

  const options = {
    filterType: "textField",
    pagination: false,
    print: false,
    enableNestedDataAccess: ".",
    download: false,
  };

  useEffect(() => {
    api
      .getProjetPendingStatus()
      .then((response) => {
        if (response) {
          setDataToVerify(response.data);
          console.log(response);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <div className="content">
      <Modal
        onClose={openResume.onClose}
        size={"xl"}
        isOpen={openResume.isOpen}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody p={10}>
            <ProjetDeVieValidationUpdate
              ficheId={ficheId}
              onclose={openResume.onClose}
              dataToVerify={dataToVerify}
              idRow={idRow}
            />
          </ModalBody>
        </ModalContent>
      </Modal>{" "}
      <Row style={{ background: "#fff", padding: "20px" }}>
        <Col md="12">
          <div style={{ marginBottom: "3rem " }}>
            {" "}
            <h3
              style={{
                fontSize: "20px",
                margin: "0 0 17px",
                fontWeight: "bolder",
              }}
            >
              Tableau projet de vie en attente
            </h3>
          </div>
          <MUIDataTable
            data={dataToVerify}
            columns={columns}
            options={options}
          />
        </Col>
      </Row>
    </div>
  );
};
export default ProjetDeVieValidation;
